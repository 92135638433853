import BusinessApprenticeDiploma from '@core/models/businessApprenticeDiploma';
/* eslint-disable-next-line import/no-cycle */
import EducationPerson from '@core/models/educationPerson';
import EducationProgramme from '@core/models/educationProgramme';
import EducationSifaSituation from '@core/models/educationSifaSituation';
import RestModel from '@core/models/_rest';
import Upload from '@core/models/upload';

export default class EducationStudy extends RestModel {

    static get businessApprenticeCommercialAgreementRemainingBalanceModeNames() {
        return {
            none: 'Aucun reste à charge',
            full: 'Reste à charge complet',
            fixed: 'Reste à charge forfaitaire',
            cap: 'Montant plancher de recouvrement',
            fullpercent: 'Pourcentage de reste à charge complet',
            fundingpercent: 'Pourcentage du NPEC',
        };
    }

    static get businessApprenticeCommercialAgreementRemainingBalanceValueIcons() {
        return {
            none: undefined,
            full: undefined,
            fixed: 'euro-sign',
            cap: 'euro-sign',
            fullpercent: 'percent',
            fundingpercent: 'percent',
        };
    }

    static get businessApprenticeCommercialAgreementRemainingBalanceValueNames() {
        return {
            none: undefined,
            full: undefined,
            fixed: 'Reste à charge forfaitaire',
            cap: 'Montant plancher de recouvrement',
            fullpercent: 'Pourcentage de reste à charge complet',
            fundingpercent: 'Pourcentage du NPEC',
        };
    }

    static get className() {
        return 'Modules\\Education\\Models\\EducationStudy';
    }

    get editableAttribues() {
        if (this.$.status === 'created') {
            return [
                'education_person_administrative_1_id',
                'education_person_administrative_2_id',
                'education_person_administrative_3_id',
                'education_person_disputing_remaining_balance_1_id',
                'education_person_disputing_remaining_balance_2_id',
                'education_person_negotiating_remaining_balance_1_id',
                'education_person_negotiating_remaining_balance_2_id',
                'education_person_pedagogy_1_id',
                'education_person_pedagogy_2_id',
                'education_person_pedagogy_3_id',
                'education_person_representative_id',
                'education_person_signature_id',
                'education_person_signing_id',
                'apprenticeCalendar',
                'business_apprentice_commercial_price',
                'business_apprentice_commercial_agreement_remaining_balance_min',
                'business_apprentice_commercial_agreement_remaining_balance_mode',
                'business_apprentice_commercial_agreement_remaining_balance_value',
                'hours_examination',
                'hours_total',
                'instructionalModel',
                'max_students',
                'min_students',
                'name',
                'start',
                'end',
            ];
        }

        return [
            'business_apprentice_diploma_id',
            'education_person_administrative_1_id',
            'education_person_administrative_2_id',
            'education_person_administrative_3_id',
            'education_person_disputing_remaining_balance_1_id',
            'education_person_disputing_remaining_balance_2_id',
            'education_person_negotiating_remaining_balance_1_id',
            'education_person_negotiating_remaining_balance_2_id',
            'education_person_pedagogy_1_id',
            'education_person_pedagogy_2_id',
            'education_person_pedagogy_3_id',
            'education_person_representative_id',
            'education_person_signature_id',
            'education_person_signing_id',
            'education_programme_id',
            'education_school_id',
            'education_sifa_situation_id',
            'next_education_study_id',
            'administrative_name',
            'apprenticeCalendar',
            'business_apprentice_commercial_price',
            'business_apprentice_commercial_agreement_achievement_description',
            'business_apprentice_commercial_agreement_remaining_balance_min',
            'business_apprentice_commercial_agreement_remaining_balance_mode',
            'business_apprentice_commercial_agreement_remaining_balance_value',
            'hours_examination',
            'hours_teaching',
            'hours_total',
            'instructionalModel',
            'location',
            'mandatory_mobility_project',
            'max_students',
            'min_students',
            'reference_fc_new',
            'reference_fc_old',
            'reference_rncp',
            'reference_sifa',
            'year',
            'name',
            'expiry_rncp',
            'start',
            'end',
            'teaching_start',
            'board_1_date',
        ];
    }

    get nameAndYear() {
        return this.$.name ? `${this.$.name} (${this.yearAsText})` : undefined;
    }

    get url() {
        return this.$.id ? `educationStudies/${this.$.id}` : 'educationStudies';
    }

    get yearAsText() {
        return `${this.$.year === 1 ? '1ère année de formation' : `${this.$.year}ème année de formation`} `
            + `- ${this.$.educationProgramme.$.start_year + this.$.year - 1}`;
    }

    fill({
        apprenticeCalendar = undefined,
        businessApprenticeDiploma = {},
        educationPersonAdministrative1 = undefined,
        educationPersonAdministrative2 = undefined,
        educationPersonAdministrative3 = undefined,
        educationPersonDisputingRemainingBalance1 = undefined,
        educationPersonDisputingRemainingBalance2 = undefined,
        educationPersonNegotiatingRemainingBalance1 = undefined,
        educationPersonNegotiatingRemainingBalance2 = undefined,
        educationPersonPedagogy1 = undefined,
        educationPersonPedagogy2 = undefined,
        educationPersonPedagogy3 = undefined,
        educationPersonRepresentative = undefined,
        educationPersonSignature = undefined,
        educationPersonSigning = undefined,
        educationProgramme = {},
        educationSifaSituation = {},
        instructionalModel = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            apprenticeCalendar: apprenticeCalendar ? new Upload(apprenticeCalendar.$ || apprenticeCalendar) : undefined,
            businessApprenticeDiploma: new BusinessApprenticeDiploma(
                businessApprenticeDiploma.$ || businessApprenticeDiploma,
            ),
            educationPersonAdministrative1: educationPersonAdministrative1
                ? new EducationPerson(educationPersonAdministrative1.$ || educationPersonAdministrative1)
                : undefined,
            educationPersonAdministrative2: educationPersonAdministrative2
                ? new EducationPerson(educationPersonAdministrative2.$ || educationPersonAdministrative2)
                : undefined,
            educationPersonAdministrative3: educationPersonAdministrative3
                ? new EducationPerson(educationPersonAdministrative3.$ || educationPersonAdministrative3)
                : undefined,
            educationPersonDisputingRemainingBalance1: educationPersonDisputingRemainingBalance1
                ? new EducationPerson(
                    educationPersonDisputingRemainingBalance1.$
                        || educationPersonDisputingRemainingBalance1,
                )
                : undefined,
            educationPersonDisputingRemainingBalance2: educationPersonDisputingRemainingBalance2
                ? new EducationPerson(
                    educationPersonDisputingRemainingBalance2.$
                        || educationPersonDisputingRemainingBalance2,
                )
                : undefined,
            educationPersonNegotiatingRemainingBalance1: educationPersonNegotiatingRemainingBalance1
                ? new EducationPerson(
                    educationPersonNegotiatingRemainingBalance1.$
                        || educationPersonNegotiatingRemainingBalance1,
                )
                : undefined,
            educationPersonNegotiatingRemainingBalance2: educationPersonNegotiatingRemainingBalance2
                ? new EducationPerson(
                    educationPersonNegotiatingRemainingBalance2.$
                        || educationPersonNegotiatingRemainingBalance2,
                )
                : undefined,
            educationPersonPedagogy1: educationPersonPedagogy1
                ? new EducationPerson(educationPersonPedagogy1.$ || educationPersonPedagogy1)
                : undefined,
            educationPersonPedagogy2: educationPersonPedagogy2
                ? new EducationPerson(educationPersonPedagogy2.$ || educationPersonPedagogy2)
                : undefined,
            educationPersonPedagogy3: educationPersonPedagogy3
                ? new EducationPerson(educationPersonPedagogy3.$ || educationPersonPedagogy3)
                : undefined,
            educationPersonRepresentative: educationPersonRepresentative
                ? new EducationPerson(educationPersonRepresentative.$ || educationPersonRepresentative)
                : undefined,
            educationPersonSignature: educationPersonSignature
                ? new EducationPerson(educationPersonSignature.$ || educationPersonSignature)
                : undefined,
            educationPersonSigning: educationPersonSigning
                ? new EducationPerson(educationPersonSigning.$ || educationPersonSigning)
                : undefined,
            educationProgramme: new EducationProgramme(educationProgramme.$ || educationProgramme),
            educationSifaSituation: new EducationSifaSituation(educationSifaSituation.$ || educationSifaSituation),
            instructionalModel: instructionalModel ? new Upload(instructionalModel.$ || instructionalModel) : undefined,
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            apprenticeCalendar: this.$.apprenticeCalendar instanceof File ? this.$.apprenticeCalendar : undefined,
            business_apprentice_diploma_id: this.$.businessApprenticeDiploma.$.id,
            education_person_administrative_1_id: this.$.educationPersonAdministrative1
                ? this.$.educationPersonAdministrative1.$.id
                : undefined,
            education_person_administrative_2_id: this.$.educationPersonAdministrative2
                ? this.$.educationPersonAdministrative2.$.id
                : undefined,
            education_person_administrative_3_id: this.$.educationPersonAdministrative3
                ? this.$.educationPersonAdministrative3.$.id
                : undefined,
            education_person_disputing_remaining_balance_1_id: this.$.educationPersonDisputingRemainingBalance1
                ? this.$.educationPersonDisputingRemainingBalance1.$.id
                : undefined,
            education_person_disputing_remaining_balance_2_id: this.$.educationPersonDisputingRemainingBalance2
                ? this.$.educationPersonDisputingRemainingBalance2.$.id
                : undefined,
            education_person_negotiating_remaining_balance_1_id: this.$.educationPersonNegotiatingRemainingBalance1
                ? this.$.educationPersonNegotiatingRemainingBalance1.$.id
                : undefined,
            education_person_negotiating_remaining_balance_2_id: this.$.educationPersonNegotiatingRemainingBalance2
                ? this.$.educationPersonNegotiatingRemainingBalance2.$.id
                : undefined,
            education_person_pedagogy_1_id: this.$.educationPersonPedagogy1
                ? this.$.educationPersonPedagogy1.$.id
                : undefined,
            education_person_pedagogy_2_id: this.$.educationPersonPedagogy2
                ? this.$.educationPersonPedagogy2.$.id
                : undefined,
            education_person_pedagogy_3_id: this.$.educationPersonPedagogy3
                ? this.$.educationPersonPedagogy3.$.id
                : undefined,
            education_person_representative_id: this.$.educationPersonRepresentative
                ? this.$.educationPersonRepresentative.$.id
                : undefined,
            education_person_signature_id: this.$.educationPersonSignature
                ? this.$.educationPersonSignature.$.id
                : undefined,
            education_person_signing_id: this.$.educationPersonSigning
                ? this.$.educationPersonSigning.$.id
                : undefined,
            education_programme_id: this.$.educationProgramme.$.id,
            education_sifa_situation_id: this.$.educationSifaSituation.$.id,
            instructionalModel: this.$.instructionalModel instanceof File ? this.$.instructionalModel : undefined,
        });
    }

}
