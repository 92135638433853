import EducationDiploma from '@core/models/educationDiploma';
import EducationTopic from '@core/models/educationTopic';
import RestModel from '@core/models/_rest';
import School from '@core/models/school';
import Upload from '@core/models/upload';
import Web from '@core/models/web';

export default class EducationProgramme extends RestModel {

    static get authorisations() {
        return {
            '': 'Non concernée',
            A: 'A',
            B: 'B',
            C: 'C',
            D: 'D',
            E: 'E',
        };
    }

    static get supportLevels() {
        return {
            0: 'Aucun',
            1: 'Socle',
            2: 'Renforcé',
        };
    }

    get nameAndYear() {
        return this.$.name ? `${this.$.name} (${this.$.start_year} - ${this.$.end_year})` : undefined;
    }

    get url() {
        return this.$.id ? `educationProgrammes/${this.$.id}` : 'educationProgrammes';
    }

    fill({
        educationDiploma = {},
        educationTopic = {},
        graduateWeb = undefined,
        logoLg = undefined,
        logoMd = undefined,
        logoSm = undefined,
        officialWeb = undefined,
        school = {},
        undergraduateWeb = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            educationDiploma: new EducationDiploma(educationDiploma.$ || educationDiploma),
            educationTopic: new EducationTopic(educationTopic.$ || educationTopic),
            graduateWeb: graduateWeb ? new Web(graduateWeb.$ || graduateWeb) : undefined,
            logoLg: logoLg ? new Upload(logoLg.$ || logoLg) : undefined,
            logoMd: logoMd ? new Upload(logoMd.$ || logoMd) : undefined,
            logoSm: logoSm ? new Upload(logoSm.$ || logoSm) : undefined,
            officialWeb: officialWeb ? new Web(officialWeb.$ || officialWeb) : undefined,
            school: new School(school.$ || school),
            undergraduateWeb: undergraduateWeb ? new Web(undergraduateWeb.$ || undergraduateWeb) : undefined,
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            education_diploma_id: this.$.educationDiploma.$.id,
            education_topic_id: this.$.educationTopic.$.id,
            logoLg: this.$.logoLg instanceof File ? this.$.logoLg : undefined,
            logoMd: this.$.logoMd instanceof File ? this.$.logoMd : undefined,
            logoSm: this.$.logoSm instanceof File ? this.$.logoSm : undefined,
            school_id: this.$.school.$.id,
        });
    }

}
