<template>

    <b-form-group
        :id="id + '-group'"
        :description="description"
        :label-class="labelClass"
        :label-size="size"
        :state="state"
    >

        <template
            v-if="label"
            #label
        >
            {{ label }}
            <form-required v-if="required" />
            <form-business-apprentice-agreement
                v-if="businessApprenticeAgreement"
                class="float-right"
            />
        </template>

        <slot name="top" />

        <b-skeleton
            v-if="pending"
            type="input"
        />

        <b-input-group
            v-else
            :class="validationClass"
            :size="size"
            class="flex-nowrap"
        >

            <slot name="left" />

            <b-input-group-prepend
                v-if="leftIcon"
                :for="id"
                tag="label"
            >
                <b-input-group-text
                    :class="leftClass"
                    class="py-0 h-100"
                >
                    <font-awesome-icon
                        :icon="[leftIconFamily, leftIcon]"
                        :spin="leftIconSpin"
                        fixed-width
                    />
                </b-input-group-text>
            </b-input-group-prepend>

            <slot
                :id="id"
                :required="required"
                :state="state"
                browse-text="Naviguer"
                drop-placeholder="Déposer les fichiers ici"
                no-drop-placeholder="Fichier(s) incompatible(s)"
            />

            <slot name="right" />

        </b-input-group>

        <b-form-invalid-feedback
            v-for="message in error"
            :key="message"
        >
            {{ message }}
        </b-form-invalid-feedback>

        <template
            v-if="$scopedSlots.description"
            #description
        >
            <slot name="description" />
        </template>

    </b-form-group>

</template>



<script>

    export default {
        props: {
            businessApprenticeAgreement: {
                type: Boolean,
                default: false,
            },
            description: {
                type: String,
                default: null,
            },
            error: {
                type: Array,
                default: null,
            },
            id: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                default: null,
            },
            labelClass: {
                type: [Array, Object, String],
                default: null,
            },
            leftClass: {
                type: [Array, Object, String],
                default: null,
            },
            leftIcon: {
                type: String,
                default: null,
            },
            leftIconFamily: {
                type: String,
                default: 'fad',
            },
            leftIconSpin: {
                type: Boolean,
                default: false,
            },
            pending: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String,
                default: null,
            },
            state: {
                type: Boolean,
                default: null,
            },
        },
        computed: {
            validationClass: (vue) => {
                if (vue.state === true) {
                    return 'is-valid';
                }
                if (vue.state === false) {
                    return 'is-invalid';
                }
                return '';
            },
        },
    };

</script>
