<template>

    <b-card
        bg-variant="light"
        border-variant="0"
        class="p-0"
        no-body
    >

        <b-card-header class="d-flex align-items-center">

            <b-skeleton-img
                v-if="isPending"
                height="50px"
                width="50px"
                no-aspect
            />

            <upload-avatar
                v-else
                :upload="businessPerson.$.user ? businessPerson.$.user.$.avatar : null"
                icon="user-hard-hat"
                width="50px"
                rounded
            />

            <component
                :is="headerTag"
                class="flex-fill mb-0 ml-2"
            >

                <b-skeleton
                    v-if="isPending"
                    class="w-75"
                    variant="dark"
                />

                <div v-else>
                    {{ businessPerson.$.fullname }}
                </div>

                <small class="text-muted">
                    {{ title }}
                </small>

            </component>

            <button-vcard
                :email="businessPerson.$.email"
                :firstname="businessPerson.$.firstname"
                :is-pending="isPending"
                :lastname="businessPerson.$.lastname"
                :organization="businessPerson.$.businessCompany.$.name"
                :phones="{ MAIN: businessPerson.$.phone }"
                :title="title"
            />

        </b-card-header>

        <b-card-body v-if="isPending">
            <b-skeleton class="w-75" />
            <b-skeleton class="w-75" />
            <b-skeleton class="w-50" />
        </b-card-body>

        <b-card-body v-else>

            <div class="mb-2">

                <p class="my-0 font-weight-bold">
                    {{ businessPerson.$.businessCompany.$.name }}
                </p>

                {{ businessPerson.$.job_title }}

            </div>

            <div v-if="businessPerson.$.email">

                <font-awesome-icon
                    :icon="['fad', 'envelope']"
                    fixed-width
                />

                <a
                    :href="`mailto:${businessPerson.$.email}`"
                    class="font-weight-bold"
                >
                    {{ businessPerson.$.email }}
                </a>

            </div>

            <div v-if="businessPerson.$.phone">

                <font-awesome-icon
                    :icon="['fad', 'phone']"
                    fixed-width
                />

                <a :href="`tel:${businessPerson.$.phone}`">
                    {{ businessPerson.$.phone }}
                </a>

            </div>

        </b-card-body>

    </b-card>

</template>



<script>

    import BusinessPerson from '@core/models/businessPerson';

    export default {
        props: {
            businessPerson: {
                type: BusinessPerson,
                required: true,
            },
            headerTag: {
                type: String,
                required: true,
            },
            isPending: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                default: "Maître d'apprentissage",
            },
        },
    };

</script>
