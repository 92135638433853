<template>

    <div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="xTemplate" />
        <slot ref="router-view" />
    </div>

</template>



<script>

    export default {
        computed: {
            xTemplate: () => document.querySelector('#layout-plain[type="text/x-template"]').innerHTML,
        },
        mounted() {
            const oldChild = this.$el.querySelector('#layout-plain-content');
            const newChild = this.$slots.default[0];
            oldChild.parentNode.replaceChild(newChild.elm, oldChild);
        },
    };

</script>
