import RestModel from '@core/models/_rest';
import Upload from '@core/models/upload';

export default class EducationClass extends RestModel {

    get url() {
        return this.$.id ? `questionnaireCategories/${this.$.id}` : 'questionnaireCategories';
    }

    fill({
        logo = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            logo: logo ? new Upload(logo.$ || logo) : undefined,
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            logo: this.$.logo instanceof File ? this.$.logo : undefined,
        });
    }

}
