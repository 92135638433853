<template>

    <b-row
        tag="form"
        @submit.prevent="formSubmit"
    >

        <b-col cols="12">
            <business-apprentice-contract-missing-information-alert
                :business-apprentice-contract="formInitialModel"
                :business-apprentice-contract-edit-route="businessApprenticeContractEditRoute"
                :business-apprentice-contract-invoice-address-route="businessApprenticeContractInvoiceAddressRoute"
                :business-apprentice-contract-location-route="businessApprenticeContractLocationRoute"
                :business-apprentice-contract-purpose-route="businessApprenticeContractPurposeRoute"
                :signatory-business-person-edit-route="signatoryBusinessPersonEditRoute"
                class="mb-4"
                success-title="Les informations du contrat d'apprentissage sont complètes."
                warning-title="Les informations suivantes manquent au contrat d'apprentissage :"
            />
        </b-col>

        <b-col md="6">

            <b-card
                bg-variant="light"
                class="mb-4"
                header="Contacts entreprise"
                header-tag="h2"
            >

                <business-person-select
                    id="view-businessApprenticeContract-edit-signatory-business-contact-id"
                    :key="`contact-${triggerBusinessPersonSelects.toString()}`"
                    v-model="signatory_business_contact_id"
                    :business-branch="formInitialModel.$.signatoryBusinessBranch"
                    :business-people="businessPeople"
                    :disabled="formInitialModel.$.updatable !== true"
                    :error="formModifiedModel.errors.signatory_business_contact_id"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.signatory_business_contact_id"
                    business-person-create-label="Contact entreprise"
                    label="Contact administratif de l'employeur"
                    business-apprentice-agreement
                    @business-person-modal-close="$emit('business-person-modal-close')"
                />

                <business-person-select
                    v-if="formInitialModel.showInvoiceFields"
                    id="view-businessApprenticeContract-edit-signatory-business-remaining-balance-granter-id"
                    :key="`remaining-balance-granter-${triggerBusinessPersonSelects.toString()}`"
                    v-model="signatory_business_remaining_balance_granter_id"
                    :business-branch="formInitialModel.$.signatoryBusinessBranch"
                    :business-people="businessPeople"
                    :disabled="formInitialModel.$.updatable !== true"
                    :error="formModifiedModel.errors.signatory_business_remaining_balance_granter_id"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.signatory_business_remaining_balance_granter_id"
                    business-person-create-label="Contact entreprise"
                    label="Personne validant le reste à charge"
                    business-apprentice-agreement
                    @business-person-modal-close="$emit('business-person-modal-close')"
                />

                <business-person-select
                    id="view-businessApprenticeContract-edit-signatory-business-representative-id"
                    :key="`representative-${triggerBusinessPersonSelects.toString()}`"
                    v-model="signatory_business_representative_id"
                    :business-branch="formInitialModel.$.signatoryBusinessBranch"
                    :business-people="businessPeople"
                    :disabled="formInitialModel.$.updatable !== true"
                    :error="formModifiedModel.errors.signatory_business_representative_id"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.signatory_business_representative_id"
                    business-person-create-label="Contact entreprise"
                    label="Représentant de l'employeur"
                    business-apprentice-agreement
                    @business-person-modal-close="$emit('business-person-modal-close')"
                />

            </b-card>

            <b-card
                v-if="isDelegatee !== true"
                bg-variant="light"
                class="mb-4"
                header="Identification"
                header-tag="h2"
            >

                <b-alert
                    variant="info"
                    show
                >
                    <font-awesome-icon
                        :icon="['fad', 'info']"
                        fixed-width
                    />
                    Ces informations ne sont pas visibles lorsque la personne remplissant cette page
                    ne fait pas partie de l'établissement ou de l'école.
                </b-alert>

                <form-group
                    id="view-businessApprenticeContract-edit-education-study-id"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.education_study_id"
                    :description="
                        formInitialModel.$.updatable
                            && ['completed_delegatee', 'completed', 'created', 'downloaded']
                                .includes(formInitialModel.$.status) !== true
                            ? 'Le parcours ne peut plus être modifié après signature des documents.'
                            : 'Choisissez parmi les parcours dans lesquels l\'apprenti est inscrit '
                                + 'avec une alternance Apprenti ou Admis en recherche d\'un contrat d\'apprentissage'
                    "
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.education_study_id"
                    label="Parcours"
                    business-apprentice-agreement
                    required
                >
                    <b-form-radio-group
                        v-model="education_study_id"
                        v-bind="inputProps"
                        :disabled="
                            formInitialModel.$.updatable !== true
                                || ['completed_delegatee', 'completed', 'created', 'downloaded']
                                    .includes(formInitialModel.$.status) !== true
                        "
                        :options="educationStudyOptions"
                        stacked
                    />
                </form-group>

                <form-group
                    id="view-businessApprenticeContract-edit-code"
                    v-slot="inputProps"
                    :business-apprentice-agreement="formInitialModel.$.amending_business_apprentice_contract_id != null"
                    :left-icon="formInitialModel.$.updatable ? 'barcode' : undefined"
                    :error="formModifiedModel.errors.code"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.code"
                    label="Numéro du contrat"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.code"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>

                <form-group
                    id="view-businessApprenticeContract-edit-previous-business-apprentice-contract-code"
                    v-slot="inputProps"
                    :left-icon="formInitialModel.$.updatable ? 'barcode' : undefined"
                    :error="formModifiedModel.errors.previous_business_apprentice_contract_code"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.previous_business_apprentice_contract_code"
                    label="Numéro du contrat précédent"
                    business-apprentice-agreement
                >
                    <b-form-input
                        v-model="formModifiedModel.$.previous_business_apprentice_contract_code"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>

                <business-branch-select
                    id="view-businessApprenticeContract-edit-signatory-business-branch-id"
                    v-model="formModifiedModel.$.signatoryBusinessBranch"
                    :parent-form-error="formModifiedModel.errors.signatory_business_branch_id"
                    :parent-form-is-pending="formInitialModel.isPending"
                    :parent-form-state="formModifiedModel.states.signatory_business_branch_id"
                    :plaintext="formInitialModel.$.updatable !== true"
                    label="Numéro de SIRET de l'établissement signataire"
                    business-apprentice-agreement
                    required
                />

                <business-branch-select
                    id="view-businessApprenticeContract-edit-executing-business-branch-id"
                    v-model="formModifiedModel.$.executingBusinessBranch"
                    :parent-form-error="formModifiedModel.errors.executing_business_branch_id"
                    :parent-form-is-pending="formInitialModel.isPending"
                    :parent-form-state="formModifiedModel.states.executing_business_branch_id"
                    :plaintext="formInitialModel.$.updatable !== true"
                    label="Numéro de SIRET de l'établissement d'exécution"
                    required
                />

                <form-group
                    id="view-businessApprenticeContract-edit-sync-sfp-contract-start"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.sync_sfp_contract_start"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.sync_sfp_contract_start"
                >
                    <b-form-checkbox
                        v-model="formModifiedModel.$.sync_sfp_contract_start"
                        v-bind="inputProps"
                        :disabled="formInitialModel.$.updatable !== true"
                    >
                        Gestion automatique du contrat SFP rentrée
                    </b-form-checkbox>
                </form-group>

                <form-group
                    id="view-businessApprenticeContract-edit-sync-sfp-contract-terminated"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.sync_sfp_contract_terminated"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.sync_sfp_contract_terminated"
                >
                    <b-form-checkbox
                        v-model="formModifiedModel.$.sync_sfp_contract_terminated"
                        v-bind="inputProps"
                        :disabled="formInitialModel.$.updatable !== true"
                    >
                        Gestion automatique du contrat SFP rupture
                    </b-form-checkbox>
                </form-group>

                <form-group
                    id="view-businessApprenticeContract-edit-external-id"
                    v-slot="inputProps"
                    :left-icon="formInitialModel.$.updatable ? 'barcode' : undefined"
                    :error="formModifiedModel.errors.external_id"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.external_id"
                    description="Identifiant du contrat dans un système d'information externe (ex: celui de l'école)."
                    label="Identifiant externe"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.external_id"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>
            </b-card>

            <b-card
                bg-variant="light"
                class="mb-4"
                header="Relation contractuelle"
                header-tag="h2"
            >

                <form-group
                    id="view-businessApprenticeContract-edit-job-title"
                    v-slot="inputProps"
                    :left-icon="formInitialModel.$.updatable ? 'text' : undefined"
                    :error="formModifiedModel.errors.job_title"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.job_title"
                    label="Intitulé du poste"
                    business-apprentice-agreement
                >
                    <b-form-input
                        v-model="formModifiedModel.$.job_title"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>

                <model-list
                    v-slot="{ collection, model, options, setValue, value: businessApprenticeBranchTypeId }"
                    v-model="business_apprentice_branch_type_id"
                    name="BusinessApprenticeBranchType"
                >
                    <form-group
                        id="view-businessApprenticeContract-edit-business-apprentice-branch-type-id"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.business_apprentice_branch_type_id"
                        :left-icon="
                            formInitialModel.$.updatable ? (collection.isPending ? 'circle-notch' : 'tag') : undefined
                        "
                        :left-icon-spin="collection.isPending"
                        :pending="formInitialModel.isPending"
                        :state="formModifiedModel.states.business_apprentice_branch_type_id"
                        label="Employeur spécifique"
                        business-apprentice-agreement
                    >
                        <b-form-select
                            v-if="formInitialModel.$.updatable"
                            v-bind="inputProps"
                            :options="options"
                            :value="businessApprenticeBranchTypeId"
                            @input="setValue"
                        />
                        <b-form-input
                            v-else
                            v-bind="inputProps"
                            :value="model ? model.$.name : undefined"
                            plaintext
                        />
                    </form-group>
                </model-list>

                <model-list
                    v-slot="{ collection, model, options, setValue, value: businessApprenticeDurationId }"
                    v-model="business_apprentice_duration_id"
                    name="BusinessApprenticeDuration"
                >
                    <form-group
                        id="view-businessApprenticeContract-edit-business-apprentice-duration-id"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.business_apprentice_duration_id"
                        :left-icon="
                            formInitialModel.$.updatable ? (collection.isPending ? 'circle-notch' : 'tag') : undefined
                        "
                        :left-icon-spin="collection.isPending"
                        :pending="formInitialModel.isPending"
                        :state="formModifiedModel.states.business_apprentice_duration_id"
                        label="Mode contractuel"
                        business-apprentice-agreement
                    >
                        <b-form-select
                            v-if="formInitialModel.$.updatable"
                            v-bind="inputProps"
                            :options="options"
                            :value="businessApprenticeDurationId"
                            @input="setValue"
                        />
                        <b-form-input
                            v-else
                            v-bind="inputProps"
                            :value="model ? model.$.name : undefined"
                            plaintext
                        />
                    </form-group>
                </model-list>

                <model-list
                    v-slot="{ collection, model, options, setValue, value: businessApprenticeExemptionId }"
                    v-model="business_apprentice_exemption_id"
                    name="BusinessApprenticeExemption"
                    allow-empty
                >
                    <form-group
                        id="view-businessApprenticeContract-edit-business-apprentice-exemption-id"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.business_apprentice_exemption_id"
                        :left-icon="
                            formInitialModel.$.updatable ? (collection.isPending ? 'circle-notch' : 'tag') : undefined
                        "
                        :left-icon-spin="collection.isPending"
                        :pending="formInitialModel.isPending"
                        :state="formModifiedModel.states.business_apprentice_exemption_id"
                        label="À compléter si une dérogation est demandée"
                        business-apprentice-agreement
                    >
                        <b-form-select
                            v-if="formInitialModel.$.updatable"
                            v-bind="inputProps"
                            :options="options"
                            :value="businessApprenticeExemptionId"
                            @input="setValue"
                        />
                        <b-form-input
                            v-else
                            v-bind="inputProps"
                            :value="model ? model.$.name : undefined"
                            plaintext
                        />
                    </form-group>
                </model-list>

                <model-list
                    v-slot="{ collection, model, options, setValue, value: businessApprenticeReasonId }"
                    v-model="business_apprentice_reason_id"
                    name="BusinessApprenticeReason"
                >
                    <form-group
                        id="view-businessApprenticeContract-edit-business-apprentice-reason-id"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.business_apprentice_reason_id"
                        :left-icon="
                            formInitialModel.$.updatable ? (collection.isPending ? 'circle-notch' : 'tag') : undefined
                        "
                        :left-icon-spin="collection.isPending"
                        :pending="formInitialModel.isPending"
                        :state="formModifiedModel.states.business_apprentice_reason_id"
                        label="Type de contrat ou d'avenant"
                        business-apprentice-agreement
                    >
                        <b-form-select
                            v-if="formInitialModel.$.updatable"
                            v-bind="inputProps"
                            :options="options"
                            :value="businessApprenticeReasonId"
                            @input="setValue"
                        />
                        <b-form-input
                            v-else
                            v-bind="inputProps"
                            :value="model ? model.$.name : undefined"
                            plaintext
                        />
                    </form-group>
                </model-list>

                <form-group
                    id="view-businessContract-edit-business-idcc-id"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.business_idcc_id"
                    :left-icon="formInitialModel.$.updatable ? 'tag' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.business_idcc_id"
                    :description="
                        formInitialModel.$.updatable
                            ? 'Seuls les codes IDCC de l\'établissement d\'exécution sont disponibles '
                                + 'dans cette liste. Si cette liste est vide ou incomplète, enregistrez le formulaire '
                                + 'et venez compléter cette information sur la page de l\'établissement.'
                            : undefined
                    "
                    label="Code IDCC"
                    business-apprentice-agreement
                >
                    <b-form-select
                        v-if="formInitialModel.$.updatable"
                        v-model="business_idcc_id"
                        v-bind="{ ...inputProps }"
                        :options="businessIdccs"
                    />
                    <b-form-input
                        v-else
                        v-bind="inputProps"
                        :value="formInitialModel.$.businessIdcc ? formInitialModel.$.businessIdcc.$.name : undefined"
                        plaintext
                    />
                </form-group>

            </b-card>

            <b-card
                bg-variant="light"
                class="mb-4"
                header="Signature électronique"
                header-tag="h2"
            >
                <form-group
                    id="view-businessContract-edit-create-business-create-business-signature-application"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.create_signature_application"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.create_signature_application"
                >
                    <b-form-checkbox
                        v-model="formModifiedModel.$.create_signature_application"
                        v-bind="inputProps"
                        :disabled="formInitialModel.$.updatable !== true"
                    >
                        Je souhaite signer électroniquement le CERFA et la convention d'apprentissage.
                    </b-form-checkbox>
                </form-group>
            </b-card>

            <b-card
                bg-variant="light"
                class="mb-4"
                header="Dépôt du contrat sur la plateforme de l'OPCO"
                header-tag="h2"
            >

                <template v-if="convergenceEnabled">

                    <form-group
                        id="view-businessContract-edit-create-business-create-business-convergence"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.create_business_convergence"
                        :pending="formInitialModel.isPending"
                        :state="formModifiedModel.states.create_business_convergence"
                        label="Dépôt du contrat sur la plateforme de l'OPCO"
                    >


                        <b-form-checkbox
                            v-model="formModifiedModel.$.create_business_convergence"
                            v-bind="inputProps"
                            :disabled="formInitialModel.$.updatable !== true"
                        >
                            Je donne mandat au CFA pour déposer le contrat sur la plateforme de l'OPCO
                            après signature des documents.
                            Je n'aurais pas à ressaisir le contrat sur la plateforme de l'OPCO.
                        </b-form-checkbox>

                    </form-group>

                    <b-alert
                        class="w-100"
                        variant="warning"
                        show
                    >
                        <font-awesome-icon
                            :icon="['fad', 'exclamation']"
                            fixed-width
                        />
                        En décochant cette case je confirme que je prends en charge
                        l'ensemble des modalités administrative relatives au dépôt du contrat auprès de l'OPCO.
                    </b-alert>

                </template>

                <b-alert
                    v-else
                    class="w-100"
                    variant="info"
                    show
                >
                    <font-awesome-icon
                        :icon="['fad', 'info']"
                        fixed-width
                    />
                    Le dépôt par le CFA des éléments du contrat n'est pas possible.
                    Il vous appartient de déposer ces éléments sur la plateforme de votre financeur.
                </b-alert>

            </b-card>

            <b-card
                v-if="isDelegatee !== true"
                bg-variant="light"
                class="mb-4"
                header="Mobilité Internationale"
                header-tag="h2"
            >

                <b-alert
                    variant="info"
                    show
                >
                    <font-awesome-icon
                        :icon="['fad', 'info']"
                        fixed-width
                    />
                    Ces informations ne sont pas visibles lorsque la personne remplissant cette page
                    ne fait pas partie de l'établissement ou de l'école.
                </b-alert>

                <file-form-group
                    id="view-businessApprenticeContract-edit-mobility-appendix"
                    v-model="formModifiedModel.$.mobilityAppendix"
                    :delete-upload.sync="formModifiedModel.$.delete_mobilityAppendix"
                    :disabled="formInitialModel.$.updatable !== true"
                    :error="formModifiedModel.errors.mobilityAppendix"
                    :initial-upload="formInitialModel.$.mobilityAppendix"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.mobilityAppendix"
                    accept="application/pdf"
                    label="Annexe de mobilité internationale"
                    left-icon="file-pdf"
                    business-apprentice-agreement
                    deletable
                >
                    <template
                        v-if="formInitialModel.$.updatable && formInitialModel.$.mobility_appendix_generator_url"
                        slot="description"
                    >
                        <a
                            :href="formInitialModel.$.mobility_appendix_generator_url"
                            target="_blank"
                        >
                            <font-awesome-icon
                                :icon="['fad', 'external-link']"
                                fixed-width
                            />
                            Générer l'annexe de mobilité internationale
                        </a>
                        <br>
                        Un document sera téléchargé sur votre ordinateur, vous pourrez ensuite le mettre en ligne ici.
                    </template>
                </file-form-group>

            </b-card>

            <b-card
                v-if="isDelegatee !== true"
                bg-variant="light"
                class="mb-4"
                header="Fonction Publique"
                header-tag="h2"
            >
                <form-group
                    id="view-businessApprenticeContract-edit-order-number"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.order_number"
                    :left-icon="formInitialModel.$.updatable ? 'barcode' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.order_number"
                    label="Numéro du bon de commande"
                    business-apprentice-agreement
                >
                    <b-form-input
                        v-model="formModifiedModel.$.order_number"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>
            </b-card>

        </b-col>

        <b-col md="6">

            <b-card
                bg-variant="light"
                class="mb-4"
                header="Conditions de travail"
                header-tag="h2"
            >

                <form-group
                    id="view-businessApprenticeContract-edit-start"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.start"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.start"
                    label="Date de début"
                    business-apprentice-agreement
                    required
                >
                    <b-form-input
                        v-model="formModifiedModel.$.start"
                        v-bind="inputProps"
                        :placeholder="formInitialModel.$.updatable ? 'AAAA-MM-JJ' : undefined"
                        :plaintext="formInitialModel.$.updatable !== true"
                        max="2999-01-01"
                        type="date"
                    />
                </form-group>

                <form-group
                    id="view-businessApprenticeContract-edit-signing-at"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.signed_at"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.signed_at"
                    description="Si laissée vide, la date de début du contrat sera insérée de manière automatique."
                    label="Date de conclusion"
                    business-apprentice-agreement
                >
                    <b-form-input
                        v-model="formModifiedModel.$.signed_at"
                        v-bind="inputProps"
                        :placeholder="formInitialModel.$.updatable ? 'AAAA-MM-JJ' : undefined"
                        :plaintext="formInitialModel.$.updatable !== true"
                        max="2999-01-01"
                        type="date"
                    />
                </form-group>

                <form-group
                    v-if="formInitialModel.$.amended_business_apprentice_contract_id"
                    id="view-businessApprenticeContract-edit-amending-at"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.amending_at"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.amending_at"
                    label="Date d'effet de l'avenant"
                    business-apprentice-agreement
                    required
                >
                    <b-form-input
                        v-model="formModifiedModel.$.amending_at"
                        v-bind="inputProps"
                        :placeholder="formInitialModel.$.updatable ? 'AAAA-MM-JJ' : undefined"
                        :plaintext="formInitialModel.$.updatable !== true"
                        max="2999-01-01"
                        type="date"
                    />
                </form-group>

                <form-group
                    id="view-businessApprenticeContract-edit-end"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.end"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.end"
                    label="Date de fin"
                    business-apprentice-agreement
                    required
                >
                    <b-form-input
                        v-model="formModifiedModel.$.end"
                        v-bind="inputProps"
                        :placeholder="formInitialModel.$.updatable ? 'AAAA-MM-JJ' : undefined"
                        :plaintext="formInitialModel.$.updatable !== true"
                        max="2999-01-01"
                        type="date"
                    />
                </form-group>

                <form-group
                    id="view-businessApprenticeContract-edit-weekly-working-minutes"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.weekly_working_minutes"
                    :left-icon="formInitialModel.$.updatable ? 'clock' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.weekly_working_minutes"
                    business-apprentice-agreement
                    label="Durée hebdomadaire de travail"
                >
                    <b-form-input
                        v-model="weekly_working_hours"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                        class="text-right"
                        min="0"
                        type="number"
                    />
                    <b-input-group-text
                        :class="{ 'bg-transparent border-0': formInitialModel.$.updatable !== true }"
                        class="border-left-0 border-right-0 rounded-0"
                    >
                        heures
                    </b-input-group-text>
                    <b-form-input
                        v-model="weekly_working_minutes"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                        class="text-right"
                        min="0"
                        type="number"
                    />
                    <b-input-group-text
                        :class="{ 'bg-transparent border-0': formInitialModel.$.updatable !== true }"
                        class="border-left-0 rounded-0"
                    >
                        minutes
                    </b-input-group-text>
                </form-group>

                <form-group
                    id="view-businessApprenticeContract-edit-dangerous"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.dangerous"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.dangerous"
                    business-apprentice-agreement
                >
                    <b-form-checkbox
                        v-model="formModifiedModel.$.dangerous"
                        v-bind="inputProps"
                        :disabled="formInitialModel.$.updatable !== true"
                    >
                        Travail sur machines dangereuses ou exposition à des risques particuliers
                    </b-form-checkbox>
                </form-group>

            </b-card>

            <b-card
                bg-variant="light"
                class="mb-4"
                header="Couverture sociale"
                header-tag="h2"
            >

                <form-group
                    id="view-businessApprenticeContract-edit-pension-fund"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.pension_fund"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.pension_fund"
                    label="Caisse complémentaire"
                    business-apprentice-agreement
                >
                    <b-form-input
                        v-model="formModifiedModel.$.pension_fund"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>

                <form-group
                    id="view-businessApprenticeContract-edit-social-security-scheme"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.social_security_scheme"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.social_security_scheme"
                    label="Régime social de l'apprenant"
                    business-apprentice-agreement
                >
                    <b-form-radio-group
                        v-model="formModifiedModel.$.social_security_scheme"
                        v-bind="inputProps"
                        :disabled="formInitialModel.$.updatable !== true"
                        :options="socialSecuritySchemes"
                    />
                </form-group>

                <form-group
                    id="view-businessApprenticeContract-edit-unemployment-insurance"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.unemployment_insurance"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.unemployment_insurance"
                    business-apprentice-agreement
                >
                    <b-form-checkbox
                        v-model="formModifiedModel.$.unemployment_insurance"
                        v-bind="inputProps"
                        :disabled="formInitialModel.$.updatable !== true"
                    >
                        Pour la fonction publique : Adhésion de l'apprenti au régime spécifique d'assurance chômage
                    </b-form-checkbox>
                </form-group>

            </b-card>

            <b-card
                v-if="isDelegatee !== true"
                bg-variant="light"
                class="mb-4"
                header="Parcours de l'apprenant"
                header-tag="h2"
            >

                <b-alert
                    variant="info"
                    show
                >
                    <font-awesome-icon
                        :icon="['fad', 'info']"
                        fixed-width
                    />
                    Ces informations ne sont pas visibles lorsque la personne remplissant cette page
                    ne fait pas partie de l'établissement ou de l'école.
                </b-alert>

                <model-list
                    v-slot="{ collection, model, options, setValue, value: businessApprenticeDiplomaId }"
                    v-model="highest_business_apprentice_diploma_id"
                    name="BusinessApprenticeDiploma"
                >
                    <form-group
                        id="view-businessApprenticeContract-edit-highest-business-apprentice-diploma-id"
                        :error="formModifiedModel.errors.highest_business_apprentice_diploma_id"
                        :left-icon="
                            formInitialModel.$.updatable
                                ? (collection.isPending ? 'circle-notch' : 'file-certificate')
                                : undefined
                        "
                        :left-icon-spin="collection.isPending"
                        :pending="formInitialModel.isPending"
                        :state="formModifiedModel.states.highest_business_apprentice_diploma_id"
                        label="Plus haut diplôme obtenu par l'apprenant"
                        business-apprentice-agreement
                    >

                        <template #default="inputProps">
                            <b-form-select
                                v-if="formInitialModel.$.updatable"
                                v-bind="inputProps"
                                :options="options"
                                :value="businessApprenticeDiplomaId"
                                @input="setValue"
                            />
                            <b-form-input
                                v-else
                                v-bind="inputProps"
                                :value="model ? model.$.name : undefined"
                                plaintext
                            />
                        </template>

                        <template
                            v-if="formInitialModel.$.learnerPerson.$.highestBusinessApprenticeDiploma"
                            #description
                        >
                            Sur le profil de l'apprenant :
                            {{ formInitialModel.$.learnerPerson.$.highestBusinessApprenticeDiploma.$.name }}.
                        </template>

                        <template
                            v-else
                            #description
                        >
                            Sur le profil de l'apprenant :
                            <font-awesome-icon
                                :icon="['fad', 'empty-set']"
                                fixed-width
                            />
                            .
                        </template>

                    </form-group>
                </model-list>

                <model-list
                    v-slot="{ collection, model, options, setValue, value: businessApprenticeClassId }"
                    v-model="last_business_apprentice_class_id"
                    name="BusinessApprenticeClass"
                >
                    <form-group
                        id="view-businessApprenticeContract-edit-last-business-apprentice-class-id"
                        :error="formModifiedModel.errors.last_business_apprentice_class_id"
                        :left-icon="
                            formInitialModel.$.updatable
                                ? (collection.isPending ? 'circle-notch' : 'file-certificate')
                                : undefined
                        "
                        :left-icon-spin="collection.isPending"
                        :pending="formInitialModel.isPending"
                        :state="formModifiedModel.states.last_business_apprentice_class_id"
                        label="Dernière classe fréquentée par l'apprenant"
                        business-apprentice-agreement
                    >

                        <template #default="inputProps">
                            <b-form-select
                                v-if="formInitialModel.$.updatable"
                                v-bind="inputProps"
                                :options="options"
                                :value="businessApprenticeClassId"
                                @input="setValue"
                            />
                            <b-form-input
                                v-else
                                v-bind="inputProps"
                                :value="model ? model.$.name : undefined"
                                plaintext
                            />
                        </template>

                        <template
                            v-if="formInitialModel.$.learnerPerson.$.lastBusinessApprenticeClass"
                            #description
                        >
                            Sur le profil de l'apprenant :
                            {{ formInitialModel.$.learnerPerson.$.lastBusinessApprenticeClass.$.name }}.
                        </template>

                        <template
                            v-else
                            #description
                        >
                            Sur le profil de l'apprenant :
                            <font-awesome-icon
                                :icon="['fad', 'empty-set']"
                                fixed-width
                            />
                            .
                        </template>

                    </form-group>
                </model-list>

                <model-list
                    v-slot="{ collection, model, options, setValue, value: businessApprenticeDiplomaId }"
                    v-model="last_business_apprentice_diploma_id"
                    name="BusinessApprenticeDiploma"
                >
                    <form-group
                        id="view-businessApprenticeContract-edit-last-business-apprentice-diploma-id"
                        :error="formModifiedModel.errors.last_business_apprentice_diploma_id"
                        :left-icon="
                            formInitialModel.$.updatable
                                ? (collection.isPending ? 'circle-notch' : 'file-certificate')
                                : undefined
                        "
                        :left-icon-spin="collection.isPending"
                        :pending="formInitialModel.isPending"
                        :state="formModifiedModel.states.last_business_apprentice_diploma_id"
                        label="Dernier diplôme préparé par l'apprenant"
                        business-apprentice-agreement
                    >

                        <template #default="inputProps">
                            <b-form-select
                                v-if="formInitialModel.$.updatable"
                                v-bind="inputProps"
                                :options="options"
                                :value="businessApprenticeDiplomaId"
                                @input="setValue"
                            />
                            <b-form-input
                                v-else
                                v-bind="inputProps"
                                :value="model ? model.$.name : undefined"
                                plaintext
                            />
                        </template>

                        <template
                            v-if="formInitialModel.$.learnerPerson.$.lastBusinessApprenticeDiploma"
                            #description
                        >
                            Sur le profil de l'apprenant :
                            {{ formInitialModel.$.learnerPerson.$.lastBusinessApprenticeDiploma.$.name }}.
                        </template>

                        <template
                            v-else
                            #description
                        >
                            Sur le profil de l'apprenant :
                            <font-awesome-icon
                                :icon="['fad', 'empty-set']"
                                fixed-width
                            />
                            .
                        </template>

                    </form-group>
                </model-list>

                <model-list
                    v-slot="{ collection, model, options, setValue, value: businessApprenticeSituationId }"
                    v-model="last_business_apprentice_situation_id"
                    name="BusinessApprenticeSituation"
                >
                    <form-group
                        id="view-businessApprenticeContract-edit-last-business-apprentice-situation-id"
                        :error="formModifiedModel.errors.last_business_apprentice_situation_id"
                        :left-icon="
                            formInitialModel.$.updatable
                                ? (collection.isPending ? 'circle-notch' : 'file-certificate')
                                : undefined
                        "
                        :left-icon-spin="collection.isPending"
                        :pending="formInitialModel.isPending"
                        :state="formModifiedModel.states.last_business_apprentice_situation_id"
                        label="Dernière situation fréquentée par l'apprenant"
                        business-apprentice-agreement
                    >

                        <template #default="inputProps">
                            <b-form-select
                                v-if="formInitialModel.$.updatable"
                                v-bind="inputProps"
                                :options="options"
                                :value="businessApprenticeSituationId"
                                @input="setValue"
                            />
                            <b-form-input
                                v-else
                                v-bind="inputProps"
                                :value="model ? model.$.name : undefined"
                                plaintext
                            />
                        </template>

                        <template
                            v-if="formInitialModel.$.learnerPerson.$.lastBusinessApprenticeSituation"
                            #description
                        >
                            Sur le profil de l'apprenant :
                            {{ formInitialModel.$.learnerPerson.$.lastBusinessApprenticeSituation.$.name }}.
                        </template>

                        <template
                            v-else
                            #description
                        >
                            Sur le profil de l'apprenant :
                            <font-awesome-icon
                                :icon="['fad', 'empty-set']"
                                fixed-width
                            />
                            .
                        </template>

                    </form-group>
                </model-list>

                <form-group
                    id="view-businessApprenticeContract-edit-last-prepared-diploma-name"
                    :error="formModifiedModel.errors.last_prepared_diploma_name"
                    :left-icon="formInitialModel.$.updatable ? 'file-certificate' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.last_prepared_diploma_name"
                    label="Nom du dernier diplôme préparé par l'apprenant"
                    business-apprentice-agreement
                >

                    <template #default="inputProps">
                        <b-form-input
                            v-model="formModifiedModel.$.last_prepared_diploma_name"
                            v-bind="inputProps"
                            :plaintext="formInitialModel.$.updatable !== true"
                        />
                    </template>

                    <template
                        v-if="formInitialModel.$.learnerPerson.$.last_prepared_diploma_name"
                        #description
                    >
                        Sur le profil de l'apprenant :
                        {{ formInitialModel.$.learnerPerson.$.last_prepared_diploma_name }}.
                    </template>

                    <template
                        v-else
                        #description
                    >
                        Sur le profil de l'apprenant :
                        <font-awesome-icon
                            :icon="['fad', 'empty-set']"
                            fixed-width
                        />
                        .
                    </template>

                </form-group>

                <div class="text-right">
                    <b-button
                        variant="secondary"
                        @click="resetEducationalHistory"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'clone']"
                            fixed-width
                        />
                        Pré-remplir avec le profil de l'apprenant
                    </b-button>
                </div>

            </b-card>

            <b-card
                bg-variant="light"
                class="mb-4"
                header="Rémunération"
                header-tag="h2"
            >

                <b-alert
                    variant="info"
                    show
                >

                    <font-awesome-icon
                        :icon="['fad', 'info']"
                        fixed-width
                    />

                    Informations nécessaires au calcul de la rémunération :

                    <ul class="mb-0">

                        <li>
                            Date de naissance de l'apprenant :
                            <b-skeleton
                                v-if="formInitialModel.isPending"
                                class="d-inline-block w-25 mb-0 align-middle"
                                variant="info"
                            />
                            <b v-else>
                                {{ dayjsShortDate(formInitialModel.$.learnerPerson.$.birth_date) }}
                                ({{ formInitialModel.$.learnerPerson.age }} ans)
                            </b>.
                        </li>

                        <li>
                            Formation concernée :
                            <b-skeleton
                                v-if="formInitialModel.isPending"
                                class="d-inline-block w-50 mb-0 align-middle"
                                variant="info"
                            />
                            <b v-else>
                                {{ formInitialModel.$.educationStudy.$.educationProgramme.$.name }}
                            </b>.
                        </li>

                    </ul>

                </b-alert>

                <b-button
                    v-if="salaryCalculatorUrl"
                    :href="salaryCalculatorUrl"
                    class="mb-3"
                    target="_blank"
                    variant="secondary"
                    block
                >
                    <font-awesome-icon
                        :icon="['fad', 'external-link']"
                        fixed-width
                    />
                    Simuler la rémunération de l'apprenant
                </b-button>

                <form-group
                    id="view-businessApprenticeContract-edit-gross-salary"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.gross_salary"
                    :left-icon="formInitialModel.$.updatable ? 'euro-sign' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.gross_salary"
                    label="Salaire brut mensuel à l'embauche versé à l'apprenti.e"
                    business-apprentice-agreement
                >
                    <b-form-input
                        v-model="formModifiedModel.$.gross_salary"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                        min="0"
                        step="0.01"
                        type="number"
                    />
                </form-group>

                <business-apprentice-salary-edit
                    v-for="businessApprenticeSalary in businessApprenticeSalaries.models"
                    :key="businessApprenticeSalary.$.id"
                    :value="businessApprenticeSalary"
                    @input="modifyBusinessApprenticeSalary"
                />

                <form-group
                    id="view-businessApprenticeContract-edit-food-benefits"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.food_benefits"
                    :left-icon="formInitialModel.$.updatable ? 'euro-sign' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.food_benefits"
                    label="Avantages en nourriture"
                    business-apprentice-agreement
                >
                    <b-form-input
                        v-model="formModifiedModel.$.food_benefits"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                        min="0"
                        step="0.01"
                        type="number"
                    />
                </form-group>

                <form-group
                    id="view-businessApprenticeContract-edit-housing-benefits"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.housing_benefits"
                    :left-icon="formInitialModel.$.updatable ? 'euro-sign' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.housing_benefits"
                    label="Avantages en logement"
                    business-apprentice-agreement
                >
                    <b-form-input
                        v-model="formModifiedModel.$.housing_benefits"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                        min="0"
                        step="0.01"
                        type="number"
                    />
                </form-group>

                <form-group
                    id="view-businessApprenticeContract-edit-other-benefits"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.other_benefits"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.other_benefits"
                    business-apprentice-agreement
                >
                    <b-form-checkbox
                        v-model="formModifiedModel.$.other_benefits"
                        v-bind="inputProps"
                        :disabled="formInitialModel.$.updatable !== true"
                    >
                        Autres avantages en nature
                    </b-form-checkbox>
                </form-group>

            </b-card>

            <b-card
                v-if="formInitialModel.showInvoiceFields"
                bg-variant="light"
                class="mb-4"
                header="Facturation"
                header-tag="h2"
            >

                <business-person-select
                    id="view-businessApprenticeContract-edit-signatory-business-invoiced-id"
                    :key="`invoiced-${triggerBusinessPersonSelects.toString()}`"
                    v-model="signatory_business_invoiced_id"
                    :business-branch="formInitialModel.$.signatoryBusinessBranch"
                    :business-people="businessPeople"
                    :disabled="formInitialModel.$.updatable !== true"
                    :error="formModifiedModel.errors.signatory_business_invoiced_id"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.signatory_business_invoiced_id"
                    business-person-create-label="Contact entreprise"
                    label="Référent comptable"
                    business-apprentice-agreement
                    @business-person-modal-close="$emit('business-person-modal-close')"
                />

                <form-group
                    id="view-businessApprenticeContract-edit-invoice-method"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.invoice_method"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.invoice_method"
                    label="Modalité de transmission de la facture"
                >
                    <b-form-radio-group
                        v-model="formModifiedModel.$.invoice_method"
                        v-bind="inputProps"
                        :disabled="formInitialModel.$.updatable !== true"
                        :options="invoiceMethodOptions"
                    />
                </form-group>

                <form-group
                    v-if="formModifiedModel.$.invoice_method === 'email'"
                    id="view-businessApprenticeContract-edit-invoice-email"
                    v-slot="inputProps"
                    :left-icon="formInitialModel.$.updatable ? 'envelope' : undefined"
                    :error="formModifiedModel.errors.invoice_email"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.invoice_email"
                    label="Adresse e-mail de facturation"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.invoice_email"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>

                <form-group
                    v-if="formModifiedModel.$.invoice_method === 'platform'"
                    id="view-businessApprenticeContract-edit-invoice-platform-name"
                    v-slot="inputProps"
                    :left-icon="formInitialModel.$.updatable ? 'text' : undefined"
                    :error="formModifiedModel.errors.invoice_platform_name"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.invoice_platform_name"
                    label="Nom de la plateforme de facturation"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.invoice_platform_name"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>

                <form-group
                    v-if="formModifiedModel.$.invoice_method === 'platform'"
                    id="view-businessApprenticeContract-edit-invoice-platform-url"
                    v-slot="inputProps"
                    :left-icon="formInitialModel.$.updatable ? 'link' : undefined"
                    :error="formModifiedModel.errors.invoice_platform_url"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.invoice_platform_url"
                    label="Adresse de la plateforme de facturation"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.invoice_platform_url"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>

                <form-group
                    id="view-businessApprenticeContract-edit-save-invoice-address-to-signatory-business-branch"
                    v-slot="inputProps"
                    :pending="formInitialModel.isPending"
                >
                    <b-form-checkbox
                        v-model="saveInvoiceFieldsToSignatoryBusinessBranch"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    >
                        Utiliser ces modalités de facturation pour les futurs contrats d'apprentissage
                        au sein de {{ formModifiedModel.$.signatoryBusinessBranch.$.businessCompany.$.name }}.
                    </b-form-checkbox>
                </form-group>

                <form-group
                    id="view-businessApprenticeContract-edit-invoice-proforma"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.invoice_proforma"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.invoice_proforma"
                >
                    <b-form-checkbox
                        v-model="formModifiedModel.$.invoice_proforma"
                        v-bind="inputProps"
                        :disabled="formInitialModel.$.updatable !== true"
                    >
                        Établissement d'une facture proforma.
                    </b-form-checkbox>
                </form-group>

                <form-group
                    id="view-businessApprenticeContract-edit-invoice-form"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.invoice_form"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.invoice_form"
                    business-apprentice-agreement
                >
                    <b-form-checkbox
                        v-model="formModifiedModel.$.invoice_form"
                        v-bind="inputProps"
                        :disabled="formInitialModel.$.updatable !== true"
                    >
                        Établissement d'un bon de commande.
                    </b-form-checkbox>
                </form-group>

                <business-person-select
                    v-if="formModifiedModel.$.invoice_form"
                    id="view-businessApprenticeContract-edit-signatory-business-purchaser-id"
                    :key="`purchaser-${triggerBusinessPersonSelects.toString()}`"
                    v-model="signatory_business_purchaser_id"
                    :business-branch="formInitialModel.$.signatoryBusinessBranch"
                    :business-people="businessPeople"
                    :disabled="formInitialModel.$.updatable !== true"
                    :error="formModifiedModel.errors.signatory_business_purchaser_id"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.signatory_business_purchaser_id"
                    business-person-create-label="Contact entreprise"
                    label="Donneur d'ordre du bon de commande"
                    business-apprentice-agreement
                    @business-person-modal-close="$emit('business-person-modal-close')"
                />

            </b-card>

        </b-col>

        <b-col
            v-if="formInitialModel.showInvoiceFields || $store.getters['auth/canAccessCenterClient']"
            cols="12"
        >
            <b-card
                bg-variant="light"
                class="mb-4"
                header="Informations complémentaires"
                header-tag="h2"
            >

                <form-group
                    v-if="formInitialModel.showInvoiceFields"
                    id="view-businessApprenticeContract-edit-invoice-description-agreement"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.invoice_description_agreement"
                    :state="formModifiedModel.states.invoice_description_agreement"
                    label="Informations complémentaires sur la facturation pour la convention"
                    business-apprentice-agreement
                >
                    <b-textarea
                        v-model="formModifiedModel.$.invoice_description_agreement"
                        v-bind="inputProps"
                        max-rows="100"
                        rows="5"
                    />
                </form-group>

                <form-group
                    v-if="$store.getters['auth/canAccessCenterClient']"
                    id="view-businessApprenticeContract-edit-invoice-description-other"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.invoice_description_other"
                    :state="formModifiedModel.states.invoice_description_other"
                    label="Informations complémentaires sur la facturation pour information"
                    description="Visible uniquement par le CFA"
                >
                    <b-textarea
                        v-model="formModifiedModel.$.invoice_description_other"
                        v-bind="inputProps"
                        max-rows="100"
                        rows="5"
                    />
                </form-group>

            </b-card>
        </b-col>

        <b-col
            v-if="formInitialModel.$.updatable"
            class="text-right"
            cols="12"
        >
            <button-submit
                :is-pending="formIsPending || businessApprenticeSalaries.models.some((m) => m.isPending)"
                icon="edit"
                variant="success"
            >
                Enregistrer le contrat d'apprentissage
            </button-submit>
        </b-col>

    </b-row>


</template>



<script>

    import BusinessApprenticeBranchType from '@core/models/businessApprenticeBranchType';
    import BusinessApprenticeDuration from '@core/models/businessApprenticeDuration';
    import BusinessApprenticeExemption from '@core/models/businessApprenticeExemption';
    import BusinessApprenticeReason from '@core/models/businessApprenticeReason';
    import BusinessBranch from '@core/models/businessBranch';
    import BusinessIdcc from '@core/models/businessIdcc';
    import BusinessApprenticeClass from '@core/models/businessApprenticeClass';
    import BusinessApprenticeContract from '@core/models/businessApprenticeContract';
    import BusinessApprenticeDiploma from '@core/models/businessApprenticeDiploma';
    import BusinessApprenticeSalary from '@core/models/businessApprenticeSalary';
    import BusinessApprenticeSituation from '@core/models/businessApprenticeSituation';
    import BusinessPerson from '@core/models/businessPerson';
    import Collection from '@core/models/collection';
    import Dayjs from '@core/mixins/dayjs';
    import Form from '@core/mixins/form';
    import LearnerEducationStudy from '@core/models/learnerEducationStudy';

    export default {
        mixins: [Dayjs, Form],
        props: {
            businessApprenticeContractEditRoute: {
                type: Object,
                required: true,
            },
            businessApprenticeContractLocationRoute: {
                type: Object,
                required: true,
            },
            businessApprenticeContractInvoiceAddressRoute: {
                type: Object,
                required: true,
            },
            businessApprenticeContractPurposeRoute: {
                type: Object,
                required: true,
            },
            isDelegatee: {
                type: Boolean,
                default: false,
            },
            signatoryBusinessPersonEditRoute: {
                type: Object,
                required: true,
            },
            triggerBusinessPersonSelects: {
                type: Boolean,
                default: true,
            },
            value: {
                type: BusinessApprenticeContract,
                required: true,
            },
        },
        data: () => ({
            businessApprenticeSalaries: new Collection(new BusinessApprenticeSalary(), { period: 'asc' }),
            businessPeople: new Collection(new BusinessPerson(), {}, 1000),
            saveInvoiceFieldsToSignatoryBusinessBranch: false,
        }),
        computed: {
            business_apprentice_branch_type_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.businessApprenticeBranchType
                        ? vue.formModifiedModel.$.businessApprenticeBranchType.$.id
                        : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.businessApprenticeBranchType = new BusinessApprenticeBranchType({ id });
                },
            },
            business_apprentice_duration_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.businessApprenticeDuration
                        ? vue.formModifiedModel.$.businessApprenticeDuration.$.id
                        : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.businessApprenticeDuration = new BusinessApprenticeDuration({ id });
                },
            },
            business_apprentice_exemption_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.businessApprenticeExemption
                        ? vue.formModifiedModel.$.businessApprenticeExemption.$.id
                        : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.businessApprenticeExemption = new BusinessApprenticeExemption({ id });
                },
            },
            business_apprentice_reason_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.businessApprenticeReason
                        ? vue.formModifiedModel.$.businessApprenticeReason.$.id
                        : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.businessApprenticeReason = new BusinessApprenticeReason({ id });
                },
            },
            businessIdcc: (vue) => vue.formInitialModel
                .$.executingBusinessBranch
                .$.businessIdccs
                .find((businessIdcc) => businessIdcc.$.id === vue.business_idcc_id),
            businessIdccs: (vue) => vue.formInitialModel
                .$.executingBusinessBranch
                .$.businessIdccs
                .map((businessIdcc) => ({
                    text: `${businessIdcc.$.code} - ${businessIdcc.$.name}`,
                    value: businessIdcc.$.id,
                })),
            business_idcc_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.businessIdcc ? vue.formModifiedModel.$.businessIdcc.$.id : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.businessIdcc = new BusinessIdcc({ id });
                },
            },
            convergenceEnabled: (vue) => vue.businessIdcc !== undefined
                && vue.businessIdcc.$.businessOpco !== undefined
                && vue.businessIdcc.$.businessOpco.$.convergence_auth_url !== '',
            education_study_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.educationStudy ? vue.formModifiedModel.$.educationStudy.$.id : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.educationStudy = this.educationStudies.find(
                        (educationStudy) => educationStudy.$.id === id,
                    );
                },
            },
            educationStudies: (vue) => vue.formInitialModel.$.learnerPerson.$.learnerEducationStudies
                .filter(
                    (learnerEducationStudy) => Object.keys(LearnerEducationStudy.schoolingsApprentice).includes(
                        learnerEducationStudy.$.schooling,
                    ),
                )
                .map((learnerEducationStudy) => learnerEducationStudy.$.educationStudy)
                .filter((educationStudy) => educationStudy.$.creatableBusinessApprenticeContracts),
            educationStudyOptions: (vue) => vue.educationStudies.map((educationStudy) => ({
                text: `${educationStudy.$.name} (${educationStudy.yearAsText}), `
                    + `${educationStudy.$.educationProgramme.$.school.$.name}`,
                value: educationStudy.$.id,
            })),
            highest_business_apprentice_diploma_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.highestBusinessApprenticeDiploma
                        ? vue.formModifiedModel.$.highestBusinessApprenticeDiploma.$.id
                        : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.highestBusinessApprenticeDiploma = new BusinessApprenticeDiploma({ id });
                },
            },
            invoiceMethodOptions: () => Object.entries(BusinessApprenticeContract.invoiceMethods).map(
                ([value, text]) => ({ text, value }),
            ),
            last_business_apprentice_class_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.lastBusinessApprenticeClass
                        ? vue.formModifiedModel.$.lastBusinessApprenticeClass.$.id
                        : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.lastBusinessApprenticeClass = new BusinessApprenticeClass({ id });
                },
            },
            last_business_apprentice_diploma_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.lastBusinessApprenticeDiploma
                        ? vue.formModifiedModel.$.lastBusinessApprenticeDiploma.$.id
                        : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.lastBusinessApprenticeDiploma = new BusinessApprenticeDiploma({ id });
                },
            },
            last_business_apprentice_situation_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.lastBusinessApprenticeSituation
                        ? vue.formModifiedModel.$.lastBusinessApprenticeSituation.$.id
                        : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.lastBusinessApprenticeSituation = new BusinessApprenticeSituation({ id });
                },
            },
            formInitialModel: {
                get: (vue) => vue.value,
                set(businessApprenticeContract) {
                    this.$emit('input', businessApprenticeContract);
                },
            },
            platformName: (vue) => vue.$store.getters['center/platformName'],
            salaryCalculatorUrl(vue) {
                if (import.meta.env.VITE_BUSINESS_APPRENTICE_SALARY_CALCULATOR_URL === '') {
                    return undefined;
                }
                const url = new URL(import.meta.env.VITE_BUSINESS_APPRENTICE_SALARY_CALCULATOR_URL);
                url.searchParams.append('business_apprentice_contract_start', vue.formModifiedModel.$.start);
                url.searchParams.append('business_apprentice_contract_end', vue.formModifiedModel.$.end);
                url.searchParams.append(
                    'education_diploma_reference',
                    vue.value.$.educationStudy.$.educationProgramme.$.educationDiploma.$.reference,
                );
                url.searchParams.append('learner_person_birth_date', vue.value.$.learnerPerson.$.birth_date);
                return url.toString();
            },
            signatory_business_contact_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.signatoryBusinessContact
                        ? vue.formModifiedModel.$.signatoryBusinessContact.$.id
                        : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.signatoryBusinessContact = new BusinessPerson({ id });
                },
            },
            signatory_business_invoiced_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.signatoryBusinessInvoiced
                        ? vue.formModifiedModel.$.signatoryBusinessInvoiced.$.id
                        : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.signatoryBusinessInvoiced = new BusinessPerson({ id });
                },
            },
            signatory_business_purchaser_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.signatoryBusinessPurchaser
                        ? vue.formModifiedModel.$.signatoryBusinessPurchaser.$.id
                        : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.signatoryBusinessPurchaser = new BusinessPerson({ id });
                },
            },
            signatory_business_remaining_balance_granter_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.signatoryBusinessRemainingBalanceGranter
                        ? vue.formModifiedModel.$.signatoryBusinessRemainingBalanceGranter.$.id
                        : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.signatoryBusinessRemainingBalanceGranter = new BusinessPerson({ id });
                },
            },
            signatory_business_representative_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.signatoryBusinessRepresentative
                        ? vue.formModifiedModel.$.signatoryBusinessRepresentative.$.id
                        : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.signatoryBusinessRepresentative = new BusinessPerson({ id });
                },
            },
            socialSecuritySchemes: () => Object.entries(BusinessApprenticeContract.socialSecuritySchemes).map(
                ([value, text]) => ({ text, value }),
            ),
            weekly_working_hours: {
                get: (vue) => Math.floor(vue.formModifiedModel.$.weekly_working_minutes / 60),
                set(weeklyWorkingHours) {
                    this.formModifiedModel.$.weekly_working_minutes = parseInt(weeklyWorkingHours, 10) * 60
                        + this.weekly_working_minutes;
                },
            },
            weekly_working_minutes: {
                get: (vue) => vue.formModifiedModel.$.weekly_working_minutes % 60,
                set(weeklyWorkingMinutes) {
                    this.formModifiedModel.$.weekly_working_minutes = this.weekly_working_hours * 60
                        + parseInt(weeklyWorkingMinutes, 10);
                },
            },
        },
        watch: {
            'formInitialModel.$.id': {
                handler: 'refreshBusinessApprenticeSalaries',
                immediate: true,
            },
            'formModifiedModel.$.signatoryBusinessBranch.$.businessCompany.$.id': {
                handler(id) {
                    if (id) {
                        this.businessPeople.request().index({
                            business_company_id: id,
                            orders: { lastname: 'asc', firstname: 'asc' },
                        });
                    }
                },
                immediate: true,
            },
        },
        methods: {
            formSubmit() {
                this.isPending = true;
                this.businessApprenticeSalaries.models
                    .reduce((promise, model) => promise.then(() => model.request().update()), Promise.resolve(true))
                    .then(() => this.formModifiedModel.request().update())
                    .then(this.formSubmitSuccessful)
                    .catch(() => { this.isPending = false; });
            },
            formSubmitSuccessful(updatedBusinessApprenticeContract) {
                this.$root.toastSuccess(
                    "Les informations du contrat d'apprentissage ont été modifiées",
                    "L'enregistrement des informations a réussi.",
                );
                if (this.saveInvoiceFieldsToSignatoryBusinessBranch) {
                    this.updateSignatoryBusinessBranchInvoiceFields().then(() => {
                        this.formInitialModel = new BusinessApprenticeContract(updatedBusinessApprenticeContract.$);
                        this.refreshBusinessApprenticeSalaries();
                    });
                } else {
                    this.formInitialModel = new BusinessApprenticeContract(updatedBusinessApprenticeContract.$);
                    this.refreshBusinessApprenticeSalaries();
                }
            },
            modifyBusinessApprenticeSalary(modifiedBusinessApprenticeSalary) {
                const initialBusinessApprenticeSalary = this.businessApprenticeSalaries.models.find(
                    (businessApprenticeSalary) => businessApprenticeSalary.$.id
                        === modifiedBusinessApprenticeSalary.$.id,
                );

                if (
                    JSON.stringify(modifiedBusinessApprenticeSalary.$)
                    === JSON.stringify(initialBusinessApprenticeSalary.$)
                ) {
                    return;
                }

                this.businessApprenticeSalaries.models = this.businessApprenticeSalaries.models.map(
                    (businessApprenticeSalary) => (
                        businessApprenticeSalary.is(modifiedBusinessApprenticeSalary)
                            ? modifiedBusinessApprenticeSalary
                            : businessApprenticeSalary
                    ),
                );
            },
            refreshBusinessApprenticeSalaries() {
                this.businessApprenticeSalaries
                    .prefill(6)
                    .request(1, undefined, { period: 'asc' })
                    .index({ business_apprentice_contract_id: this.formInitialModel.$.id });
            },
            resetEducationalHistory() {
                this.highest_business_apprentice_diploma_id = (
                    this.formInitialModel.$.learnerPerson.$.highestBusinessApprenticeDiploma
                        ? this.formInitialModel.$.learnerPerson.$.highestBusinessApprenticeDiploma.$.id
                        : null
                );
                this.last_business_apprentice_class_id = (
                    this.formInitialModel.$.learnerPerson.$.lastBusinessApprenticeClass
                        ? this.formInitialModel.$.learnerPerson.$.lastBusinessApprenticeClass.$.id
                        : null
                );
                this.last_business_apprentice_diploma_id = (
                    this.formInitialModel.$.learnerPerson.$.lastBusinessApprenticeDiploma
                        ? this.formInitialModel.$.learnerPerson.$.lastBusinessApprenticeDiploma.$.id
                        : null
                );
                this.last_business_apprentice_situation_id = (
                    this.formInitialModel.$.learnerPerson.$.lastBusinessApprenticeSituation
                        ? this.formInitialModel.$.learnerPerson.$.lastBusinessApprenticeSituation.$.id
                        : null
                );
                this.formModifiedModel.$.last_prepared_diploma_name = this.formInitialModel
                    .$.learnerPerson
                    .$.last_prepared_diploma_name;
            },
            updateSignatoryBusinessBranchInvoiceFields() {
                return new BusinessBranch({
                    ...this.formInitialModel.$.signatoryBusinessBranch.$,
                    invoice_email: this.formInitialModel.$.invoice_email,
                    invoice_method: this.formInitialModel.$.invoice_method,
                    invoice_platform_name: this.formInitialModel.$.invoice_platform_name,
                    invoice_platform_url: this.formInitialModel.$.invoice_platform_url,
                }).request().silent().update();
            },
        },
    };

</script>
