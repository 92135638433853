<template>

    <b-card
        class="address-edit"
        tag="form"
        no-body
        @submit.prevent="formSubmit"
    >

        <b-card-header
            v-if="header"
            header-tag="h2"
        >
            {{ header }}
            <form-business-apprentice-agreement v-if="businessApprenticeAgreement" />
        </b-card-header>

        <b-card-body>

            <slot />

            <b-alert
                :show="formInitialModel.$.data_source === 'opendatasoft'"
                class="mb-4"
                variant="info"
            >
                <font-awesome-icon
                    :icon="['fad', 'info']"
                    fixed-width
                />
                Cette adresse est issue du registre officiel de l'INSEE :
                certaines informations ne sont pas modifiables.
            </b-alert>

            <div
                v-if="formInitialModel.$.updatable && formInitialModel.editableAttribues.includes('country')"
                class="mb-4 text-center"
            >
                <b-button-group>

                    <b-button
                        id="view-address-edit-is-france"
                        :pressed.sync="isFrance"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'hexagon']"
                            rotation="90"
                            fixed-width
                        />
                        En France
                    </b-button>

                    <b-button
                        id="view-address-edit-is-aborad"
                        :pressed.sync="isAbroad"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'globe']"
                            fixed-width
                        />
                        À l'étranger
                    </b-button>

                </b-button-group>
            </div>

            <b-row>

                <b-col cols="12">
                    <form-group
                        id="view-address-edit-appartment"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.appartment"
                        :left-icon="formInitialModel.$.updatable ? 'building' : undefined"
                        :pending="formInitialModel.$.id === undefined"
                        :state="formModifiedModel.states.appartment"
                        label="N° appartement ou bureau, Étage, Couloir, Escalier"
                    >
                        <b-form-input
                            v-model="formModifiedModel.$.appartment"
                            v-bind="inputProps"
                            :plaintext="formInitialModel.$.updatable !== true"
                            :readonly="formInitialModel.editableAttribues.includes('appartment') !== true"
                            autocomplete="address-line1"
                        />
                    </form-group>
                </b-col>

                <b-col cols="12">
                    <form-group
                        id="view-address-edit-building"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.building"
                        :left-icon="formInitialModel.$.updatable ? 'building' : undefined"
                        :pending="formInitialModel.$.id === undefined"
                        :state="formModifiedModel.states.building"
                        label="Entrée, Bâtiment, Immeuble, Résidence, Zone Industrielle"
                    >
                        <b-form-input
                            v-model="formModifiedModel.$.building"
                            v-bind="inputProps"
                            :plaintext="formInitialModel.$.updatable !== true"
                            :readonly="formInitialModel.editableAttribues.includes('building') !== true"
                            autocomplete="address-line2"
                        />
                    </form-group>
                </b-col>

                <b-col
                    lg="2"
                    md="3"
                    sm="4"
                >
                    <form-group
                        id="view-address-edit-street_number"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.street_number"
                        :left-icon="formInitialModel.$.updatable ? 'road' : undefined"
                        :pending="formInitialModel.$.id === undefined"
                        :state="formModifiedModel.states.street_number"
                        label="Numéro"
                    >
                        <b-form-input
                            v-model="formModifiedModel.$.street_number"
                            v-bind="inputProps"
                            :plaintext="formInitialModel.$.updatable !== true"
                            :readonly="formInitialModel.editableAttribues.includes('street_number') !== true"
                            autocomplete="off"
                        />
                    </form-group>
                </b-col>

                <b-col
                    lg="10"
                    md="9"
                    sm="8"
                >
                    <form-group
                        id="view-address-edit-street_name"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.street_name"
                        :left-icon="formInitialModel.$.updatable ? 'road' : undefined"
                        :pending="formInitialModel.$.id === undefined"
                        :state="formModifiedModel.states.street_name"
                        label="Voie"
                    >
                        <b-form-input
                            v-model="formModifiedModel.$.street_name"
                            v-bind="inputProps"
                            :plaintext="formInitialModel.$.updatable !== true"
                            :readonly="formInitialModel.editableAttribues.includes('street_name') !== true"
                            autocomplete="address-line3"
                        />
                    </form-group>
                </b-col>

                <b-col cols="12">
                    <form-group
                        id="view-address-edit-locality"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.locality"
                        :left-icon="formInitialModel.$.updatable ? 'city' : undefined"
                        :pending="formInitialModel.$.id === undefined"
                        :state="formModifiedModel.states.locality"
                        label="Lieu dit, Service particulier de distribution"
                    >
                        <b-form-input
                            v-model="formModifiedModel.$.locality"
                            v-bind="inputProps"
                            :plaintext="formInitialModel.$.updatable !== true"
                            :readonly="formInitialModel.editableAttribues.includes('locality') !== true"
                            autocomplete="address-level3"
                        />
                    </form-group>
                </b-col>

                <b-col
                    lg="2"
                    md="3"
                    sm="4"
                >
                    <form-group
                        id="view-address-edit-post-code"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.post_code"
                        :left-icon="formInitialModel.$.updatable ? 'city' : undefined"
                        :pending="formInitialModel.$.id === undefined"
                        :state="formModifiedModel.states.post_code"
                        label="Code postal"
                    >
                        <b-form-input
                            v-model="formModifiedModel.$.post_code"
                            v-bind="inputProps"
                            :plaintext="formInitialModel.$.updatable !== true"
                            :readonly="formInitialModel.editableAttribues.includes('post_code') !== true"
                            debounce="500"
                            autocomplete="postal-code"
                        />
                    </form-group>
                </b-col>

                <b-col
                    lg="10"
                    md="9"
                    sm="8"
                >

                    <model-list
                        v-if="isFrance"
                        v-slot="{ collection, model, options, setValue, value: inseeCode }"
                        :value="city.$.insee_code"
                        :option-value="(city) => city.$.insee_code"
                        :request-data="{ codePostal: formModifiedModel.$.post_code }"
                        name="City"
                        @change="city = $event"
                    >
                        <form-group
                            id="view-address-edit-city"
                            v-slot="inputProps"
                            :error="formModifiedModel.errors.city"
                            :left-icon="
                                formInitialModel.$.updatable
                                    ? (collection.isPending ? 'circle-notch' : 'city')
                                    : undefined
                            "
                            :left-icon-spin="collection.isPending"
                            :pending="formInitialModel.isPending"
                            :state="formModifiedModel.states.city"
                            label="Ville"
                        >
                            <b-form-select
                                v-if="
                                    formInitialModel.$.updatable && formInitialModel.editableAttribues.includes('city')
                                "
                                v-bind="inputProps"
                                :options="options"
                                :value="inseeCode"
                                @input="setValue"
                            />
                            <b-form-input
                                v-else
                                v-bind="inputProps"
                                :plaintext="formInitialModel.$.updatable !== true"
                                :readonly="formInitialModel.editableAttribues.includes('city') !== true"
                                :value="model ? model.$.name : undefined"
                            />
                        </form-group>
                    </model-list>

                    <form-group
                        v-else
                        id="view-address-edit-city"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.city"
                        :left-icon="formInitialModel.$.updatable ? 'city' : undefined"
                        :pending="formInitialModel.$.id === undefined"
                        :state="formModifiedModel.states.city"
                        label="Ville"
                    >
                        <b-form-input
                            v-model="formModifiedModel.$.city"
                            v-bind="inputProps"
                            :plaintext="formInitialModel.$.updatable !== true"
                            :readonly="formInitialModel.editableAttribues.includes('city') !== true"
                            autocomplete="address-level2"
                        />
                    </form-group>

                </b-col>

                <b-col
                    v-if="isAbroad"
                    cols="12"
                >
                    <form-group
                        id="view-address-edit-country"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.country"
                        :left-icon="
                            formInitialModel.$.updatable && formInitialModel.editableAttribues.includes('country')
                                ? 'globe'
                                : undefined
                        "
                        :pending="formInitialModel.$.id === undefined"
                        :state="formModifiedModel.states.country"
                        label="Pays"
                        required
                    >
                        <b-form-input
                            v-model="formModifiedModel.$.country"
                            v-bind="inputProps"
                            :plaintext="formInitialModel.$.updatable !== true"
                            :readonly="formInitialModel.editableAttribues.includes('country') !== true"
                            autocomplete="country-name"
                        />
                    </form-group>
                </b-col>

                <b-col cols="12">
                    <form-group
                        id="view-address-edit-landline_phone"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.landline_phone"
                        :left-icon="formInitialModel.$.updatable ? 'phone-office' : undefined"
                        :pending="formInitialModel.$.id === undefined"
                        :state="formModifiedModel.states.landline_phone"
                        label="Téléphone fixe"
                    >
                        <b-form-input
                            v-model="formModifiedModel.$.landline_phone"
                            v-bind="inputProps"
                            :plaintext="formInitialModel.$.updatable !== true"
                            :readonly="formInitialModel.editableAttribues.includes('landline_phone') !== true"
                            autocomplete="tel"
                            pattern="^\+?\d{7,14}$"
                            title="7 à 14 chiffres"
                        />
                    </form-group>
                </b-col>

                <b-col cols="12">
                    <form-group
                        id="view-address-edit-mobile_phone"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.mobile_phone"
                        :left-icon="formInitialModel.$.updatable ? 'mobile' : undefined"
                        :pending="formInitialModel.$.id === undefined"
                        :state="formModifiedModel.states.mobile_phone"
                        label="Téléphone portable"
                    >
                        <b-form-input
                            v-model="formModifiedModel.$.mobile_phone"
                            v-bind="inputProps"
                            :plaintext="formInitialModel.$.updatable !== true"
                            :readonly="formInitialModel.editableAttribues.includes('mobile_phone') !== true"
                            autocomplete="tel"
                            pattern="^\+?\d{7,14}$"
                            title="7 à 14 chiffres"
                        />
                    </form-group>
                </b-col>

                <b-col
                    v-if="showFax"
                    cols="12"
                >
                    <form-group
                        id="view-address-edit-fax"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.fax"
                        :left-icon="formInitialModel.$.updatable ? 'fax' : undefined"
                        :pending="formInitialModel.$.id === undefined"
                        :state="formModifiedModel.states.fax"
                        label="Fax"
                    >
                        <b-form-input
                            v-model="formModifiedModel.$.fax"
                            v-bind="inputProps"
                            :plaintext="formInitialModel.$.updatable !== true"
                            :readonly="formInitialModel.editableAttribues.includes('fax') !== true"
                            pattern="^\+?\d{7,14}$"
                            title="7 à 14 chiffres"
                        />
                    </form-group>
                </b-col>

            </b-row>
        </b-card-body>

        <b-card-footer
            v-if="formInitialModel.$.updatable"
            class="text-right"
        >
            <slot
                :form-is-pending="formIsPending"
                name="footer"
            >
                <button-submit
                    :is-pending="formIsPending"
                    icon="edit"
                    variant="success"
                >
                    Enregistrer l'adresse
                </button-submit>
            </slot>
        </b-card-footer>

    </b-card>

</template>



<script>

    import Address from '@core/models/address';
    import City from '@core/models/city';
    import Form from '@core/mixins/form';

    export default {
        mixins: [Form],
        props: {
            businessApprenticeAgreement: {
                type: Boolean,
                default: false,
            },
            header: {
                type: String,
                default: undefined,
            },
            showFax: {
                type: Boolean,
                default: false,
            },
            value: {
                type: Address,
                required: true,
            },
        },
        computed: {
            city: {
                get: (vue) => new City({
                    name: vue.formModifiedModel.$.city,
                    post_code: vue.formModifiedModel.$.post_code,
                    insee_code: vue.formModifiedModel.$.insee_code,
                }),
                set(city) {
                    this.formModifiedModel.$.city = (city || new City()).$.name || '';
                    this.formModifiedModel.$.post_code = (city || new City()).$.post_code || '';
                    this.formModifiedModel.$.insee_code = (city || new City()).$.insee_code || '';
                },
            },
            formInitialModel: (vue) => vue.value,
            isAbroad: {
                get: (vue) => vue.isFrance === false,
                set(isAbroad) { this.isFrance = !isAbroad; },
            },
            isFrance: {
                get: (vue) => vue.formModifiedModel.$.country === 'France',
                set(isFrance) {
                    this.formModifiedModel.$.country = isFrance ? 'France' : '';
                },
            },
        },
        methods: {
            formSubmitSuccessful(address) {
                this.$root.toastSuccess(
                    "L'adresse a été modifiée",
                    "L'enregistrement des informations a réussi.",
                );
                this.$emit('input', address);
            },
        },
    };

</script>
