<template>

    <span
        v-b-tooltip.noninteractive
        :title="title"
        class="text-danger"
    >
        <font-awesome-icon
            :icon="['fad', 'asterisk']"
            size="xs"
            transform="up-6"
            fixed-width
        />
    </span>

</template>



<script>

    export default {
        props: {
            title: {
                type: String,
                default: 'Cette information est obligatoire',
            },
        },
    };

</script>
