<template>

    <div class="d-flex">

        <font-awesome-icon
            :class="`bg-${variant}`"
            :icon="['fad', questionnaireEntry.isPending ? 'circle-notch' : icon]"
            :spin="questionnaireEntry.isPending"
            class="mr-2 p-3 rounded-circle text-white"
            size="lg"
        />

        <div class="flex-fill">

            <b-skeleton
                v-if="questionnaireEntry.isPending"
                class="w-75"
                variant="primary"
            />

            <b v-else>
                {{ title }}
            </b>

            <template v-if="questionnaireEntry.isPending">
                <b-skeleton class="w-100" />
                <b-skeleton class="w-50" />
            </template>

            <p
                v-else-if="validatedAt"
                class="my-0 text-muted"
            >
                A validé le formulaire le {{ dayjsShortDate(validatedAt) }}
            </p>

            <p
                v-else
                class="my-0 text-muted"
            >
                {{ questionnaireSurveyAccessName }}
            </p>

        </div>

    </div>

</template>



<script>

    import Dayjs from '@core/mixins/dayjs';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';
    import QuestionnaireSurvey from '@core/models/questionnaireSurvey';

    export default {
        mixins: [Dayjs],
        props: {
            questionnaireEntry: {
                type: QuestionnaireEntry,
                required: true,
            },
            questionnaireSurveyAccess: {
                type: String,
                default: '',
            },
            title: {
                type: String,
                required: true,
            },
            validatedAt: {
                type: String,
                default: null,
            },
        },
        computed: {
            icon: (vue) => vue.questionnaireEntry.icon(vue.questionnaireSurveyAccess),
            questionnaireSurveyAccessName: (vue) => QuestionnaireSurvey.accessNames[vue.questionnaireSurveyAccess],
            variant: (vue) => vue.questionnaireEntry.variant(vue.questionnaireSurveyAccess, vue.validatedAt !== null),
        },
    };

</script>



<style scoped>

    .not-collapsed > .fa-caret-down,
    .collapsed > .fa-caret-up {
        display: none;
    }

</style>
