import Academy from '@core/models/academy';
import Center from '@core/models/center';
import EducationStudy from '@core/models/educationStudy';
import Request from '@core/request';
import School from '@core/models/school';
import User from '@core/models/user';
import * as Sentry from '@sentry/browser';

const formatCanCreate = (resource) => `canCreate${resource.charAt(0).toUpperCase()}${resource.slice(1)}`;

export default {

    namespaced: true,

    state: () => ({
        user: undefined,
    }),

    getters: {
        academyJurisdictions: (state) => (
            state.user
                ? state.user.$.capabilities
                    .map((capability) => capability.$.jurisdiction)
                    .filter((jurisdiction) => jurisdiction.constructor.className === Academy.className)
                    .reduce((academies, academy) => {
                        if (academies.length === 0 || academies.some((a) => a.is(academy)) === false) {
                            academies.push(academy);
                        }
                        return academies;
                    }, [])
                : []
        ),
        avatar: (state, getters) => {
            if (state.user === undefined) {
                return undefined;
            }
            if (getters.learnerPerson) {
                return getters.learnerPerson.$.avatar;
            }
            return state.user.$.avatar;
        },
        businessPeople: (state) => (state.user ? state.user.$.businessPeople : []),
        canAccessCenterClient: (state) => (
            state.user
                ? state.user.$.capabilities.some((c) => c.$.jurisdiction_type === Center.className)
                : false
        ),
        canAccessLearnerClient: (state) => (state.user ? state.user.$.learnerPerson !== undefined : false),
        canAccessMentorClient: (state, getters) => getters.isMentor,
        canAccessSchoolClient: (state) => (
            state.user
                ? state.user.$.capabilities.some(
                    (c) => c.$.jurisdiction_type === Academy.className
                        || c.$.jurisdiction_type === Center.className
                        || c.$.jurisdiction_type === EducationStudy.className
                        || c.$.jurisdiction_type === School.className,
                )
                : false
        ),
        canCreate: (state) => (resource) => (state.user ? state.user.$[formatCanCreate(resource)] : false),
        centerJurisdiction: (state) => (
            state.user
                ? state.user.$.capabilities
                    .map((capability) => capability.$.jurisdiction)
                    .filter((jurisdiction) => jurisdiction.constructor.className === Center.className)
                    .shift()
                : undefined
        ),
        defaultClient: (state, getters) => {
            if (getters.canAccessCenterClient) {
                return import.meta.env.VITE_APP_URL_CENTER;
            }
            if (getters.canAccessSchoolClient) {
                return import.meta.env.VITE_APP_URL_SCHOOL;
            }
            if (getters.canAccessLearnerClient) {
                return import.meta.env.VITE_APP_URL_LEARNER;
            }
            if (getters.canAccessMentorClient) {
                return import.meta.env.VITE_APP_URL_MENTOR;
            }
            return undefined;
        },
        educationPeople: (state) => (state.user ? state.user.$.educationPeople : []),
        educationStudyJurisdictions: (state) => (
            state.user
                ? state.user.$.capabilities
                    .map((capability) => capability.$.jurisdiction)
                    .filter(
                        (jurisdiction) => jurisdiction.constructor.className === EducationStudy.className,
                    )
                    .reduce((educationStudies, educationStudy) => {
                        if (educationStudies.length === 0
                            || educationStudies.every((a) => a.is(educationStudy)) === false) {
                            educationStudies.push(educationStudy);
                        }
                        return educationStudies;
                    }, [])
                : []
        ),
        fullname: (state) => (state.user ? state.user.$.fullname : undefined),
        id: (state) => (state.user ? state.user.$.id : undefined),
        isConnected: (state) => Boolean(state.user ? state.user.$.id : undefined),
        isMentor: (state) => state.user && (
            state.user.$.businessPeople.some(
                (businessPerson) => businessPerson.$.hasBusinessDirectMentorships
                    || businessPerson.$.hasOfficialBusinessApprenticeMentorships
                    || businessPerson.$.hasUnofficialBusinessApprenticeMentorships,
            )
            || state.user.$.educationPeople.some((educationPerson) => educationPerson.$.hasEducationMentorships)
        ),
        learnerPerson: (state) => (state.user ? state.user.$.learnerPerson : undefined),
        schoolJurisdictions: (state) => (
            state.user
                ? state.user.$.capabilities
                    .map((capability) => capability.$.jurisdiction)
                    .filter((jurisdiction) => jurisdiction.constructor.className === School.className)
                    .reduce((schools, school) => {
                        if (schools.length === 0 || schools.every((a) => a.is(school)) === false) {
                            schools.push(school);
                        }
                        return schools;
                    }, [])
                : []
        ),
        uniqueJurisdiction: (state, getters) => {
            if (getters.centerJurisdiction) {
                return undefined;
            }
            if (
                getters.academyJurisdictions.length
                    + getters.schoolJurisdictions.length
                    + getters.educationStudyJurisdictions.length !== 1
            ) {
                return undefined;
            }
            return getters.academyJurisdictions[0]
                || getters.schoolJurisdictions[0]
                || getters.educationStudyJurisdictions[0];
        },
        user: (state) => new User({ ...state.user.$ }),
    },

    mutations: {
        user: (state, user) => {
            state.user = user;
            if (user) {
                Sentry.setUser({ id: user.$.id, email: user.$.email });
            } else {
                Sentry.getCurrentScope().setUser(null);
            }
        },
    },

    actions: {
        set: ({ commit, getters }, user) => {
            if (getters.id && getters.id !== user.$.id) {
                return;
            }
            if (getters.learnerPerson && user.$.learnerPerson === undefined) {
                return;
            }
            commit('user', user);
            if (window.app.printMode !== true) {
                new Center({ id: 1 }).request().silent().show();
            }
            if (window.origin !== import.meta.env.VITE_APP_URL_CONTRACT && getters.defaultClient === undefined) {
                new Request().axios.post('/auth/logout').then(() => window.location.reload());
                return;
            }
            if (window.origin === import.meta.env.VITE_APP_URL_CENTER && getters.canAccessCenterClient === false) {
                window.location = getters.defaultClient;
            }
            if (window.origin === import.meta.env.VITE_APP_URL_LEARNER && getters.canAccessLearnerClient === false) {
                window.location = getters.defaultClient;
            }
            if (window.origin === import.meta.env.VITE_APP_URL_MENTOR && getters.canAccessMentorClient === false) {
                window.location = getters.defaultClient;
            }
            if (window.origin === import.meta.env.VITE_APP_URL_SCHOOL && getters.canAccessSchoolClient === false) {
                window.location = getters.defaultClient;
            }
        },
        unset: ({ commit }) => commit('user', undefined),
    },

};
