import Address from '@core/models/address';
import RestModel from '@core/models/_rest';
import Upload from '@core/models/upload';
import Web from '@core/models/web';

export default class Center extends RestModel {

    static get centerClientLink() {
        return `${import.meta.env.VITE_APP_URL_CENTER}/cfa/informations-generales`;
    }

    static get className() {
        return 'App\\Models\\Center';
    }

    get url() {
        return this.$.id ? `centers/${this.$.id}` : 'centers';
    }

    fill({
        businessApprenticeCertificateTemplate = undefined,
        businessApprenticeCommercialAgreementTemplate = undefined,
        companyLogoLg = undefined,
        companyLogoMd = undefined,
        companyLogoSm = undefined,
        learnerBylaw = undefined,
        office = undefined,
        platformLogoLg = undefined,
        platformLogoMd = undefined,
        platformLogoSm = undefined,
        web = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            businessApprenticeCertificateTemplate:
                businessApprenticeCertificateTemplate
                    ? new Upload(businessApprenticeCertificateTemplate.$ || businessApprenticeCertificateTemplate)
                    : undefined,
            businessApprenticeCommercialAgreementTemplate:
                businessApprenticeCommercialAgreementTemplate
                    ? new Upload(
                        businessApprenticeCommercialAgreementTemplate.$
                            || businessApprenticeCommercialAgreementTemplate,
                    )
                    : undefined,
            companyLogoLg: companyLogoLg ? new Upload(companyLogoLg.$ || companyLogoLg) : undefined,
            companyLogoMd: companyLogoMd ? new Upload(companyLogoMd.$ || companyLogoMd) : undefined,
            companyLogoSm: companyLogoSm ? new Upload(companyLogoSm.$ || companyLogoSm) : undefined,
            learnerBylaw: learnerBylaw ? new Upload(learnerBylaw.$ || learnerBylaw) : undefined,
            office: office ? new Address(office.$ || office) : undefined,
            platformLogoLg: platformLogoLg ? new Upload(platformLogoLg.$ || platformLogoLg) : undefined,
            platformLogoMd: platformLogoMd ? new Upload(platformLogoMd.$ || platformLogoMd) : undefined,
            platformLogoSm: platformLogoSm ? new Upload(platformLogoSm.$ || platformLogoSm) : undefined,
            web: web ? new Web(web.$ || web) : undefined,
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            businessApprenticeCertificateTemplate:
                this.$.businessApprenticeCertificateTemplate instanceof File
                    ? this.$.businessApprenticeCertificateTemplate
                    : undefined,
            businessApprenticeCommercialAgreementTemplate:
                this.$.businessApprenticeCommercialAgreementTemplate instanceof File
                    ? this.$.businessApprenticeCommercialAgreementTemplate
                    : undefined,
            companyLogoLg: this.$.companyLogoLg instanceof File ? this.$.companyLogoLg : undefined,
            companyLogoMd: this.$.companyLogoMd instanceof File ? this.$.companyLogoMd : undefined,
            companyLogoSm: this.$.companyLogoSm instanceof File ? this.$.companyLogoSm : undefined,
            learnerBylaw: this.$.learnerBylaw instanceof File ? this.$.learnerBylaw : undefined,
            platformLogoLg: this.$.platformLogoLg instanceof File ? this.$.platformLogoLg : undefined,
            platformLogoMd: this.$.platformLogoMd instanceof File ? this.$.platformLogoMd : undefined,
            platformLogoSm: this.$.platformLogoSm instanceof File ? this.$.platformLogoSm : undefined,
        });
    }

    showSuccessful(response) {
        super.showSuccessful(response);
        window.app.$store.dispatch('center/set', this);
        return this;
    }

    updateSuccessful(response) {
        super.updateSuccessful(response);
        window.app.$store.dispatch('center/set', this);
    }

}
