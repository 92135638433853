import Address from '@core/models/address';
import RestModel from '@core/models/_rest';
import Upload from '@core/models/upload';
import Web from '@core/models/web';

export default class Academy extends RestModel {

    get centerClientLink() {
        return `${import.meta.env.VITE_APP_URL_CENTER}/etablissements/${this.$.id}/informations-generales`;
    }

    get schoolClientLink() {
        return `${import.meta.env.VITE_APP_URL_SCHOOL}?scope=${this.constructor.scopeType}-${this.$.id}`;
    }

    static get scopeType() {
        return 'academy';
    }

    static get className() {
        return 'App\\Models\\Academy';
    }

    get url() {
        return this.$.id ? `academies/${this.$.id}` : 'academies';
    }

    fill({
        graduateWeb = undefined,
        logoLg = undefined,
        logoMd = undefined,
        logoSm = undefined,
        office = undefined,
        officialWeb = undefined,
        undergraduateWeb = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            graduateWeb: graduateWeb ? new Web(graduateWeb.$ || graduateWeb) : undefined,
            logoLg: logoLg ? new Upload(logoLg.$ || logoLg) : undefined,
            logoMd: logoMd ? new Upload(logoMd.$ || logoMd) : undefined,
            logoSm: logoSm ? new Upload(logoSm.$ || logoSm) : undefined,
            office: office ? new Address(office.$ || office) : undefined,
            officialWeb: officialWeb ? new Web(officialWeb.$ || officialWeb) : undefined,
            undergraduateWeb: undergraduateWeb ? new Web(undergraduateWeb.$ || undergraduateWeb) : undefined,
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            logoLg: this.$.logoLg instanceof File ? this.$.logoLg : undefined,
            logoMd: this.$.logoMd instanceof File ? this.$.logoMd : undefined,
            logoSm: this.$.logoSm instanceof File ? this.$.logoSm : undefined,
        });
    }

}
