<template>

    <b-skeleton
        v-if="isPending"
        class="w-100 my-2 py-4"
        type="button"
        variant="secondary"
    />

    <b-button
        v-else
        :to="{
            name: `${routeNamePrefix}questionnaireEntry-show`,
            params: { questionnaireEntryId: questionnaireEntry.$.id },
        }"
        :variant="`outline-${variant}`"
        class="d-flex my-2 text-left"
        block
    >

        <upload-avatar
            :upload="questionnaireEntry.$.questionnaireSurvey.$.questionnaireCategory.$.logo"
            icon="folder-open"
            width="50px"
            rounded
        />

        <div class="flex-fill ml-2">

            <div
                :class="{ 'text-body': active !== true && questionnaireEntry.isOpen }"
                class="font-weight-bold"
            >
                {{ questionnaireEntry.$.questionnaireSurvey.$.name }}
            </div>

            <div>
                {{ questionnaireEntry.$.questionnaireSurvey.$.questionnaireCategory.$.name }}
            </div>

        </div>

    </b-button>

</template>



<script>

    import Dayjs from '@core/mixins/dayjs';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';

    export default {
        mixins: [Dayjs],
        props: {
            isPending: {
                type: Boolean,
                required: true,
            },
            questionnaireEntry: {
                type: QuestionnaireEntry,
                required: true,
            },
            routeNamePrefix: {
                type: String,
                default: '',
            },
        },
        computed: {
            active: (vue) => vue.$route.params.questionnaireEntryId === vue.questionnaireEntry.$.id,
            variant: (vue) => vue.questionnaireEntry.variant(
                vue.questionnaireEntry.$.questionnaireSurvey.$[`access_${vue.questionnaireEntry.$.user_role}`],
                vue.questionnaireEntry.$.questionnaireValidation.$[
                    `${vue.questionnaireEntry.$.user_role}_validated_at`
                ],
            ),
        },
    };

</script>
