import User from '@core/models/user';

export default class UserAuth extends User {

    /* eslint-disable-next-line class-methods-use-this */
    get url() {
        return 'auth/user';
    }

}
