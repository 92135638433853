<template>

    <div v-if="isPending">

        <b-badge variant="light">
            <font-awesome-icon
                :icon="['fad', 'circle-notch']"
                fixed-width
                spin
            />
        </b-badge>

        <b-badge variant="light">
            <font-awesome-icon
                :icon="['fad', 'circle-notch']"
                fixed-width
                spin
            />
        </b-badge>

        <b-badge variant="light">
            <font-awesome-icon
                :icon="['fad', 'circle-notch']"
                fixed-width
                spin
            />
        </b-badge>

    </div>

    <div v-else>

        <b-badge
            v-b-tooltip.noninteractive
            class="w-100 mb-1"
            title="Formulaires en cours d'édition non finalisés"
            variant="light"
        >
            <font-awesome-icon
                :icon="['fad', 'edit']"
                fixed-width
            />
            {{
                qualifiedQuestionnaireEntries
                    .filter((questionnaireEntry) => questionnaireEntry.isLocked !== true)
                    .length
            }}
        </b-badge>

        <b-badge
            v-b-tooltip.noninteractive
            class="w-100 mb-1"
            title="Formulaires finalisés en attente de validation"
            variant="light"
        >
            <font-awesome-icon
                :icon="['fad', 'lock']"
                fixed-width
            />
            {{
                qualifiedQuestionnaireEntries
                    .filter((questionnaireEntry) => questionnaireEntry.isLocked)
                    .filter((questionnaireEntry) => questionnaireEntry.isValidated !== true)
                    .length
            }}
        </b-badge>

        <b-badge
            v-b-tooltip.noninteractive
            class="w-100"
            title="Formulaires entièrement validés"
            variant="light"
        >
            <font-awesome-icon
                :icon="['fad', 'check']"
                fixed-width
            />
            {{
                qualifiedQuestionnaireEntries
                    .filter((questionnaireEntry) => questionnaireEntry.isValidated)
                    .length
            }}
        </b-badge>

    </div>

</template>



<script>

    export default {
        props: {
            isPending: {
                type: Boolean,
                default: false,
            },
            questionnaireEntries: {
                type: Array,
                required: true,
            },
        },
        computed: {
            qualifiedQuestionnaireEntries: (vue) => vue.questionnaireEntries
                .filter((questionnaireEntry) => questionnaireEntry.$.id)
                .filter((questionnaireEntry) => questionnaireEntry.isOpen),
        },
    };

</script>
