export default {

    data: () => ({
        toastAssetVersionSent: false,
        toastIcons: {
            danger: 'do-not-enter',
            info: 'info',
            success: 'check',
            warning: 'exclamation',
        },
    }),

    methods: {
        toast(title, description, href, variant) {
            this.$bvToast.toast(description, {
                appendToast: true,
                href,
                title: [this.toastIconElement(variant), title],
                variant,
            });
        },
        toastAssetVersion() {
            if (this.toastAssetVersionSent) {
                return;
            }

            this.toastAssetVersionSent = true;

            this.$bvToast.toast('Veuillez recharger la page pour bénéficier des dernières améliorations.', {
                noAutoHide: true,
                title: [this.toastIconElement('warning'), 'Une mise à jour est disponible !'],
                variant: 'warning',
            });
        },
        toastDanger(title, description, href) {
            this.toast(title, description, href, 'danger');
        },
        toastIconElement(variant) {
            return this.$createElement('font-awesome-icon', {
                class: 'mr-1',
                props: {
                    icon: ['fad', this.toastIcons[variant]],
                    'fixed-width': true,
                },
            });
        },
        toastInfo(title, description, href) {
            this.toast(title, description, href, 'info');
        },
        toastSuccess(title, description, href) {
            this.toast(title, description, href, 'success');
        },
        toastWarning(title, description, href) {
            this.toast(title, description, href, 'warning');
        },
    },

};
