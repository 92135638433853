import Academy from '@core/models/academy';
import Address from '@core/models/address';
import CenterTeam from '@core/models/centerTeam';
import RestModel from '@core/models/_rest';
import Upload from '@core/models/upload';
import Web from '@core/models/web';

export default class School extends RestModel {

    static get className() {
        return 'App\\Models\\School';
    }

    get centerClientLink() {
        return `${import.meta.env.VITE_APP_URL_CENTER}/antennes/${this.$.id}/informations-generales`;
    }

    get schoolClientLink() {
        return `${import.meta.env.VITE_APP_URL_SCHOOL}?scope=${this.constructor.scopeType}-${this.$.id}`;
    }

    static get scopeType() {
        return 'school';
    }

    get url() {
        return this.$.id ? `schools/${this.$.id}` : 'schools';
    }

    fill({
        academy = {},
        centerTeam = undefined,
        graduateWeb = undefined,
        logoLg = undefined,
        logoMd = undefined,
        logoSm = undefined,
        office = undefined,
        officialWeb = undefined,
        undergraduateWeb = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            academy: new Academy(academy.$ || academy),
            centerTeam: centerTeam ? new CenterTeam(centerTeam.$ || centerTeam) : undefined,
            graduateWeb: graduateWeb ? new Web(graduateWeb.$ || graduateWeb) : undefined,
            logoLg: logoLg ? new Upload(logoLg.$ || logoLg) : undefined,
            logoMd: logoMd ? new Upload(logoMd.$ || logoMd) : undefined,
            logoSm: logoSm ? new Upload(logoSm.$ || logoSm) : undefined,
            office: office ? new Address(office.$ || office) : undefined,
            officialWeb: officialWeb ? new Web(officialWeb.$ || officialWeb) : undefined,
            undergraduateWeb: undergraduateWeb ? new Web(undergraduateWeb.$ || undergraduateWeb) : undefined,
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            academy_id: this.$.academy.$.id,
            center_team_id: this.$.centerTeam ? this.$.centerTeam.$.id : null,
            logoLg: this.$.logoLg instanceof File ? this.$.logoLg : undefined,
            logoMd: this.$.logoMd instanceof File ? this.$.logoMd : undefined,
            logoSm: this.$.logoSm instanceof File ? this.$.logoSm : undefined,
        });
    }

}
