<template>

    <div>

        <b-alert
            :show="businessPeople.length === 0"
            class="my-3"
            variant="warning"
        >

            <font-awesome-icon
                :icon="['fad', 'exclamation']"
                fixed-width
            />

            <template v-if="questionnaireEntry.$.questionnaireSurvey.$.type === 'business_apprentice_contract_purpose'">
                Aucun délégataire n'a été renseigné pour ce contrat.
            </template>

            <template v-else>
                Aucun maître d'apprentissage / tuteur entreprise
                n'a été affecté à {{ questionnaireEntry.learnerPerson.$.fullname }}
                au {{ dayjsShortDate(questionnaireEntry.$.questionnaireSurvey.$.end) }}.
            </template>

        </b-alert>

        <business-person-card
            v-for="businessPerson in businessPeople"
            :key="businessPerson.$.id"
            :business-person="businessPerson"
            :is-pending="questionnaireEntry.isPending"
            :title="roleName"
            class="py-3"
            header-tag="h3"
            no-body
        />

    </div>

</template>



<script>

    import BusinessPerson from '@core/models/businessPerson';
    import Dayjs from '@core/mixins/dayjs';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';

    export default {
        mixins: [Dayjs],
        props: {
            questionnaireEntry: {
                type: QuestionnaireEntry,
                required: true,
            },
        },
        computed: {
            businessPeople: (vue) => (
                vue.questionnaireEntry.isPending
                    ? [new BusinessPerson()]
                    : vue.questionnaireEntry.$.businessPeople
            ),
            roleName: (vue) => vue.questionnaireEntry.$.questionnaireSurvey.roleTitleNames.business_mentor,
        },
    };

</script>



<style scoped>

    :deep(.card-header) {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 0.5rem;
        padding-left: 0px;
    }

    :deep(.card-body) {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

</style>
