import RestModel from '@core/models/_rest';

export default class StorableCollection extends RestModel {

    get errors() {
        return this.models.reduce(
            (errors, model) => Object.fromEntries(
                Object
                    .keys(model.errors)
                    .map((field) => [
                        field,
                        [...(errors[field] || []), ...(model.errors[field] || [])]
                            .filter((error, index, array) => array.findIndex((e) => e === error) === index),
                    ]),
            ),
            {},
        );
    }

    set errors(errors) {
        if (this.models === undefined) {
            return;
        }
        this.models.forEach((model, index) => {
            this.models[index].errors = Object.fromEntries(
                Object
                    .entries(errors)
                    .filter(([key]) => key.startsWith(`${index}.`))
                    .map(([key, value]) => [key.replace(`${index}.`, ''), value]),
            );
        });
    }

    get model() {
        return this.models[0];
    }

    get states() {
        return {
            ...Object.fromEntries(
                Object.keys(this.errors).length ? Object.keys(this.model.formData()).map((field) => [field, true]) : [],
            ),
            ...Object.fromEntries(
                Object.keys(this.errors).map((field) => [field, false]),
            ),
        };
    }

    get url() {
        return this.model.url;
    }

    fill(models) {
        this.models = models.map((model) => (model.$ ? model : new this.model.constructor(model)));
        return this;
    }

    formData() {
        return this.models.map((model) => model.formData());
    }

}
