export default class Model {

    constructor(attributes = {}) {
        this.fill(attributes);
    }

    fill(attributes) {
        this.$ = { ...attributes };
        return this;
    }

    formData(attributes = {}) {
        return { ...this.$, ...attributes };
    }

}
