import RestModel from '@core/models/_rest';

export default class BusinessApprenticeSalary extends RestModel {

    static get salaryReferences() {
        return {
            smc: 'SMC',
            smic: 'SMIC',
        };
    }

    get url() {
        return this.$.id ? `businessApprenticeSalaries/${this.$.id}` : 'businessApprenticeSalaries';
    }

}
