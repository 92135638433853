/* eslint-disable-next-line import/no-cycle */
import BusinessPerson from '@core/models/businessPerson';
/* eslint-disable-next-line import/no-cycle */
import Capability from '@core/models/capability';
/* eslint-disable-next-line import/no-cycle */
import EducationPerson from '@core/models/educationPerson';
/* eslint-disable-next-line import/no-cycle */
import LearnerPerson from '@core/models/learnerPerson';
import RestModel from '@core/models/_rest';
import Upload from '@core/models/upload';

export default class User extends RestModel {

    get url() {
        return this.$.id ? `users/${this.$.id}` : 'users';
    }

    fill({
        avatar = undefined,
        businessPeople = [],
        capabilities = [],
        educationPeople = [],
        learnerPerson = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            avatar: avatar ? new Upload(avatar.$ || avatar) : undefined,
            businessPeople: businessPeople.map(
                (businessPerson) => new BusinessPerson(businessPerson.$ || businessPerson),
            ),
            capabilities: capabilities.map((capability) => new Capability(capability.$ || capability)),
            educationPeople: educationPeople.map(
                (educationPerson) => new EducationPerson(educationPerson.$ || educationPerson),
            ),
            learnerPerson: learnerPerson ? new LearnerPerson(learnerPerson.$ || learnerPerson) : undefined,
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            avatar: this.$.avatar instanceof File ? this.$.avatar : undefined,
            capabilities: this.$.id ? null : this.$.capabilities.map((capability) => capability.formData()),
        });
    }

    showSuccessful(response) {
        super.showSuccessful(response);
        window.app.$store.dispatch('auth/set', this);
        return this;
    }

    updateSuccessful(response) {
        super.updateSuccessful(response);
        window.app.$store.dispatch('auth/set', this);
        return this;
    }

}
