import BusinessNafSector from '@core/models/businessNafSector';
import RestModel from '@core/models/_rest';

export default class BusinessNaf extends RestModel {

    get url() {
        return this.$.id ? `businessNafs/${this.$.id}` : 'businessNafs';
    }

    fill({
        businessNafSector = {},
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            businessNafSector: new BusinessNafSector(businessNafSector.$ || businessNafSector),
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            business_naf_sector_id: this.$.businessNafSector.$.id,
        });
    }

}
