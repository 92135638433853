export default {

    data: () => ({
        printMode: window.matchMedia('print').matches,
    }),

    created() {
        window.matchMedia('print').onchange = () => {
            this.printMode = window.matchMedia('print').matches;
        };
    },

};
