import Request from '@core/request';

export default class RestRequest extends Request {

    constructor(model) {
        super();
        this.model = model;
    }

    interceptFailedResponse(error) {
        this.model.isPending = false;
        return super.interceptFailedResponse(error);
    }

    interceptRequest(config) {
        this.model.isPending = true;
        return super.interceptRequest(config);
    }

    interceptSuccessfulResponse(response) {
        this.model.isPending = false;
        return super.interceptSuccessfulResponse(response);
    }

    create() {
        this.model.errors = {};
        return this.axios
            .post(this.model.url, this.model.formData())
            .then((response) => this.model.createSuccessful(response))
            .catch((error) => this.model.createFailed(error));
    }

    delete() {
        return this.axios.delete(this.model.url)
            .catch((error) => this.model.deleteFailed(error));
    }

    index(requestData) {
        return this.axios
            .get(this.model.url, {
                cancelToken: this.cancelSource.token,
                params: { ...this.model.requestData, ...requestData },
            })
            .then((response) => this.model.indexSuccessful(response));
    }

    match(withFields, withoutFields = []) {
        return this.axios.get(`${this.model.url}/match`, {
            params: {
                with: Object.fromEntries(
                    Object.entries(this.model.formData()).filter(([key]) => withFields.includes(key)),
                ),
                without: Object.fromEntries(
                    Object.entries(this.model.formData()).filter(([key]) => withoutFields.includes(key)),
                ),
            },
        });
    }

    show(requestData) {
        return this.axios
            .get(this.model.url, { params: requestData })
            .then((response) => this.model.showSuccessful(response))
            .catch((error) => this.model.showFailed(error));
    }

    update() {
        this.model.errors = {};
        return this.axios
            .post(this.model.url, this.model.formData({ _method: 'put' }))
            .then((response) => this.model.updateSuccessful(response))
            .catch((error) => this.model.updateFailed(error));
    }

}
