<template>

    <b-form
        v-if="formInitialModel.$.deletable"
        @submit.prevent="formSubmit"
    >

        <b-alert
            v-if="confirm"
            ref="alert"
            variant="danger"
            show
        >

            En invalidant le formulaire :

            <ul>

                <li>
                    les validations actuelles seront effacées.
                </li>

                <li>
                    les participants seront informés par e-mail de cet événement.
                </li>

                <li>
                    les participants pourront de nouveau modifier les réponses.
                </li>

                <li>
                    les participants devront de nouveau finaliser et valider le formulaire.
                </li>

            </ul>

            <div class="text-center">

                <b-textarea
                    v-model="formModifiedModel.$.message"
                    max-rows="100"
                    placeholder="Message facultatif à destination des participants ayant déjà validé le formulaire."
                    rows="3"
                    class="mb-2"
                />

                <b-btn-group>

                    <button-submit
                        :is-pending="formIsPending"
                        icon="times"
                        variant="danger"
                    >
                        J'invalide le formulaire
                    </button-submit>

                    <b-button
                        variant="secondary"
                        @click="confirm = false"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'times']"
                            fixed-width
                        />
                        Annuler
                    </b-button>

                </b-btn-group>
            </div>

        </b-alert>

        <b-button
            v-else
            :pressed.sync="confirm"
            variant="outline-danger"
            block
        >
            <font-awesome-icon
                :icon="['fad', 'times']"
                fixed-width
            />
            Invalider les réponses au formulaire
        </b-button>

    </b-form>

</template>



<script>

    import Form from '@core/mixins/form';
    import QuestionnaireValidation from '@core/models/questionnaireValidation';

    export default {
        mixins: [Form],
        props: {
            value: {
                type: QuestionnaireValidation,
                required: true,
            },
        },
        data: () => ({
            confirm: false,
        }),
        computed: {
            formInitialModel: {
                get: (vue) => new QuestionnaireValidation({ ...vue.value.$ }),
                set(value) {
                    this.$emit('input', value);
                },
            },
        },
        watch: {
            confirm(confirm) {
                if (confirm) {
                    this.$nextTick(() => {
                        this.$refs.alert.$el.parentNode.scrollIntoView({ behavior: 'smooth', block: 'end' });
                    });
                }
            },
        },
        methods: {
            formSubmit() {
                return this.formModifiedModel
                    .request()
                    .delete()
                    .then((response) => new QuestionnaireValidation().updateSuccessful(response))
                    .then(this.formSubmitSuccessful)
                    .catch(() => {});
            },
            formSubmitSuccessful(questionnaireValidation) {
                this.$root.toastSuccess(
                    'Le formulaire a été invalidé',
                    "L'enregistrement des informations a réussi.",
                );
                this.formInitialModel = new QuestionnaireValidation(questionnaireValidation.$);
                this.$nextTick(() => {
                    document
                        .getElementById('view-questionnaireEntry-show')
                        .scrollIntoView({ behavior: 'smooth', block: 'start' });
                });
            },
        },
    };

</script>



<style scoped>

    :deep(.d-flex.justify-content-between .btn-group) {
        width: 100%;
    }

</style>
