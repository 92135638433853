<script>

    import BusinessApprenticeContract from '@core/models/businessApprenticeContract';

    export default {
        props: {
            businessApprenticeContract: {
                type: BusinessApprenticeContract,
                required: true,
            },
        },
        computed: {
            businessApprenticeContractStatusIndex: (vue) => Object.keys(BusinessApprenticeContract.statusNames).indexOf(
                vue.businessApprenticeContract.$.status,
            ),
            currentStatus: (vue) => vue.statuses.findLast(
                ({ status, variant }) => variant === 'info'
                    && status !== 'purpose'
                    && status !== 'commercial_agreement_status',
            )
                || vue.statuses.findLast(
                    ({ status, variant }) => variant === 'success'
                        && status !== 'purpose'
                        && status !== 'commercial_agreement_status',
                ),
            legends: () => ({
                dark: 'Non disponible',
                info: 'En cours',
                success: 'Terminé',
            }),
            purposeLegend: (vue) => ({
                dark: 'Non disponible',
                info: 'À valider',
                success: 'Validée',
                warning: 'À compléter',
            }[vue.purposeVariant]),
            purposeVariant: (vue) => {
                if (vue.businessApprenticeContract.$.missing_information === undefined) {
                    return 'dark';
                }
                if (vue.businessApprenticeContract.$.questionnaire_validation_rate_purpose === null) {
                    return 'dark';
                }
                if (
                    vue.businessApprenticeContract.$.missing_information.includes(
                        'locked_questionnaire_validation_purpose',
                    )
                ) {
                    return 'warning';
                }
                if (
                    vue.businessApprenticeContract.$.missing_information.includes(
                        'validated_questionnaire_validation_purpose',
                    )
                ) {
                    return 'info';
                }
                return 'success';
            },
            statuses: (vue) => {
                const statuses = Object.keys(BusinessApprenticeContract.statusNames).map((status) => {
                    const statusIndex = Object.keys(BusinessApprenticeContract.statusNames).indexOf(status);
                    let variant = 'info';

                    if (status === 'uploaded') {
                        if (vue.businessApprenticeContract.$.create_business_convergence !== true) {
                            variant = 'success';
                        }
                    }

                    if (status === 'registered' && vue.businessApprenticeContract.$.create_business_convergence) {
                        variant = 'success';
                    }

                    if (vue.businessApprenticeContractStatusIndex > statusIndex) {
                        variant = 'success';
                    }

                    if (vue.businessApprenticeContractStatusIndex < statusIndex) {
                        variant = 'dark';
                    }

                    return {
                        name: BusinessApprenticeContract.statusNames[status],
                        icon: BusinessApprenticeContract.statusIcons[status],
                        legend: vue.legends[variant],
                        status,
                        variant,
                    };
                }).filter(
                    ({ status }) => vue.businessApprenticeContract.$.create_business_convergence
                        || status !== 'registered',
                );


                statuses.unshift({
                    name: 'Missions',
                    icon: 'folder-open',
                    legend: vue.purposeLegend,
                    status: 'purpose',
                    variant: vue.purposeVariant,
                });

                statuses.splice(-1, 0, {
                    name: 'Accord de prise en charge',
                    icon: 'euro-sign',
                    legend: BusinessApprenticeContract.commercialAgreementStatusNames[
                        vue.businessApprenticeContract.$.commercial_agreement_status
                    ],
                    status: 'commercial_agreement_status',
                    variant: BusinessApprenticeContract.commercialAgreementStatusVariants[
                        vue.businessApprenticeContract.$.commercial_agreement_status
                    ],
                });

                return statuses;
            },
        },
        render() {
            return this.$scopedSlots.default({ currentStatus: this.currentStatus, statuses: this.statuses });
        },
    };

</script>
