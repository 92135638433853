<template>

    <form-group
        :id="`questionnaireField-${questionnaireField.$.id}`"
        :error="value.errors.content"
        :label="questionnaireField.$.name"
        :required="questionnaireField.$.mandatory"
        :state="value.states.content"
        label-class="pb-0"
    >

        <template #top>
            <div class="small text-muted pb-2">
                <div v-if="questionnaireField.$.description">
                    {{ questionnaireField.$.description }}.
                </div>
            </div>
        </template>

        <template #default="inputProps">
            <b-textarea
                v-if="questionnaireField.$.attributes.type === 'text'"
                v-bind="inputProps"
                v-model="content"
                :disabled="disabled || value.isPending"
                max-rows="100"
                rows="3"
                @blur="saveAndUnlock"
                @focus="lock"
            />
            <form-datepicker
                v-else-if="questionnaireField.$.attributes.type === 'date'"
                v-model="content"
                v-bind="inputProps"
                :disabled="disabled || value.isPending"
                :placeholder="placeholder"
                @input="saveAndUnlock"
            />
            <b-form-input
                v-else
                v-bind="inputProps"
                v-model="content"
                :disabled="disabled || value.isPending"
                :placeholder="placeholder"
                :type="questionnaireField.$.attributes.type"
                @blur="saveAndUnlock"
                @focus="lock"
            />
        </template>

        <template #description>
            <div class="d-sm-flex flex-row-reverse">

                <div class="text-right">
                    <button-submit
                        v-if="
                            questionnaireLock.$.belongs_to_self
                                && value.states.content === undefined
                                && questionnaireField.$.attributes.type !== 'date'
                        "
                        :is-pending="questionnaireLock.isPending || value.isPending"
                        class="mt-1 ml-sm-2 text-nowrap"
                        icon="edit"
                        variant="success"
                        @click="saveAndUnlock"
                    >
                        Enregistrer
                    </button-submit>
                </div>

                <div class="mr-auto">
                    <slot name="description" />
                </div>

            </div>
        </template>

    </form-group>

</template>



<script>

    import QuestionnaireAnswer from '@core/models/questionnaireAnswer';
    import QuestionnaireField from '@core/models/questionnaireField';
    import QuestionnaireLock from '@core/models/questionnaireLock';

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            questionnaireField: {
                type: QuestionnaireField,
                required: true,
            },
            value: {
                type: QuestionnaireAnswer,
                required: true,
            },
        },
        data: () => ({
            content: '',
            promiseChain: Promise.resolve(true),
        }),
        computed: {
            placeholder: (vue) => {
                if (vue.questionnaireField.$.type === 'text' && vue.questionnaireField.$.attributes.type === 'url') {
                    return 'https://';
                }
                return undefined;
            },
            questionnaireLock: (vue) => vue.questionnaireField.$.questionnaireLock,
        },
        watch: {
            'value.$.content': {
                handler(content) {
                    this.content = content || '';
                },
                immediate: true,
            },
        },
        destroyed() {
            if (this.questionnaireLock.$.exists && this.questionnaireLock.$.deletable) {
                this.saveAndUnlock();
            }
        },
        methods: {
            lock() {
                const questionnaireLock = new QuestionnaireLock({ ...this.questionnaireLock.$ });
                delete questionnaireLock.$.id;
                this.promiseChain = this.promiseChain.then(() => questionnaireLock.request().create());
            },
            saveAndUnlock() {
                if (this.questionnaireField.$.attributes.type === 'date') {
                    this.$emit('input', new QuestionnaireAnswer({ ...this.value.$, content: this.content }));
                    return;
                }

                this.promiseChain = this.promiseChain.then((previousPromise) => {
                    if (previousPromise.url === undefined && this.questionnaireLock.$.exists !== true) {
                        return;
                    }
                    if (previousPromise.url || this.questionnaireLock.$.belongs_to_self) {
                        this.$emit('input', new QuestionnaireAnswer({ ...this.value.$, content: this.content }));
                    }
                    if (previousPromise.url || this.questionnaireLock.$.deletable) {
                        this.questionnaireLock.request().silent().delete().catch(() => {});
                    }
                });
            },
        },
    };

</script>
