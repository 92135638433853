import Address from '@core/models/address';
import BusinessBranchType from '@core/models/businessBranchType';
import BusinessCompany from '@core/models/businessCompany';
import BusinessIdcc from '@core/models/businessIdcc';
import BusinessNaf from '@core/models/businessNaf';
import RestModel from '@core/models/_rest';
import Upload from '@core/models/upload';

export default class BusinessBranch extends RestModel {

    get editableAttribues() {
        if (this.$.data_source === 'opendatasoft') {
            return [
                'business_branch_type_id',
                'business_idcc_ids',
                'email',
                'invoice_method',
                'invoice_email',
                'invoice_platform_name',
                'invoice_platform_url',
                'regional_authority',
                'importance',
                'center_note',
                'employee_headcount',
                'businessApprenticeCommercialAgreementTemplate',
            ];
        }

        if (this.$.data_source === 'user') {
            return [
                'business_branch_type_id',
                'business_idcc_ids',
                'business_naf_id',
                'email',
                'invoice_method',
                'invoice_email',
                'invoice_platform_name',
                'invoice_platform_url',
                'regional_authority',
                'importance',
                'center_note',
                'employee_headcount',
                'businessApprenticeCommercialAgreementTemplate',
            ];
        }

        if (this.$.data_source === 'unknown') {
            return [
                'business_branch_type_id',
                'business_idcc_ids',
                'business_naf_id',
                'email',
                'invoice_method',
                'invoice_email',
                'invoice_platform_name',
                'invoice_platform_url',
                'regional_authority',
                'importance',
                'center_note',
                'employee_headcount',
                'businessApprenticeCommercialAgreementTemplate',
            ];
        }

        return [];
    }

    static get importances() {
        return {
            headquarters: 'Siège',
            affiliate: 'Filiale',
            single_branch: 'Établissement unique',
            secondary_branch: 'Établissement secondaire',
        };
    }

    static get invoiceMethods() {
        return {
            email: 'E-mail',
            mail: 'Courrier',
            platform: 'Plateforme',
        };
    }

    get url() {
        return this.$.id ? `businessBranches/${this.$.id}` : 'businessBranches';
    }

    fill({
        businessApprenticeCommercialAgreementTemplate = undefined,
        businessBranchType = undefined,
        businessCompany = {},
        businessIdccs = [],
        businessNaf = undefined,
        invoiceAddress = {},
        office = {},
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            businessApprenticeCommercialAgreementTemplate: businessApprenticeCommercialAgreementTemplate
                ? new Upload(
                    businessApprenticeCommercialAgreementTemplate.$ || businessApprenticeCommercialAgreementTemplate,
                )
                : undefined,
            businessBranchType: businessBranchType
                ? new BusinessBranchType(businessBranchType.$ || businessBranchType)
                : undefined,
            businessCompany: new BusinessCompany(businessCompany.$ || businessCompany),
            businessIdccs: businessIdccs.map((businessIdcc) => new BusinessIdcc(businessIdcc.$ || businessIdcc)),
            businessNaf: businessNaf ? new BusinessNaf(businessNaf.$ || businessNaf) : undefined,
            invoiceAddress: new Address(invoiceAddress.$ || invoiceAddress),
            office: new Address(office.$ || office),
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            businessApprenticeCommercialAgreementTemplate:
                this.$.businessApprenticeCommercialAgreementTemplate instanceof File
                    ? this.$.businessApprenticeCommercialAgreementTemplate
                    : undefined,
            business_branch_type_id: this.$.businessBranchType ? this.$.businessBranchType.$.id : null,
            business_company_id: this.$.businessCompany ? this.$.businessCompany.$.id : null,
            business_idcc_ids: this.$.businessIdccs.map((businessIdcc) => businessIdcc.$.id),
            business_naf_id: this.$.businessNaf ? this.$.businessNaf.$.id : null,
        });
    }

}
