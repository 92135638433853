<template>

    <b-row
        tag="form"
        @submit.prevent="formSubmit"
    >

        <b-col
            class="mb-4"
            md="12"
        >
            <b-card
                :header="`Informations personnelles concernant ${businessPersonCreateLabel}`"
                bg-variant="light"
                header-tag="h2"
            >

                <form-group
                    id="view-businessPerson-create-title"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.title"
                    :state="formModifiedModel.states.title"
                    label="Titre"
                    required
                >
                    <b-form-radio-group
                        v-model="formModifiedModel.$.title"
                        v-bind="inputProps"
                        :options="titles"
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-create-firstname"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.firstname"
                    :state="formModifiedModel.states.firstname"
                    label="Prénom"
                    left-icon="text"
                    business-apprentice-agreement
                    required
                >
                    <b-form-input
                        v-model="formModifiedModel.$.firstname"
                        v-bind="inputProps"
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-create-lastname"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.lastname"
                    :state="formModifiedModel.states.lastname"
                    label="Nom"
                    left-icon="text"
                    business-apprentice-agreement
                    required
                >
                    <b-form-input
                        v-model="formModifiedModel.$.lastname"
                        v-bind="inputProps"
                    />
                </form-group>

            </b-card>
        </b-col>

        <b-col
            class="mb-4"
            md="12"
        >
            <b-card
                :header="`Informations professionnelles concernant ${businessPersonCreateLabel}`"
                bg-variant="light"
                header-tag="h2"
            >

                <form-group
                    id="view-businessPerson-create-business-company-id"
                    v-slot="inputProps"
                    label="Employeur"
                    left-icon="industry-alt"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.businessCompany.$.name"
                        v-bind="inputProps"
                        readonly
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-create-job-type"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.job_type"
                    :state="formModifiedModel.states.job_type"
                    label="Département"
                    left-icon="tag"
                    required
                >
                    <b-form-select
                        v-model="formModifiedModel.$.job_type"
                        v-bind="inputProps"
                        :options="jobTypeOptions"
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-create-job-title"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.job_title"
                    :state="formModifiedModel.states.job_title"
                    label="Intitulé de poste"
                    left-icon="text"
                    required
                >
                    <b-form-input
                        v-model="formModifiedModel.$.job_title"
                        v-bind="inputProps"
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-create-loacation"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.location"
                    :state="formModifiedModel.states.location"
                    label="Zone géographique"
                    left-icon="map-marker"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.location"
                        v-bind="inputProps"
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-create-email"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.email"
                    :state="formModifiedModel.states.email"
                    label="Adresse e-mail"
                    left-icon="envelope"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.email"
                        v-bind="inputProps"
                        type="email"
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-create-phone"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.phone"
                    :state="formModifiedModel.states.phone"
                    label="Téléphone"
                    left-icon="mobile"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.phone"
                        v-bind="inputProps"
                        pattern="^\+?\d{7,14}$"
                        title="7 à 14 chiffres"
                    />
                </form-group>

            </b-card>
        </b-col>

        <b-col
            v-if="showBusinessApprenticeInputs"
            class="mb-4"
            md="12"
        >
            <b-card
                header="Si cette personne est un maître d'apprentissage, merci de compléter les informations suivantes"
                bg-variant="light"
                header-tag="h2"
            >

                <form-group
                    id="view-businessPerson-create-birth-date"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.birth_date"
                    :state="formModifiedModel.states.birth_date"
                    description="Obligatoire pour les maîtres d'apprentissage."
                    label="Date de naissance"
                    business-apprentice-agreement
                >
                    <b-form-input
                        v-model="formModifiedModel.$.birth_date"
                        v-bind="inputProps"
                        max="2999-01-01"
                        placeholder="AAAA-MM-JJ"
                        type="date"
                    />
                </form-group>

                <form-group-nirpp
                    id="view-businessPerson-create-nirpp"
                    v-model="formModifiedModel.$.nirpp"
                    :error="formModifiedModel.errors.nirpp"
                    :missing="formInitialModel.$.nirpp_count !== 1"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.nirpp"
                    :updating="formIsPending"
                    updatable
                />

                <model-list
                    v-slot="{ collection, options, setValue, value: highestBusinessPersonDiplomaId }"
                    v-model="highest_business_person_diploma_id"
                    name="BusinessPersonDiploma"
                    allow-empty
                >
                    <form-group
                        id="view-businessPerson-create-business-person-diploma-id"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.highest_business_person_diploma_id"
                        :left-icon="collection.isPending ? 'circle-notch' : 'file-certificate'"
                        :left-icon-spin="collection.isPending"
                        :state="formModifiedModel.states.highest_business_person_diploma_id"
                        label="Diplôme le plus élevé obtenu"
                        business-apprentice-agreement
                    >
                        <b-form-select
                            v-bind="inputProps"
                            :options="options"
                            :value="highestBusinessPersonDiplomaId"
                            @input="setValue"
                        />
                    </form-group>
                </model-list>

                <form-group
                    id="view-businessPerson-create-highest-business-person-diploma-name"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.highest_business_person_diploma_name"
                    :state="formModifiedModel.states.highest_business_person_diploma_name"
                    label="Intitulé du diplôme le plus élevé obtenu"
                    left-icon="text"
                    business-apprentice-agreement
                >
                    <b-form-input
                        v-model="formModifiedModel.$.highest_business_person_diploma_name"
                        v-bind="inputProps"
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-create-mentorship-certification-start"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.mentorship_certification_start"
                    :state="formModifiedModel.states.mentorship_certification_start"
                    description="Si la personne dispose d'une certification MATU."
                    label="Début de certification MATU"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.mentorship_certification_start"
                        v-bind="inputProps"
                        max="2999-01-01"
                        placeholder="AAAA-MM-JJ"
                        type="date"
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-create-mentorship-certification-end"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.mentorship_certification_end"
                    :state="formModifiedModel.states.mentorship_certification_end"
                    description="Si la personne dispose d'une certification MATU."
                    label="Fin de certification MATU"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.mentorship_certification_end"
                        v-bind="inputProps"
                        max="2999-01-01"
                        placeholder="AAAA-MM-JJ"
                        type="date"
                    />
                </form-group>

            </b-card>
        </b-col>

        <b-col
            class="text-right"
            md="12"
        >
            <button-submit
                :is-pending="formIsPending"
                icon="plus"
                variant="success"
            >
                Enregistrer {{ businessPersonCreateLabel }}
            </button-submit>
        </b-col>
    </b-row>

</template>



<script>

    import BusinessCompany from '@core/models/businessCompany';
    import BusinessPerson from '@core/models/businessPerson';
    import BusinessPersonDiploma from '@core/models/businessPersonDiploma';
    import Form from '@core/mixins/form';

    export default {
        mixins: [Form],
        props: {
            businessCompany: {
                type: BusinessCompany,
                required: true,
            },
            businessPersonCreateLabel: {
                type: String,
                required: true,
            },
            showBusinessApprenticeInputs: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            formInitialModel: new BusinessPerson(),
        }),
        computed: {
            highest_business_person_diploma_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.highestBusinessPersonDiploma
                        ? vue.formModifiedModel.$.highestBusinessPersonDiploma.$.id
                        : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.highestBusinessPersonDiploma = new BusinessPersonDiploma({ id });
                },
            },
            jobTypes: () => BusinessPerson.jobTypes,
            jobTypeOptions: () => Object.entries(BusinessPerson.jobTypes).map(
                ([value, text]) => ({ text, value }),
            ),
            titles: () => Object.entries(BusinessPerson.titles).map(
                ([value, text]) => ({ text, value }),
            ),
        },
        watch: {
            businessCompany: {
                deep: true,
                handler(businessCompany) {
                    this.formModifiedModel.$.businessCompany = businessCompany;
                },
                immediate: true,
            },
        },
        methods: {
            formSubmitSuccessful(businessPerson) {
                this.$root.toastSuccess(
                    `${this.businessPersonCreateLabel[0].toUpperCase()}${this.businessPersonCreateLabel.slice(1)} `
                        + 'a été créé',
                    "Il peut maintenant être rattaché à un contrat d'apprentissage ou à un tutorat entreprise.",
                );
                this.$emit('created', businessPerson);
            },
        },
    };

</script>
