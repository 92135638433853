<template>

    <b-card
        :header="title"
        bg-variant="light"
        class="mb-4"
        header-tag="h2"
        tag="form"
        @submit.prevent="formSubmit"
    >

        <b-alert
            :show="formModifiedModel.$.delegatee_email === ''"
            variant="warning"
        >
            <font-awesome-icon
                :icon="['fad', 'exclamation']"
                fixed-width
            />
            Aucune adresse e-mail n'est renseignée : la validation des missions ne pourra pas être finalisée.
        </b-alert>

        <b-alert
            v-if="formModifiedModel.$.status === 'downloaded'"
            variant="warning"
            show
        >
            <font-awesome-icon
                :icon="['fad', 'exclamation']"
                fixed-width
            />
            L'envoi de l'invitation rétrogradera le statut du contrat
            en <i>{{ statuses.created }}</i> ou <i>{{ statuses.completed }}</i>
            afin de permettre la modification de ses informations par le destinataire.
        </b-alert>

        <form-group
            id="view-businessApprenticeContract-edit-delegatee-email"
            v-slot="inputProps"
            :left-icon="formInitialModel.$.updatable ? 'envelope' : undefined"
            :error="formModifiedModel.errors.delegatee_email"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.delegatee_email"
            description="Un lien d'accès à l'assistant de contractualisation sera envoyé à l'adresse e-mail indiquée."
            label="Inviter une personne à utiliser l'assistant de contractualisation :"
        >
            <b-form-input
                v-model="formModifiedModel.$.delegatee_email"
                v-bind="inputProps"
                :plaintext="formInitialModel.$.updatable !== true"
                type="email"
            />
        </form-group>

        <b-col
            v-if="formInitialModel.$.updatable"
            class="text-right"
            cols="12"
        >

            <button-submit
                v-if="formInitialModel.$.delegatee_email && formModifiedModel.$.delegatee_email === ''"
                :is-pending="formIsPending"
                icon="times"
                variant="warning"
            >
                Effacer l'e-mail
            </button-submit>

            <button-submit
                v-else
                :is-pending="formIsPending"
                icon="paper-plane"
                variant="success"
            >
                Envoyer l'invitation
            </button-submit>

        </b-col>

    </b-card>

</template>



<script>

    import BusinessApprenticeContract from '@core/models/businessApprenticeContract';
    import Form from '@core/mixins/form';

    export default {
        mixins: [Form],
        props: {
            value: {
                type: BusinessApprenticeContract,
                required: true,
            },
            title: {
                type: String,
                default: undefined,
            },
        },
        computed: {
            formInitialModel: {
                get: (vue) => vue.value,
                set(businessApprenticeContract) {
                    this.$emit('input', businessApprenticeContract);
                },
            },
            statuses: () => BusinessApprenticeContract.statusNames,
        },
        watch: {
            formModifiedModel: {
                deep: true,
                handler() {
                    this.formModifiedModel.$.delegatee_send = true;
                },
                immediate: true,
            },
        },
        methods: {
            formSubmitSuccessful(updatedBusinessApprenticeContract) {
                this.$root.toastSuccess(
                    "L'adresse e-mail a été modifiée",
                    updatedBusinessApprenticeContract.$.delegatee_email
                        ? "L'e-mail d'invitation a été envoyé."
                        : "Aucun e-mail d'invitation n'a été envoyé.",
                );
                this.formInitialModel = new BusinessApprenticeContract(updatedBusinessApprenticeContract.$);
            },
        },
    };

</script>
