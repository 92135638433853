<template>

    <form-group
        :id="`questionnaireField-${questionnaireField.$.id}`"
        :error="error"
        :label="questionnaireField.$.name"
        :required="questionnaireField.$.mandatory"
        :state="state"
        label-class="pb-0"
    >

        <template #top>
            <div class="small text-muted pb-2">

                <div v-if="questionnaireField.$.description">
                    {{ questionnaireField.$.description }}.
                </div>

                <div>
                    Coefficient : {{ questionnaireField.$.attributes.coefficient }}.
                </div>

                <div v-if="questionnaireField.$.attributes.trigger_average">
                    Cette note sera prise en compte dans le calcul automatique de la moyenne du formulaire.
                </div>

            </div>
        </template>

        <template #default="inputProps">
            <b-form-radio-group
                v-if="options"
                v-bind="inputProps"
                v-model="content"
                :disabled="disabled || value.isPending"
                :options="optionOptions"
                stacked
                @input="saveAndUnlock"
            />
            <b-form-input
                v-else
                v-bind="inputProps"
                v-model="content"
                :disabled="disabled || value.isPending"
                class="text-right"
                lazy
                @blur="saveAndUnlock"
                @focus="lock"
            />
        </template>

        <template
            v-if="options === undefined"
            #right
        >
            <b-input-group-append tag="label">
                <b-input-group-text class="border-left-0">
                    / 20
                </b-input-group-text>
            </b-input-group-append>
        </template>

        <template #description>

            <div class="d-sm-flex flex-row-reverse">

                <div class="text-right">
                    <button-submit
                        v-if="questionnaireLock.$.belongs_to_self && state === undefined && options === undefined"
                        :is-pending="value.isPending"
                        class="mt-1 ml-sm-2 text-nowrap"
                        icon="edit"
                        variant="success"
                        @click="saveAndUnlock"
                    >
                        Enregistrer
                    </button-submit>
                </div>

                <div class="mr-auto">
                    <slot name="description" />
                </div>

            </div>

        </template>

    </form-group>

</template>



<script>

    import QuestionnaireAnswer from '@core/models/questionnaireAnswer';
    import QuestionnaireField from '@core/models/questionnaireField';
    import QuestionnaireLock from '@core/models/questionnaireLock';

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            questionnaireField: {
                type: QuestionnaireField,
                required: true,
            },
            value: {
                type: QuestionnaireAnswer,
                required: true,
            },
        },
        data: () => ({
            content: '',
            promiseChain: Promise.resolve(true),
        }),
        computed: {
            error: (vue) => Object.values(vue.value.errors).reduce((errors, error) => [...errors, ...error], []),
            options: (vue) => (
                Object.keys(vue.questionnaireField.$.attributes.options || {}).length > 0
                    ? vue.questionnaireField.$.attributes.options
                    : undefined
            ),
            optionOptions: (vue) => Object.entries(vue.options).map(
                ([value, text]) => ({ text: `${value} : ${text}`, value }),
            ),
            questionnaireLock: (vue) => vue.questionnaireField.$.questionnaireLock,
            state: (vue) => {
                if (vue.error.length > 0) {
                    return false;
                }
                if (Object.values(vue.value.states).some((state) => state === true)) {
                    return true;
                }
                return undefined;
            },
        },
        watch: {
            'value.$.content': {
                handler(content) {
                    this.content = (content || content === 0) ? content : '';
                },
                immediate: true,
            },
        },
        destroyed() {
            if (this.questionnaireLock.$.exists && this.questionnaireLock.$.deletable) {
                this.saveAndUnlock();
            }
        },
        methods: {
            lock() {
                const questionnaireLock = new QuestionnaireLock({ ...this.questionnaireLock.$ });
                delete questionnaireLock.$.id;
                this.promiseChain = this.promiseChain.then(() => questionnaireLock.request().create());
            },
            saveAndUnlock() {
                if (this.options) {
                    this.$emit(
                        'input',
                        new QuestionnaireAnswer({
                            ...this.value.$,
                            content: this.content.toString().replace(',', '.'),
                        }),
                    );
                    return;
                }

                this.promiseChain = this.promiseChain.then((previousPromise) => {
                    if (previousPromise.url === undefined && this.questionnaireLock.$.exists !== true) {
                        return;
                    }
                    if (previousPromise.url || this.questionnaireLock.$.belongs_to_self) {
                        this.$emit(
                            'input',
                            new QuestionnaireAnswer({
                                ...this.value.$,
                                content: this.content.toString().replace(',', '.'),
                            }),
                        );
                    }
                    if (previousPromise.url || this.questionnaireLock.$.deletable) {
                        this.questionnaireLock.request().silent().delete().catch(() => {});
                    }
                });
            },
        },
    };

</script>
