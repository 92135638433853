import RestModel from '@core/models/_rest';
import SfpContract from '@core/models/sfpContract';
import SignatureApplication from '@core/models/signatureApplication';
import Upload from '@core/models/upload';

export default class BusinessApprenticeAgreement extends RestModel {

    fill({
        generatedBusinessApprenticeCommercialAgreement = undefined,
        generatedBusinessApprenticeLegalAgreement = undefined,
        sfpContract = undefined,
        signatureApplication = undefined,
        signedBusinessApprenticeLegalAgreement = undefined,
        signedBusinessApprenticeCommercialAgreement = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            generatedBusinessApprenticeCommercialAgreement: generatedBusinessApprenticeCommercialAgreement
                ? new Upload(
                    generatedBusinessApprenticeCommercialAgreement.$ || generatedBusinessApprenticeCommercialAgreement,
                )
                : undefined,
            generatedBusinessApprenticeLegalAgreement: generatedBusinessApprenticeLegalAgreement
                ? new Upload(generatedBusinessApprenticeLegalAgreement.$ || generatedBusinessApprenticeLegalAgreement)
                : undefined,
            sfpContract: sfpContract ? new SfpContract(sfpContract.$ || sfpContract) : undefined,
            signatureApplication: signatureApplication
                ? new SignatureApplication(signatureApplication.$ || signatureApplication)
                : undefined,
            signedBusinessApprenticeLegalAgreement: signedBusinessApprenticeLegalAgreement
                ? new Upload(signedBusinessApprenticeLegalAgreement.$ || signedBusinessApprenticeLegalAgreement)
                : undefined,
            signedBusinessApprenticeCommercialAgreement: signedBusinessApprenticeCommercialAgreement
                ? new Upload(
                    signedBusinessApprenticeCommercialAgreement.$ || signedBusinessApprenticeCommercialAgreement,
                )
                : undefined,
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            signedBusinessApprenticeLegalAgreement: this.$.signedBusinessApprenticeLegalAgreement instanceof File
                ? this.$.signedBusinessApprenticeLegalAgreement
                : undefined,
            signedBusinessApprenticeCommercialAgreement:
                this.$.signedBusinessApprenticeCommercialAgreement instanceof File
                    ? this.$.signedBusinessApprenticeCommercialAgreement
                    : undefined,
        });
    }

}
