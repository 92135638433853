<template>

    <b-alert
        v-if="missingInformation"
        :variant="variant"
        fade
        show
    >

        <font-awesome-icon
            v-if="title"
            :icon="['fad', icon]"
            fixed-width
        />

        <b v-if="title">
            {{ title }}
        </b>

        <ul
            v-if="missingInformation.length"
            class="mb-0"
        >
            <li
                v-for="({ name, route }, index) in missingInformation"
                :key="index"
            >

                <router-link
                    v-if="$route.name !== route.name"
                    :to="route"
                >
                    {{ name }}
                </router-link>

                <template
                    v-else
                >
                    {{ name }}
                </template>

            </li>
        </ul>

    </b-alert>

</template>



<script>

    import BusinessBranch from '@core/models/businessBranch';

    export default {
        props: {
            businessBranch: {
                type: BusinessBranch,
                required: true,
            },
            businessBranchEditRoute: {
                type: Object,
                required: true,
            },
            businessBranchOfficeRoute: {
                type: Object,
                required: true,
            },
            businessCompanyEditRoute: {
                type: Object,
                required: true,
            },
            businessCompanyOfficeRoute: {
                type: Object,
                required: true,
            },
            successTitle: {
                type: String,
                default: '',
            },
            warningTitle: {
                type: String,
                default: '',
            },
        },
        computed: {
            fieldData: (vue) => ({
                business_branch_type_id: {
                    name: "la forme juridique de l'établissement",
                    route: vue.businessBranchEditRoute,
                },
                business_naf_id: {
                    name: "le code NAF de l'établissement",
                    route: vue.businessBranchEditRoute,
                },
                importance: {
                    name: "le type d'établissement de l'établissement",
                    route: vue.businessBranchEditRoute,
                },
                regional_authority: {
                    name: "la fonction publique territoriale de l'établissement",
                    route: vue.businessBranchEditRoute,
                },
                email: {
                    name: "l'adresse e-mail de l'établissement",
                    route: vue.businessBranchEditRoute,
                },
                'office.all': {
                    name: "l'adresse de l'établissement",
                    route: vue.businessBranchOfficeRoute,
                },
                'office.post_code': {
                    name: "le code postal de l'adresse de l'établissement",
                    route: vue.businessBranchOfficeRoute,
                },
                'office.insee_code': {
                    name: "la commune de l'adresse de l'établissement",
                    route: vue.businessBranchOfficeRoute,
                },
                'office.city': {
                    name: "la commune de l'adresse de l'établissement",
                    route: vue.businessBranchOfficeRoute,
                },
                'office.phone': {
                    name: "un numéro de téléphone pour l'adresse de l'établissement",
                    route: vue.businessBranchOfficeRoute,
                },
                'businessCompany.business_naf_id': {
                    name: "le code NAF de l'employeur",
                    route: vue.businessCompanyEditRoute,
                },
                'businessCompany.name': {
                    name: "la raison sociale de l'employeur",
                    route: vue.businessCompanyEditRoute,
                },
                'businessCompany.office.all': {
                    name: "l'adresse de l'employeur",
                    route: vue.businessCompanyOfficeRoute,
                },
                'businessCompany.office.post_code': {
                    name: "le code postal de l'adresse de l'employeur",
                    route: vue.businessCompanyOfficeRoute,
                },
                'businessCompany.office.insee_code': {
                    name: "la commune de l'adresse de l'employeur",
                    route: vue.businessCompanyOfficeRoute,
                },
                'businessCompany.office.city': {
                    name: "la commune de l'adresse de l'employeur",
                    route: vue.businessCompanyOfficeRoute,
                },
            }),
            icon: (vue) => (vue.missingInformation.length ? 'exclamation' : 'check'),
            missingInformation: (vue) => (
                vue.businessBranch.$.missing_information
                    ? vue.businessBranch.$.missing_information.map((field) => vue.fieldData[field])
                    : undefined
            ),
            title: (vue) => (vue.missingInformation.length ? vue.warningTitle : vue.successTitle),
            variant: (vue) => (vue.missingInformation.length ? 'warning' : 'success'),
        },
    };

</script>
