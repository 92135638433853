<template>

    <b-button
        v-b-tooltip.noninteractive
        :download="title"
        :href="`data:text/calendar;charset=UTF-8,${encodeURIComponent(icsData)}`"
        title="Ajouter au calendrier"
        variant="outline-secondary"
    >
        <font-awesome-icon
            :icon="['fad', 'calendar']"
            fixed-width
        />
    </b-button>

</template>



<script>

    import QuestionnaireEntry from '@core/models/questionnaireEntry';
    import Dayjs from '@core/mixins/dayjs';

    export default {
        mixins: [Dayjs],
        props: {
            questionnaireEntry: {
                type: QuestionnaireEntry,
                required: true,
            },
        },
        computed: {
            icsData: (vue) => [
                'BEGIN:VCALENDAR',
                'VERSION:2.0',
                `PRODID:${vue.$store.getters['center/platformName']}`,
                'BEGIN:VEVENT',
                `UID:${Math.random().toString(16).slice(2)}`,
                `URL:${window.location.href}`,
                `DTSTAMP:${vue.dayjsMake().format('YYYYMMDDTHHmmss')}`,
                `SUMMARY:${vue.title}`,
                `DTSTART:${vue.dayjsMake(vue.questionnaireEntry.$.questionnaireSurvey.$.end).format('YYYYMMDD')}`,
                `DTEND:${vue.dayjsMake(vue.questionnaireEntry.$.questionnaireSurvey.$.end).format('YYYYMMDD')}`,
                'TRANSP:TRANSPARENT',
                `DESCRIPTION:${
                    [
                        'Apprenant :',
                        [
                            vue.questionnaireEntry.learnerPerson.$.fullname,
                            vue.questionnaireEntry.learnerPerson.learnerEducationStudy
                                ? vue.questionnaireEntry.learnerPerson.learnerEducationStudy.$.user.$.email
                                : undefined,
                            vue.questionnaireEntry.learnerPerson.$.primaryAddress.$.mobile_phone,
                            vue.questionnaireEntry.learnerPerson.$.primaryAddress.$.landline_phone,
                            vue.questionnaireEntry.learnerPerson.$.secondaryAddress.$.mobile_phone,
                            vue.questionnaireEntry.learnerPerson.$.secondaryAddress.$.landline_phone,
                        ].filter((line) => line).join(' '),
                        ...vue.questionnaireEntry.$.educationMentors.length === 0 ? [] : [
                            '',
                            'Tuteur pédagogique :',
                            ...vue.questionnaireEntry.$.educationMentors.map((educationPerson) => [
                                '-',
                                educationPerson.$.fullname,
                                educationPerson.$.email,
                                educationPerson.$.direct_phone,
                                educationPerson.$.mobile_phone,
                                educationPerson.$.reception_phone,
                            ].filter((line) => line).join(' ')),
                        ],
                        ...vue.questionnaireEntry.$.businessPeople.length === 0 ? [] : [
                            '',
                            'Maître d\'apprentissage / Tuteur entreprise :',
                            ...vue.questionnaireEntry.$.businessPeople.map((businessPerson) => [
                                '-',
                                businessPerson.$.fullname,
                                businessPerson.$.email,
                                businessPerson.$.phone,
                            ].filter((line) => line).join(' ')),
                        ],
                        ...Object.values(vue.questionnaireEntry.$.educationStudyPeople).length === 0 ? [] : [
                            '',
                            'Responsable de formation :',
                            ...Object.values(vue.questionnaireEntry.$.educationStudyPeople).map((educationPerson) => [
                                '-',
                                educationPerson.$.fullname,
                                educationPerson.$.email,
                                educationPerson.$.direct_phone,
                                educationPerson.$.mobile_phone,
                                educationPerson.$.reception_phone,
                            ].filter((line) => line).join(' ')),
                        ],
                    ].join('\\n')
                }`,
                'BEGIN:VALARM',
                'TRIGGER:-P14D',
                `UID:${Math.random().toString(16).slice(2)}`,
                `DESCRIPTION:[${vue.title}`,
                'ACTION:DISPLAY',
                'END:VALARM',
                'END:VEVENT',
                'END:VCALENDAR',
            ]
                .flatMap(
                    (line) => line.match(/.{1,70}/g).map((segment, index) => (index === 0 ? segment : ` ${segment}`)),
                )
                .join('\r\n'),
            title: (vue) => `[${vue.questionnaireEntry.learnerPerson.$.fullname}] `
                + `${vue.questionnaireEntry.$.questionnaireSurvey.$.name}`,
        },
    };

</script>
