import EducationClass from '@core/models/educationClass';
import LearnerPerson from '@core/models/learnerPerson';
import RestModel from '@core/models/_rest';

export default class LearnerEducationClass extends RestModel {

    get url() {
        return this.$.id ? `learnerEducationClasses/${this.$.id}` : 'learnerEducationClasses';
    }

    fill({
        educationClass = {},
        learnerPerson = {},
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            educationClass: new EducationClass(educationClass.$ || educationClass),
            learnerPerson: new LearnerPerson(learnerPerson.$ || learnerPerson),
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            education_class_id: this.$.educationClass.$.id,
            learner_person_id: this.$.learnerPerson.$.id,
        });
    }

}
