import Collection from '@core/models/collection';

export default class Cities extends Collection {

    indexSuccessful(response) {
        this.models = response.data.map(
            (data) => new this.model.constructor({
                name: data.nom,
                insee_code: data.code,
                post_code: response.config.params.codePostal,
            }),
        );
        this.prefilled = false;
        return this;
    }

    request(page, search, sort) {
        const request = super.request(page, search, sort);
        request.axios.defaults.withCredentials = false;
        return request;
    }

}
