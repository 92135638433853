import RestModel from '@core/models/_rest';
import User from '@core/models/user';

export default class Datalog extends RestModel {

    get variant() {
        if (this.$.event === 'create') {
            return 'success';
        }
        if (this.$.before === null && this.$.after === null) {
            return 'warning';
        }
        if (this.$.before === null || this.$.before === '') {
            return 'success';
        }
        if (this.$.after === null || this.$.after === '') {
            return 'danger';
        }
        return 'warning';
    }

    get verb() {
        if (this.$.event === 'create') {
            return 'a créé';
        }
        if (this.$.before === null && this.$.after === null) {
            return 'a modifié';
        }
        if (this.$.before === null || this.$.before === '') {
            return 'a renseigné';
        }
        if (this.$.after === null || this.$.after === '') {
            return 'a effacé';
        }
        return 'a remplacé';
    }

    get url() {
        return this.$.id ? undefined : 'datalogs';
    }

    fill({ user, ...attributes }) {
        return super.fill({
            ...attributes,
            user: user ? new User(user) : null,
        });
    }

}
