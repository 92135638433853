<template>

    <b-tr>

        <b-td class="align-middle">
            <form-group
                :id="`questionnaireField-${questionnaireField.$.id}`"
                :error="formModifiedModel.errors.content"
                :label="questionnaireField.$.name"
                :required="questionnaireField.$.mandatory"
                :state="formModifiedModel.states.content"
                class="mb-0"
            >
                <template slot="description">

                    <div v-if="questionnaireField.$.attributes.coefficient">
                        Coefficient : {{ questionnaireField.$.attributes.coefficient }}.
                    </div>

                    <div v-if="formIsPending">
                        <b-badge variant="success">
                            <font-awesome-icon
                                :icon="['fad', 'circle-notch']"
                                fixed-width
                                flip
                                spin
                            />
                            Enregistrement...
                        </b-badge>
                    </div>

                    <div
                        v-for="(questionnaireAnswer, role) in questionnaireAnswersByRole"
                        :key="role"
                    >
                        <b-badge variant="light">
                            <template v-if="questionnaireAnswer.$.updated_at">
                                <font-awesome-icon
                                    :icon="['fad', 'history']"
                                    fixed-width
                                />
                                Enregistré
                                <template v-if="questionnaireAnswer.$.user_fullname">
                                    par {{ questionnaireAnswer.$.user_fullname }}
                                </template>
                                le {{ dayjsLongDate(questionnaireAnswer.$.updated_at) }}.
                            </template>
                        </b-badge>
                    </div>

                </template>
            </form-group>
        </b-td>

        <b-td
            v-for="(columnText, columnValue) in columns"
            :key="columnValue"
            class="text-center align-middle"
        >
            <div
                v-for="role in roles"
                :key="role"
                v-b-tooltip.noninteractive="roles.length > 1"
                :title="roles.length > 1 ? questionnaireSurvey.roleTitleNames[role] : undefined"
            >
                <b-form-radio
                    :checked="questionnaireAnswersByRole[role].$.content"
                    :disabled="disabled || formModifiedModel.isPending"
                    :error="formModifiedModel.errors.content"
                    :name="`questionnaireField-${questionnaireField.$.id}-${role}`"
                    :state="formModifiedModel.states.content"
                    :value="columnValue"
                    @change="setContent($event)"
                >
                    <font-awesome-icon
                        v-if="roles.length > 1 && roleIcons[role]"
                        :icon="['fad', roleIcons[role]]"
                        fixed-width
                    />
                </b-form-radio>
            </div>
        </b-td>

    </b-tr>

</template>



<script>

    import Dayjs from '@core/mixins/dayjs';
    import Form from '@core/mixins/form';
    import QuestionnaireAnswer from '@core/models/questionnaireAnswer';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';
    import QuestionnaireField from '@core/models/questionnaireField';
    import QuestionnaireSurvey from '@core/models/questionnaireSurvey';

    export default {
        mixins: [Dayjs, Form],
        props: {
            columns: {
                type: [Array, Object],
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            questionnaireAnswers: {
                type: Array,
                required: true,
            },
            questionnaireEntry: {
                type: QuestionnaireEntry,
                default: null,
            },
            questionnaireField: {
                type: QuestionnaireField,
                required: true,
            },
            questionnaireSurvey: {
                type: QuestionnaireSurvey,
                required: true,
            },
            roles: {
                type: Array,
                required: true,
            },
        },
        computed: {
            formInitialModel: (vue) => Object.entries(vue.questionnaireAnswersByRole)[0][1],
            questionnaireAnswersByRole: (vue) => vue.roles.reduce((questionnaireAnswersByRole, role) => {
                questionnaireAnswersByRole[role] = vue.questionnaireAnswers.find(
                    (questionnaireAnswer) => questionnaireAnswer.$.role === role,
                ) || new QuestionnaireAnswer({ questionnaire_field_id: vue.questionnaireField.$.id, role });
                return questionnaireAnswersByRole;
            }, {}),
            roleIcons: () => QuestionnaireSurvey.roleIcons,
        },
        methods: {
            formSubmit() {
                this.formModifiedModel.$.questionnaire_entry_id = this.questionnaireEntry.$.id;
                return this.formModifiedModel.request().create().then(this.formSubmitSuccessful).catch(() => {});
            },
            formSubmitSuccessful() {
                this.formModifiedModel.errors = { fake: [] };
            },
            setContent(content) {
                this.formModifiedModel.$.content = content;
                this.formSubmit();
            },
        },
    };

</script>
