import RestModel from '@core/models/_rest';
import School from '@core/models/school';
/* eslint-disable-next-line import/no-cycle */
import User from '@core/models/user';

export default class EducationPerson extends RestModel {

    static get titles() {
        return {
            mister: 'Monsieur',
            mistress: 'Madame',
        };
    }

    get url() {
        return this.$.id ? `educationPeople/${this.$.id}` : 'educationPeople';
    }

    fill({
        school = {},
        user = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            school: new School(school.$ || school),
            user: user ? new User(user.$ || user) : undefined,
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            school_id: this.$.school ? this.$.school.$.id : null,
        });
    }

}
