<template>

    <div class="d-flex align-items-center justify-content-between">

        <small>
            {{ label }}
            {{ collection.firstVisibleRecord }} à {{ collection.lastVisibleRecord }} sur {{ collection.totalRecord }}.
        </small>

        <b-pagination
            v-if="collection.lastPage > collection.firstPage"
            :per-page="collection.perPage"
            :total-rows="collection.totalRecord"
            :value="value"
            align="end"
            class="mb-0"
            @input="input"
        />

    </div>

</template>



<script>

    import Collection from '@core/models/collection';

    export default {
        props: {
            collection: {
                type: Collection,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            value: {
                type: Number,
                default: 1,
            },
        },
        methods: {
            input(event) {
                this.$emit('input', event);
                window.scroll({ top: 0, behavior: 'smooth' });
            },
        },
    };

</script>
