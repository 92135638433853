import Address from '@core/models/address';
import BusinessApprenticeAgreement from '@core/models/businessApprenticeAgreement';
import BusinessApprenticeBranchType from '@core/models/businessApprenticeBranchType';
import BusinessApprenticeClass from '@core/models/businessApprenticeClass';
import BusinessApprenticeDiploma from '@core/models/businessApprenticeDiploma';
import BusinessApprenticeDuration from '@core/models/businessApprenticeDuration';
import BusinessApprenticeExemption from '@core/models/businessApprenticeExemption';
import BusinessApprenticeReason from '@core/models/businessApprenticeReason';
import BusinessApprenticeSituation from '@core/models/businessApprenticeSituation';
import BusinessApprenticeTerminationReason from '@core/models/businessApprenticeTerminationReason';
import BusinessBranch from '@core/models/businessBranch';
import BusinessConvergence from '@core/models/businessConvergence';
import BusinessPerson from '@core/models/businessPerson';
import BusinessIdcc from '@core/models/businessIdcc';
import EducationStudy from '@core/models/educationStudy';
import LearnerPerson from '@core/models/learnerPerson';
import RestModel from '@core/models/_rest';
import Upload from '@core/models/upload';

export default class BusinessApprenticeContract extends RestModel {

    static get commercialAgreementStatusNames() {
        return {
            '': 'Non renseigné',
            canceled: 'Annulé',
            invalid: 'Incohérence CFA OPCO',
            missing: "Préparation sans numéro d'accord",
            pending: "En cours d'instruction",
            preparing: "Données en cours de transmission par l'OPCO",
            rejected: 'Refusé',
            settled: 'Soldé',
            started: 'Engagé',
            terminated: 'Rupture',
            transmitted: 'Transmis',
        };
    }

    static get commercialAgreementStatusVariants() {
        return {
            '': 'dark',
            canceled: 'danger',
            invalid: 'warning',
            missing: 'danger',
            pending: 'warning',
            preparing: 'warning',
            rejected: 'danger',
            settled: 'info',
            started: 'success',
            terminated: 'success',
            transmitted: 'warning',
        };
    }

    static get invoiceMethods() {
        return {
            email: 'Email',
            mail: 'Courrier',
            platform: 'Plateforme',
        };
    }

    get showInvoiceFields() {
        return this.$.educationStudy.$.business_apprentice_commercial_agreement_remaining_balance_mode !== 'none' || (
            this.$.businessApprenticeAgreement
                && Object.values(this.$.businessApprenticeAgreement.$.remaining_balances).reduce((a, b) => a + b, 0) > 0
        );
    }

    static get socialSecuritySchemes() {
        return {
            msa: 'MSA',
            urssaf: 'URSSAF',
            '': 'Aucun',
        };
    }

    static get statusNames() {
        return {
            created: "Rédaction des informations par l'entreprise",
            completed_delegatee: 'Rédaction des informations',
            completed: 'Génération des documents',
            downloaded: 'Signature des documents',
            uploaded: 'Documents signés',
            registered: 'Dépôt OPCO',
            terminated: 'Rupture',
        };
    }

    static get statusIcons() {
        return {
            created: 'industry-alt',
            completed_delegatee: 'edit',
            completed: 'download',
            downloaded: 'upload',
            uploaded: 'check',
            registered: 'key-skeleton',
            terminated: 'times',
        };
    }

    get url() {
        if (this.$.status !== 'terminated' && this.$.terminated_at) {
            return `businessApprenticeContracts/${this.$.id}/terminate`;
        }

        return this.$.id ? `businessApprenticeContracts/${this.$.id}` : 'businessApprenticeContracts';
    }

    fill({
        businessApprenticeAgreement = undefined,
        businessApprenticeBranchType = undefined,
        businessApprenticeDuration = undefined,
        businessApprenticeExemption = undefined,
        businessApprenticeReason = undefined,
        businessApprenticeTerminationReason = undefined,
        businessConvergence = undefined,
        businessIdcc = undefined,
        educationStudy = {},
        executingBusinessBranch = {},
        highestBusinessApprenticeDiploma = undefined,
        invoiceAddress = {},
        lastBusinessApprenticeClass = undefined,
        lastBusinessApprenticeDiploma = undefined,
        lastBusinessApprenticeSituation = undefined,
        learnerPerson = {},
        mobilityAppendix = undefined,
        location = {},
        signatoryBusinessBranch = {},
        signatoryBusinessContact = undefined,
        signatoryBusinessInvoiced = undefined,
        signatoryBusinessPurchaser = undefined,
        signatoryBusinessRemainingBalanceGranter = undefined,
        signatoryBusinessRepresentative = undefined,
        terminationStatement = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            businessApprenticeAgreement: businessApprenticeAgreement
                ? new BusinessApprenticeAgreement(businessApprenticeAgreement.$ || businessApprenticeAgreement)
                : null,
            businessApprenticeBranchType: businessApprenticeBranchType
                ? new BusinessApprenticeBranchType(businessApprenticeBranchType.$ || businessApprenticeBranchType)
                : null,
            businessApprenticeDuration: businessApprenticeDuration
                ? new BusinessApprenticeDuration(businessApprenticeDuration.$ || businessApprenticeDuration)
                : null,
            businessApprenticeExemption: businessApprenticeExemption
                ? new BusinessApprenticeExemption(businessApprenticeExemption.$ || businessApprenticeExemption)
                : null,
            businessApprenticeReason: businessApprenticeReason
                ? new BusinessApprenticeReason(businessApprenticeReason.$ || businessApprenticeReason)
                : null,
            businessApprenticeTerminationReason: businessApprenticeTerminationReason
                ? new BusinessApprenticeTerminationReason(
                    businessApprenticeTerminationReason.$ || businessApprenticeTerminationReason,
                )
                : null,
            businessConvergence: businessConvergence
                ? new BusinessConvergence(businessConvergence.$ || businessConvergence)
                : null,
            businessIdcc: businessIdcc
                ? new BusinessIdcc(businessIdcc.$ || businessIdcc)
                : null,
            executingBusinessBranch: new BusinessBranch(executingBusinessBranch.$ || executingBusinessBranch),
            educationStudy: new EducationStudy(educationStudy.$ || educationStudy),
            highestBusinessApprenticeDiploma: highestBusinessApprenticeDiploma
                ? new BusinessApprenticeDiploma(highestBusinessApprenticeDiploma.$ || highestBusinessApprenticeDiploma)
                : null,
            invoiceAddress: new Address(invoiceAddress.$ || invoiceAddress),
            lastBusinessApprenticeClass: lastBusinessApprenticeClass
                ? new BusinessApprenticeClass(lastBusinessApprenticeClass.$ || lastBusinessApprenticeClass)
                : null,
            lastBusinessApprenticeDiploma: lastBusinessApprenticeDiploma
                ? new BusinessApprenticeDiploma(lastBusinessApprenticeDiploma.$ || lastBusinessApprenticeDiploma)
                : null,
            lastBusinessApprenticeSituation: lastBusinessApprenticeSituation
                ? new BusinessApprenticeSituation(lastBusinessApprenticeSituation.$ || lastBusinessApprenticeSituation)
                : null,
            learnerPerson: new LearnerPerson(learnerPerson.$ || learnerPerson),
            mobilityAppendix: mobilityAppendix ? new Upload(mobilityAppendix.$ || mobilityAppendix) : undefined,
            location: new Address(location.$ || location),
            signatoryBusinessBranch: new BusinessBranch(signatoryBusinessBranch.$ || signatoryBusinessBranch),
            signatoryBusinessContact: signatoryBusinessContact
                ? new BusinessPerson(signatoryBusinessContact.$ || signatoryBusinessContact)
                : null,
            signatoryBusinessInvoiced: signatoryBusinessInvoiced
                ? new BusinessPerson(signatoryBusinessInvoiced.$ || signatoryBusinessInvoiced)
                : null,
            signatoryBusinessPurchaser: signatoryBusinessPurchaser
                ? new BusinessPerson(signatoryBusinessPurchaser.$ || signatoryBusinessPurchaser)
                : null,
            signatoryBusinessRemainingBalanceGranter: signatoryBusinessRemainingBalanceGranter
                ? new BusinessPerson(
                    signatoryBusinessRemainingBalanceGranter.$ || signatoryBusinessRemainingBalanceGranter,
                )
                : null,
            signatoryBusinessRepresentative: signatoryBusinessRepresentative
                ? new BusinessPerson(signatoryBusinessRepresentative.$ || signatoryBusinessRepresentative)
                : null,
            terminationStatement: terminationStatement
                ? new Upload(terminationStatement.$ || terminationStatement)
                : undefined,
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            business_apprentice_branch_type_id: this.$.businessApprenticeBranchType
                ? this.$.businessApprenticeBranchType.$.id
                : null,
            business_apprentice_duration_id: this.$.businessApprenticeDuration
                ? this.$.businessApprenticeDuration.$.id
                : null,
            business_apprentice_exemption_id: this.$.businessApprenticeExemption
                ? this.$.businessApprenticeExemption.$.id
                : null,
            business_apprentice_reason_id: this.$.businessApprenticeReason
                ? this.$.businessApprenticeReason.$.id
                : null,
            business_apprentice_termination_reason_id: this.$.businessApprenticeTerminationReason
                ? this.$.businessApprenticeTerminationReason.$.id
                : null,
            business_idcc_id: this.$.businessIdcc ? this.$.businessIdcc.$.id : null,
            education_study_id: this.$.educationStudy.$.id,
            executing_business_branch_id: this.$.executingBusinessBranch ? this.$.executingBusinessBranch.$.id : null,
            highest_business_apprentice_diploma_id: this.$.highestBusinessApprenticeDiploma
                ? this.$.highestBusinessApprenticeDiploma.$.id
                : null,
            last_business_apprentice_class_id: this.$.lastBusinessApprenticeClass
                ? this.$.lastBusinessApprenticeClass.$.id
                : null,
            last_business_apprentice_diploma_id: this.$.lastBusinessApprenticeDiploma
                ? this.$.lastBusinessApprenticeDiploma.$.id
                : null,
            last_business_apprentice_situation_id: this.$.lastBusinessApprenticeSituation
                ? this.$.lastBusinessApprenticeSituation.$.id
                : null,
            learner_person_id: this.$.learnerPerson.$.id,
            mobilityAppendix: this.$.mobilityAppendix instanceof File ? this.$.mobilityAppendix : undefined,
            signatory_business_branch_id: this.$.signatoryBusinessBranch ? this.$.signatoryBusinessBranch.$.id : null,
            signatory_business_contact_id: this.$.signatoryBusinessContact
                ? this.$.signatoryBusinessContact.$.id
                : null,
            signatory_business_invoiced_id: this.$.signatoryBusinessInvoiced
                ? this.$.signatoryBusinessInvoiced.$.id
                : null,
            signatory_business_purchaser_id: this.$.signatoryBusinessPurchaser
                ? this.$.signatoryBusinessPurchaser.$.id
                : null,
            signatory_business_remaining_balance_granter_id: this.$.signatoryBusinessRemainingBalanceGranter
                ? this.$.signatoryBusinessRemainingBalanceGranter.$.id
                : null,
            signatory_business_representative_id: this.$.signatoryBusinessRepresentative
                ? this.$.signatoryBusinessRepresentative.$.id
                : null,
            terminationStatement: this.$.terminationStatement instanceof File ? this.$.terminationStatement : undefined,
        });
    }

}
