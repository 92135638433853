import RestModel from '@core/models/_rest';

export default class Address extends RestModel {

    get editableAttribues() {
        if (this.$.data_source === 'opendatasoft') {
            return ['landline_phone', 'mobile_phone', 'fax'];
        }

        if (this.$.data_source === 'user') {
            return [
                'appartment',
                'building',
                'street_number',
                'street_name',
                'locality',
                'post_code',
                'insee_code',
                'city',
                'country',
                'landline_phone',
                'mobile_phone',
                'fax',
            ];
        }

        return [];
    }

    get isEmpty() {
        return Object.values(this.$).filter((value) => value).length === 4;
    }

    get url() {
        return this.$.id ? `addresses/${this.$.id}` : 'addresses';
    }

}
