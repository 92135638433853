import BusinessCompany from '@core/models/businessCompany';
import BusinessPersonDiploma from '@core/models/businessPersonDiploma';
import RestModel from '@core/models/_rest';
/* eslint-disable-next-line import/no-cycle */
import User from '@core/models/user';

export default class BusinessPerson extends RestModel {

    static get jobTypes() {
        return {
            director: 'Direction',
            finances: 'Finances',
            hr: 'Ressources humaines',
            production: 'Production',
            research: 'Recherche et développement',
        };
    }

    static get titles() {
        return {
            mister: 'Monsieur',
            mistress: 'Madame',
        };
    }

    get url() {
        return this.$.id ? `businessPeople/${this.$.id}` : 'businessPeople';
    }

    fill({
        businessCompany = {},
        highestBusinessPersonDiploma = undefined,
        user = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            businessCompany: new BusinessCompany(businessCompany.$ || businessCompany),
            highestBusinessPersonDiploma: highestBusinessPersonDiploma
                ? new BusinessPersonDiploma(highestBusinessPersonDiploma.$ || highestBusinessPersonDiploma)
                : undefined,
            user: user ? new User(user.$ || user) : undefined,
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            business_company_id: this.$.businessCompany ? this.$.businessCompany.$.id : null,
            highest_business_person_diploma_id: this.$.highestBusinessPersonDiploma
                ? this.$.highestBusinessPersonDiploma.$.id
                : null,
        });
    }

}
