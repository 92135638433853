<template>

    <b-card
        :header="header"
        tag="form"
        header-tag="h2"
        no-body
        @submit.prevent="formSubmit"
    >

        <b-card-body>

            <form-group
                id="view-web-edit-email"
                v-slot="inputProps"
                :error="formModifiedModel.errors.email"
                :left-icon="formInitialModel.$.updatable ? 'envelope' : undefined"
                :pending="formInitialModel.$.id === undefined"
                :state="formModifiedModel.states.email"
                label="Adresse e-mail"
            >
                <b-form-input
                    v-model="formModifiedModel.$.email"
                    v-bind="inputProps"
                    :plaintext="formInitialModel.$.updatable !== true"
                    type="email"
                />
            </form-group>

            <form-group
                id="view-web-edit-facebook"
                v-slot="inputProps"
                :error="formModifiedModel.errors.facebook"
                :left-icon="formInitialModel.$.updatable ? 'facebook' : undefined"
                :pending="formInitialModel.$.id === undefined"
                :state="formModifiedModel.states.facebook"
                label="Facebook"
                left-icon-family="fab"
            >
                <b-form-input
                    v-model="formModifiedModel.$.facebook"
                    v-bind="inputProps"
                    :placeholder="formInitialModel.$.updatable ? 'https://www.facebook.com/' : undefined"
                    :plaintext="formInitialModel.$.updatable !== true"
                    type="url"
                />
            </form-group>

            <form-group
                id="view-web-edit-instagram"
                v-slot="inputProps"
                :error="formModifiedModel.errors.instagram"
                :left-icon="formInitialModel.$.updatable ? 'instagram' : undefined"
                :pending="formInitialModel.$.id === undefined"
                :state="formModifiedModel.states.instagram"
                label="Instagram"
                left-icon-family="fab"
            >
                <b-form-input
                    v-model="formModifiedModel.$.instagram"
                    v-bind="inputProps"
                    :placeholder="formInitialModel.$.updatable ? 'https://www.instagram.com/' : undefined"
                    :plaintext="formInitialModel.$.updatable !== true"
                    type="url"
                />
            </form-group>

            <form-group
                id="view-web-edit-linkedin"
                v-slot="inputProps"
                :error="formModifiedModel.errors.linkedin"
                :left-icon="formInitialModel.$.updatable ? 'linkedin' : undefined"
                :pending="formInitialModel.$.id === undefined"
                :state="formModifiedModel.states.linkedin"
                label="Linkedin"
                left-icon-family="fab"
            >
                <b-form-input
                    v-model="formModifiedModel.$.linkedin"
                    v-bind="inputProps"
                    :placeholder="formInitialModel.$.updatable ? 'https://fr.linkedin.com/' : undefined"
                    :plaintext="formInitialModel.$.updatable !== true"
                    type="url"
                />
            </form-group>

            <form-group
                id="view-web-edit-twitter"
                v-slot="inputProps"
                :error="formModifiedModel.errors.twitter"
                :left-icon="formInitialModel.$.updatable ? 'twitter' : undefined"
                :pending="formInitialModel.$.id === undefined"
                :state="formModifiedModel.states.twitter"
                label="Twitter"
                left-icon-family="fab"
            >
                <b-form-input
                    v-model="formModifiedModel.$.twitter"
                    v-bind="inputProps"
                    :placeholder="formInitialModel.$.updatable ? 'https://twitter.com/' : undefined"
                    :plaintext="formInitialModel.$.updatable !== true"
                    type="url"
                />
            </form-group>

            <form-group
                id="view-web-edit-website"
                v-slot="inputProps"
                :error="formModifiedModel.errors.website"
                :left-icon="formInitialModel.$.updatable ? 'browser' : undefined"
                :pending="formInitialModel.$.id === undefined"
                :state="formModifiedModel.states.website"
                label="Site Internet"
            >
                <b-form-input
                    v-model="formModifiedModel.$.website"
                    v-bind="inputProps"
                    :placeholder="formInitialModel.$.updatable ? 'https://www.' : undefined"
                    :plaintext="formInitialModel.$.updatable !== true"
                    type="url"
                />
            </form-group>

            <form-group
                id="view-web-edit-youtube"
                v-slot="inputProps"
                :error="formModifiedModel.errors.youtube"
                :left-icon="formInitialModel.$.updatable ? 'youtube' : undefined"
                :pending="formInitialModel.$.id === undefined"
                :state="formModifiedModel.states.youtube"
                label="Youtube"
                left-icon-family="fab"
            >
                <b-form-input
                    v-model="formModifiedModel.$.youtube"
                    v-bind="inputProps"
                    :placeholder="formInitialModel.$.updatable ? 'https://www.youtube.com/' : undefined"
                    :plaintext="formInitialModel.$.updatable !== true"
                    type="url"
                />
            </form-group>

        </b-card-body>

        <b-card-footer
            v-if="formInitialModel.$.updatable"
            class="text-right"
        >
            <button-submit
                :is-pending="formIsPending"
                icon="edit"
                variant="success"
            >
                Enregistrer les réseaux
            </button-submit>
        </b-card-footer>

    </b-card>

</template>



<script>

    import Web from '@core/models/web';
    import Form from '@core/mixins/form';

    export default {
        mixins: [Form],
        props: {
            header: {
                type: String,
                default: undefined,
            },
            value: {
                type: Web,
                required: true,
            },
        },
        computed: {
            formInitialModel: (vue) => vue.value,
        },
        methods: {
            formSubmitSuccessful(web) {
                this.$root.toastSuccess(
                    'Les réseaux sociaux ont été modifiés',
                    "L'enregistrement des informations a réussi.",
                );
                this.$emit('input', web);
            },
        },
    };

</script>
