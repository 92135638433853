<template>

    <div class="view-businessApprenticeSalary-index">

        <div
            v-if="formInitialModel.$.id === undefined"
            class="mb-3"
        >
            <b-skeleton class="w-50 mb-2" />
            <b-skeleton type="input" />
        </div>

        <form-group
            v-else
            :id="`view-businessApprenticeSalary-edit-${formInitialModel.$.id}`"
            v-slot="inputProps"
            :error="error"
            :label="label"
            :state="state"
            business-apprentice-agreement
        >

            <b-form-input
                v-model="formModifiedModel.$.salary_percentage"
                v-bind="inputProps"
                :plaintext="formInitialModel.$.updatable !== true"
                class="pr-4 text-right"
                min="0"
                type="number"
            />

            <b-input-group-text
                :class="{ 'bg-transparent border-0': formInitialModel.$.updatable !== true }"
                class="pl-0 border-left-0 border-right-0 rounded-0"
            >
                % du
            </b-input-group-text>

            <b-form-select
                v-if="formInitialModel.$.updatable"
                v-model="formModifiedModel.$.salary_reference"
                v-bind="inputProps"
                :options="salaryReferenceOptions"
            />

            <b-form-input
                v-else
                v-bind="inputProps"
                :value="salaryReferences[formInitialModel.$.salary_reference]"
                plaintext
            />

        </form-group>

    </div>

</template>



<script>

    import BusinessApprenticeSalary from '@core/models/businessApprenticeSalary';
    import Dayjs from '@core/mixins/dayjs';
    import Form from '@core/mixins/form';

    export default {
        mixins: [Dayjs, Form],
        props: {
            value: {
                type: BusinessApprenticeSalary,
                required: true,
            },
        },
        data: () => ({
        }),
        computed: {
            formInitialModel: {
                get: (vue) => vue.value,
                set(businessApprenticeSalary) {
                    this.$emit('input', businessApprenticeSalary);
                },
            },
            error: (vue) => [
                ...vue.formInitialModel.errors.salary_percentage || [],
                ...vue.formInitialModel.errors.salary_reference || [],
            ],
            label: (vue) => `Salaire de la période ${vue.formInitialModel.$.period} : `
                + `du ${vue.dayjsShortDate(vue.formInitialModel.$.start)} `
                + `au ${vue.dayjsShortDate(vue.formInitialModel.$.end)}`,
            salaryReferences: () => BusinessApprenticeSalary.salaryReferences,
            salaryReferenceOptions: (vue) => Object.entries(vue.salaryReferences).map(
                ([value, text]) => ({ text, value }),
            ),
            state: (vue) => (
                vue.formInitialModel.states.salary_percentage === false
                    ? vue.formInitialModel.states.salary_percentage
                    : vue.formInitialModel.states.salary_reference
            ),
        },
        watch: {
            formModifiedModel: {
                deep: true,
                handler() {
                    if (this.formModifiedModel.$.id && this.formModifiedModel.$.id === this.formInitialModel.$.id) {
                        this.$emit('input', this.formModifiedModel);
                    }
                },
            },
        },
        methods: {
            formCreateAllowed: () => true,
        },
    };

</script>
