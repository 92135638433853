import BusinessBranch from '@core/models/businessBranch';
import BusinessPerson from '@core/models/businessPerson';
import EducationStudy from '@core/models/educationStudy';
import LearnerPerson from '@core/models/learnerPerson';
import RestModel from '@core/models/_rest';

export default class BusinessDirectMentorship extends RestModel {

    get url() {
        return this.$.id ? `businessDirectMentorships/${this.$.id}` : 'businessDirectMentorships';
    }

    fill({
        businessBranch = {},
        businessPerson = undefined,
        educationStudy = {},
        learnerPerson = {},
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            businessBranch: new BusinessBranch(businessBranch.$ || businessBranch),
            businessPerson: businessPerson ? new BusinessPerson(businessPerson.$ || businessPerson) : undefined,
            educationStudy: new EducationStudy(educationStudy.$ || educationStudy),
            learnerPerson: new LearnerPerson(learnerPerson.$ || learnerPerson),
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            business_branch_id: this.$.businessBranch.$.id,
            business_person_id: this.$.businessPerson ? this.$.businessPerson.$.id : undefined,
            education_study_id: this.$.educationStudy.$.id,
            learner_person_id: this.$.learnerPerson.$.id,
        });
    }

}
