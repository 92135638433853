<template>

    <model-list
        v-slot="{ collection, setValue }"
        :prefill="10"
        :request-data="requestData"
        name="School"
        @change="$emit('change', $event)"
    >
        <b-list-group flush>

            <b-list-group-item
                class="font-weight-bold"
                button
                @click="$emit('cancel')"
            >
                <font-awesome-icon
                    :icon="['fad', 'arrow-left']"
                    fixed-width
                />
                Antennes
            </b-list-group-item>

            <b-list-group-item
                v-for="school in collection.models"
                :key="school.$.id"
                :disabled="collection.isPending"
                button
                @click="setValue(school.$.id)"
            >

                <b-skeleton v-if="collection.isPending" />

                <template v-else>
                    <font-awesome-icon
                        :icon="['fad', 'school']"
                        class="mr-1"
                        fixed-width
                    />
                    {{ school.$.name }}
                </template>

            </b-list-group-item>

        </b-list-group>
    </model-list>

</template>



<script>

    export default {
        props: {
            requestData: {
                type: Object,
                required: true,
            },
        },
    };

</script>
