<template>

    <form-group
        :id="`questionnaireElement-${questionnaireElement.$.id}`"
        v-slot="inputProps"
        :label="questionnaireElement.$.attributes.text"
    >

        <div
            v-if="isYoutube"
            v-bind="inputProps"
            class="embed-responsive"
        >
            <iframe
                :src="questionnaireElement.$.attributes.url"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                class="embed-responsive-item"
                allowfullscreen
            />
        </div>


        <b-button
            v-else
            v-bind="inputProps"
            :href="questionnaireElement.$.attributes.url"
            target="_blank"
            variant="link"
        >
            <font-awesome-icon
                :icon="['fad', 'external-link']"
                fixed-width
            />
            Ouvrir dans un nouvel onglet
        </b-button>

    </form-group>

</template>



<script>

    import QuestionnaireElement from '@core/models/questionnaireElement';

    export default {
        props: {
            questionnaireElement: {
                type: QuestionnaireElement,
                required: true,
            },
        },
        computed: {
            isYoutube: (vue) => vue.questionnaireElement.$.attributes.url.match(
                /https:\/\/(www\.)?youtube\.com\/embed\/[a-zA-Z0-9-]+/,
            ),
        },
    };

</script>



<style scoped>

    .embed-responsive {
        height: 500px;
    }

</style>
