<template>

    <b-modal
        id="jurisdiction-selector"
        :title="title"
        body-class="p-0"
        content-class="overflow-hidden"
        hide-footer
        scrollable
        @hidden="className = null"
    >
        <transition
            :enter-active-class="enterTransition"
            :leave-active-class="leaveTransition"
            mode="out-in"
        >

            <component
                :is="jurisdictionSelector"
                v-if="jurisdictionSelector"
                key="with-jurisdiction-type"
                :request-data="requestData"
                @change="select($event)"
                @cancel="className = null"
            />

            <b-list-group
                v-else
                key="without-jurisdiction-type"
                flush
            >

                <b-list-group-item class="font-weight-bold">
                    Tous les périmètres
                </b-list-group-item>

                <b-list-group-item
                    v-for="type in visibleJurisdictionsTypes"
                    :key="type.value"
                    button
                    @click="className = type.value"
                >
                    <font-awesome-icon
                        :icon="['fad', type.iconLeft]"
                        fixed-width
                    />
                    {{ type.title }}
                    <font-awesome-icon
                        :icon="['fad', type.iconRight]"
                        class="float-right"
                        fixed-width
                    />
                </b-list-group-item>

            </b-list-group>

        </transition>
    </b-modal>

</template>



<script>

    import Academy from '@core/models/academy';
    import Center from '@core/models/center';
    import EducationStudy from '@core/models/educationStudy';
    import School from '@core/models/school';

    export default {
        props: {
            requestData: {
                type: Object,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            selectableJurisdictions: {
                type: Array,
                required: true,
            },
        },
        data: () => ({
            enterTransition: '',
            className: null,
            leaveTransition: '',
        }),
        computed: {
            jurisdictionSelector: (vue) => (
                vue.className
                    ? vue.jurisdictionsTypes.find(({ value }) => vue.className === value).selector
                    : undefined
            ),
            jurisdictionsTypes: (vue) => ([
                {
                    iconLeft: 'landmark-alt',
                    iconRight: 'plus',
                    title: vue.$store.getters['center/companyName'],
                    selector: undefined,
                    value: Center.className,
                },
                {
                    iconLeft: 'university',
                    iconRight: 'arrow-right',
                    title: 'Établissements',
                    selector: 'jurisdiction-academy-selector',
                    value: Academy.className,
                },
                {
                    iconLeft: 'school',
                    iconRight: 'arrow-right',
                    title: 'Antennes',
                    selector: 'jurisdiction-school-selector',
                    value: School.className,
                },
                {
                    iconLeft: 'file-certificate',
                    iconRight: 'arrow-right',
                    title: 'Parcours',
                    selector: 'jurisdiction-education-study-selector',
                    value: EducationStudy.className,
                },
            ]),
            visibleJurisdictionsTypes: (vue) => vue.jurisdictionsTypes.filter(
                ({ value }) => vue.selectableJurisdictions.includes(value),
            ),
        },
        watch: {
            className(className) {
                if (className === Center.className) {
                    this.select(this.$store.getters['center/copy']);
                }
                this.enterTransition = className ? 'fadeInRight' : 'fadeInLeft';
                this.leaveTransition = className ? 'fadeOutLeft' : 'fadeOutRight';
            },
        },
        methods: {
            select(jurisdiction) {
                this.$bvModal.hide('jurisdiction-selector');
                this.$emit('change', jurisdiction);
            },
        },
    };

</script>
