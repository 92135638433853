<template>

    <b-form-input
        v-if="plaintext"
        v-model="plainValue"
        v-bind="$attrs"
        plaintext
    />

    <b-form-datepicker
        v-else
        v-model="localValue"
        v-bind="$attrs"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        :label-no-date-selected="placeholder"
        :reset-button="required !== true"
        label-calendar="Calendrier"
        label-close-button="Fermer"
        label-current-month="Mois courant"
        label-help=""
        label-nav=" Navigation dans le calendrier"
        label-next-decade="Décennie suivante"
        label-next-month="Mois suivant"
        label-next-year="Année suivante"
        label-prev-decade="Décennie précédente"
        label-prev-month="Mois précédent"
        label-prev-year="Année précédente"
        label-reset-button="Effacer"
        label-selected="Valider"
        label-today="Aujourd'hui"
        label-today-button="Sélectionner la date du jour"
        hide-header
    />

</template>


<script>

    import Dayjs from '@core/mixins/dayjs';

    export default {
        mixins: [Dayjs],
        props: {
            id: {
                type: String,
                default: undefined,
            },
            placeholder: {
                type: String,
                default: '',
            },
            plaintext: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            value: {
                type: String,
                default: '',
            },
        },
        computed: {
            localValue: {
                get: (vue) => vue.value,
                set(value) {
                    this.$emit('input', value);
                },
            },
            plainValue: (vue) => (vue.localValue ? vue.dayjsShortDate(vue.localValue) : ''),
        },
    };

</script>
