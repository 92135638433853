<template>

    <div>

        <form-group
            :id="`questionnaireField-${questionnaireField.$.id}-contentValue`"
            :label="questionnaireField.$.name"
            :required="questionnaireField.$.mandatory"
            :state="state"
            class="mb-0"
            label-class="pb-0"
        >

            <template
                v-if="questionnaireField.$.description"
                #top
            >
                <div class="small text-muted pb-2">
                    {{ questionnaireField.$.description }}.
                </div>
            </template>

            <template #default="inputProps">
                <b-form-input
                    v-bind="inputProps"
                    v-model="contentValue"
                    :disabled="disabled || contentAutomated || value.isPending"
                    class="text-right"
                    lazy
                    @blur="saveAndUnlock"
                    @focus="lock"
                />
            </template>

            <template #right>
                <b-input-group-append tag="label">
                    <b-input-group-text class="border-left-0">
                        / 20
                    </b-input-group-text>
                </b-input-group-append>
            </template>

        </form-group>

        <form-group
            :id="`questionnaireField-${questionnaireField.$.id}-contentAutomated`"
            :error="error"
            :state="state"
        >

            <template
                v-if="questionnaireLock.$.belongs_to_self !== true"
                #default="inputProps"
            >
                <b-form-checkbox
                    v-model="contentAutomated"
                    v-bind="inputProps"
                    :disabled="disabled || value.isPending"
                    @click="saveAndUnlock"
                >
                    Calculer automatiquement la moyenne des notes du formulaire.
                </b-form-checkbox>
            </template>

            <template #description>
                <div class="d-sm-flex flex-row-reverse">

                    <div class="text-right">
                        <button-submit
                            v-if="questionnaireLock.$.belongs_to_self && state === undefined"
                            :is-pending="value.isPending"
                            class="mt-1 ml-sm-2 text-nowrap"
                            icon="edit"
                            variant="success"
                            @click="saveAndUnlock"
                        >
                            Enregistrer
                        </button-submit>
                    </div>

                    <div class="mr-auto">
                        <slot name="description" />
                    </div>

                </div>
            </template>

        </form-group>

    </div>

</template>



<script>

    import QuestionnaireAnswer from '@core/models/questionnaireAnswer';
    import QuestionnaireField from '@core/models/questionnaireField';
    import QuestionnaireLock from '@core/models/questionnaireLock';

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            questionnaireField: {
                type: QuestionnaireField,
                required: true,
            },
            value: {
                type: QuestionnaireAnswer,
                required: true,
            },
        },
        data: () => ({
            content: { automated: true, value: null },
            promiseChain: Promise.resolve(true),
        }),
        computed: {
            contentAutomated: {
                get: (vue) => vue.content.automated,
                set(contentAutomated) {
                    this.content = { ...this.content, automated: contentAutomated };
                    this.$emit('input', new QuestionnaireAnswer({ ...this.value.$, content: this.content }));
                },
            },
            contentValue: {
                get: (vue) => vue.content.value,
                set(contentValue) {
                    this.content = { ...this.content, value: contentValue.toString().replace(',', '.') };
                },
            },
            error: (vue) => Object.values(vue.value.errors).reduce((errors, error) => [...errors, ...error], []),
            questionnaireLock: (vue) => vue.questionnaireField.$.questionnaireLock,
            state: (vue) => {
                if (vue.error.length > 0) {
                    return false;
                }
                if (Object.values(vue.value.states).some((state) => state === true)) {
                    return true;
                }
                return undefined;
            },
        },
        watch: {
            'value.$.content': {
                handler(content) {
                    this.content = content || this.content;
                },
                immediate: true,
            },
        },
        destroyed() {
            if (this.questionnaireLock.$.exists && this.questionnaireLock.$.deletable) {
                this.saveAndUnlock();
            }
        },
        methods: {
            lock() {
                const questionnaireLock = new QuestionnaireLock({ ...this.questionnaireLock.$ });
                delete questionnaireLock.$.id;
                this.promiseChain = this.promiseChain.then(() => questionnaireLock.request().create());
            },
            saveAndUnlock() {
                this.promiseChain = this.promiseChain.then((previousPromise) => {
                    if (previousPromise.url === undefined && this.questionnaireLock.$.exists !== true) {
                        return;
                    }
                    if (previousPromise.url || this.questionnaireLock.$.belongs_to_self) {
                        this.$emit('input', new QuestionnaireAnswer({ ...this.value.$, content: this.content }));
                    }
                    if (previousPromise.url || this.questionnaireLock.$.deletable) {
                        this.questionnaireLock.request().silent().delete().catch(() => {});
                    }
                });
            },
        },
    };

</script>
