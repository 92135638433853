<template>

    <button-delete
        v-if="businessPerson.$.deletable"
        :is-pending="businessPerson.isPending || parentFormIsPending"
        label="Supprimer le contact"
        @submit="submit"
    >

        <slot />

        <div slot="left">

            <b>
                Seront effacées :
            </b>

            <ul class="mb-0">
                <li>les informations personnelles.</li>
                <li>les informations professionnelles.</li>
                <li>les informations maître d'apprentissage.</li>
                <li>les mentions relatives à cette personne dans les conventions d'apprentissage.</li>
            </ul>

        </div>

        <div slot="right">

            <b>
                Ne seront pas effacés :
            </b>

            <ul class="mb-0">
                <li>
                    les contrats pour lesquels cette personne est le contact administratif de l'employeur.
                </li>
                <li>
                    les contrats pour lesquels cette personne est le référent comptable.
                </li>
                <li>
                    les contrats pour lesquels cette personne est le représentant de l'employeur.
                </li>
            </ul>

        </div>

    </button-delete>

    <div
        v-else
        class="d-flex justify-content-end"
    >
        <slot />
    </div>

</template>



<script>

    import BusinessPerson from '@core/models/businessPerson';

    export default {
        props: {
            businessPerson: {
                type: BusinessPerson,
                required: true,
            },
            parentFormIsPending: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            submit() {
                this.businessPerson.request().delete().then(this.submitSuccessful).catch(() => {});
            },
            submitSuccessful() {
                this.$root.toastSuccess(
                    'Le contact a été supprimé',
                    'Ses informations sont effacées.',
                );
                this.$emit('deleted');
            },
        },
    };

</script>
