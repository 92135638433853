import Request from '@core/request';

export default {

    namespaced: true,

    state: () => ({
        end: undefined,
        start: undefined,
    }),

    getters: {
        end: (state) => state.end,
        scheduled: (state) => state.start && state.end,
        start: (state) => state.start,
    },

    mutations: {
        end: (state, end) => { state.end = end; },
        start: (state, start) => { state.start = start; },
    },

    actions: {
        fetch: ({ commit }) => new Request().silent().axios.get('maintenance').then((response) => {
            commit('end', response.data.data.end);
            commit('start', response.data.data.start);
        }),
    },

};
