<template>

    <component
        :is="acceptUnknownSiret ? 'b-alert' : 'div'"
        variant="success"
        show
    >

        <form-group
            v-bind="$attrs"
            :error="formModifiedModel.errors.siret === undefined ? parentFormError : formModifiedModel.errors.siret"
            :pending="parentFormIsPending || formIsPending"
            :state="formModifiedModel.states.siret === undefined ? parentFormState : formModifiedModel.states.siret"
            left-icon="barcode"
            required
        >

            <template #default="inputProps">
                <b-form-input
                    v-model="formModifiedModel.$.siret"
                    v-bind="inputProps"
                    :plaintext="plaintext"
                    debounce="500"
                />
            </template>

            <template #description>

                <template v-if="formModifiedModel.$.businessCompany.$.id">
                    Il s'agit de l'employeur {{ formModifiedModel.$.businessCompany.$.name }}.
                </template>

                <b-form-checkbox
                    v-else-if="allowUnknownSiret && hasUnknownSiret"
                    v-model="acceptUnknownSiret"
                >
                    Forcer la création d'une entreprise pour ce SIRET invalide. Cette action ne peut être annulée.
                </b-form-checkbox>

                <b-button
                    v-else-if="hasUnknownSiret && centerEmail"
                    :href="`mailto:${centerEmail}`"
                    variant="info"
                    size="sm"
                >
                    <font-awesome-icon
                        :icon="['fad', 'paper-plane']"
                        fixed-width
                    />
                    Demander à {{ centerCompanyName }} l'enregistrement de cette entreprise
                </b-button>

                <slot
                    v-else-if="$scopedSlots.description"
                    name="description"
                />

            </template>

        </form-group>

        <form-group
            v-if="acceptUnknownSiret"
            :id="`${$attrs.id}-name`"
            v-slot="inputProps"
            :error="formModifiedModel.errors.name"
            label="Raison sociale"
            left-icon="text"
            required
        >
            <b-form-input
                v-bind="inputProps"
                v-model="formModifiedModel.$.name"
            />
        </form-group>

        <div class="text-right">
            <button-submit
                v-if="acceptUnknownSiret"
                :is-pending="parentFormIsPending || formIsPending"
                icon="plus"
                variant="success"
                @click="formSubmit"
            >
                Créer l'entreprise
            </button-submit>
        </div>

    </component>

</template>



<script>

    import BusinessBranch from '@core/models/businessBranch';
    import Form from '@core/mixins/form';

    export default {
        mixins: [Form],
        props: {
            parentFormError: {
                type: Array,
                default: undefined,
            },
            parentFormIsPending: {
                type: Boolean,
                default: false,
            },
            parentFormState: {
                type: Boolean,
                default: undefined,
            },
            plaintext: {
                type: Boolean,
                default: false,
            },
            value: {
                type: BusinessBranch,
                default: undefined,
            },
        },
        data: () => ({
            acceptUnknownSiret: false,
        }),
        computed: {
            allowUnknownSiret: (vue) => vue.$store.getters['auth/canCreate']('businessBranchUnknownSiret'),
            centerCompanyName: (vue) => vue.$store.getters['center/companyName'],
            centerEmail: (vue) => vue.$store.getters['center/email'],
            formInitialModel: (vue) => new BusinessBranch(vue.value ? { ...vue.value.$ } : {}),
            hasUnknownSiret: (vue) => vue.formModifiedModel.states.siret === false,
        },
        watch: {
            'formModifiedModel.$.siret': {
                handler(siret) {
                    if (siret === this.formInitialModel.$.siret) {
                        return;
                    }
                    if (siret.length < 14) {
                        this.$emit('input', new BusinessBranch({ siret }));
                        return;
                    }
                    this.formModifiedModel = new BusinessBranch({ siret: this.formModifiedModel.$.siret });
                    this.formSubmit();
                },
                immediate: true,
            },
        },
        methods: {
            formSubmitSuccessful(businessBranch) {
                this.$emit('input', businessBranch);
                this.acceptUnknownSiret = false;
            },
        },
    };

</script>
