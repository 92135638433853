import EducationStudy from '@core/models/educationStudy';
import RestModel from '@core/models/_rest';

export default class EducationDiscipline extends RestModel {

    static get methods() {
        return {
            digital: 'E-learning',
            lecture: 'Cours',
            practice: 'Travaux pratiques',
            project: 'Projets',
            flipped: 'Classe inversée',
        };
    }

    static get terms() {
        return {
            1: '1er semestre',
            2: '2nd semestre',
        };
    }

    static get types() {
        return {
            '': '',
            site: 'Présentiel',
            remotely: 'Distance',
            mixed: 'Hybride',
        };
    }

    get url() {
        return this.$.id ? `educationDisciplines/${this.$.id}` : 'educationDisciplines';
    }

    fill({
        educationStudy = {},
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            educationStudy: new EducationStudy(educationStudy.$ || educationStudy),
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            education_study_id: this.$.educationStudy.$.id,
        });
    }

}
