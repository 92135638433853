<template>

    <button-delete
        v-if="educationPerson.$.deletable"
        label="Supprimer le profil"
        :is-pending="educationPerson.isPending || parentFormIsPending"
        @submit="submit"
    >

        <slot />

        <div slot="left">

            <b>
                Seront effacées :
            </b>

            <ul class="mb-0">
                <li>les information personnelles.</li>
                <li>les information professionnelles.</li>
                <li>les références à cette personne dans les conventions d'apprentissage.</li>
            </ul>

        </div>

        <div slot="right">

            <b>
                Seront conservés :
            </b>

            <ul class="mb-0">
                <li>le compte utilisateur.</li>
                <li>les conventions d'apprentissage où cette personne apparaît.</li>
            </ul>

        </div>

    </button-delete>

    <div
        v-else
        class="d-flex justify-content-end"
    >
        <slot />
    </div>

</template>



<script>

    import EducationPerson from '@core/models/educationPerson';

    export default {
        props: {
            parentFormIsPending: {
                type: Boolean,
                default: false,
            },
            educationPerson: {
                type: EducationPerson,
                required: true,
            },
        },
        methods: {
            submit() {
                this.educationPerson.request().delete().then(this.submitSuccessful).catch(() => {});
            },
            submitSuccessful() {
                this.$root.toastSuccess(
                    'Le profil a été supprimé',
                    'Ses informations sont effacées.',
                );
                this.$router.push({ name: 'educationPerson-index' });
            },
        },
    };

</script>
