<template>

    <div class="d-flex justify-content-center px-5">

        <div
            v-for="{ boldText, icon, mutedText, title, variant }, index in statuses"
            :key="boldText"
            class="d-flex align-items-center"
            :class="{ 'w-100': index !== 0 }"
        >

            <hr
                v-if="index !== 0"
                class="w-100 mt-n3"
            >

            <div
                v-b-tooltip.noninteractive
                class="d-flex flex-column align-items-center px-2 text-center"
                :title="title"
                :class="`text-${variant}`"
            >

                <font-awesome-icon
                    v-if="questionnaireEntry.isPending !== true"
                    :icon="['fad', questionnaireEntry.isPending ? 'circle-notch' : icon]"
                    :spin="questionnaireEntry.isPending"
                    size="2x"
                    fixed-width
                />

                <b-skeleton
                    v-if="questionnaireEntry.isPending"
                    class="w-50 mx-auto"
                    variant="dark"
                />

                <div
                    v-else
                    class="font-weight-bold px-0 mt-2 mx-0"
                >
                    {{ boldText }}
                </div>

                <b-skeleton v-if="questionnaireEntry.isPending" />

                <div
                    v-else
                    class="text-muted"
                >
                    {{ mutedText }}
                </div>

            </div>

        </div>

    </div>

</template>



<script>

    import QuestionnaireEntry from '@core/models/questionnaireEntry';
    import Dayjs from '@core/mixins/dayjs';

    export default {
        mixins: [Dayjs],
        props: {
            questionnaireEntry: {
                type: QuestionnaireEntry,
                required: true,
            },
        },
        computed: {
            statuses: (vue) => [
                {
                    boldText: 'Ouverture',
                    icon: 'calendar',
                    mutedText: vue.dayjsShortDate(vue.questionnaireEntry.$.questionnaireSurvey.$.start),
                    title: vue.getTitle(true, vue.questionnaireEntry.isCompleted),
                    variant: vue.getVariant(
                        vue.questionnaireEntry.isPending !== true,
                        vue.questionnaireEntry.isCompleted,
                    ),
                },
                {
                    boldText: 'Réponses',
                    icon: 'edit',
                    mutedText: `${vue.questionnaireEntry.$.questionnaireValidation.$.answer_rate} %`,
                    title: vue.getTitle(vue.questionnaireEntry.isOpen, vue.questionnaireEntry.isCompleted),
                    variant: vue.getVariant(vue.questionnaireEntry.isOpen, vue.questionnaireEntry.isCompleted),
                },
                {
                    boldText: 'Finalisation',
                    icon: 'lock',
                    mutedText: vue.questionnaireEntry.$.questionnaireSurvey.roleTitleNames[
                        Object.keys(vue.questionnaireEntry.$.questionnaireSurvey.roleTitleNames).find(
                            (role) => vue.questionnaireEntry.$.questionnaireSurvey.$[`access_${role}`]
                                === 'write_validation',
                        )
                    ],
                    title: vue.getTitle(vue.questionnaireEntry.isCompleted, vue.questionnaireEntry.isLocked),
                    variant: vue.getVariant(vue.questionnaireEntry.isCompleted, vue.questionnaireEntry.isLocked),
                },
                {
                    boldText: 'Validations',
                    icon: 'check',
                    mutedText: `${vue.questionnaireEntry.$.questionnaireValidation.$.validation_rate} %`,
                    title: vue.getTitle(vue.questionnaireEntry.isLocked, vue.questionnaireEntry.isValidated),
                    variant: vue.getVariant(vue.questionnaireEntry.isLocked, vue.questionnaireEntry.isValidated),
                },
            ],
        },
        methods: {
            getTitle: (previousStep, currentStep) => {
                if (currentStep) {
                    return 'Terminé';
                }
                if (previousStep) {
                    return 'En cours';
                }
                return 'Non disponible';
            },
            getVariant: (previousStep, currentStep) => {
                if (currentStep) {
                    return 'success';
                }
                if (previousStep) {
                    return 'info';
                }
                return 'dark';
            },
        },
    };

</script>
