<template>

    <b-skeleton
        v-if="learnerPerson.isPending"
        type="input"
        class="mb-3"
    />

    <b-alert
        v-else-if="missingInformation"
        :variant="variant"
        fade
        show
    >

        <font-awesome-icon
            v-if="title"
            :icon="['fad', icon]"
            fixed-width
        />

        <b v-if="title">
            {{ title }}
        </b>

        <ul
            v-if="missingInformation.length"
            class="mb-0"
        >
            <li
                v-for="({ name, route }, index) in missingInformation"
                :key="index"
            >

                <router-link
                    v-if="$route.name !== route.name"
                    :to="route"
                >
                    {{ name }}
                </router-link>

                <template
                    v-else
                >
                    {{ name }}
                </template>

            </li>
        </ul>

    </b-alert>

</template>



<script>

    import LearnerPerson from '@core/models/learnerPerson';

    export default {
        props: {
            learnerPerson: {
                type: LearnerPerson,
                required: true,
            },
            routeNamePrefix: {
                type: String,
                default: '',
            },
            successTitle: {
                type: String,
                default: '',
            },
            warningTitle: {
                type: String,
                default: '',
            },
        },
        computed: {
            fieldData: (vue) => ({
                highest_business_apprentice_diploma_id: {
                    name: 'le plus haut diplôme obtenu',
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                last_business_apprentice_class_id: {
                    name: 'la dernière classe suivie',
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                last_business_apprentice_diploma_id: {
                    name: 'le dernier diplôme préparé',
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                last_business_apprentice_situation_id: {
                    name: 'la dernière situation',
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                previous_year_education_sifa_situation_id: {
                    name: "la situation avant l'inscription",
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                birth_place: {
                    name: 'le lieu de naissance',
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                birth_department: {
                    name: 'le département de naissance',
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                gender: {
                    name: "le genre déclaré à l'état civil",
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                nationality_type: {
                    name: 'la nationalité',
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                nationality_other: {
                    name: 'la nationalité',
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                marital_statuses: {
                    name: ' la situation familiale',
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                nirpp: {
                    name: 'le numéro de Sécurité Sociale',
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                representative_firstname: {
                    name: 'le prénom du représentant légal',
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                representative_lastname: {
                    name: 'le nom du représentant légal',
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                representative_email: {
                    name: "l'adresse e-mail du représentant légal",
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                last_prepared_diploma_name: {
                    name: 'le nom du dernier diplôme préparé',
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-edit`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                'primaryAddress.all': {
                    name: "l'adresse personnelle",
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-primaryAddress`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                'primaryAddress.street_name': {
                    name: "la voie de l'adresse personnelle",
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-primaryAddress`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                'primaryAddress.phone': {
                    name: "un numéro de téléphone de l'adresse personnelle",
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-primaryAddress`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                'primaryAddress.post_code': {
                    name: "le code postal de l'adresse personnelle",
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-primaryAddress`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                'primaryAddress.insee_code': {
                    name: "la commune de l'adresse personnelle",
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-primaryAddress`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                'primaryAddress.city': {
                    name: "la commune de l'adresse personnelle",
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-primaryAddress`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                'representativeAddress.all': {
                    name: "l'adresse du responsable légal",
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-representativeAddress`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                'representativeAddress.post_code': {
                    name: "le code postal de l'adresse du responsable légal",
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-representativeAddress`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                'representativeAddress.insee_code': {
                    name: "la commune de l'adresse du responsable légal",
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-representativeAddress`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
                'representativeAddress.city': {
                    name: "la commune de l'adresse du responsable légal",
                    route: {
                        name: `${vue.routeNamePrefix}learnerPerson-representativeAddress`,
                        params: { learnerPersonId: vue.learnerPerson.$.id },
                    },
                },
            }),
            icon: (vue) => (vue.missingInformation.length ? 'exclamation' : 'check'),
            missingInformation: (vue) => (
                vue.learnerPerson.$.missing_information
                    ? vue.learnerPerson.$.missing_information.map((field) => vue.fieldData[field])
                    : undefined
            ),
            title: (vue) => (vue.missingInformation.length ? vue.warningTitle : vue.successTitle),
            variant: (vue) => (vue.missingInformation.length ? 'warning' : 'success'),
        },
    };

</script>
