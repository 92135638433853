import Center from '@core/models/center';

export default {

    namespaced: true,

    state: () => ({
        center: undefined,
    }),

    getters: {
        businessApprenticeCertificateTemplate: (state) => (
            state.center ? state.center.$.businessApprenticeCertificateTemplate : null
        ),
        businessApprenticeCommercialAgreementTemplate: (state) => (
            state.center ? state.center.$.businessApprenticeCommercialAgreementTemplate : null
        ),
        companyLogoLg: (state) => (state.center ? state.center.$.companyLogoLg : null),
        companyLogoMd: (state) => (state.center ? state.center.$.companyLogoMd : null),
        companyLogoSm: (state) => (state.center ? state.center.$.companyLogoSm : null),
        companyName: (state) => (state.center ? state.center.$.company_name : undefined),
        documentationUrl: (state) => (state.center ? state.center.$.documentation_url : undefined),
        copy: (state) => (state.center ? new Center({ ...state.center.$ }) : new Center()),
        email: (state) => (state.center && state.center.$.web ? state.center.$.web.$.email : null),
        id: (state) => (state.center ? state.center.$.id : undefined),
        learnerBylaw: (state) => (state.center ? state.center.$.learnerBylaw : null),
        office: (state) => (state.center ? state.center.$.office : null),
        platformLogoLg: (state) => (state.center ? state.center.$.platformLogoLg : null),
        platformLogoMd: (state) => (state.center ? state.center.$.platformLogoMd : null),
        platformLogoSm: (state) => (state.center ? state.center.$.platformLogoSm : null),
        platformName: (state) => (state.center ? state.center.$.platform_name : undefined),
        siret: (state) => (state.center ? state.center.$.siret : undefined),
        uai: (state) => (state.center ? state.center.$.uai : undefined),
        updatable: (state) => Boolean(state.center ? state.center.$.updatable : undefined),
        web: (state) => (state.center ? state.center.$.web : null),
    },

    mutations: {
        center: (state, center) => { state.center = center; },
    },

    actions: {
        set: ({ commit }, center) => commit('center', center),
    },

};
