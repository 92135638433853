<template>

    <b-overlay :show="questionnaireElements.isPending">

        <b-alert
            v-if="readOnlySurvey"
            class="text-center"
            variant="info"
            show
        >
            <font-awesome-icon
                :icon="['fad', 'info']"
                fixed-width
            />
            Vous n'êtes pas autorisé(e) à consulter le contenu de ce formulaire.
        </b-alert>

        <b-alert
            v-else-if="questionnaireElements.isEmpty"
            class="text-center"
            variant="warning"
            show
        >
            <font-awesome-icon
                :icon="['fad', 'empty-set']"
                fixed-width
            />
            Ce formulaire ne possède pas encore de contenu.
        </b-alert>

        <b-card
            v-for="(questionnaireElementsInSection, index) in sections"
            :key="questionnaireElementsInSection[0] ? questionnaireElementsInSection[0].id : null"
            bg-variant="light"
            class="mb-4"
            no-body
        >

            <b-card-header
                v-if="questionnaireElementsInSection[0]"
                v-b-toggle="'collapse-' + index"
                header-bg-variant="primary"
                header-text-variant="light"
                class="pb-2 d-flex"
            >

                <questionnaire-element-show
                    :allow-edit="allowEdit"
                    :cloning="cloningQuestionnaireElementId === questionnaireElementsInSection[0].$.id"
                    :editing="editingQuestionnaireElementId === questionnaireElementsInSection[0].$.id"
                    :questionnaire-element="questionnaireElementsInSection[0]"
                    :questionnaire-survey="questionnaireSurvey"
                    @update:cloning="
                        $emit(
                            'update:cloningQuestionnaireElementId',
                            $event ? questionnaireElementsInSection[0].$.id : null
                        )
                    "
                    @update:editing="
                        $emit(
                            'update:editingQuestionnaireElementId',
                            $event ? questionnaireElementsInSection[0].$.id : null
                        )
                    "
                />

                <font-awesome-icon
                    class="float-right d-print-none"
                    :icon="['fad', 'caret-down']"
                    fixed-width
                />

                <font-awesome-icon
                    class="float-right d-print-none"
                    :icon="['fad', 'caret-up']"
                    fixed-width
                />

            </b-card-header>

            <b-collapse
                :id="'collapse-' + index"
                visible
            >
                <b-card-body>
                    <questionnaire-element-show
                        v-for="questionnaireElement in questionnaireElementsInSection.slice(1)"
                        :key="questionnaireElement.$.id"
                        :allow-answer="allowAnswer"
                        :allow-edit="allowEdit"
                        :cloning="cloningQuestionnaireElementId === questionnaireElement.$.id"
                        :editing="editingQuestionnaireElementId === questionnaireElement.$.id"
                        :questionnaire-element="questionnaireElement"
                        :questionnaire-entry="questionnaireEntry"
                        :questionnaire-survey="questionnaireSurvey"
                        @update:cloning="
                            $emit(
                                'update:cloningQuestionnaireElementId',
                                $event ? questionnaireElement.$.id : null
                            )
                        "
                        @update:editing="
                            $emit(
                                'update:editingQuestionnaireElementId',
                                $event ? questionnaireElement.$.id : null
                            )
                        "
                    />
                </b-card-body>
            </b-collapse>

        </b-card>

    </b-overlay>

</template>



<script>

    import Collection from '@core/models/collection';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';
    import QuestionnaireSurvey from '@core/models/questionnaireSurvey';

    export default {
        props: {
            allowAnswer: {
                type: Boolean,
                default: false,
            },
            allowEdit: {
                type: Boolean,
                default: false,
            },
            cloningQuestionnaireElementId: {
                type: Number,
                default: null,
            },
            editingQuestionnaireElementId: {
                type: Number,
                default: null,
            },
            questionnaireEntry: {
                type: QuestionnaireEntry,
                default: null,
            },
            questionnaireElements: {
                type: Collection,
                required: true,
            },
            questionnaireSurvey: {
                type: QuestionnaireSurvey,
                required: true,
            },
        },
        computed: {
            readOnlySurvey: (vue) => vue.questionnaireEntry && (
                (
                    vue.questionnaireEntry.$.user_role === 'learner_person'
                    && ['none', 'read_survey'].includes(vue.questionnaireSurvey.$.access_learner_person)
                )
                || (
                    vue.questionnaireEntry.$.user_role === 'education_mentor'
                    && ['none', 'read_survey'].includes(vue.questionnaireSurvey.$.access_education_mentor)
                )
                || (
                    vue.questionnaireEntry.$.user_role === 'business_mentor'
                    && ['none', 'read_survey'].includes(vue.questionnaireSurvey.$.access_business_mentor)
                )
                || (
                    vue.questionnaireEntry.$.user_role === 'education_study'
                    && ['none', 'read_survey'].includes(vue.questionnaireSurvey.$.access_education_study)
                )
            ),
            sections: (vue) => vue.questionnaireElements.models.reduce((sections, questionnaireElement) => {
                if (questionnaireElement.$.type === 'title') {
                    sections.push([]);
                } else if (sections.length === 0) {
                    sections.push([null]);
                }
                sections[sections.length - 1].push(questionnaireElement);
                return sections;
            }, []),
        },
    };

</script>

<style scoped>

    .not-collapsed > .fa-caret-down,
    .collapsed > .fa-caret-up {
        display: none;
    }

</style>
