<template>

    <div :id="`questionnaireElement-${questionnaireElement.$.id}`">
        <component
            :is="components[questionnaireField.$.type]"
            v-model="formModifiedModel"
            :disabled="disabled || questionnaireLock.$.belongs_to_other"
            :questionnaire-field="questionnaireField"
            @input="formSubmit"
        >
            <template slot="description">

                <div
                    v-if="formIsPending"
                    class="mt-1"
                >
                    <b-badge
                        class="text-wrap text-left"
                        variant="success"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'circle-notch']"
                            fixed-width
                            flip
                            spin
                        />
                        Enregistrement...
                    </b-badge>
                </div>

                <div
                    v-else-if="questionnaireLock.$.belongs_to_self && state === undefined"
                    class="mt-1"
                >
                    <b-alert
                        class="mb-0"
                        variant="info"
                        show
                    >
                        <font-awesome-icon
                            :icon="['fad', 'info']"
                            fixed-width
                        />
                        Ce champ vous est reservé le temps de votre saisie.
                        Quittez le champ ou cliquez sur "Enregistrer" pour enregistrer votre réponse.
                    </b-alert>
                </div>

                <div
                    v-else-if="questionnaireLock.$.belongs_to_other"
                    class="mt-1"
                >
                    <b-badge
                        class="text-wrap text-left"
                        variant="warning"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'lock']"
                            fixed-width
                        />
                        En cours d'édition
                        par {{ questionnaireLock.$.owner_fullname }}
                        depuis {{ questionnaireLockAge }}.
                    </b-badge>
                </div>

                <div
                    v-else-if="formInitialModel.$.updated_at"
                    class="mt-1"
                >
                    <b-badge
                        class="text-wrap text-left"
                        variant="light"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'history']"
                            fixed-width
                        />
                        Enregistré
                        <template v-if="formInitialModel.$.user_fullname">
                            par {{ formInitialModel.$.user_fullname }}
                        </template>
                        le {{ dayjsLongDate(formInitialModel.$.updated_at) }}.
                    </b-badge>
                </div>

            </template>
        </component>
    </div>

</template>



<script>

    import Dayjs from '@core/mixins/dayjs';
    import Form from '@core/mixins/form';
    import QuestionnaireAnswer from '@core/models/questionnaireAnswer';
    import QuestionnaireElement from '@core/models/questionnaireElement';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';

    export default {
        mixins: [Dayjs, Form],
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            questionnaireElement: {
                type: QuestionnaireElement,
                required: true,
            },
            questionnaireEntry: {
                type: QuestionnaireEntry,
                default: null,
            },
        },
        data: () => ({
            questionnaireLockAge: '',
            questionnaireLockAgeIntervalId: undefined,
        }),
        computed: {
            components: () => ({
                text: 'questionnaire-field-show-text',
                unique_choice: 'questionnaire-field-show-choices',
                multiple_choices: 'questionnaire-field-show-choices',
                upload: 'questionnaire-field-show-upload',
                grade: 'questionnaire-field-show-grade',
                average: 'questionnaire-field-show-average',
            }),
            error: (vue) => Object.values(vue.formModifiedModel.errors)
                .reduce((errors, error) => [...errors, ...error], []),
            formInitialModel: (vue) => (
                vue.questionnaireAnswerIndex !== -1
                    ? vue.questionnaireAnswers[vue.questionnaireAnswerIndex]
                    : new QuestionnaireAnswer({ questionnaire_field_id: vue.questionnaireField.$.id, role: 'all' })
            ),
            questionnaireAnswers: (vue) => (
                vue.questionnaireEntry ? vue.questionnaireEntry.$.questionnaireAnswers : []
            ),
            questionnaireAnswerIndex: (vue) => vue.questionnaireAnswers.findIndex(
                (questionnaireAnswer) => questionnaireAnswer.$.role === 'all'
                    && questionnaireAnswer.$.questionnaire_field_id === vue.questionnaireField.$.id,
            ),
            questionnaireField: (vue) => vue.questionnaireElement.$.questionnaireFields[0],
            questionnaireLock: (vue) => vue.questionnaireField.$.questionnaireLock,
            state: (vue) => {
                if (vue.error.length > 0) {
                    return false;
                }
                if (Object.values(vue.formModifiedModel.states).some((state) => state === true)) {
                    return true;
                }
                return undefined;
            },
        },
        created() {
            this.questionnaireLockAgeIntervalId = setInterval(() => {
                if (this.questionnaireLock.$.created_at) {
                    this.questionnaireLockAge = this.dayjsFrom(this.questionnaireLock.$.created_at, undefined, true);
                }
            }, 1000);
        },
        destroyed() {
            clearInterval(this.questionnaireLockAgeIntervalId);
        },
        methods: {
            formSubmit() {
                this.formModifiedModel.$.questionnaire_entry_id = this.questionnaireEntry.$.id;
                return this.formModifiedModel.request().create().then(this.formSubmitSuccessful).catch(() => {});
            },
            formSubmitSuccessful() {
                this.formModifiedModel.errors = { fake: [] };
                setTimeout(() => { this.formModifiedModel.errors = {}; }, 3000);
            },
        },
    };

</script>



<style scoped>

    .badge {
        font-size: 100%;
    }

</style>
