<script>

    import Collection from '@core/models/collection';
    import QuestionnaireElement from '@core/models/questionnaireElement';

    export default {
        props: {
            initialValue: {
                type: Number,
                default: null,
            },
            plaintext: {
                type: Boolean,
                default: false,
            },
            requestData: {
                type: Object,
                default: () => ({}),
            },
            value: {
                type: Number,
                default: null,
            },
        },
        data: () => ({
            questionnaireElements: new Collection(new QuestionnaireElement(), { position: 'asc' }, 1000),
        }),
        computed: {
            questionnaireElement: (vue) => vue.questionnaireElements.models.find(
                (questionnaireElement) => vue.convertPositionToValue(questionnaireElement.$.position)
                    === vue.localValue,
            ),
            localValue: {
                get: (vue) => vue.value,
                set(value) {
                    this.$emit('input', value);
                    this.$emit(
                        'change',
                        this.questionnaireElements.models.find(
                            (questionnaireElement) => this.convertPositionToValue(questionnaireElement.$.position)
                                === value,
                        ),
                    );
                },
            },
            types: () => ({
                title: 'titre',
                paragraph: 'texte',
                upload: 'fichier',
                link: 'lien',
                single_field: 'champ',
                multiple_fields: 'tableau',
            }),
        },
        watch: {
            requestData: {
                handler(newValue, oldValue) {
                    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                        this.request();
                    }
                },
                immediate: true,
            },
        },
        methods: {
            convertPositionToValue(position) {
                if (position > this.initialValue) {
                    return position;
                }
                return position + 1;
            },
            request() {
                this.questionnaireElements
                    .request()
                    .index(this.requestData)
                    .then((questionnaireElements) => {
                        questionnaireElements.models = questionnaireElements.models.filter(
                            (questionnaireElement) => questionnaireElement.$.position !== this.initialValue,
                        );
                    });
            },
        },
        render() {
            return this.$scopedSlots.default({
                questionnaireElement: this.questionnaireElement,
                questionnaireElements: this.questionnaireElements,
                dropdownItemEvents: (position) => ({
                    click: () => { this.localValue = position; },
                }),
                selectEvents: {
                    input: (position) => {
                        this.localValue = position;
                    },
                },
                selectProps: {
                    options: [
                        {
                            text: 'Au début',
                            value: 1,
                        },
                        ...this.questionnaireElements.models.map(
                            (questionnaireElement) => ({
                                text: `Après le ${this.types[questionnaireElement.$.type]} `
                                    + `${
                                        (
                                            questionnaireElement.$.attributes.text
                                            || questionnaireElement.$.questionnaireFields[0].$.name
                                        ).substring(0, 30)
                                    }`,
                                value: this.convertPositionToValue(questionnaireElement.$.position),
                            }),
                        ),
                    ],
                    value: this.localValue,
                },
            });
        },
    };

</script>
