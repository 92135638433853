<template>

    <form-group
        v-bind="$attrs"
        :left-icon="updatable && (editing || missing) ? 'barcode' : undefined"
        label="Numéro de Sécurité Sociale avec clé de sécurité"
        business-apprentice-agreement
    >

        <template #default="inputProps">
            <b-form-input
                v-model="nirpp"
                v-bind="inputProps"
                :placeholder="updatable ? '123456789012345' : undefined"
                :plaintext="!(updatable && (editing || missing))"
                pattern="[0-9AB]{15}"
                title="15 chiffres ou lettres A ou B"
            />
        </template>

        <template
            v-if="missing === false"
            #description
        >

            Cette information, cryptée, ne peut pas être affichée.

            <b-button
                v-if="editing"
                size="sm"
                variant="outline-secondary"
                @click="editing = false"
            >
                <font-awesome-icon
                    :icon="['fad', 'times']"
                    fixed-width
                />
                Annuler cette modification
            </b-button>

            <b-button
                v-else-if="updatable"
                size="sm"
                variant="outline-primary"
                @click="editing = true"
            >
                <font-awesome-icon
                    :icon="['fad', 'edit']"
                    fixed-width
                />
                Mettre à jour cette information
            </b-button>

        </template>

    </form-group>

</template>



<script>

    export default {
        inheritAttrs: false,
        props: {
            missing: {
                type: Boolean,
                required: true,
            },
            updatable: {
                type: Boolean,
                default: false,
            },
            updating: {
                type: Boolean,
                default: false,
            },
            value: {
                type: String,
                default: '',
            },
        },
        data: () => ({
            editing: false,
        }),
        computed: {
            nirpp: {
                get: (vue) => (vue.editing || vue.missing ? vue.value : '•••••••••••••••'),
                set(nirpp) {
                    this.$emit('input', nirpp);
                },
            },
        },
        watch: {
            editing(editing) {
                if (editing) {
                    this.nirpp = '';
                } else {
                    this.nirpp = undefined;
                }
            },
            updating(updating) {
                if (updating) {
                    this.editing = false;
                }
            },
        },
    };

</script>
