<template>

    <b-alert
        v-if="missingInformation"
        :variant="variant"
        fade
        show
    >

        <font-awesome-icon
            v-if="title"
            :icon="['fad', icon]"
            fixed-width
        />

        <b v-if="title">
            {{ title }}
        </b>

        <ul
            v-if="missingInformation.length"
            class="mb-0"
        >
            <li
                v-for="({ name, route }, index) in missingInformation"
                :key="index"
            >

                <router-link
                    v-if="$route.name !== route.name"
                    :to="route"
                >
                    {{ name }}
                </router-link>

                <template
                    v-else
                >
                    {{ name }}
                </template>

            </li>
        </ul>

    </b-alert>

</template>



<script>

    import BusinessPerson from '@core/models/businessPerson';

    export default {
        props: {
            businessPerson: {
                type: BusinessPerson,
                required: true,
            },
            businessPersonEditRoute: {
                type: Object,
                required: true,
            },
            successTitle: {
                type: String,
                default: '',
            },
            warningTitle: {
                type: String,
                default: '',
            },
        },
        computed: {
            fieldData: (vue) => ({
                job_title: {
                    name: "l'intitulé de poste",
                    route: vue.businessPersonEditRoute,
                },
                phone: {
                    name: 'le numéro de téléphone',
                    route: vue.businessPersonEditRoute,
                },
                email: {
                    name: "l'adresse e-mail",
                    route: vue.businessPersonEditRoute,
                },
                birth_date: {
                    name: 'la date de naissance',
                    route: vue.businessPersonEditRoute,
                },
            }),
            icon: (vue) => (vue.missingInformation.length ? 'exclamation' : 'check'),
            missingInformation: (vue) => (
                vue.businessPerson.$.missing_information
                    ? vue.businessPerson.$.missing_information.map((field) => vue.fieldData[field])
                    : undefined
            ),
            title: (vue) => (vue.missingInformation.length ? vue.warningTitle : vue.successTitle),
            variant: (vue) => (vue.missingInformation.length ? 'warning' : 'success'),
        },
    };

</script>
