<template>

    <b-alert
        v-if="missingInformation"
        :variant="variant"
        fade
        show
    >

        <font-awesome-icon
            v-if="title"
            :icon="['fad', icon]"
            fixed-width
        />

        <b v-if="title">
            {{ title }}
        </b>

        <b-button
            v-if="missingInformation.length && showByDefault !== true"
            size="sm"
            variant="warning"
            :pressed.sync="showByUser"
        >
            <font-awesome-icon
                v-if="title"
                :icon="['fad', showByUser ? 'caret-up' : 'caret-down']"
                fixed-width
            />
            Voir les {{ missingInformation.length }} éléments
        </b-button>

        <ul
            v-if="missingInformation.length && (showByDefault || showByUser)"
            class="mb-0"
        >
            <li
                v-for="({ name, route }, index) in missingInformation"
                :key="index"
            >

                <router-link
                    v-if="$route.name !== route.name"
                    :to="route"
                >
                    {{ name }}
                </router-link>

                <template
                    v-else
                >
                    {{ name }}
                </template>

            </li>
        </ul>

    </b-alert>

</template>



<script>

    import BusinessApprenticeContract from '@core/models/businessApprenticeContract';

    export default {
        props: {
            businessApprenticeContract: {
                type: BusinessApprenticeContract,
                required: true,
            },
            businessApprenticeContractEditRoute: {
                type: Object,
                required: true,
            },
            businessApprenticeContractLocationRoute: {
                type: Object,
                required: true,
            },
            businessApprenticeContractInvoiceAddressRoute: {
                type: Object,
                required: true,
            },
            businessApprenticeContractPurposeRoute: {
                type: Object,
                required: true,
            },
            signatoryBusinessPersonEditRoute: {
                type: Object,
                required: true,
            },
            routeNamePrefix: {
                type: String,
                default: '',
            },
            successTitle: {
                type: String,
                default: '',
            },
            warningTitle: {
                type: String,
                default: '',
            },
        },
        data: () => ({
            showByUser: false,
        }),
        computed: {
            fieldData: (vue) => ({
                business_apprentice_branch_type_id: {
                    name: "l'employeur spécifique",
                    route: vue.businessApprenticeContractEditRoute,
                },
                business_apprentice_duration_id: {
                    name: 'le mode contractuel',
                    route: vue.businessApprenticeContractEditRoute,
                },
                business_apprentice_reason_id: {
                    name: "le type de contrat ou d'avenant",
                    route: vue.businessApprenticeContractEditRoute,
                },
                business_idcc_id: {
                    name: "le code IDCC de l'activité du contrat",
                    route: vue.businessApprenticeContractEditRoute,
                },
                highest_business_apprentice_diploma_id: {
                    name: "le plus haut diplôme obtenu par l'apprenant",
                    route: vue.businessApprenticeContractEditRoute,
                },
                last_business_apprentice_class_id: {
                    name: "la dernière classe suivie par l'apprenant",
                    route: vue.businessApprenticeContractEditRoute,
                },
                last_business_apprentice_diploma_id: {
                    name: "le dernier diplôme préparé par l'apprenant",
                    route: vue.businessApprenticeContractEditRoute,
                },
                last_business_apprentice_situation_id: {
                    name: "la dernière situation de l'apprenant",
                    route: vue.businessApprenticeContractEditRoute,
                },
                signatory_business_contact_id: {
                    name: "le contact administratif de l'employeur",
                    route: vue.businessApprenticeContractEditRoute,
                },
                signatory_business_invoiced_id: {
                    name: 'le référent comptable',
                    route: vue.businessApprenticeContractEditRoute,
                },
                signatory_business_purchaser_id: {
                    name: "le donneur d'ordre du bon de commande",
                    route: vue.businessApprenticeContractEditRoute,
                },
                signatory_business_remaining_balance_granter_id: {
                    name: 'la personne validant le reste à charge',
                    route: vue.businessApprenticeContractEditRoute,
                },
                signatory_business_representative_id: {
                    name: "le représentant de l'employeur",
                    route: vue.businessApprenticeContractEditRoute,
                },
                job_title: {
                    name: "l'intitulé du poste",
                    route: vue.businessApprenticeContractEditRoute,
                },
                pension_fund: {
                    name: 'la caisse complémentaire',
                    route: vue.businessApprenticeContractEditRoute,
                },
                social_security_scheme: {
                    name: "le régime social de l'apprenant",
                    route: vue.businessApprenticeContractEditRoute,
                },
                weekly_working_minutes: {
                    name: 'la durée hebdomadaire de travail',
                    route: vue.businessApprenticeContractEditRoute,
                },
                gross_salary: {
                    name: "le salaire brut mensuel à l'embauche versé à l'apprenti.e",
                    route: vue.businessApprenticeContractEditRoute,
                },
                invoice_email: {
                    name: "l'adresse e-mail de facturation",
                    route: vue.businessApprenticeContractEditRoute,
                },
                invoice_method: {
                    name: 'la modalité de transmission de la facture',
                    route: vue.businessApprenticeContractEditRoute,
                },
                invoice_platform_name: {
                    name: 'le nom de la plateforme de facturation',
                    route: vue.businessApprenticeContractEditRoute,
                },
                invoice_platform_url: {
                    name: "l'adresse de la plateforme de facturation",
                    route: vue.businessApprenticeContractEditRoute,
                },
                invoice_description_agreement: {
                    name: "l'informations complémentaires sur la facturation pour la convention",
                    route: vue.businessApprenticeContractEditRoute,
                },
                last_prepared_diploma_name: {
                    name: "le nom du dernier diplôme préparé par l'apprenant",
                    route: vue.businessApprenticeContractEditRoute,
                },
                amending_at: {
                    name: "la date d'effet de l'avenant",
                    route: vue.businessApprenticeContractEditRoute,
                },
                'invoiceAddress.all': {
                    name: "l'adresse de facturation",
                    route: vue.businessApprenticeContractInvoiceAddressRoute,
                },
                'invoiceAddress.post_code': {
                    name: "le code postal de l'adresse de facturation",
                    route: vue.businessApprenticeContractInvoiceAddressRoute,
                },
                'invoiceAddress.insee_code': {
                    name: "la commune de l'adresse de facturation",
                    route: vue.businessApprenticeContractInvoiceAddressRoute,
                },
                'invoiceAddress.city': {
                    name: "la commune de l'adresse de facturation",
                    route: vue.businessApprenticeContractInvoiceAddressRoute,
                },
                mobilityAppendix: {
                    name: "l'annexe de mobilité internationale",
                    route: vue.businessApprenticeContractEditRoute,
                },
                'location.all': {
                    name: "le lieu d'exécution",
                    route: vue.businessApprenticeContractLocationRoute,
                },
                'location.post_code': {
                    name: "le code postal du lieu d'exécution",
                    route: vue.businessApprenticeContractLocationRoute,
                },
                'location.insee_code': {
                    name: "la commune du lieu d'exécution",
                    route: vue.businessApprenticeContractLocationRoute,
                },
                'location.city': {
                    name: "la commune du lieu d'exécution",
                    route: vue.businessApprenticeContractLocationRoute,
                },
                locked_questionnaire_validation_purpose: {
                    name: 'la validation des missions (à finaliser)',
                    route: vue.businessApprenticeContractPurposeRoute,
                },
                validated_questionnaire_validation_purpose: {
                    name: 'la validation des missions (à valider)',
                    route: vue.businessApprenticeContractPurposeRoute,
                },
                ...Object.fromEntries(
                    Object
                        .entries({
                            signatoryBusinessContact: 'du contact administratif',
                            signatoryBusinessInvoiced: 'du référent comptable',
                            signatoryBusinessPurchaser: "du donneur d'ordre du bon de commande",
                            signatoryBusinessRemainingBalanceGranter: 'du contact validant le reste à charge',
                            signatoryBusinessRepresentative: 'du représentant',
                        })
                        .filter(([businessPersonKey]) => vue.businessApprenticeContract.$[businessPersonKey])
                        .map(([businessPersonKey, businessPersonValue]) => Object
                            .entries({
                                birth_date: 'la date de naissance',
                                job_title: "l'intitulé de poste",
                                phone: 'le téléphone',
                                email: "l'adresse e-mail",
                            })
                            .map(([fieldKey, fieldValue]) => [`${businessPersonKey}.${fieldKey}`, {
                                name: `${fieldValue} ${businessPersonValue} de l'employeur`,
                                route: {
                                    name: vue.signatoryBusinessPersonEditRoute.name,
                                    params: {
                                        ...vue.signatoryBusinessPersonEditRoute.params,
                                        businessPersonId: vue.businessApprenticeContract.$[businessPersonKey].$.id,
                                    },
                                },
                            }]))
                        .flat(),
                ),
                ...Object.fromEntries(
                    Array.from(
                        { length: 8 },
                        (value, index) => [
                            `salary.${index + 1}.all`,
                            {
                                name: `le salaire de la ${index + 1}${index ? 'ème' : 'ère'} période`,
                                route: vue.businessApprenticeContractEditRoute,
                            },
                        ],
                    ),
                ),
                ...Object.fromEntries(
                    Array.from(
                        { length: 8 },
                        (value, index) => [
                            `salary.${index + 1}.salary_percentage`,
                            {
                                name: `la part du salaire de la ${index + 1}${index ? 'ème' : 'ère'} période`,
                                route: vue.businessApprenticeContractEditRoute,
                            },
                        ],
                    ),
                ),
                ...Object.fromEntries(
                    Array.from(
                        { length: 8 },
                        (value, index) => [
                            `salary.${index + 1}.salary_reference`,
                            {
                                name: `la référence du salaire de la ${index + 1}${index ? 'ème' : 'ère'} période`,
                                route: vue.businessApprenticeContractEditRoute,
                            },
                        ],
                    ),
                ),
            }),
            icon: (vue) => (vue.missingInformation.length ? 'exclamation' : 'check'),
            missingInformation: (vue) => (
                vue.businessApprenticeContract.$.missing_information
                    ? vue.businessApprenticeContract
                        .$.missing_information
                        .map((field) => vue.fieldData[field] || { name: field, route: { name: vue.$route.name } })
                    : undefined
            ),
            showByDefault: (vue) => vue.missingInformation.length > 0 && vue.missingInformation.length <= 5,
            title: (vue) => (vue.missingInformation.length ? vue.warningTitle : vue.successTitle),
            variant: (vue) => (vue.missingInformation.length ? 'warning' : 'success'),
        },
    };

</script>
