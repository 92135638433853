import BusinessCpne from '@core/models/businessCpne';
import BusinessOpco from '@core/models/businessOpco';
import RestModel from '@core/models/_rest';

export default class BusinessIdcc extends RestModel {

    get url() {
        return this.$.id ? `businessIdccs/${this.$.id}` : 'businessIdccs';
    }

    fill({
        businessCpne = {},
        businessOpco = {},
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            businessCpne: businessCpne ? new BusinessCpne(businessCpne.$ || businessCpne) : new BusinessCpne(),
            businessOpco: businessOpco ? new BusinessOpco(businessOpco.$ || businessOpco) : new BusinessOpco(),
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            business_cpne_id: this.$.businessCpne.$.id,
            business_opco_id: this.$.businessOpco.$.id,
        });
    }

}
