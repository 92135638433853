<template>

    <b-progress
        :animated="value < max"
        :height="height"
        :max="max"
        :value="value"
        :variant="variant"
        striped
    />

</template>



<script>

    import LearnerEducationStudy from '@core/models/learnerEducationStudy';
    import LearnerPerson from '@core/models/learnerPerson';

    export default {
        props: {
            height: {
                type: String,
                default: undefined,
            },
            learnerEducationStudy: {
                type: LearnerEducationStudy,
                required: true,
            },
            learnerPerson: {
                type: LearnerPerson,
                required: true,
            },
        },
        computed: {
            max: () => 3,
            missingInformationRatio: (vue) => (15 - vue.learnerPerson.$.missing_information.length) / 15,
            value: (vue) => ({
                created: 1 + vue.missingInformationRatio,
                completed: 2,
                accepted: 3,
                rejected: 3,
                canceled: 3,
            }[vue.learnerEducationStudy.$.status]),
            variant: (vue) => LearnerEducationStudy.statusVariants[vue.learnerEducationStudy.$.status],
        },
    };

</script>
