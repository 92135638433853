import Dayjs from 'dayjs';
import IsBetween from 'dayjs/plugin/isBetween';
import Locale from 'dayjs/locale/fr';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import QuarterOfYear from 'dayjs/plugin/quarterOfYear';
import RelativeTime from 'dayjs/plugin/relativeTime';

Dayjs.locale(Locale);
Dayjs.extend(IsBetween);
Dayjs.extend(LocalizedFormat);
Dayjs.extend(QuarterOfYear);
Dayjs.extend(RelativeTime);

export default {

    methods: {
        dayjsAcademicYear: (input) => (Dayjs(input).month() < 6 ? Dayjs(input).year() - 1 : Dayjs(input).year()),
        dayjsDayBetween: (input, min, max) => Dayjs(input).isBetween(min, max, 'day', '[]'),
        dayjsDifference: (from, to, unit) => Dayjs(from).diff(to, unit),
        dayjsFrom: (input, compared, withoutSuffix) => Dayjs(input).from(compared, withoutSuffix),
        dayjsIsAfter: (input, compared, unit) => Dayjs(input).isAfter(compared, unit),
        dayjsLongDate: (input) => Dayjs(input).format('dddd D MMMM YYYY'),
        dayjsLongDatetime: (input) => Dayjs(input).format('LLLL'),
        dayjsMake: (input) => Dayjs(input),
        dayjsQuarter: (input) => Dayjs(input).startOf('quarter'),
        dayjsShortDate: (input) => Dayjs(input).format('L'),
        dayjsShortDateTime: (input) => Dayjs(input).format('L LT'),
    },

};
