<template>

    <b-navbar-nav id="navbar-user">

        <b-nav-item-dropdown
            :class="`d-${toggleable}-block`"
            toggle-class="py-2"
            class="d-none"
            no-caret
            right
        >

            <upload-avatar
                slot="button-content"
                :icon="icon"
                :upload="avatar"
                height="23px"
                icon-size-classes="h-100 w-100"
                rounded
            />

            <b-dropdown-text text-class="text-center">

                <upload-avatar
                    :icon="icon"
                    :upload="avatar"
                    class="mb-2"
                    rounded
                />

                <div class="font-weight-bold">
                    {{ fullname }}
                </div>

            </b-dropdown-text>

            <b-dropdown-divider v-if="centerClientUrl || mentorClientUrl || schoolClientUrl" />

            <b-dropdown-item
                v-if="centerClientUrl"
                :href="centerClientUrl"
                target="_blank"
            >
                <font-awesome-icon
                    :icon="['fad', 'landmark-alt']"
                    fixed-width
                />
                Espace CFA
            </b-dropdown-item>

            <b-dropdown-item
                v-if="schoolClientUrl"
                :href="schoolClientUrl"
                target="_blank"
            >
                <font-awesome-icon
                    :icon="['fad', 'school']"
                    fixed-width
                />
                Espace école
            </b-dropdown-item>

            <b-dropdown-item
                v-if="mentorClientUrl"
                :href="mentorClientUrl"
                target="_blank"
            >
                <font-awesome-icon
                    :icon="['fad', 'user-hard-hat']"
                    fixed-width
                />
                Espace tuteur
            </b-dropdown-item>

            <b-dropdown-divider />

            <b-dropdown-item
                v-if="showEditUserLink"
                :to="{ name: 'user-edit', params: { userId } }"
            >
                <font-awesome-icon
                    :icon="['fad', 'id-card']"
                    fixed-width
                />
                Mon profil
            </b-dropdown-item>

            <b-dropdown-item-button @click="formSubmit">
                <font-awesome-icon
                    :icon="['fad', 'house-leave']"
                    fixed-width
                />
                Déconnexion
            </b-dropdown-item-button>

        </b-nav-item-dropdown>

        <b-dropdown-divider v-if="centerClientUrl || mentorClientUrl || schoolClientUrl" />

        <b-nav-item
            v-if="centerClientUrl"
            :class="`d-${toggleable}-none`"
            :href="centerClientUrl"
            target="_blank"
        >
            <font-awesome-icon
                :icon="['fad', 'landmark-alt']"
                fixed-width
            />
            Espace CFA
        </b-nav-item>

        <b-nav-item
            v-if="schoolClientUrl"
            :class="`d-${toggleable}-none`"
            :href="schoolClientUrl"
            target="_blank"
        >
            <font-awesome-icon
                :icon="['fad', 'school']"
                fixed-width
            />
            Espace école
        </b-nav-item>

        <b-nav-item
            v-if="mentorClientUrl"
            :class="`d-${toggleable}-none`"
            :href="mentorClientUrl"
            target="_blank"
        >
            <font-awesome-icon
                :icon="['fad', 'user-hard-hat']"
                fixed-width
            />
            Espace tuteur
        </b-nav-item>

        <b-dropdown-divider />

        <b-nav-item
            v-if="showEditUserLink"
            :class="`d-${toggleable}-none`"
            :to="{ name: 'user-edit', params: { userId } }"
        >
            <font-awesome-icon
                :icon="['fad', 'id-card']"
                fixed-width
            />
            Mon profil
        </b-nav-item>

        <b-nav-item
            :class="`d-${toggleable}-none`"
            @click="formSubmit"
        >
            <font-awesome-icon
                :icon="['fad', 'house-leave']"
                fixed-width
            />
            Déconnexion
        </b-nav-item>

    </b-navbar-nav>

</template>



<script>

    import Request from '@core/request';

    export default {
        props: {
            toggleable: {
                type: String,
                required: true,
            },
        },
        computed: {
            avatar: (vue) => vue.$store.getters['auth/avatar'],
            centerClientUrl: (vue) => (
                vue.$store.getters['auth/canAccessCenterClient'] ? import.meta.env.VITE_APP_URL_CENTER : undefined
            ),
            fullname: (vue) => vue.$store.getters['auth/fullname'],
            icon: (vue) => (vue.$store.getters['auth/learnerPerson'] ? 'user-graduate' : 'user-tie'),
            mentorClientUrl: (vue) => (
                vue.$store.getters['auth/canAccessMentorClient'] ? import.meta.env.VITE_APP_URL_MENTOR : undefined
            ),
            schoolClientUrl: (vue) => (
                vue.$store.getters['auth/canAccessSchoolClient'] ? import.meta.env.VITE_APP_URL_SCHOOL : undefined
            ),
            showEditUserLink: (vue) => vue
                .$router
                .resolve({ name: 'user-edit', params: { userId: vue.userId } })
                .resolved
                .matched
                .length > 0,
            userId: (vue) => vue.$store.getters['auth/id'],
        },
        methods: {
            formSubmit() {
                new Request().axios.post('/auth/logout').then(this.formSubmitSuccessful);
            },
            formSubmitSuccessful() {
                this.$root.toastSuccess(
                    `À bientôt ${this.$store.getters['auth/fullname']}`,
                    'Vous êtes maintenant déconnecté(e) de votre espace.',
                );
                this.$store.dispatch('auth/unset');
                this.$router.push({ name: 'login-form' });
            },
        },
    };

</script>
