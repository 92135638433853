<template>

    <div class="d-flex w-100 page-break-avoid">

        <b-button
            v-if="allowEdit && questionnaireElement.$.updatable"
            v-b-tooltip.noninteractive.left
            :class="{ 'mb-3': questionnaireElement.$.type !== 'title' }"
            :pressed.sync="localEditing"
            class="mr-3"
            title="Modifier l'élément"
            variant="outline-success"
        >
            <font-awesome-icon
                :icon="['fad', 'edit']"
                fixed-width
            />
        </b-button>

        <b-button
            v-if="allowEdit && allowClone"
            v-b-tooltip.noninteractive.right
            :pressed.sync="localCloning"
            class="mb-3 mr-3"
            title="Dupliquer l'élément"
            variant="outline-info"
            @click="$emit('update:cloning', questionnaireElement)"
        >
            <font-awesome-icon
                :icon="['fad', 'clone']"
                fixed-width
            />
        </b-button>

        <component
            :is="components[questionnaireElement.$.type]"
            v-if="components[questionnaireElement.$.type]"
            :disabled="disabled"
            :questionnaire-element="questionnaireElement"
            :questionnaire-entry="questionnaireEntry"
            :questionnaire-survey="questionnaireSurvey"
            class="w-100 align-self-center"
        />

        <form-group
            v-else
            :id="`questionnaireField-${questionnaireElement.$.id}`"
            class="w-100"
            pending
        />

    </div>

</template>



<script>

    import Dayjs from '@core/mixins/dayjs';
    import QuestionnaireElement from '@core/models/questionnaireElement';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';
    import QuestionnaireSurvey from '@core/models/questionnaireSurvey';

    export default {
        mixins: [Dayjs],
        props: {
            allowAnswer: {
                type: Boolean,
                default: false,
            },
            allowEdit: {
                type: Boolean,
                default: false,
            },
            cloning: {
                type: Boolean,
                default: false,
            },
            editing: {
                type: Boolean,
                default: false,
            },
            questionnaireEntry: {
                type: QuestionnaireEntry,
                default: null,
            },
            questionnaireElement: {
                type: QuestionnaireElement,
                required: true,
            },
            questionnaireSurvey: {
                type: QuestionnaireSurvey,
                required: true,
            },
        },
        computed: {
            allowClone: (vue) => vue.questionnaireSurvey.$.creatableQuestionnaireElements
                && vue.questionnaireElement.$.type === 'multiple_fields',
            components: () => ({
                title: 'questionnaire-element-show-title',
                paragraph: 'questionnaire-element-show-paragraph',
                upload: 'questionnaire-element-show-upload',
                link: 'questionnaire-element-show-link',
                single_field: 'questionnaire-element-show-single-field',
                multiple_fields: 'questionnaire-element-show-multiple-fields',
            }),
            disabled: (vue) => vue.allowAnswer !== true
                || vue.questionnaireEntry.$.creatableQuestionnaireAnswers !== true,
            localCloning: {
                get: (vue) => vue.cloning,
                set(localCloning) {
                    this.$emit('update:cloning', localCloning);
                },
            },
            localEditing: {
                get: (vue) => vue.editing,
                set(localEditing) {
                    this.$emit('update:editing', localEditing);
                },
            },
        },
    };

</script>
