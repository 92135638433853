<template>

    <div>

        <h2 class="mb-3">
            Validation du formulaire
        </h2>

        <b-list-group
            v-if="formInitialModel.$.validation_rate < 100"
            class="mb-4"
            flush
        >
            <b-list-group-item
                v-for="({ success, message }, index) in checks"
                :key="index"
                :class="{
                    'bg-success': success,
                    'bg-warning': !success,
                    'rounded-top': index === 0,
                    'rounded-bottom': index === checks.length - 1,
                }"
                class="my-0 px-3 py-2 text-white"
            >
                <font-awesome-icon
                    :icon="['fad', success ? 'check' : 'exclamation']"
                    class="mr-1"
                    fixed-width
                />
                {{ message }}
            </b-list-group-item>
        </b-list-group>

        <b-alert
            v-if="formInitialModel.$.validation_rate === 100"
            class="mb-4"
            variant="success"
            show
        >
            <font-awesome-icon
                :icon="['fad', 'check']"
                fixed-width
            />
            Le fomulaire a été validé par {{
                [
                    questionnaireEntry.$.questionnaireSurvey.writeValidationRole,
                    ...questionnaireEntry.$.questionnaireSurvey.writeAnswersRoles,
                ]
                    .map((role) => questionnaireEntry.$.questionnaireSurvey.rolePhraseNames[role])
                    .join(' et ')
            }}.
        </b-alert>

        <div
            v-else-if="checks.some(({ success }) => success !== true)"
            class="text-center"
        >
            <b-button
                v-if="
                    formInitialModel.$.validation_rate === 0
                        || formInitialModel.$.validation_rate === 100
                        || formInitialModel.$.updatable
                "
                size="lg"
                variant="secondary"
                disabled
            >
                <font-awesome-icon
                    :icon="['fad', 'times']"
                    fixed-width
                />
                Validation impossible pour le moment
            </b-button>
        </div>

        <div
            v-else-if="formInitialModel.$.updatable !== true"
            class="text-center"
        >
            <b-button
                size="lg"
                variant="secondary"
                disabled
            >
                <font-awesome-icon
                    :icon="['fad', 'times']"
                    fixed-width
                />
                <template v-if="formInitialModel.$.validation_rate === 0">
                    En attente de finalisation par
                    {{
                        questionnaireEntry.$.questionnaireSurvey.rolePhraseNames[
                            questionnaireEntry.$.questionnaireSurvey.writeValidationRole
                        ]
                    }}
                </template>
                <template v-else>
                    En attente de validation par
                    {{
                        questionnaireEntry
                            .remainingWriteAnswersRoles
                            .map((role) => questionnaireEntry.$.questionnaireSurvey.rolePhraseNames[role])
                            .join(' et ')
                    }}
                </template>
            </b-button>
        </div>

        <b-form
            v-else
            class="text-center"
            @submit.prevent="formSubmit"
        >

            <b-alert
                ref="alert"
                variant="success"
                show
            >

                <p v-if="formInitialModel.$.validation_rate === 0">
                    En finalisant le formulaire, plus aucun participant ne pourra modifier les réponses ci-dessus.<br>
                    Les autres participants dont la validation est nécessaire seront informés par e-mail.
                </p>

                <p>
                    En validant le formulaire, je reconnais avoir pris connaissance des réponses ci-dessus.
                </p>


                <button-submit
                    :is-pending="formIsPending"
                    :icon="formInitialModel.$.validation_rate === 0 ? 'lock' : 'check'"
                    class="mx-1"
                    variant="light"
                >
                    {{
                        formInitialModel.$.validation_rate === 0
                            ? "Je finalise et demande la validation aux autres participants s'ils sont concernés"
                            : 'Je valide les réponses'
                    }}
                </button-submit>


            </b-alert>

            <p class="mt-2 font-weight-bold">
                Après validation de ce formulaire, vous pourrez joindre un commentaire.
            </p>

        </b-form>

    </div>

</template>



<script>

    import Form from '@core/mixins/form';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';
    import QuestionnaireValidation from '@core/models/questionnaireValidation';

    export default {
        mixins: [Form],
        props: {
            questionnaireEntry: {
                type: QuestionnaireEntry,
                required: true,
            },
            questionnaireFields: {
                type: Array,
                required: true,
            },
            value: {
                type: QuestionnaireValidation,
                required: true,
            },
        },
        computed: {
            checks: (vue) => [
                {
                    success: vue.questionnaireEntry.isOpen,
                    message: vue.questionnaireEntry.isOpen
                        ? 'Le formulaire est ouvert.'
                        : `${vue.questionnaireEntry.$.questionnaireSurvey.countdownMessage}.`,
                },
                {
                    success: vue.formInitialModel.$.answer_rate === 100,
                    message: vue.formInitialModel.$.answer_rate === 100
                        ? 'Tous les champs obligatoires ont été répondus.'
                        : "Des champs obligatoires n'ont pas été répondus.",
                },
                {
                    success: vue.questionnaireLocks.length === 0,
                    message: vue.questionnaireLocks.length === 0
                        ? "Aucun champ en cours d'édition."
                        : `${vue.questionnaireLocks.length} champ${vue.questionnaireLocks.length > 1 ? 's' : ''} `
                            + "en cours d'édition.",
                },
                vue.formInitialModel.$.updatable ? undefined : {
                    success: vue.formInitialModel.$.validation_rate > 0,
                    message: vue.formInitialModel.$.validation_rate > 0
                        ? `Le formulaire a été finalisé par ${
                            vue.questionnaireEntry.$.questionnaireSurvey.rolePhraseNames[
                                vue.questionnaireEntry.$.questionnaireSurvey.writeValidationRole
                            ]
                        }.`
                        : `Le formulaire doit être finalisé par ${
                            vue.questionnaireEntry.$.questionnaireSurvey.rolePhraseNames[
                                vue.questionnaireEntry.$.questionnaireSurvey.writeValidationRole
                            ]
                        }.`,
                },
                vue.formInitialModel.$.validation_rate === 0
                    || vue.formInitialModel.$.validation_rate === 100
                    || vue.formInitialModel.$.updatable
                    ? undefined : {
                        success: false,
                        message: `Le formulaire doit encore être validé par ${
                            vue.questionnaireEntry.remainingWriteAnswersRoles
                                .map((role) => vue.questionnaireEntry.$.questionnaireSurvey.rolePhraseNames[role])
                                .join(' et ')
                        }.`,
                    },
            ].filter((check) => check),
            formInitialModel: {
                get: (vue) => new QuestionnaireValidation({ ...vue.value.$ }),
                set(value) {
                    this.$emit('input', value);
                },
            },
            questionnaireLocks: (vue) => vue.questionnaireFields
                .map((questionnaireField) => questionnaireField.$.questionnaireLock)
                .filter((questionnaireLock) => questionnaireLock.$.exists),
        },
        methods: {
            formSubmitSuccessful(questionnaireValidation) {
                this.$root.toastSuccess(
                    this.formInitialModel.$.validation_rate === 0
                        ? 'Le formulaire a été finalisé et validé'
                        : 'Le formulaire a été validé',
                    "L'enregistrement des informations a réussi.",
                );
                this.formInitialModel = new QuestionnaireValidation(questionnaireValidation.$);
                this.$nextTick(() => {
                    document
                        .getElementById('view-questionnaireEntry-show')
                        .scrollIntoView({ behavior: 'smooth', block: 'start' });
                });
            },
        },
    };

</script>
