<template>

    <b-card
        class="text-muted"
        header="Autorisations"
        header-class="text-center"
        header-tag="span"
        no-body
    >
        <b-list-group flush>
            <b-list-group-item
                v-for="(rule, title) in rules[className]"
                :key="title"
                :class="{ [`text-${modeVariants[rule[area][mode]]}`]: outline }"
                :variant="outline ? undefined : modeVariants[rule[area][mode]]"
            >

                <span
                    v-b-tooltip.noninteractive
                    :title="modeNames[rule[area][mode]]"
                >
                    <font-awesome-icon
                        :icon="['fad', modeIcons[rule[area][mode]]]"
                        fixed-width
                    />
                </span>

                {{ title }}

            </b-list-group-item>
        </b-list-group>
    </b-card>

</template>


<script>

    import Academy from '@core/models/academy';
    import Capability from '@core/models/capability';
    import Center from '@core/models/center';
    import EducationStudy from '@core/models/educationStudy';
    import School from '@core/models/school';

    export default {
        props: {
            area: {
                type: String,
                required: true,
            },
            className: {
                type: String,
                required: true,
            },
            mode: {
                type: String,
                required: true,
            },
            outline: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            modeIcons: () => Capability.modeIcons,
            modeNames: () => Capability.modes,
            modeVariants: () => Capability.modeVariants,
            rules: () => ({
                [Academy.className]: {
                    Personnels: {
                        accounting: { read: 'read', write: 'read' },
                        business: { read: 'read', write: 'read' },
                        management: { read: 'read', write: 'write' },
                        pedagogy: { read: 'read', write: 'read' },
                    },
                    'Formations & Parcours': {
                        accounting: { read: 'read', write: 'read' },
                        business: { read: 'read', write: 'read' },
                        management: { read: 'read', write: 'read' },
                        pedagogy: { read: 'read', write: 'read' },
                    },
                    'Classes & Modules': {
                        accounting: { read: 'read', write: 'read' },
                        business: { read: 'read', write: 'read' },
                        management: { read: 'read', write: 'read' },
                        pedagogy: { read: 'read', write: 'write' },
                    },
                },
                [Center.className]: {
                    CFA: {
                        accounting: { read: 'read', write: 'read' },
                        business: { read: 'read', write: 'read' },
                        management: { read: 'read', write: 'write' },
                        pedagogy: { read: 'read', write: 'read' },
                    },
                    Écoles: {
                        accounting: { read: 'read', write: 'read' },
                        business: { read: 'read', write: 'read' },
                        management: { read: 'read', write: 'write' },
                        pedagogy: { read: 'read', write: 'read' },
                    },
                    Utilisateurs: {
                        accounting: { read: 'read', write: 'read' },
                        business: { read: 'read', write: 'read' },
                        management: { read: 'read', write: 'write' },
                        pedagogy: { read: 'read', write: 'read' },
                    },
                    'Formations & Parcours': {
                        accounting: { read: 'read', write: 'read' },
                        business: { read: 'read', write: 'read' },
                        management: { read: 'read', write: 'read' },
                        pedagogy: { read: 'read', write: 'write' },
                    },
                    'Classes & Modules': {
                        accounting: { read: 'read', write: 'read' },
                        business: { read: 'read', write: 'read' },
                        management: { read: 'read', write: 'read' },
                        pedagogy: { read: 'read', write: 'write' },
                    },
                },
                [EducationStudy.className]: {
                    Personnels: {
                        accounting: { read: 'read', write: 'read' },
                        business: { read: 'read', write: 'read' },
                        management: { read: 'read', write: 'write' },
                        pedagogy: { read: 'read', write: 'read' },
                    },
                    'Formations & Parcours': {
                        accounting: { read: 'read', write: 'read' },
                        business: { read: 'read', write: 'read' },
                        management: { read: 'read', write: 'read' },
                        pedagogy: { read: 'read', write: 'read' },
                    },
                    'Classes & Modules': {
                        accounting: { read: 'read', write: 'read' },
                        business: { read: 'read', write: 'read' },
                        management: { read: 'read', write: 'read' },
                        pedagogy: { read: 'read', write: 'write' },
                    },
                },
                [School.className]: {
                    Personnels: {
                        accounting: { read: 'read', write: 'read' },
                        business: { read: 'read', write: 'read' },
                        management: { read: 'read', write: 'write' },
                        pedagogy: { read: 'read', write: 'read' },
                    },
                    'Formations & Parcours': {
                        accounting: { read: 'read', write: 'read' },
                        business: { read: 'read', write: 'read' },
                        management: { read: 'read', write: 'read' },
                        pedagogy: { read: 'read', write: 'read' },
                    },
                    'Classes & Modules': {
                        accounting: { read: 'read', write: 'read' },
                        business: { read: 'read', write: 'read' },
                        management: { read: 'read', write: 'read' },
                        pedagogy: { read: 'read', write: 'write' },
                    },
                },
            }),
        },
    };

</script>
