<template>

    <b-navbar
        v-if="printMode !== true"
        v-bind="$attrs"
        :toggleable="toggleable"
    >

        <b-navbar-brand
            v-if="$scopedSlots.brand"
            to="/"
        >
            <slot name="brand" />
        </b-navbar-brand>

        <slot name="mobile" />

        <b-navbar-toggle target="view-navbar-top-collapse" />

        <b-collapse
            id="view-navbar-top-collapse"
            class="justify-content-between"
            is-nav
        >

            <b-navbar-nav>
                <slot name="left" />
            </b-navbar-nav>

            <b-navbar-nav class="ml-auto">
                <b-dropdown-divider />
            </b-navbar-nav>

            <b-navbar-nav v-if="$scopedSlots.right">
                <slot name="right" />
            </b-navbar-nav>

            <navbar-help :toggleable="toggleable" />

            <navbar-maintenance :toggleable="toggleable" />

            <navbar-user :toggleable="toggleable" />

        </b-collapse>

    </b-navbar>

</template>



<script>

    export default {
        props: {
            toggleable: {
                type: String,
                required: true,
            },
        },
    };

</script>



<style scoped>

    .navbar-brand {
        padding-top: 0px;
        padding-bottom: 0px;
    }

</style>
