import QuestionnaireField from '@core/models/questionnaireField';
import RestModel from '@core/models/_rest';
import Upload from '@core/models/upload';

const formDataOptions = (options) => {
    if (Array.isArray(options)) {
        return options.filter((option) => option !== '');
    }
    if (options) {
        return Object.entries(options)
            .filter(([value, text]) => text !== '' && value !== '')
            .reduce((object, [value, text]) => Object.assign(object, { [value]: text }), {});
    }
    return undefined;
};

export default class QuestionnaireElement extends RestModel {

    get url() {
        return this.$.id ? `questionnaireElements/${this.$.id}` : 'questionnaireElements';
    }

    static get variants() {
        return [
            { icon: undefined, text: 'Sans', variant: 'none' },
            { icon: 'info', text: 'Information', variant: 'info' },
            { icon: 'check', text: 'Succès', variant: 'success' },
            { icon: 'exclamation', text: 'Alerte', variant: 'warning' },
            { icon: 'times', text: 'Danger', variant: 'danger' },
        ];
    }

    fill({
        questionnaireFields = [],
        typeUpload = undefined,
        attributes = {},
        ...otherAttributes
    }) {
        return super.fill({
            ...otherAttributes,
            attributes: JSON.parse(JSON.stringify(attributes)),
            questionnaireFields: questionnaireFields.map(
                (questionnaireField) => new QuestionnaireField(questionnaireField.$ || questionnaireField),
            ),
            typeUpload: typeUpload ? new Upload(typeUpload.$ || typeUpload) : undefined,
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            attributes: {
                ...this.$.attributes,
                questionnaire_field_attributes: {
                    ...this.$.attributes.questionnaire_field_attributes,
                    options: this.$.attributes.questionnaire_field_attributes
                        ? formDataOptions(this.$.attributes.questionnaire_field_attributes.options)
                        : undefined,
                },
                options: formDataOptions(this.$.attributes.options),
            },
            questionnaireFields: this.$.questionnaireFields
                .filter((questionnaireField) => questionnaireField.$.name !== '')
                .map((questionnaireField) => questionnaireField.formData()),
        });
    }

}
