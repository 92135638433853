<template>

    <div
        class="d-flex flex-shrink-0 align-items-center justify-content-center mx-auto text-center"
        :style="{ height, 'max-height': '100%', 'max-width': '100%', width }"
    >

        <b-img
            v-if="src"
            :rounded="rounded"
            :src="src"
            :style="{ 'max-height': height, 'max-width': '100%', width }"
            fluid
            @error="failed = true"
        />

        <font-awesome-icon
            v-else
            :class="iconSizeClasses"
            :icon="['fad', icon]"
            class="text-muted"
        />

    </div>

</template>



<script>

    import Upload from '@core/models/upload';

    export default {
        props: {
            height: {
                type: String,
                default: undefined,
            },
            icon: {
                type: String,
                required: true,
            },
            iconSizeClasses: {
                type: String,
                default: 'w-75 h-75',
            },
            rounded: {
                type: [Boolean, String],
                default: false,
            },
            upload: {
                type: Upload,
                default: null,
            },
            width: {
                type: String,
                default: undefined,
            },
        },
        data: () => ({
            failed: false,
        }),
        computed: {
            src: (vue) => (vue.upload && vue.failed !== true ? vue.upload.$.url : undefined),
        },
    };

</script>
