<template>

    <div>

        <b-alert
            :show="educationMentors.length === 0"
            class="my-3"
            variant="warning"
        >
            <font-awesome-icon
                :icon="['fad', 'exclamation']"
                fixed-width
            />
            Aucun tuteur pédagogique n'a été affecté à {{ questionnaireEntry.learnerPerson.$.fullname }}
            au {{ dayjsShortDate(questionnaireEntry.$.questionnaireSurvey.$.end) }}
            ou le responsable de formation est son tuteur pédagogique.
        </b-alert>

        <div
            v-for="educationPerson in educationMentors"
            :key="educationPerson.$.id"
            class="py-3"
        >

            <div class="d-flex align-items-center mb-2">

                <b-skeleton-img
                    v-if="questionnaireEntry.isPending"
                    height="50px"
                    width="50px"
                    no-aspect
                />

                <upload-avatar
                    v-else
                    :upload="educationPerson.$.user.$.avatar"
                    icon="user-tie"
                    width="50px"
                />

                <h3 class="flex-fill mb-0 ml-2">

                    <b-skeleton
                        v-if="questionnaireEntry.isPending"
                        class="w-75"
                        variant="dark"
                    />

                    <div v-else>
                        {{ educationPerson.$.fullname }}
                    </div>

                    <small class="d-block text-muted">
                        Tuteur pédagogique
                    </small>

                </h3>

                <button-vcard
                    :email="educationPerson.$.email"
                    :firstname="educationPerson.$.firstname"
                    :is-pending="questionnaireEntry.isPending"
                    :lastname="educationPerson.$.lastname"
                    :organization="educationPerson.$.school.$.name"
                    :phones="{
                        CELL: educationPerson.$.mobile_phone,
                        MAIN: educationPerson.$.direct_phone,
                        WORK: educationPerson.$.reception_phone,
                    }"
                    title="Tuteur pédagogique"
                />

            </div>

            <div v-if="questionnaireEntry.isPending">
                <b-skeleton class="w-75" />
                <b-skeleton class="w-50" />
                <b-skeleton class="w-50" />
                <b-skeleton class="w-50" />
            </div>

            <div v-else>

                <div class="mb-2">
                    <p class="my-0 font-weight-bold">
                        {{ educationPerson.$.school.$.name }}
                    </p>
                </div>

                <div v-if="educationPerson.$.email">

                    <font-awesome-icon
                        :icon="['fad', 'envelope']"
                        fixed-width
                    />

                    <a
                        :href="`mailto:${educationPerson.$.email}`"
                        class="font-weight-bold"
                    >
                        {{ educationPerson.$.email }}
                    </a>
                </div>


                <div v-if="educationPerson.$.direct_phone">

                    <font-awesome-icon
                        :icon="['fad', 'phone']"
                        fixed-width
                    />

                    <a :href="`tel:${educationPerson.$.direct_phone}`">
                        {{ educationPerson.$.direct_phone }}
                    </a>

                </div>

                <div v-if="educationPerson.$.mobile_phone">

                    <font-awesome-icon
                        :icon="['fad', 'mobile']"
                        fixed-width
                    />

                    <a :href="`tel:${educationPerson.$.mobile_phone}`">
                        {{ educationPerson.$.mobile_phone }}
                    </a>
                </div>

                <div v-if="educationPerson.$.reception_phone">
                    <font-awesome-icon
                        :icon="['fad', 'phone-office']"
                        fixed-width
                    />

                    <a :href="`tel:${educationPerson.$.reception_phone}`">
                        {{ educationPerson.$.reception_phone }}
                    </a>

                </div>
            </div>

        </div>

    </div>

</template>



<script>

    import EducationPerson from '@core/models/educationPerson';
    import Dayjs from '@core/mixins/dayjs';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';

    export default {
        mixins: [Dayjs],
        props: {
            questionnaireEntry: {
                type: QuestionnaireEntry,
                required: true,
            },
        },
        computed: {
            educationMentors: (vue) => (
                vue.questionnaireEntry.isPending
                    ? [new EducationPerson()]
                    : vue.questionnaireEntry.$.educationMentors
            ),
        },
    };

</script>
