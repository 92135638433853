<template>

    <b-card
        bg-variant="light"
        class="mb-3"
        tag="form"
        no-body
        @submit.prevent="formSubmit"
    >

        <b-card-body>

            <form-group
                id="questionnaireComment-create-content"
                v-slot="inputProps"
                :error="formModifiedModel.errors.content"
                :state="formModifiedModel.states.content"
                required
            >
                <b-textarea
                    v-bind="inputProps"
                    v-model="formModifiedModel.$.content"
                    max-rows="100"
                    placeholder="Nouveau commentaire"
                    rows="3"
                />
            </form-group>
        </b-card-body>

        <b-card-footer class="d-flex flex-wrap align-items-center">

            <form-group
                id="questionnaireComment-create-recipients"
                :error="formModifiedModel.errors.recipients"
                :state="formModifiedModel.states.recipients"
            >

                <template #default="inputProps">
                    <b-form-checkbox-group
                        v-model="formModifiedModel.$.recipients"
                        v-bind="inputProps"
                        :options="recipientsOptions"
                    />
                </template>

                <template #description>
                    Le commentaire sera visible par <b>{{ recipientsLabels.join(' et ') }}</b>
                    indépendamment des choix de notification.<br>
                    Le commentaire sera également visible par l'encadrement pédagogique de
                    <b>{{ questionnaireEntry.$.questionnaireSurvey.$.jurisdiction.$.jurisdiction_name }}</b>.
                </template>

            </form-group>

            <button-submit
                :is-pending="formIsPending"
                class="ml-auto"
                icon="plus"
                variant="success"
            >
                Enregistrer le commentaire
            </button-submit>

        </b-card-footer>

    </b-card>

</template>



<script>

    import Dayjs from '@core/mixins/dayjs';
    import Form from '@core/mixins/form';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';
    import QuestionnaireComment from '@core/models/questionnaireComment';

    export default {
        mixins: [Dayjs, Form],
        props: {
            questionnaireEntry: {
                type: QuestionnaireEntry,
                required: true,
            },
        },
        data: (vue) => ({
            formInitialModel: vue.makeQuestionnaireComment(),
        }),
        computed: {
            recipientsOptions: (vue) => Object
                .entries(vue.questionnaireEntry.$.questionnaireSurvey.questionnaireCommentRecipients)
                .map(([value, text]) => ({ text: `Notifier ${text}`, value })),
            recipientsLabels: (vue) => Object
                .values(vue.questionnaireEntry.$.questionnaireSurvey.questionnaireCommentRecipients),
        },
        methods: {
            formSubmitSuccessful() {
                this.formInitialModel = this.makeQuestionnaireComment();
                this.$root.toastSuccess(
                    'Le commentaire a été enregistré',
                    'Les participants concernés seront informés par e-mail.',
                );
            },
            makeQuestionnaireComment() {
                return new QuestionnaireComment({
                    questionnaire_entry_id: this.questionnaireEntry.$.id,
                    recipients: Object
                        .keys(this.questionnaireEntry.$.questionnaireSurvey.questionnaireCommentRecipients)
                        .filter((recipient) => recipient !== this.questionnaireEntry.$.user_role),
                });
            },
        },
    };

</script>
