<template>

    <form-group
        :id="id"
        :business-apprentice-agreement="businessApprenticeAgreement"
        :error="error"
        :label="label"
        :left-icon="disabled ? undefined : leftIcon"
        :left-icon-spin="disabled ? undefined : leftIconSpin"
        :pending="pending"
        :required="required"
        :size="size"
        :state="state"
    >

        <template #top>
            <slot name="top" />
        </template>

        <template #default="inputProps">

            <b-form-file
                v-if="disabled !== true"
                v-model="modifiedUpload"
                v-bind="inputProps"
                :accept="accept"
                browse=""
                placeholder="Aucun fichier sélectionné"
            />

            <b-button
                v-else-if="initialUpload"
                :href="`${initialUpload.$.url}?filename=${filename || label}`"
                class="px-0"
                target="_blank"
                variant="link"
            >
                <font-awesome-icon
                    :icon="['fad', 'download']"
                    fixed-width
                />
                Télécharger le fichier
            </b-button>

            <b-form-input
                v-else
                value="Aucun fichier"
                plaintext
            />

        </template>

        <template
            v-if="disabled !== true && initialUpload"
            #right
        >
            <b-input-group-append>
                <b-button
                    :href="`${initialUpload.$.url}?filename=${filename || label}`"
                    target="_blank"
                    variant="primary"
                >
                    <font-awesome-icon
                        :icon="['fad', 'download']"
                        fixed-width
                    />
                    Télécharger le fichier
                </b-button>
            </b-input-group-append>
        </template>

        <template #description>

            <slot name="description">
                {{ description }}
            </slot>

            <div v-if="disabled !== true">
                Taille maximale : {{ max }}.
            </div>

            <b-form-checkbox
                v-if="disabled !== true && deletable && initialUpload"
                :checked="deleteUpload"
                class="ml-3"
                @input="$emit('update:delete-upload', $event)"
            >
                Supprimer le fichier
            </b-form-checkbox>

        </template>

    </form-group>

</template>



<script>

    import Upload from '@core/models/upload';

    export default {
        props: {
            accept: {
                type: String,
                default: undefined,
            },
            businessApprenticeAgreement: {
                type: Boolean,
                default: false,
            },
            deletable: {
                type: Boolean,
                default: false,
            },
            deleteUpload: {
                type: Boolean,
                default: false,
            },
            description: {
                type: String,
                default: undefined,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            error: {
                type: Array,
                default: undefined,
            },
            filename: {
                type: String,
                default: undefined,
            },
            id: {
                type: String,
                required: true,
            },
            initialUpload: {
                type: Upload,
                default: null,
            },
            label: {
                type: String,
                default: null,
            },
            leftIcon: {
                type: String,
                required: true,
            },
            leftIconSpin: {
                type: Boolean,
                default: false,
            },
            max: {
                type: String,
                default: '2 Mo',
            },
            pending: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String,
                default: undefined,
            },
            state: {
                type: Boolean,
                default: undefined,
            },
            required: {
                type: Boolean,
                default: undefined,
            },
            value: {
                type: [File, Upload],
                default: null,
            },
        },
        computed: {
            modifiedUpload: {
                get: (vue) => (
                    vue.value instanceof File ? vue.value : null
                ),
                set(modifiedUpload) {
                    if (modifiedUpload || this.value instanceof File) {
                        this.$emit('input', modifiedUpload);
                    }
                },
            },
        },
    };

</script>
