import EducationDiscipline from '@core/models/educationDiscipline';
import LearnerPerson from '@core/models/learnerPerson';
import RestModel from '@core/models/_rest';

export default class LearnerEducationDiscipline extends RestModel {

    get url() {
        return this.$.id ? `learnerEducationDisciplines/${this.$.id}` : 'learnerEducationDisciplines';
    }

    fill({
        educationDiscipline = {},
        learnerPerson = {},
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            educationDiscipline: new EducationDiscipline(educationDiscipline.$ || educationDiscipline),
            learnerPerson: new LearnerPerson(learnerPerson.$ || learnerPerson),
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            education_discipline_id: this.$.educationDiscipline.$.id,
            learner_person_id: this.$.learnerPerson.$.id,
        });
    }

}
