<template>

    <h2
        :id="`questionnaireElement-${questionnaireElement.$.id}`"
        class="mb-0"
    >
        {{ questionnaireElement.$.attributes.text }}
    </h2>

</template>



<script>

    import QuestionnaireElement from '@core/models/questionnaireElement';

    export default {
        props: {
            questionnaireElement: {
                type: QuestionnaireElement,
                required: true,
            },
        },
    };

</script>
