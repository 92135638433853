import RestRequest from '@core/request/rest';

export default class Collection {

    get isEmpty() {
        return this.isPending === false && this.models.length === 0;
    }

    get isPending() {
        if (this.prefilled) {
            return true;
        }
        return this.model.isPending;
    }

    set isPending(isPending) {
        this.model.isPending = isPending;
    }

    get requestData() {
        return {
            orders: this.sort,
            page: this.page,
            perPage: this.perPage,
            search: this.search,
        };
    }

    get url() {
        return this.model.url;
    }

    constructor(model, sort, perPage = 10) {
        this.sort = sort;
        this.firstPage = 1;
        this.firstVisibleRecord = 0;
        this.lastPage = 1;
        this.lastVisibleRecord = 0;
        this.model = model;
        this.perPage = perPage;
        this.prefilled = false;
        this.models = [];
        this.search = '';
        this.totalRecord = 0;
    }

    indexSuccessful(response) {
        this.models = response.data.data.map((data) => new this.model.constructor(data));
        this.prefilled = false;
        if (response.data.meta && response.data.meta.last_page < this.page) {
            throw new RangeError(response.data.meta.last_page);
        }
        if (response.data.meta) {
            this.firstVisibleRecord = response.data.meta.from || 0;
            this.lastPage = response.data.meta.last_page;
            this.lastVisibleRecord = response.data.meta.to || 0;
            this.totalRecord = response.data.meta.total;
        }
        return this;
    }

    prefill(quantity) {
        if (quantity > 0 && this.models.length === 0) {
            this.prefilled = true;
            this.models = Array(quantity).fill(this.model);
        }
        return this;
    }

    request(page, search, sort = this.sort) {
        if (this.previousRequest) {
            this.previousRequest.cancel();
        }
        this.page = page;
        this.search = search;
        this.sort = sort;
        this.previousRequest = new RestRequest(this);
        return this.previousRequest;
    }

}
