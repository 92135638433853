<template>

    <b-container
        v-if="items.length"
        class="d-print-none"
        fluid
    >
        <div class="d-md-flex align-items-center">

            <div class="flex-fill">
                <b-breadcrumb class="mb-0 bg-transparent">
                    <b-breadcrumb-item
                        v-for="({ active, href, icon, text, to, truncated }, index) in items"
                        :key="index"
                        v-b-tooltip.noninteractive="truncated === true"
                        :active="active"
                        :href="href"
                        :title="truncated ? text : undefined"
                        :to="to"
                        exact
                    >
                        <font-awesome-icon
                            v-if="icon || text === undefined"
                            :icon="['fad', text === undefined ? 'circle-notch' : icon]"
                            :spin="text === undefined"
                            fixed-width
                        />
                        {{ truncated ? (icon ? '' : '...') : text }}
                    </b-breadcrumb-item>
                </b-breadcrumb>
            </div>

            <portal-target
                class="mx-auto ml-md-3"
                name="breadcrumb"
            />

        </div>
    </b-container>

</template>



<script>

    export default {
        computed: {
            items: (vue) => vue.$store.getters['breadcrumb/items'].map(
                (item, index, items) => ({
                    ...item,
                    truncated: items.length - index >= 3,
                    active: index === items.length - 1,
                }),
            ),
        },
    };

</script>



<style scoped>

    .sticky-top {
        z-index: 999;
    }

    .active {
        font-weight: bold;
    }

</style>
