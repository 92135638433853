import EducationPerson from '@core/models/educationPerson';
import LearnerPerson from '@core/models/learnerPerson';
import RestModel from '@core/models/_rest';

export default class EducationMentorship extends RestModel {

    get url() {
        return this.$.id ? `educationMentorships/${this.$.id}` : 'educationMentorships';
    }

    fill({
        educationPerson = {},
        learnerPerson = {},
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            educationPerson: new EducationPerson(educationPerson.$ || educationPerson),
            learnerPerson: new LearnerPerson(learnerPerson.$ || learnerPerson),
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            education_person_id: this.$.educationPerson.$.id,
            learner_person_id: this.$.learnerPerson.$.id,
        });
    }

}
