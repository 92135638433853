<template>

    <b-navbar-nav
        v-if="!!centerEmail || !!documentationUrl"
        id="navbar-help"
    >
        <b-nav-item-dropdown
            class="d-none d-md-block"
            no-caret
            right
        >

            <template slot="button-content">
                <font-awesome-icon
                    :icon="['fad', 'life-ring']"
                    fixed-width
                />
                <span :class="`d-${toggleable}-none`">
                    Besoin d'aide ?
                </span>
            </template>

            <b-dropdown-item
                v-if="documentationUrl"
                :href="documentationUrl"
                target="_blank"
            >
                <font-awesome-icon
                    :icon="['fad', 'external-link']"
                    fixed-width
                />
                Documentation
            </b-dropdown-item>

            <b-dropdown-item
                v-if="centerEmail"
                :href="`mailto:${centerEmail}?subject=${emailSubject}`"
            >
                <font-awesome-icon
                    :icon="['fad', 'paper-plane']"
                    fixed-width
                />
                Nous contacter
            </b-dropdown-item>

        </b-nav-item-dropdown>
    </b-navbar-nav>

</template>



<script>

    export default {
        props: {
            toggleable: {
                type: String,
                required: true,
            },
        },
        computed: {
            centerEmail: (vue) => vue.$store.getters['center/email'],
            documentationUrl: (vue) => vue.$store.getters['center/documentationUrl'],
            emailSubject: (vue) => `[${vue.platformName}] ${window.location.origin}${vue.$route.fullPath}`,
            platformName: (vue) => vue.$store.getters['center/platformName'],
        },
    };

</script>
