<template>

    <b-card
        no-body
        class="mb-4"
    >

        <b-list-group
            class="mb-3"
            flush
        >
            <b-list-group-item
                v-for="datalog in paginationCollection.models"
                :key="datalog.$.id"
                :variant="paginationCollection.isPending ? 'secondary' : datalog.variant"
                class="d-md-flex justify-content-between"
            >

                <b-skeleton
                    v-if="paginationCollection.isPending"
                    class="w-50"
                />

                <div v-else>

                    <router-link
                        v-if="datalog.$.user"
                        :to="{ name: 'user-edit', params: { userId: datalog.$.user.$.id } }"
                        class="font-weight-bold"
                    >
                        {{ datalog.$.user.$.fullname }}
                    </router-link>

                    <font-awesome-icon
                        v-else
                        :icon="['fad', 'question']"
                        fixed-width
                    />

                    {{ verb(datalog) }}

                    <template v-if="datalog.$.event === 'create'">
                        {{ createText }}.
                    </template>

                    <template v-else>

                        <b>
                            {{ field(datalog) }}
                        </b>

                        <mark
                            v-if="value(datalog, 'before')"
                            v-text="value(datalog, 'before')"
                        />

                        <template v-if="value(datalog, 'before') && value(datalog, 'after')">
                            par
                        </template>

                        <mark
                            v-if="value(datalog, 'after')"
                            v-text="value(datalog, 'after')"
                        />

                        .

                    </template>

                </div>

                <b-skeleton
                    v-if="paginationCollection.isPending"
                    class="w-25"
                />

                <div
                    v-else
                    class="text-right"
                >
                    <i class="small">
                        {{ dayjsLongDatetime(datalog.$.created_at) }}
                    </i>
                </div>

            </b-list-group-item>
        </b-list-group>

        <b-card-footer v-if="paginationCollection.isEmpty === false">
            <pagination-links
                v-model="paginationPage"
                :collection="paginationCollection"
                label="Changement(s)"
            />
        </b-card-footer>

    </b-card>

</template>



<script>

    import Collection from '@core/models/collection';
    import Datalog from '@core/models/datalog';
    import Dayjs from '@core/mixins/dayjs';
    import Pagination from '@core/mixins/pagination';

    export default {
        mixins: [Dayjs, Pagination],
        props: {
            createText: {
                type: String,
                required: true,
            },
            booleanFields: {
                type: Array,
                default: () => ([]),
            },
            dateFields: {
                type: Array,
                default: () => ([]),
            },
            formatField: {
                type: Function,
                required: true,
            },
            formatValue: {
                type: Function,
                default: (datalog, key) => datalog.$[key],
            },
            formatVerb: {
                type: Function,
                default: (datalog) => datalog.verb,
            },
            modelId: {
                type: [Number, String],
                required: true,
            },
            modelType: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            paginationCollection: new Collection(new Datalog(), {}),
        }),
        computed: {
            paginationRequestData: (vue) => ({
                model_type: vue.modelType,
                model_id: vue.modelId,
            }),
        },
        methods: {
            field(datalog) {
                return this.formatField(datalog);
            },
            formatBoolean: (value) => (parseInt(value, 10) ? 'oui' : 'non'),
            value(datalog, key) {
                if (this.booleanFields.some((regex) => new RegExp(regex).test(datalog.$.field))) {
                    return this.formatBoolean(datalog.$[key]);
                }
                if (datalog.$[key] === null) {
                    return null;
                }
                if (datalog.$[key] === 'App\\Models\\Upload') {
                    return null;
                }
                if (this.dateFields.some((regex) => new RegExp(regex).test(datalog.$.field))) {
                    return this.dayjsLongDate(datalog.$[key]);
                }
                return this.formatValue(datalog, key);
            },
            verb(datalog) {
                if (this.booleanFields.some((regex) => new RegExp(regex).test(datalog.$.field))) {
                    return 'a remplacé';
                }
                return this.formatVerb(datalog);
            },
        },
    };

</script>
