import AcademyModel from '@core/models/academy';
import CenterModel from '@core/models/center';
import EducationStudyModel from '@core/models/educationStudy';
import QuestionnaireCategory from '@core/models/questionnaireCategory';
import RestModel from '@core/models/_rest';
import SchoolModel from '@core/models/school';
import dayjs from '@core/mixins/dayjs';

const newJurisdiction = (type, attributes) => {
    if (type === AcademyModel.className) {
        return new AcademyModel(attributes);
    }
    if (type === CenterModel.className) {
        return new CenterModel(attributes);
    }
    if (type === EducationStudyModel.className) {
        return new EducationStudyModel(attributes);
    }
    if (type === SchoolModel.className) {
        return new SchoolModel(attributes);
    }
    return undefined;
};

export default class QuestionnaireSurvey extends RestModel {

    static get accessColors() {
        return {
            none: 'muted',
            read_survey: 'muted',
            read_fields: 'info',
            read_answers: 'info',
            write_answers: 'success',
            write_validation: 'success',
        };
    }

    static get accessNames() {
        return {
            none: 'Ne voit pas le questionnaire',
            read_survey: 'Ne voit pas les questions',
            read_fields: 'Consulte les questions',
            read_answers: 'Consulte les réponses',
            write_answers: 'Participe aux réponses et valide le questionnaire',
            write_validation: 'Participe aux réponses et finalise le questionnaire',
        };
    }

    static get accessVerbs() {
        return {
            none: undefined,
            read_survey: 'Savoir que ce formulaire existe',
            read_fields: 'Consulter les questions',
            read_answers: 'Consulter les réponses',
            write_answers: 'Participer aux réponses et valider le formulaire',
            write_validation: 'Finaliser les réponses',
        };
    }

    get activeNames() {
        if (this.$.type === 'template') {
            return {
                '': 'Brouillon : le modèle est réservé au CFA.',
                1: 'Publié : le modèle est accessible en dehors du CFA.',
            };
        }

        return {
            '': "Brouillon : le formulaire n'est pas accessible aux participants.",
            1: 'Publié : le formulaire est accessible aux participants selon les dates ci-dessous.',
        };
    }

    get countdownMessage() {
        let duration;
        let status;
        if (dayjs.methods.dayjsIsAfter(undefined, this.$.end, 'day')) {
            duration = dayjs.methods.dayjsDifference(undefined, this.$.end, 'days');
            status = 'Échéance passée depuis';
        } else if (dayjs.methods.dayjsIsAfter(undefined, this.$.start)) {
            duration = dayjs.methods.dayjsDifference(undefined, this.$.start, 'days');
            status = 'Ouvert depuis';
        } else {
            duration = dayjs.methods.dayjsDifference(this.$.start, undefined, 'days');
            status = 'Ouverture dans';
        }
        if (duration === 0) {
            return `${status} aujourd'hui`;
        }
        return `${status} ${duration} jour${duration > 1 ? 's' : ''}`;
    }

    static get endDescriptions() {
        return {
            assignable: 'Il sera demandé aux participants de valider les réponses du formulaire pour cette date. '
                + 'Après cette date, le formulaire peut encore être consulté, répondu et validé.',
            business_apprentice_contract_purpose:
                "Ce formulaire sera affecté aux contrats d'apprentissage qui débutent avant cette date.",
            business_apprentice_contract_termination:
                "Ce formulaire sera affecté aux contrats d'apprentissage qui ont été rompus avant cette date.",
            template: undefined,
        };
    }

    static get mandatoryNames() {
        return {
            0: 'Espace ressource',
            1: 'Livret de suivi',
        };
    }

    static get mandatoryDescriptions() {
        return {
            0: 'Ne génère aucune notification ni alerte.',
            1: 'Génère notifications et alertes.',
        };
    }

    get questionnaireCommentRecipients() {
        const accesses = ['read_answers', 'write_answers', 'write_validation'];
        const recipients = {};
        if (accesses.includes(this.$.access_learner_person)) {
            recipients.learner_person = "l'apprenant";
        }
        if (accesses.includes(this.$.access_education_mentor)) {
            recipients.education_mentor = 'le(s) tuteur(s) pédagogique(s)';
        }
        if (accesses.includes(this.$.access_business_mentor)) {
            recipients.business_mentor = this.$.type === 'business_apprentice_contract_purpose'
                ? "le délégataire du contrat d'apprentissage"
                : "le(s) maître(s) d'apprentissage / tuteur(s) entreprise";
        }
        if (accesses.includes(this.$.access_education_study)) {
            recipients.education_study = 'les responsables de formation';
        }
        return recipients;
    }

    static get roleIcons() {
        return {
            learner_person: 'user-graduate',
            education_mentor: 'user-tie',
            business_mentor: 'user-hard-hat',
            education_study: 'chalkboard-teacher',
        };
    }

    get rolePhraseNames() {
        return {
            learner_person: "l'apprenant",
            education_mentor: 'le tuteur pédagogique',
            business_mentor: this.$.type === 'business_apprentice_contract_purpose'
                ? "le délégataire du contrat d'apprentissage"
                : "le maître d'apprentissage / tuteur entreprise",
            education_study: 'les responsables de formation',
        };
    }


    get roleTitleNames() {
        return {
            learner_person: 'Apprenant',
            education_mentor: 'Tuteur pédagogique',
            business_mentor: this.$.type === 'business_apprentice_contract_purpose'
                ? "Délégataire du contrat d'apprentissage"
                : "Maître d'apprentissage / Tuteur entreprise",
            education_study: 'Responsable de formation',
        };
    }

    static get startDescriptions() {
        return {
            assignable: 'Avant cette date, le formulaire peut être consulté mais ne peut pas être répondu ni validé.',
            business_apprentice_contract_purpose:
                "Ce formulaire sera affecté aux contrats d'apprentissage qui débutent après cette date.",
            business_apprentice_contract_termination:
                "Ce formulaire sera affecté aux contrats d'apprentissage qui ont été rompus après cette date.",
            template: undefined,
        };
    }

    static get typeDescriptions() {
        return {
            assignable: 'Les participants concernés pourront y répondre directement.',
            business_apprentice_contract_purpose: "Proposé lors de la saisie du contrat d'apprentissage.",
            business_apprentice_contract_termination: "Proposé lorsqu'un contrat d'apprentissage a été rompu.",
            template: 'Doit être dupliqué pour être assigné puis répondu par ses participants.',
        };
    }

    static get typeNames() {
        return {
            assignable: 'Formulaire assignable',
            business_apprentice_contract_purpose: 'Validation des missions',
            business_apprentice_contract_termination: "Formulaire de rupture du contrat d'apprentissage",
            template: 'Modèle de formulaire',
        };
    }

    get url() {
        return this.$.id ? `questionnaireSurveys/${this.$.id}` : 'questionnaireSurveys';
    }

    get writeAnswersRoles() {
        const roles = [];
        if (this.$.access_learner_person === 'write_answers') {
            roles.push('learner_person');
        }
        if (this.$.access_education_mentor === 'write_answers') {
            roles.push('education_mentor');
        }
        if (this.$.access_business_mentor === 'write_answers') {
            roles.push('business_mentor');
        }
        if (this.$.access_education_study === 'write_answers') {
            roles.push('education_study');
        }
        return roles;
    }

    get writeValidationRole() {
        if (this.$.access_learner_person === 'write_validation') {
            return 'learner_person';
        }
        if (this.$.access_education_mentor === 'write_validation') {
            return 'education_mentor';
        }
        if (this.$.access_business_mentor === 'write_validation') {
            return 'business_mentor';
        }
        if (this.$.access_education_study === 'write_validation') {
            return 'education_study';
        }
        return '';
    }

    fill({
        jurisdiction = {},
        questionnaireCategory = {},
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            jurisdiction: newJurisdiction(
                attributes.jurisdiction_type || jurisdiction.constructor.className,
                jurisdiction.$ || jurisdiction,
            ),
            questionnaireCategory: new QuestionnaireCategory(questionnaireCategory.$ || questionnaireCategory),
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            jurisdiction_type: this.$.jurisdiction ? this.$.jurisdiction.constructor.className : undefined,
            jurisdiction_id: this.$.jurisdiction ? this.$.jurisdiction.$.id : undefined,
            questionnaire_category_id: this.$.questionnaireCategory.$.id,
        });
    }

}
