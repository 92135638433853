import Address from '@core/models/address';
import BusinessApprenticeClass from '@core/models/businessApprenticeClass';
import BusinessApprenticeDiploma from '@core/models/businessApprenticeDiploma';
import BusinessApprenticeSituation from '@core/models/businessApprenticeSituation';
import EducationSifaSituation from '@core/models/educationSifaSituation';
/* eslint-disable-next-line import/no-cycle */
import LearnerEducationStudy from '@core/models/learnerEducationStudy';
import RestModel from '@core/models/_rest';
import Upload from '@core/models/upload';
import dayjs from '@core/mixins/dayjs';

export default class LearnerPerson extends RestModel {

    get age() {
        return dayjs.methods.dayjsDifference(undefined, this.$.birth_date, 'year');
    }

    static get className() {
        return 'Modules\\Learner\\Models\\LearnerPerson';
    }

    get currentLearnerEducationStudy() {
        return this.$.learnerEducationStudies
            .filter((learnerEducationStudy) => learnerEducationStudy.$.status !== 'canceled')
            .filter((learnerEducationStudy) => learnerEducationStudy.$.status !== 'rejected')
            .find(
                (learnerEducationStudy) => dayjs.methods.dayjsDayBetween(
                    undefined,
                    learnerEducationStudy.$.start || learnerEducationStudy.$.educationStudy.$.start,
                    learnerEducationStudy.$.end || learnerEducationStudy.$.educationStudy.$.end,
                ),
            );
    }

    static get genders() {
        return {
            '': '',
            male: 'Masculin',
            female: 'Féminin',
        };
    }

    get lastLearnerEducationStudy() {
        return this.$.learnerEducationStudies
            .filter((learnerEducationStudy) => learnerEducationStudy.$.status !== 'canceled')
            .filter((learnerEducationStudy) => learnerEducationStudy.$.status !== 'rejected')
            .reduce(
                (lastlearnerEducationStudy, currentlearnerEducationStudy) => (
                    lastlearnerEducationStudy && dayjs.methods.dayjsIsAfter(
                        lastlearnerEducationStudy.$.educationStudy.$.start,
                        currentlearnerEducationStudy.$.educationStudy.$.start,
                    )
                        ? lastlearnerEducationStudy
                        : currentlearnerEducationStudy
                ),
                undefined,
            );
    }

    get learnerEducationStudy() {
        return this.currentLearnerEducationStudy || this.lastLearnerEducationStudy;
    }

    static get maritalStatuses() {
        return {
            single: 'Célibataire',
            married: 'Marié(e) ou pacsé(e)',
            cohabitation: 'En concubinage',
            divorced: 'Divorcé(e)',
            widowed: 'Veuf(ve)',
            parent: 'Enfant(s) à charge',
        };
    }

    static get nationalityTypes() {
        return {
            '': '',
            france: 'France',
            european_union: 'Union Européenne',
            other: 'Autre',
        };
    }

    get url() {
        return this.$.id ? `learnerPeople/${this.$.id}` : 'learnerPeople';
    }

    fill({
        avatar = undefined,
        highestBusinessApprenticeDiploma = undefined,
        lastBusinessApprenticeClass = undefined,
        lastBusinessApprenticeDiploma = undefined,
        lastBusinessApprenticeSituation = undefined,
        learnerEducationStudies = [],
        previousYearEducationSifaSituation = undefined,
        primaryAddress = {},
        representativeAddress = {},
        secondaryAddress = {},
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            avatar: avatar ? new Upload(avatar.$ || avatar) : undefined,
            highestBusinessApprenticeDiploma: highestBusinessApprenticeDiploma
                ? new BusinessApprenticeDiploma(highestBusinessApprenticeDiploma.$ || highestBusinessApprenticeDiploma)
                : null,
            lastBusinessApprenticeClass: lastBusinessApprenticeClass
                ? new BusinessApprenticeClass(lastBusinessApprenticeClass.$ || lastBusinessApprenticeClass)
                : null,
            lastBusinessApprenticeDiploma: lastBusinessApprenticeDiploma
                ? new BusinessApprenticeDiploma(lastBusinessApprenticeDiploma.$ || lastBusinessApprenticeDiploma)
                : null,
            lastBusinessApprenticeSituation: lastBusinessApprenticeSituation
                ? new BusinessApprenticeSituation(lastBusinessApprenticeSituation.$ || lastBusinessApprenticeSituation)
                : null,
            learnerEducationStudies: learnerEducationStudies.map(
                (learnerEducationStudy) => new LearnerEducationStudy(learnerEducationStudy.$ || learnerEducationStudy),
            ),
            previousYearEducationSifaSituation: previousYearEducationSifaSituation
                ? new EducationSifaSituation(previousYearEducationSifaSituation.$ || previousYearEducationSifaSituation)
                : undefined,
            primaryAddress: new Address(primaryAddress.$ || primaryAddress),
            representativeAddress: new Address(representativeAddress.$ || representativeAddress),
            secondaryAddress: new Address(secondaryAddress.$ || secondaryAddress),
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            avatar: this.$.avatar instanceof File ? this.$.avatar : undefined,
            highest_business_apprentice_diploma_id: this.$.highestBusinessApprenticeDiploma
                ? this.$.highestBusinessApprenticeDiploma.$.id
                : null,
            last_business_apprentice_class_id: this.$.lastBusinessApprenticeClass
                ? this.$.lastBusinessApprenticeClass.$.id
                : null,
            last_business_apprentice_diploma_id: this.$.lastBusinessApprenticeDiploma
                ? this.$.lastBusinessApprenticeDiploma.$.id
                : null,
            last_business_apprentice_situation_id: this.$.lastBusinessApprenticeSituation
                ? this.$.lastBusinessApprenticeSituation.$.id
                : null,
            previous_year_education_sifa_situation_id: this.$.previousYearEducationSifaSituation
                ? this.$.previousYearEducationSifaSituation.$.id
                : undefined,
        });
    }

}
