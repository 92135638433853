import Model from '@core/models/_model';
import RestRequest from '@core/request/rest';

export default class Rest extends Model {

    constructor(attributes = {}) {
        super(attributes);
        this.errors = {};
        this.pendingRequests = 0;
    }

    get isPending() {
        return this.pendingRequests > 0;
    }

    set isPending(isPending) {
        if (isPending) {
            this.pendingRequests += 1;
        } else {
            this.pendingRequests -= 1;
        }
    }

    get states() {
        return {
            ...Object.fromEntries(
                Object.keys(this.errors).length ? Object.keys(this.formData()).map((field) => [field, true]) : [],
            ),
            ...Object.fromEntries(
                Object.keys(this.errors).map((field) => [field, false]),
            ),
        };
    }

    createFailed(error) {
        if (error.response && error.response.status === 422) {
            this.fillErrorsFromResponse(error.response);
        }
        throw error;
    }

    createSuccessful(response) {
        if (response.status === 204) {
            return this;
        }
        return this.fill(response.data.data);
    }

    deleteFailed(error) {
        if (error.response && error.response.status === 422) {
            this.fillErrorsFromResponse(error.response);
        }
        throw error;
    }

    fillErrorsFromResponse(response) {
        this.errors = Object.fromEntries(Object.entries(response.data.errors).map(([key, value]) => [key, value]));
    }

    is(model) {
        return model.url === this.url;
    }

    request() {
        return new RestRequest(this);
    }

    showFailed(error) {
        if (error.response && error.response.status === 422) {
            this.fillErrorsFromResponse(error.response);
        }
        throw error;
    }

    showSuccessful(response) {
        return this.fill(response.data.data);
    }

    updateFailed(error) {
        if (error.response && error.response.status === 422) {
            this.fillErrorsFromResponse(error.response);
        }
        throw error;
    }

    updateSuccessful(response) {
        return this.fill(response.data.data);
    }

}
