import RestModel from '@core/models/_rest';

export default class QuestionnaireValidation extends RestModel {

    get url() {
        return this.$.id
            ? `questionnaireValidations/${this.$.id}?message=${encodeURIComponent(this.$.message || '')}`
            : 'questionnaireValidations';
    }

    fill({
        updatable = [],
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            updatable,
        });
    }

}
