<template>

    <b-row
        id="view-businessPerson-edit"
        tag="form"
        @submit.prevent="formSubmit"
    >

        <b-col md="6">

            <slot />

            <business-person-missing-information-alert
                :business-person="formInitialModel"
                :business-person-edit-route="businessPersonEditRoute"
                class="mb-4"
                success-title="Le profil du maître d'apprentissage complet."
                warning-title="Les informations suivantes manquent au profil du maître d'apprentissage :"
            />

            <b-card
                bg-variant="light"
                class="mb-4"
                header="Informations personnelles"
                header-tag="h2"
            >

                <form-group
                    id="view-businessPerson-edit-title"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.title"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.title"
                    label="Titre"
                    required
                >
                    <b-form-radio-group
                        v-model="formModifiedModel.$.title"
                        v-bind="inputProps"
                        :disabled="formInitialModel.$.updatable !== true"
                        :options="titles"
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-edit-firstname"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.firstname"
                    :left-icon="formInitialModel.$.updatable ? 'text' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.firstname"
                    label="Prénom"
                    business-apprentice-agreement
                    required
                >
                    <b-form-input
                        v-model="formModifiedModel.$.firstname"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-edit-lastname"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.lastname"
                    :left-icon="formInitialModel.$.updatable ? 'text' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.lastname"
                    label="Nom"
                    business-apprentice-agreement
                    required
                >
                    <b-form-input
                        v-model="formModifiedModel.$.lastname"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-edit-active"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.active"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.active"
                    label="Actif"
                >
                    <b-form-checkbox
                        v-model="formModifiedModel.$.active"
                        v-bind="inputProps"
                        :disabled="formInitialModel.$.updatable !== true"
                    >
                        Autoriser la création de tutorat d'apprentissage et de tutorat entreprise.
                    </b-form-checkbox>
                </form-group>

            </b-card>

        </b-col>

        <b-col md="6">

            <b-card
                bg-variant="light"
                class="mb-4"
                header="Informations professionnelles"
                header-tag="h2"
            >

                <form-group
                    id="view-businessPerson-edit-business-company-id"
                    v-slot="inputProps"
                    :left-icon="formInitialModel.$.updatable ? 'industry-alt' : undefined"
                    :pending="formInitialModel.isPending"
                    label="Employeur"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.businessCompany.$.name"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                        readonly
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-edit-job-type"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.job_type"
                    :left-icon="formInitialModel.$.updatable ? 'tag' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.job_type"
                    label="Département"
                    required
                >
                    <b-form-select
                        v-if="formInitialModel.$.updatable"
                        v-model="formModifiedModel.$.job_type"
                        v-bind="inputProps"
                        :options="jobTypeOptions"
                    />
                    <b-form-input
                        v-else
                        v-bind="inputProps"
                        :value="jobTypes[formModifiedModel.$.job_type]"
                        plaintext
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-edit-job-title"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.job_title"
                    :left-icon="formInitialModel.$.updatable ? 'text' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.job_title"
                    label="Intitulé de poste"
                    required
                >
                    <b-form-input
                        v-model="formModifiedModel.$.job_title"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-edit-loacation"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.location"
                    :left-icon="formInitialModel.$.updatable ? 'map-marker' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.location"
                    label="Zone géographique"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.location"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-edit-email"
                    :error="formModifiedModel.errors.email"
                    :left-icon="
                        formInitialModel.$.updatable && formInitialModel.$.updatableEmail ? 'envelope' : undefined
                    "
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.email"
                    label="Adresse e-mail"
                >

                    <template #default="inputProps">
                        <b-form-input
                            v-model="formModifiedModel.$.email"
                            v-bind="inputProps"
                            :plaintext="
                                formInitialModel.$.updatable !== true || formInitialModel.$.updatableEmail !== true
                            "
                            type="email"
                        />
                    </template>

                    <template
                        v-if="
                            formInitialModel.$.updatableEmail !== true
                                && formInitialModel.$.user
                                && formInitialModel.$.user.$.updatableEmail
                        "
                        #description
                    >
                        L'adresse e-mail peut-être modifiée sur
                        <router-link :to="{ name: `user-edit`, params: { userId: formInitialModel.$.user.$.id } }">
                            la page du compte utilisateur
                        </router-link>
                        .
                    </template>

                </form-group>

                <form-group
                    id="view-businessPerson-edit-phone"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.phone"
                    :left-icon="formInitialModel.$.updatable ? 'mobile' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.phone"
                    label="Téléphone"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.phone"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                        pattern="^\+?\d{7,14}$"
                        title="7 à 14 chiffres"
                    />
                </form-group>

            </b-card>

            <b-card
                bg-variant="light"
                class="mb-4"
                header="Si cette personne est un maître d'apprentissage, merci de compléter les informations suivantes"
                header-tag="h2"
            >

                <form-group
                    id="view-businessPerson-edit-birth-date"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.birth_date"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.birth_date"
                    label="Date de naissance"
                    description="Obligatoire pour les maîtres d'apprentissage."
                    business-apprentice-agreement
                >
                    <b-form-input
                        v-model="formModifiedModel.$.birth_date"
                        v-bind="inputProps"
                        :placeholder="formInitialModel.$.updatable ? 'AAAA-MM-JJ' : undefined"
                        :plaintext="formInitialModel.$.updatable !== true"
                        max="2999-01-01"
                        type="date"
                    />
                </form-group>

                <form-group-nirpp
                    id="view-businessPerson-edit-nirpp"
                    v-model="formModifiedModel.$.nirpp"
                    :error="formModifiedModel.errors.nirpp"
                    :missing="formInitialModel.$.nirpp_count !== 1"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.nirpp"
                    :updatable="formInitialModel.$.updatable"
                    :updating="formIsPending"
                />

                <model-list
                    v-slot="{ collection, options, setValue, value: highestBusinessPersonDiplomaId }"
                    v-model="highest_business_person_diploma_id"
                    name="BusinessPersonDiploma"
                    allow-empty
                >
                    <form-group
                        id="view-businessPerson-edit-business-person-diploma-id"
                        v-slot="inputProps"
                        :error="formModifiedModel.errors.highest_business_person_diploma_id"
                        :left-icon="collection.isPending ? 'circle-notch' : 'file-certificate'"
                        :left-icon-spin="collection.isPending"
                        :pending="formInitialModel.isPending"
                        :state="formModifiedModel.states.highest_business_person_diploma_id"
                        label="Diplôme le plus élevé obtenu"
                        business-apprentice-agreement
                    >
                        <b-form-select
                            v-bind="inputProps"
                            :options="options"
                            :value="highestBusinessPersonDiplomaId"
                            @input="setValue"
                        />
                    </form-group>
                </model-list>

                <form-group
                    id="view-businessPerson-edit-highest-business-person-diploma-name"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.highest_business_person_diploma_name"
                    :left-icon="formInitialModel.$.updatable ? 'text' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.highest_business_person_diploma_name"
                    label="Intitulé du diplôme le plus élevé obtenu"
                    business-apprentice-agreement
                >
                    <b-form-input
                        v-model="formModifiedModel.$.highest_business_person_diploma_name"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-edit-mentorship-certification-start"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.mentorship_certification_start"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.mentorship_certification_start"
                    description="Si la personne dispose d'une certification MATU."
                    label="Début de certification MATU"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.mentorship_certification_start"
                        v-bind="inputProps"
                        :placeholder="formInitialModel.$.updatable ? 'AAAA-MM-JJ' : undefined"
                        :plaintext="formInitialModel.$.updatable !== true"
                        max="2999-01-01"
                        type="date"
                    />
                </form-group>

                <form-group
                    id="view-businessPerson-edit-mentorship-certification-end"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.mentorship_certification_end"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.mentorship_certification_end"
                    description="Si la personne dispose d'une certification MATU."
                    label="Fin de certification MATU"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.mentorship_certification_end"
                        v-bind="inputProps"
                        :placeholder="formInitialModel.$.updatable ? 'AAAA-MM-JJ' : undefined"
                        :plaintext="formInitialModel.$.updatable !== true"
                        max="2999-01-01"
                        type="date"
                    />
                </form-group>

            </b-card>

        </b-col>

        <b-col
            v-if="formInitialModel.$.updatable"
            md="12"
        >
            <business-person-delete
                :parent-form-is-pending="formIsPending"
                :business-person="formInitialModel"
                @deleted="$emit('deleted')"
            >
                <button-submit
                    :is-pending="formIsPending"
                    icon="edit"
                    variant="success"
                >
                    Enregistrer le contact
                </button-submit>
            </business-person-delete>
        </b-col>

    </b-row>

</template>



<script>

    import BusinessPerson from '@core/models/businessPerson';
    import BusinessPersonDiploma from '@core/models/businessPersonDiploma';
    import Form from '@core/mixins/form';

    export default {
        mixins: [Form],
        props: {
            businessPersonEditRoute: {
                type: Object,
                required: true,
            },
            value: {
                type: BusinessPerson,
                required: true,
            },
        },
        computed: {
            highest_business_person_diploma_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.highestBusinessPersonDiploma
                        ? vue.formModifiedModel.$.highestBusinessPersonDiploma.$.id
                        : undefined
                ),
                set(id) {
                    this.formModifiedModel.$.highestBusinessPersonDiploma = new BusinessPersonDiploma({ id });
                },
            },
            formInitialModel: {
                get: (vue) => vue.value,
                set(value) {
                    this.$emit('input', value);
                },
            },
            jobTypes: () => BusinessPerson.jobTypes,
            jobTypeOptions: () => Object.entries(BusinessPerson.jobTypes).map(
                ([value, text]) => ({ text, value }),
            ),
            titles: () => Object.entries(BusinessPerson.titles).map(
                ([value, text]) => ({ text, value }),
            ),
        },
        methods: {
            formSubmitSuccessful(updatedBusinessPerson) {
                this.$root.toastSuccess(
                    'Les informations du contact ont été modifiées',
                    "L'enregistrement des informations a réussi.",
                );
                this.formInitialModel = new BusinessPerson(updatedBusinessPerson.$);
            },
        },
    };

</script>
