import Address from '@core/models/address';
import BusinessNaf from '@core/models/businessNaf';
import RestModel from '@core/models/_rest';
import Upload from '@core/models/upload';

export default class BusinessCompany extends RestModel {

    get editableAttribues() {
        if (this.$.data_source === 'opendatasoft') {
            return ['employee_headcount', 'private_employer', 'logo'];
        }

        if (this.$.data_source === 'user') {
            return ['business_naf_id', 'name', 'employee_headcount', 'private_employer', 'logo'];
        }

        if (this.$.data_source === 'unknown') {
            return ['business_naf_id', 'name', 'employee_headcount', 'private_employer', 'logo'];
        }

        return [];
    }

    get url() {
        return this.$.id ? `businessCompanies/${this.$.id}` : 'businessCompanies';
    }

    fill({
        businessNaf = undefined,
        logo = undefined,
        office = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            businessNaf: businessNaf ? new BusinessNaf(businessNaf.$ || businessNaf) : undefined,
            logo: logo ? new Upload(logo.$ || logo) : undefined,
            office: office ? new Address(office.$ || office) : undefined,
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            business_naf_id: this.$.businessNaf ? this.$.businessNaf.$.id : null,
            logo: this.$.logo instanceof File ? this.$.logo : undefined,
        });
    }

}
