<script>

    import Cities from '@core/models/cities';
    import Collection from '@core/models/collection';
    import RestModel from '@core/models/_rest';

    const models = Object.fromEntries(
        Object.entries(import.meta.glob('@core/models/*.js', { eager: true }))
            .map(([path, definition]) => [path.split('/').pop().split('.').shift(), definition.default]),
    );

    models.educationProgrammeYear = class extends RestModel {

        /* eslint-disable class-methods-use-this */
        get url() {
            return 'educationProgrammes/years';
        }

        fill(year) {
            this.$ = Number.isInteger(year) ? year : 0;
            return this;
        }

    };


    export default {
        props: {
            allowEmpty: {
                type: Boolean,
                default: false,
            },
            autoSelect: {
                type: Boolean,
                default: false,
            },
            emptyText: {
                type: String,
                default: '',
            },
            externalCollection: {
                type: Object,
                default: undefined,
            },
            name: {
                type: String,
                required: true,
            },
            optionText: {
                type: Function,
                default: (model) => model.$.name,
            },
            optionValue: {
                type: Function,
                default: (model) => model.$.id,
            },
            prefill: {
                type: Number,
                default: 0,
            },
            requestData: {
                type: Object,
                default: () => ({}),
            },
            requestSort: {
                type: Object,
                default: () => ({ name: 'asc' }),
            },
            value: {
                type: [Number, String],
                default: null,
            },
        },
        data: (vue) => {
            const modelIndex = vue.name.charAt(0).toLowerCase() + vue.name.slice(1);
            const model = new models[modelIndex]();
            const internalCollection = modelIndex === 'city'
                ? new Cities(model, undefined, vue.requestSort)
                : new Collection(model, vue.requestSort, 1000);
            return { internalCollection };
        },
        computed: {
            collection: (vue) => vue.externalCollection || vue.internalCollection,
            localValue: {
                get: (vue) => vue.value,
                set(value) {
                    this.$emit('input', value);
                    this.$emit('change', this.collection.models.find((model) => this.optionValue(model) === value));
                },
            },
            options: (vue) => {
                const options = vue.collection.models.map((model) => ({
                    text: vue.optionText(model),
                    value: vue.optionValue(model),
                }));
                if (vue.allowEmpty) {
                    options.unshift({ text: vue.emptyText, value: null });
                }
                return options;
            },
        },
        watch: {
            requestData: {
                handler(newValue, oldValue) {
                    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                        this.request();
                    }
                },
                immediate: true,
            },
        },
        methods: {
            request() {
                if (this.externalCollection === undefined) {
                    this.internalCollection
                        .prefill(this.prefill)
                        .request()
                        .index(this.requestData)
                        .then((internalCollection) => {
                            if (this.autoSelect && !this.localValue && internalCollection.isEmpty !== true) {
                                this.localValue = this.optionValue(internalCollection.models[0]);
                            }
                        });
                }
            },
        },
        render() {
            return this.$scopedSlots.default({
                collection: this.collection,
                model: this.collection.models.find((model) => this.optionValue(model) === this.localValue),
                options: this.options,
                setValue: (value) => { this.localValue = value; },
                value: this.localValue,
            });
        },
    };

</script>
