<template>

    <div
        v-if="questionnaireElement.$.typeUpload === undefined"
        class="d-flex"
    >

        <b-alert
            class="flex-fill"
            variant="danger"
            show
        >
            <font-awesome-icon
                :icon="['fad', 'exclamation']"
                fixed-width
            />
            Le document {{ label }} n'a pas pu être trouvé.
        </b-alert>

        <button-help class="mb-3 ml-3" />

    </div>

    <form-group
        v-else-if="['jpg', 'jpeg', 'png'].includes(uploadExtension)"
        :id="`questionnaireElement-${questionnaireElement.$.id}`"
    >
        <b-img
            :src="uploadUrl"
            fluid
            thumbnail
        />
    </form-group>

    <form-group
        v-else
        :id="`questionnaireElement-${questionnaireElement.$.id}`"
        :label="label"
    >

        <template #default="inputProps">

            <em v-if="printMode">

                <template v-if="uploadExtension === 'pdf'">
                    Rendez-vous à la fin du PDF pour consulter le document normalement inséré ici.
                </template>

                <template v-else>
                    <a :href="currentUrl">
                        Connectez-vous
                    </a>
                    pour télécharger le document normalement inséré ici.
                </template>

            </em>

            <div
                v-else-if="uploadExtension === 'pdf'"
                v-bind="inputProps"
                class="embed-responsive"
            >
                <iframe
                    :src="`${uploadUrl}&disposition=inline`"
                    class="embed-responsive-item"
                />
            </div>

            <b-button
                v-else
                v-bind="inputProps"
                :href="uploadUrl"
                target="_blank"
                variant="link"
            >
                <font-awesome-icon
                    :icon="['fad', 'download']"
                    fixed-width
                />
                Télécharger
            </b-button>

        </template>

        <template #description>

            <slot name="description" />

            <div
                v-if="questionnaireElement.$.description"
                class="mt-1"
            >
                {{ questionnaireElement.$.description }}.
            </div>

        </template>

    </form-group>

</template>



<script>

    import QuestionnaireElement from '@core/models/questionnaireElement';

    export default {
        props: {
            questionnaireElement: {
                type: QuestionnaireElement,
                required: true,
            },
        },
        computed: {
            currentUrl: () => window.location.href,
            label: (vue) => vue.questionnaireElement.$.attributes.text,
            uploadExtension: (vue) => vue.questionnaireElement.$.typeUpload.$.url.split('.').pop().toLowerCase(),
            uploadUrl: (vue) => (
                vue.label
                    ? `${vue.questionnaireElement.$.typeUpload.$.url}?filename=${vue.label}`
                    : `${vue.questionnaireElement.$.typeUpload.$.url}?`
            ),
        },
    };

</script>



<style scoped>

    .embed-responsive {
        height: 500px;
    }

</style>
