<template>

    <b-row
        tag="form"
        @submit.prevent="formSubmit"
    >

        <b-col
            order="1"
            md="12"
        >

            <business-branch-missing-information-alert
                v-if="showMissingInformation"
                :business-branch="businessBranch"
                :business-branch-edit-route="businessBranchEditRoute"
                :business-branch-office-route="businessBranchOfficeRoute"
                :business-company-edit-route="businessCompanyEditRoute"
                :business-company-office-route="businessCompanyOfficeRoute"
                class="mb-4"
                success-title="Le profil de l'établissement est complet."
                warning-title="Les informations suivantes manquent au profil de l'établissement :"
            />

            <b-alert
                :show="formInitialModel.$.data_source === 'opendatasoft'"
                variant="info"
                class="mb-4"
            >
                <font-awesome-icon
                    :icon="['fad', 'info']"
                    fixed-width
                />
                Cet employeur est issu du registre officiel de l'INSEE :
                certaines informations ne sont pas modifiables.
            </b-alert>

            <b-alert
                :show="formInitialModel.$.data_source === 'unknown'"
                variant="warning"
            >
                <font-awesome-icon
                    :icon="['fad', 'exclamation']"
                    fixed-width
                />
                Cet employeur est inconnu du registre officiel de l'INSEE.
            </b-alert>

        </b-col>

        <b-col
            class="mb-4"
            order="3"
            md="8"
        >
            <b-card bg-variant="light">

                <form-group
                    id="view-businessCompany-edit-siren"
                    v-slot="inputProps"
                    :left-icon="formInitialModel.$.updatable ? 'barcode' : undefined"
                    :pending="formInitialModel.isPending"
                    label="Numéro de SIREN"
                >
                    <b-form-input
                        v-model="formModifiedModel.$.siren"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                        :readonly="formInitialModel.editableAttribues.includes('siren') === false"
                    />
                </form-group>

                <form-group
                    id="view-businessCompany-edit-name"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.name"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.name"
                    label="Raison sociale"
                    :left-icon="formInitialModel.$.updatable ? 'text' : undefined"
                    business-apprentice-agreement
                >
                    <b-form-input
                        v-model="formModifiedModel.$.name"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                        :readonly="formInitialModel.editableAttribues.includes('name') === false"
                    />
                </form-group>

                <model-list
                    v-slot="{ collection, model, options, setValue, value: businessNafId }"
                    v-model="business_naf_id"
                    :option-text="(businessNaf) => `${businessNaf.$.code} - ${businessNaf.$.name}`"
                    name="BusinessNaf"
                >
                    <form-group
                        id="view-businessCompany-edit-business-naf-id"
                        v-slot="inputProps"
                        :description="
                            formInitialModel.editableAttribues.includes('business_naf_id')
                                ? undefined
                                : 'Cette information est issue du registre officiel de l\'INSEE : '
                                    + 'elle n\'est pas modifiable.'
                        "
                        :error="formModifiedModel.errors.business_naf_id"
                        :left-icon="
                            formInitialModel.$.updatable ? (collection.isPending ? 'circle-notch' : 'tag') : undefined
                        "
                        :left-icon-spin="collection.isPending"
                        :pending="formInitialModel.isPending"
                        :state="formModifiedModel.states.business_naf_id"
                        label="Code NAF"
                    >
                        <b-form-select
                            v-if="
                                formInitialModel.$.updatable
                                    && formInitialModel.editableAttribues.includes('business_naf_id')
                            "
                            v-bind="inputProps"
                            :options="options"
                            :value="businessNafId"
                            @input="setValue"
                        />
                        <b-form-input
                            v-else
                            v-bind="inputProps"
                            :value="model ? `${model.$.code} - ${model.$.name}` : undefined"
                            :plaintext="formInitialModel.$.updatable !== true"
                            readonly
                        />
                    </form-group>
                </model-list>

                <form-group
                    id="view-businessCompany-edit-employee-headcount"
                    :error="formModifiedModel.errors.employee_headcount"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.employee_headcount"
                    label="Nombre de salariés"
                    :left-icon="formInitialModel.$.updatable ? 'abacus' : undefined"
                    business-apprentice-agreement
                >

                    <template #default="inputProps">
                        <b-form-input
                            v-model="formModifiedModel.$.employee_headcount"
                            v-bind="inputProps"
                            :max="formModifiedModel.$.max_employee_headcount || undefined"
                            :min="formModifiedModel.$.min_employee_headcount"
                            :plaintext="formInitialModel.$.updatable !== true"
                            :readonly="formInitialModel.editableAttribues.includes('employee_headcount') === false"
                            type="number"
                        />
                    </template>

                    <template
                        v-if="formModifiedModel.$.max_employee_headcount"
                        #description
                    >
                        Selon l'INSEE, l'effectif salarial de l'employeur est compris
                        entre {{ formModifiedModel.$.min_employee_headcount }}
                        et {{ formModifiedModel.$.max_employee_headcount }}.
                    </template>

                    <template
                        v-else
                        #description
                    >
                        Il s'agit de l'effectif salarial de l'employeur (SIREN),
                        pas celui de l'établissement (SIRET).
                    </template>

                </form-group>

                <form-group
                    id="view-businessCompany-edit-private-employer"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.private_employer"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.private_employer"
                    description="Cette information est ignorée dans le cas des entreprises privées."
                    label="Employeur public/privé"
                    business-apprentice-agreement
                >
                    <b-form-checkbox
                        v-model="formModifiedModel.$.private_employer"
                        v-bind="inputProps"
                        :disabled="
                            formInitialModel.$.updatable !== true
                                || formInitialModel.editableAttribues.includes('private_employer') !== true
                        "
                    >
                        Il s'agit d'une entreprise publique ayant un statut d'employeur privé.
                    </b-form-checkbox>
                </form-group>

            </b-card>
        </b-col>

        <b-col
            class="mb-4"
            order="2"
            md="4"
        >
            <b-card
                bg-variant="light"
                header="Logo"
                header-class="text-center"
                header-tag="h2"
            >
                <image-form-group
                    id="view-businessCompany-edit-logo"
                    v-model="formModifiedModel.$.logo"
                    :delete-upload.sync="formModifiedModel.$.delete_logo"
                    :disabled="
                        formInitialModel.$.updatable !== true
                            || formInitialModel.editableAttribues.includes('logo') === false
                    "
                    :error="formModifiedModel.errors.logo"
                    :initial-upload="formInitialModel.$.logo"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.logo"
                    icon="industry-alt"
                    rounded
                />
            </b-card>
        </b-col>

        <b-col
            v-if="formInitialModel.$.updatable"
            class="text-right"
            order="4"
            md="12"
        >
            <button-submit
                :is-pending="formIsPending"
                icon="edit"
                variant="success"
            >
                Enregistrer l'employeur
            </button-submit>
        </b-col>

    </b-row>

</template>



<script>

    import BusinessBranch from '@core/models/businessBranch';
    import BusinessCompany from '@core/models/businessCompany';
    import BusinessNaf from '@core/models/businessNaf';
    import Form from '@core/mixins/form';

    export default {
        mixins: [Form],
        props: {
            businessBranch: {
                type: BusinessBranch,
                required: true,
            },
            businessBranchEditRoute: {
                type: Object,
                required: true,
            },
            businessBranchOfficeRoute: {
                type: Object,
                required: true,
            },
            businessCompanyEditRoute: {
                type: Object,
                required: true,
            },
            businessCompanyOfficeRoute: {
                type: Object,
                required: true,
            },
            showMissingInformation: {
                type: Boolean,
                default: false,
            },
            value: {
                type: BusinessCompany,
                required: true,
            },
        },
        computed: {
            business_naf_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.businessNaf
                        ? vue.formModifiedModel.$.businessNaf.$.id
                        : undefined
                ),
                set(id) {
                    if (id) {
                        this.formModifiedModel.$.businessNaf = new BusinessNaf({ id });
                    } else {
                        this.formModifiedModel.$.businessNaf = undefined;
                    }
                },
            },
            formInitialModel: {
                get: (vue) => vue.value,
                set(value) {
                    this.$emit('input', value);
                },
            },
        },
        methods: {
            formSubmitSuccessful(updatedBusinessCompany) {
                this.$root.toastSuccess(
                    "Les informations de l'employeur ont été modifiées",
                    "L'enregistrement des informations a réussi.",
                );
                this.formInitialModel = new BusinessCompany(updatedBusinessCompany.$);
            },
        },
    };

</script>
