<template>

    <b-row :id="id">
        <b-col
            v-for="areaKey in areaKeys"
            :key="areaKey"
            class="my-2"
            lg="3"
            sm="6"
        >

            <label class="d-block text-center">
                {{ areaNames[areaKey] }}
            </label>

            <form-group
                :id="`${id}-${areaKey}`"
                v-slot="inputProps"
            >

                <div
                    v-if="pending"
                    class="w-50 mx-auto"
                >
                    <b-skeleton
                        v-for="modeKey in modeKeys"
                        :key="modeKey"
                        class="rounded-0"
                        type="input"
                    />
                </div>

                <b-button
                    v-else-if="disabled"
                    :variant="modeVariants[$props[areaKey]]"
                    class="mx-auto"
                    disabled
                >
                    <font-awesome-icon
                        :icon="['fad', modeIcons[$props[areaKey]]]"
                        fixed-width
                    />
                    {{ modeNames[$props[areaKey]] }}
                </b-button>

                <b-form-radio-group
                    v-else
                    v-bind="inputProps"
                    :button-variant="
                        outline ? `outline-${modeVariants[$props[areaKey]]}` : modeVariants[$props[areaKey]]
                    "
                    :checked="$props[areaKey]"
                    class="mx-auto"
                    buttons
                    required
                    stacked
                    @input="$emit(`update.${areaKey}`, $event)"
                >
                    <b-form-radio
                        v-for="modeKey in modeKeys"
                        :id="`${id}-${areaKey}-${modeKey}`"
                        :key="modeKey"
                        :value="modeKey"
                    >
                        <font-awesome-icon
                            :icon="['fad', modeIcons[modeKey]]"
                            fixed-width
                        />
                        {{ modeNames[modeKey] }}
                    </b-form-radio>
                </b-form-radio-group>

            </form-group>

            <div v-if="pending">

                <label class="d-block text-center text-muted">
                    Autorisations
                </label>

                <b-skeleton
                    class="rounded-0"
                    type="input"
                    variant="info"
                />

                <b-skeleton
                    class="rounded-0"
                    type="input"
                    variant="info"
                />

                <b-skeleton
                    class="rounded-0"
                    type="input"
                    variant="info"
                />

            </div>

            <capability-form-helper
                v-if="className && $props[areaKey]"
                :area="areaKey"
                :jurisdiction-type="className"
                :mode="$props[areaKey]"
                :outline="outline"
            />

        </b-col>
    </b-row>

</template>



<script>

    import Capability from '@core/models/capability';

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            id: {
                type: String,
                required: true,
            },
            className: {
                type: String,
                default: null,
            },
            outline: {
                type: Boolean,
                default: false,
            },
            pending: {
                type: Boolean,
                default: false,
            },
            ...Object.fromEntries(
                Object.entries(Capability.areas).map(([area]) => [area, {
                    validator: (prop) => Object.keys(Capability.modes)
                        .map((mode) => (mode === 'null' ? null : mode))
                        .includes(prop),
                    required: true,
                }]),
            ),
        },
        computed: {
            areaKeys: () => Object.keys(Capability.areas),
            areaNames: () => Capability.areas,
            modeIcons: () => Capability.modeIcons,
            modeKeys: () => Object.keys(Capability.modes).map((mode) => (mode === 'null' ? null : mode)),
            modeNames: () => Capability.modes,
            modeVariants: () => Capability.modeVariants,
        },
    };

</script>
