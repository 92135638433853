<script>

    import Request from '@core/request/index';

    const models = Object.fromEntries(
        Object.entries(import.meta.glob('@core/models/*.js', { eager: true }))
            .map(([path, definition]) => [path.split('/').pop().split('.').shift(), definition.default]),
    );

    export default {
        props: {
            name: {
                type: String,
                required: true,
            },
            requestData: {
                type: Object,
                default: () => ({}),
            },
        },
        computed: {
            model: (vue) => new models[vue.modelIndex](),
            modelIndex: (vue) => vue.name.charAt(0).toLowerCase() + vue.name.slice(1),
            url: (vue) => `${new Request().axiosConfig.baseURL}/${vue.model.url}/csv?${vue.urlSearchParams}`,
            urlSearchParams: (vue) => new URLSearchParams(
                Object.entries(vue.requestData).filter(([, value]) => value !== undefined),
            ),
        },
        render() {
            return this.$scopedSlots.default({
                url: this.url,
            });
        },
    };

</script>
