<template>

    <div v-if="hasErrors">
        <slot
            name="errors"
            :errors="businessApprenticeAgreement.errors"
        />
    </div>

    <b-alert
        v-else-if="isPending !== true && businessApprenticeAgreement.$.funding_start === null"
        variant="info"
        show
    >
        <font-awesome-icon
            :icon="['fad', 'info']"
            fixed-width
        />
        {{ emptyMessage }}
    </b-alert>

    <div v-else>

        <b-table-simple
            class="text-right"
            responsive
            small
            striped
        >

            <b-thead>
                <b-tr>

                    <b-th>
                        Année de financement
                    </b-th>

                    <b-th>
                        Prix de la prestation, net de taxe
                    </b-th>

                    <b-th>
                        Montant du niveau de prise en charge OPCO
                    </b-th>

                    <b-th>
                        Autre financement
                    </b-th>

                    <b-th>
                        Reste à charge employeur, net de taxe
                    </b-th>

                </b-tr>
            </b-thead>

            <b-tbody>

                <b-tr
                    v-for="(month, year) in months"
                    :key="year"
                >

                    <b-td class="align-middle text-nowrap">

                        <template v-if="isPending">
                            <b-skeleton class="w-75 ml-auto" />
                            <b-skeleton
                                class="w-50 ml-auto"
                                variant="light"
                            />
                        </template>

                        <template v-else>
                            {{ year }}<sup>{{ year === 1 ? 'ère' : 'ème' }}</sup> année
                            <div class="text-muted">
                                {{ month }} mois
                            </div>
                        </template>

                    </b-td>

                    <b-td class="align-middle text-nowrap">

                        <b-skeleton v-if="isPending" />

                        <template v-else>
                            {{ educationPrices[year].toFixed(2) }}&nbsp;€
                        </template>

                    </b-td>

                    <b-td class="align-middle text-nowrap">

                        <b-skeleton v-if="isPending" />

                        <template v-else>
                            {{ fundingOpcoPayments[year].toFixed(2) }}&nbsp;€
                        </template>

                    </b-td>

                    <b-td
                        class="align-middle text-nowrap"
                    >

                        <b-skeleton v-if="isPending" />

                        <template v-else>
                            {{ discounts[year].toFixed(2) }}&nbsp;€
                        </template>

                    </b-td>

                    <b-td class="align-middle text-nowrap">

                        <b-skeleton v-if="isPending" />

                        <slot
                            v-else
                            name="remainingBalance"
                            :education-price="educationPrices[year]"
                            :funding-opco-payment="fundingOpcoPayments[year]"
                            :year="year"
                        >
                            {{ remainingBalances[year].toFixed(2) }}&nbsp;€
                        </slot>

                    </b-td>

                </b-tr>

            </b-tbody>

            <b-tfoot>
                <b-tr>

                    <b-td class="align-middle text-nowrap">
                        Total
                    </b-td>

                    <b-td class="align-middle text-nowrap">

                        <b-skeleton v-if="isPending" />

                        <template v-else>
                            {{ total(educationPrices).toFixed(2) }}&nbsp;€
                        </template>

                    </b-td>

                    <b-td class="align-middle text-nowrap">

                        <b-skeleton v-if="isPending" />

                        <template v-else>
                            {{ total(fundingOpcoPayments).toFixed(2) }}&nbsp;€
                        </template>

                    </b-td>

                    <b-td
                        class="align-middle text-nowrap"
                    >

                        <b-skeleton v-if="isPending" />

                        <template v-else>
                            {{ total(discounts).toFixed(2) }}&nbsp;€
                        </template>

                    </b-td>

                    <b-td class="align-middle text-nowrap">

                        <b-skeleton v-if="isPending" />

                        <template v-else>
                            {{ total(remainingBalances).toFixed(2) }}&nbsp;€
                        </template>

                    </b-td>

                </b-tr>
            </b-tfoot>

        </b-table-simple>

        <slot name="footer" />

    </div>

</template>

<script>

    import BusinessApprenticeAgreement from '@core/models/businessApprenticeAgreement';

    export default {
        props: {
            businessApprenticeAgreement: {
                type: BusinessApprenticeAgreement,
                default: null,
            },
            emptyMessage: {
                type: String,
                required: true,
            },
            parentIsPending: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            discounts: (vue) => Object.fromEntries(
                Object.entries(vue.months).map(([year]) => [
                    year,
                    Math.max(
                        vue.educationPrices[year] - vue.fundingOpcoPayments[year] - vue.remainingBalances[year],
                        0,
                    ),
                ]),
            ),
            educationPrices: (vue) => Object.fromEntries(
                Object.entries(vue.months).map(([year]) => [
                    year,
                    (vue.businessApprenticeAgreement.$.education_prices || {})[year] || 0,
                ]),
            ),
            fundingOpcoPayments: (vue) => Object.fromEntries(
                Object.entries(vue.months).map(([year]) => [
                    year,
                    (vue.businessApprenticeAgreement.$.funding_opco_payments || {})[year] || 0,
                ]),
            ),
            hasErrors: (vue) => Object.keys(vue.businessApprenticeAgreement.errors).length > 0,
            isPending: (vue) => vue.businessApprenticeAgreement === null || vue.parentIsPending,
            months: (vue) => vue.businessApprenticeAgreement.$.funding_months || { 1: 12, 2: 12, 3: 12 },
            remainingBalances: (vue) => Object.fromEntries(
                Object.entries(vue.months).map(([year]) => [
                    year,
                    (vue.businessApprenticeAgreement.$.remaining_balances || {})[year] || 0,
                ]),
            ),
        },
        methods: {
            total: (object) => (object ? Object.values(object).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) : 0),
        },
    };

</script>
