<template>

    <div>

        <b-list-group flush>
            <b-list-group-item
                class="font-weight-bold"
                button
                @click="$emit('cancel')"
            >
                <font-awesome-icon
                    :icon="['fad', 'arrow-left']"
                    fixed-width
                />
                Parcours
            </b-list-group-item>
        </b-list-group>

        <model-list
            v-slot="{ collection, options, setValue, value: localSchoolId }"
            v-model="schoolId"
            name="School"
        >
            <form-group
                id="jurisdiction-educationStudy-selector-school"
                v-slot="inputProps"
                :left-icon="collection.isPending ? 'circle-notch' : 'school'"
                :left-icon-spin="collection.isPending"
                class="px-3 small"
                label="Choisir une antenne"
                required
            >
                <b-form-select
                    v-bind="inputProps"
                    :options="options"
                    :value="localSchoolId"
                    size="sm"
                    @input="setValue"
                />
            </form-group>
        </model-list>

        <model-list
            v-if="schoolId"
            v-slot="{ collection, options, setValue, value: selectedYear }"
            v-model="year"
            :option-text="(educationProgrammeYear) => educationProgrammeYear.$"
            :option-value="(educationProgrammeYear) => educationProgrammeYear.$"
            :request-data="{ school_id: schoolId }"
            name="EducationProgrammeYear"
        >

            <b-list-group
                v-if="collection.isEmpty"
                flush
            >
                <b-list-group-item
                    class="text-center"
                    variant="warning"
                >
                    <font-awesome-icon
                        :icon="['fad', 'empty-set']"
                        class="small"
                        fixed-width
                    />
                    Cette antenne ne possède aucune formation.
                </b-list-group-item>
            </b-list-group>

            <form-group
                v-else
                id="jurisdiction-educationStudy-selector-start_year"
                v-slot="inputProps"
                class="px-3 small"
                label="Choisir une année de formation"
                required
            >
                <b-form-radio-group
                    v-bind="inputProps"
                    :options="options"
                    :value="selectedYear"
                    size="sm"
                    @input="setValue"
                />
            </form-group>

        </model-list>

        <model-list
            v-if="year"
            v-slot="{ collection, options, setValue, value: localEducationProgrammeId }"
            :key="year"
            v-model="educationProgrammeId"
            :option-text="(educationProgramme) => educationProgramme.nameAndYear"
            :request-data="{ school_id: schoolId, start_year: year }"
            name="EducationProgramme"
        >

            <b-list-group
                v-if="collection.isEmpty"
                flush
            >
                <b-list-group-item
                    class="text-center"
                    variant="warning"
                >
                    <font-awesome-icon
                        :icon="['fad', 'empty-set']"
                        class="small"
                        fixed-width
                    />
                    Cette antenne ne possède aucune formation pour l'année scolaire <b>{{ year }}</b>.
                </b-list-group-item>
            </b-list-group>

            <form-group
                v-else
                id="jurisdiction-educationStudy-selector-educationProgramme"
                v-slot="inputProps"
                :key="year + schoolId"
                :left-icon="collection.isPending ? 'circle-notch' : 'file-certificate'"
                :left-icon-spin="collection.isPending"
                class="px-3 small"
                label="Choisir une formation"
                required
            >
                <b-form-select
                    v-bind="inputProps"
                    :options="options"
                    :value="localEducationProgrammeId"
                    size="sm"
                    @input="setValue"
                />
            </form-group>

        </model-list>

        <model-list
            v-if="educationProgrammeId"
            v-slot="{ collection, setValue }"
            :prefill="5"
            :request-data="{ ...requestData, education_programme_id: educationProgrammeId }"
            name="EducationStudy"
            @change="$emit('change', $event)"
        >
            <div>

                <label
                    v-if="collection.isEmpty === false"
                    class="px-3 small"
                >
                    Choisir un parcours
                </label>

                <b-list-group flush>

                    <b-list-group-item
                        v-if="collection.isEmpty"
                        class="text-center"
                        variant="warning"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'empty-set']"
                            class="small"
                            fixed-width
                        />
                        Cette formation ne possède aucun parcours auquel un rôle peut être attaché.
                    </b-list-group-item>

                    <b-list-group-item
                        v-for="educationStudy in collection.models"
                        :key="educationStudy.$.id"
                        :disabled="collection.isPending"
                        button
                        @click="setValue(educationStudy.$.id)"
                    >

                        <template v-if="collection.isPending">
                            <b-skeleton class="w-100" />
                            <b-skeleton class="w-75" />
                        </template>

                        <template v-else>
                            <font-awesome-icon
                                :icon="['fad', 'file-certificate']"
                                class="mr-1"
                                fixed-width
                            />
                            {{ educationStudy.nameAndYear }}
                        </template>

                    </b-list-group-item>

                </b-list-group>

            </div>
        </model-list>

    </div>

</template>



<script>

    export default {
        props: {
            requestData: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            educationProgrammeId: null,
            schoolId: null,
            year: 0,
        }),
        watch: {
            schoolId() {
                this.educationProgrammeId = null;
                this.year = 0;
            },
            year() {
                this.educationProgrammeId = null;
            },
        },
    };

</script>
