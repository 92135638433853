<template>

    <div
        v-if="isPending"
        class="mx-2 text-center"
        style="width: 40px"
    >

        <b-skeleton-img
            class="mx-auto"
            height="30px"
            width="30px"
        />

        <b-badge>
            <font-awesome-icon
                :icon="['fad', 'circle-notch']"
                fixed-width
                spin
            />
        </b-badge>

    </div>

    <router-link
        v-else
        :to="to"
        class="mx-2 text-center"
        style="width: 40px"
    >

        <upload-avatar
            v-b-tooltip.noninteractive
            :title="questionnaireEntry.$.questionnaireSurvey.$.name"
            :upload="questionnaireEntry.$.questionnaireSurvey.$.questionnaireCategory.$.logo"
            height="30px"
            icon="folder-open"
            width="30px"
        />

        <b-badge
            v-b-tooltip.noninteractive
            :title="[action, questionnaireEntry.dateText].filter((title) => title).join(' ')"
            :variant="variant"
            class="w-100 px-0"
        >

            <font-awesome-icon
                v-if="questionnaireEntry.isOpen !== true"
                :icon="['fad', questionnaireEntry.icon()]"
                :spin="isPending"
                fixed-width
            />

            <template v-else>
                {{ questionnaireEntry.$.questionnaireValidation.$.validation_rate }} %
            </template>

        </b-badge>

    </router-link>

</template>



<script>

    import QuestionnaireEntry from '@core/models/questionnaireEntry';

    export default {
        props: {
            isPending: {
                type: Boolean,
                default: false,
            },
            questionnaireEntry: {
                type: QuestionnaireEntry,
                required: true,
            },
            to: {
                type: Object,
                required: true,
            },
        },
        computed: {
            action: (vue) => vue.questionnaireEntry.action(
                vue.questionnaireEntry.$.questionnaireSurvey.$[`access_${vue.questionnaireEntry.$.user_role}`],
                vue.questionnaireEntry.$.questionnaireValidation.$[
                    `${vue.questionnaireEntry.$.user_role}_validated_at`
                ],
            ),
            variant: (vue) => vue.questionnaireEntry.variant(
                vue.questionnaireEntry.$.questionnaireSurvey.$[`access_${vue.questionnaireEntry.$.user_role}`],
                vue.questionnaireEntry.$.questionnaireValidation.$[
                    `${vue.questionnaireEntry.$.user_role}_validated_at`
                ],
            ),
        },
    };

</script>
