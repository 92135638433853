export default {
    watch: {
        pageTitle: {
            handler(pageTitle) {
                if (pageTitle !== undefined) {
                    document.title = `${pageTitle} — ${this.$store.getters['center/platformName']}`;
                }
            },
            immediate: true,
        },
    },
};
