import Axios from 'axios';
import ErrorHandlers from '@core/request/errors';
import Model from '@core/models/_model';
import qs from 'qs';

const transformRequest = (data, headers, formData = new FormData(), rootKey = undefined) => {
    Object.entries(data || {})
        .filter((entry) => entry[1] !== undefined)
        .filter((entry) => entry[1] instanceof Model === false)
        .map(([key, value]) => (value === true ? [key, '1'] : [key, value]))
        .map(([key, value]) => (value === false ? [key, '0'] : [key, value]))
        .map(([key, value]) => (value === null ? [key, ''] : [key, value]))
        .filter(([key, value]) => {
            if (value instanceof File) {
                return true;
            }
            if (typeof value === 'object') {
                transformRequest(value, headers, formData, rootKey ? `${rootKey}[${key}]` : key);
            }
            return typeof value !== 'object';
        })
        .forEach(([key, value]) => formData.append(rootKey ? `${rootKey}[${key}]` : key, value));
    return formData;
};

export default class Request {

    /* eslint-disable-next-line class-methods-use-this */
    get axiosConfig() {
        return {
            baseURL: '/api',
            headers: {
                common: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
            },
            paramsSerializer: {
                serialize: qs.stringify,
            },
            transformRequest,
            withCredentials: true,
        };
    }

    constructor() {
        this.cancelSource = Axios.CancelToken.source();
        this.isPending = false;
        this.isSilent = false;
        this.axios = Axios.create(this.axiosConfig);
        this.axios.interceptors.request.use((config) => this.interceptRequest(config));
        this.axios.interceptors.response.use(
            (response) => this.interceptSuccessfulResponse(response),
            (error) => this.interceptFailedResponse(error),
        );
    }

    cancel() {
        this.cancelSource.cancel();
    }

    interceptFailedResponse(error) {
        this.isPending = false;
        if (Axios.isCancel(error)) {
            return Promise.reject(error);
        }
        if (this.isSilent) {
            return Promise.reject(error);
        }
        if (error.response && ErrorHandlers[error.response.status]) {
            ErrorHandlers[error.response.status](error.response);
        } else if (error.response) {
            ErrorHandlers[500]();
        } else {
            ErrorHandlers[400]();
        }
        return Promise.reject(error);
    }

    interceptRequest(config) {
        this.isPending = true;
        return config;
    }

    interceptSuccessfulResponse(response) {
        if (response.headers['asset-version']) {
            if (response.headers['asset-version'] !== document.querySelector('meta[name=asset-version]').content) {
                window.app.toastAssetVersion();
            }
        }
        this.isPending = false;
        return response;
    }

    silent() {
        this.isSilent = true;
        return this;
    }

}
