<template>

    <div>

        <div class="d-flex justify-content-between">

            <b-btn-group>

                <slot name="button-left" />

                <b-button
                    :size="size"
                    :variant="show ? 'secondary' : 'outline-danger'"
                    @click="show = !show"
                >
                    <font-awesome-icon
                        :icon="['fad', show ? 'times' : 'trash']"
                        fixed-width
                    />
                    {{ show ? 'Annuler la suppression' : label }}
                </b-button>

                <slot name="button-right" />

            </b-btn-group>

            <slot />

        </div>

        <div
            v-if="$scopedSlots.left || $scopedSlots.right"
            class="d-flex justify-content-center"
        >
            <b-alert
                ref="alert"
                :show="show"
                class="mt-3"
                variant="danger"
            >

                <div class="d-flex mb-3">

                    <div
                        v-if="$scopedSlots.left"
                        class="pr-3"
                    >
                        <slot name="left" />
                    </div>

                    <div
                        v-if="$scopedSlots.right"
                        class="pl-3"
                    >
                        <slot name="right" />
                    </div>

                </div>

                <div class="text-center">
                    <button-submit
                        :is-pending="isPending"
                        :size="size"
                        class="text-danger"
                        icon="trash"
                        type="button"
                        variant="light"
                        @click.native="$emit('submit')"
                    >
                        Confirmer la suppression
                    </button-submit>
                </div>

            </b-alert>
        </div>

        <button-submit
            v-else-if="show"
            :is-pending="isPending"
            :size="size"
            class="mt-3"
            icon="trash"
            type="button"
            variant="danger"
            @click.native="$emit('submit')"
        >
            Confirmer la suppression
        </button-submit>

    </div>

</template>



<script>

    export default {
        props: {
            isPending: {
                type: Boolean,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            size: {
                type: String,
                default: null,
            },
        },
        data: () => ({
            show: false,
        }),
        watch: {
            show(show) {
                if (show) {
                    this.$nextTick(() => {
                        if (this.$refs.alert) {
                            this.$refs.alert.$el.parentNode.scrollIntoView({ behavior: 'smooth', block: 'end' });
                        }
                    });
                }
            },
        },
    };

</script>
