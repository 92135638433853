import EducationStudy from '@core/models/educationStudy';
import RestModel from '@core/models/_rest';
import Upload from '@core/models/upload';

export default class EducationClass extends RestModel {

    static get className() {
        return 'Modules\\Education\\Models\\EducationClass';
    }

    static get rhythmTypes() {
        return {
            '': '',
            '2d3d': '2 jours / 3 jours',
            '2w2w': '2 semaines / 2 semaines',
            '1w3w': '1 semaine / 3 semaines',
            other: 'Autre',
        };
    }

    get url() {
        return this.$.id ? `educationClasses/${this.$.id}` : 'educationClasses';
    }

    fill({
        apprenticeCalendar = undefined,
        educationStudy = {},
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            apprenticeCalendar: apprenticeCalendar ? new Upload(apprenticeCalendar.$ || apprenticeCalendar) : undefined,
            educationStudy: new EducationStudy(educationStudy.$ || educationStudy),
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            apprenticeCalendar: this.$.apprenticeCalendar instanceof File ? this.$.apprenticeCalendar : undefined,
            education_study_id: this.$.educationStudy.$.id,
        });
    }

}
