import QuestionnaireLock from '@core/models/questionnaireLock';
import RestModel from '@core/models/_rest';

const formDataOptions = (options) => {
    if (Array.isArray(options)) {
        return options.filter((option) => option !== '');
    }
    if (options) {
        return Object.entries(options)
            .filter(([value, text]) => text !== '' && value !== '')
            .reduce((object, [value, text]) => Object.assign(object, { [value]: text }), {});
    }
    return undefined;
};

export default class QuestionnaireField extends RestModel {

    get url() {
        return this.$.id ? `questionnaireFields/${this.$.id}` : 'questionnaireFields';
    }

    fill({
        attributes = {},
        questionnaireLock = {},
        ...otherAttributes
    }) {
        return super.fill({
            ...otherAttributes,
            attributes: JSON.parse(JSON.stringify(attributes)),
            questionnaireLock: new QuestionnaireLock(questionnaireLock.$ || questionnaireLock),
        });
    }

    formData(otherAttributes = {}) {
        return super.formData({
            ...otherAttributes,
            attributes: {
                ...this.$.attributes,
                options: formDataOptions(this.$.attributes.options),
            },
        });
    }

}
