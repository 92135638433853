import RestModel from '@core/models/_rest';
import Upload from '@core/models/upload';

export default class SignatureApplication extends RestModel {

    get url() {
        return this.$.id ? `signatureApplications/${this.$.id}` : 'signatureApplications';
    }

    static get statusIcons() {
        return {
            created: 'info',
            failed: 'times',
            rejected: 'exclamation',
            signed: 'check',
        };
    }

    static get statusNames() {
        return {
            created: "Le processus de signature est en cours : les documents attendent d'être signés.",
            failed: 'Le processus de signature a été interrompu par une erreur technique : '
                + 'consultez le journal des dernières requêtes ci-dessous pour plus de détails',
            rejected: "L'un des signataire a refusé de signer le document : "
                + "rendez-vous dans l'onglet Annulation pour relancer le processus.",
            signed: 'Le document a été signé par tous les signataires : '
                + "téléchargez ci-dessous les documents signés ainsi que l'attestation de signature.",
        };
    }

    static get statusVariants() {
        return {
            created: 'info',
            failed: 'danger',
            rejected: 'warning',
            signed: 'success',
        };
    }

    fill({
        certificate = undefined,
        participants = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            certificate: certificate ? new Upload(certificate.$ || certificate) : undefined,
            participants: participants
                ? Object.fromEntries(Object.entries(participants).map(([key, value]) => [key, { ...value }]))
                : undefined,
        });
    }

}
