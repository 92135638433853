import BusinessApprenticeAgreement from '@core/models/businessApprenticeAgreement';

export default class BusinessApprenticeLegalAgreement extends BusinessApprenticeAgreement {

    get url() {
        return `businessApprenticeAgreements/${this.$.id}?${new URLSearchParams({
            generatable: 'Modules\\Business\\Models\\BusinessApprenticeLegalAgreement',
        })}`;
    }

}
