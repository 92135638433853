import Vue from 'vue';
import * as Sentry from '@sentry/vue';

export default () => {
    Sentry.init({
        Vue,
        attachProps: true,
        dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
        ignoreErrors: [
            /^canceled$/,
            'Avoided redundant navigation to current location',
            'Redirected when going from',
        ],
        integrations: [
            // @error "vueRouterInstrumentation" is not exported by "node_modules/@sentry/vue/esm/index.js"
            // Sentry.browserTracingIntegration({
            //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            // }),
        ],
        logErrors: true,
        replaysOnErrorSampleRate: 1,
        replaysSessionSampleRate: 0,
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
        trackComponents: true,
    });
};
