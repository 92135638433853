import RestModel from '@core/models/_rest';

export default class EducationDiploma extends RestModel {

    get longName() {
        return `${this.$.name} - ${this.$.reference} - ${this.$.years} an${this.$.years > 1 ? 's' : ''}`;
    }

    get url() {
        return this.$.id ? `educationDiplomas/${this.$.id}` : 'educationDiplomas';
    }

}
