<script>

    import BusinessApprenticeContract from '@core/models/businessApprenticeContract';
    import Collection from '@core/models/collection';

    export default {
        props: {
            businessApprenticeContract: {
                type: BusinessApprenticeContract,
                required: true,
            },
            businessApprenticeContractEditRoute: {
                type: Object,
                default: undefined,
            },
            businessApprenticeContractPurposeRoute: {
                type: Object,
                default: undefined,
            },
            businessApprenticeMentorships: {
                type: Collection,
                required: true,
            },
            businessApprenticeMentorshipRoute: {
                type: Object,
                default: undefined,
            },
            executingBusinessBranchEditRoute: {
                type: Object,
                default: undefined,
            },
            learnerPersonEditRoute: {
                type: Object,
                default: undefined,
            },
            signatoryBusinessBranchEditRoute: {
                type: Object,
                default: undefined,
            },
        },
        computed: {
            legends: () => ({
                warning: 'Information(s) manquante(s)',
                success: 'Information complètes',
            }),
            businessApprenticeContractCounter: (vue) => (
                vue.businessApprenticeContract.$.missing_information
                    ? vue.businessApprenticeContract.$.missing_information.filter(
                        (field) => field !== 'locked_questionnaire_validation_purpose'
                            && field !== 'validated_questionnaire_validation_purpose',
                    ).length
                    : 0
            ),
            businessApprenticeContractPurposeCounter: (vue) => (
                vue.businessApprenticeContract.$.missing_information
                    ? vue.businessApprenticeContract.$.missing_information.filter(
                        (field) => field === 'locked_questionnaire_validation_purpose'
                            || field === 'validated_questionnaire_validation_purpose',
                    ).length
                    : 0
            ),
            businessApprenticeMentorshipsCounter: (vue) => {
                if (vue.businessApprenticeMentorships.models.filter((model) => model.$.official).length === 0) {
                    return 1;
                }
                return vue.businessApprenticeMentorships.models
                    .map((businessApprenticeMentorship) => businessApprenticeMentorship.$.businessPerson)
                    .reduce(
                        (total, businessPerson) => total + (
                            businessPerson.$.missing_information ? businessPerson.$.missing_information.length : 0
                        ),
                        0,
                    );
            },
            executingBusinessBranchCounter: (vue) => (
                vue.businessApprenticeContract.$.executingBusinessBranch.$.missing_information
                    ? vue.businessApprenticeContract.$.executingBusinessBranch.$.missing_information.length
                    : 0
            ),
            learnerPersonCounter: (vue) => (
                vue.businessApprenticeContract.$.learnerPerson.$.missing_information
                    ? vue.businessApprenticeContract.$.learnerPerson.$.missing_information.length
                    : 0
            ),
            signatoryBusinessBranchCounter: (vue) => (
                vue.businessApprenticeContract.$.signatoryBusinessBranch.$.missing_information
                    ? vue.businessApprenticeContract.$.signatoryBusinessBranch.$.missing_information.length
                    : 0
            ),
            statuses: (vue) => [
                {
                    icon: 'user-graduate',
                    route: vue.learnerPersonEditRoute,
                    title: 'Apprenant',
                    counter: vue.learnerPersonCounter,
                    visible: true,
                },
                {
                    icon: 'file-signature',
                    route: vue.businessApprenticeContractPurposeRoute,
                    title: 'Missions',
                    counter: vue.businessApprenticeContractPurposeCounter,
                    visible: true,
                },
                {
                    icon: 'industry-alt',
                    route: vue.signatoryBusinessBranchEditRoute,
                    title: 'Employeur',
                    counter: vue.signatoryBusinessBranchCounter,
                    visible: true,
                },
                {
                    icon: 'industry-alt',
                    route: vue.executingBusinessBranchEditRoute,
                    title: "Établissement d'exécution",
                    counter: vue.executingBusinessBranchCounter,
                    visible: vue.businessApprenticeContract.$.signatoryBusinessBranch.is(
                        vue.businessApprenticeContract.$.executingBusinessBranch,
                    ) === false,
                },
                {
                    icon: 'file-contract',
                    route: vue.businessApprenticeContractEditRoute,
                    title: 'Informations contrat',
                    counter: vue.businessApprenticeContractCounter,
                    visible: true,
                },
                {
                    icon: 'user-hard-hat',
                    route: vue.businessApprenticeMentorshipRoute,
                    title: "Maîtres d'apprentissage",
                    counter: vue.businessApprenticeMentorshipsCounter,
                    visible: true,
                },
            ],
        },
        render() {
            return this.$scopedSlots.default({
                statuses: this.statuses
                    .filter(({ route, visible }) => route && visible)
                    .map((status) => ({
                        legend: this.legends[status.variant],
                        variant: status.counter ? 'warning' : 'success',
                        ...status,
                    })),
            });
        },
    };

</script>
