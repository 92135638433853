<template>

    <file-form-group
        :id="`questionnaireField-${questionnaireField.$.id}`"
        v-model="content"
        :class="{ 'no-input': upload && disabled }"
        :disabled="disabled || value.isPending"
        :error="value.errors.content"
        :label="questionnaireField.$.name"
        :required="questionnaireField.$.mandatory"
        :state="value.states.content"
        left-icon="upload"
        max="50 Mo"
        label-class="pb-0"
    >

        <template #top>

            <div class="small text-muted pb-2">
                <div v-if="questionnaireField.$.description">
                    {{ questionnaireField.$.description }}.
                </div>
            </div>

            <questionnaire-element-show-upload
                v-if="upload"
                :questionnaire-element="fakeQuestionnaireElement"
            />

        </template>

        <template #description>

            <slot name="description" />

            <div
                v-if="questionnaireField.$.description"
                class="mt-1"
            >
                Fichiers acceptés : {{ questionnaireField.$.attributes.extensions.join(', ') }}.<br>
            </div>

        </template>

    </file-form-group>

</template>



<script>

    import QuestionnaireAnswer from '@core/models/questionnaireAnswer';
    import QuestionnaireField from '@core/models/questionnaireField';
    import QuestionnaireElement from '@core/models/questionnaireElement';
    import Upload from '@core/models/upload';

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            initialQuestionnaireAnswer: {
                type: QuestionnaireAnswer,
                default: undefined,
            },
            questionnaireField: {
                type: QuestionnaireField,
                required: true,
            },
            value: {
                type: QuestionnaireAnswer,
                required: true,
            },
        },
        computed: {
            content: {
                get: (vue) => (
                    (vue.value.$.content instanceof File || vue.value.$.content instanceof Upload)
                        ? vue.value.$.content
                        : null
                ),
                set(content) {
                    if (content !== this.content) {
                        this.$emit('input', new QuestionnaireAnswer({ ...this.value.$, content }));
                    }
                },
            },
            fakeQuestionnaireElement: (vue) => new QuestionnaireElement({
                id: `questionnaire-field-show-upload-${vue.questionnaireField.$.id}`,
                typeUpload: vue.upload,
            }),
            upload: (vue) => (vue.value.$.content instanceof Upload ? vue.value.$.content : null),
        },
    };

</script>


<style scoped>

    .no-input :deep(.form-control-plaintext) {
        display: none;
    }

</style>
