<template>

    <b-navbar-nav
        v-if="scheduled"
        id="navbar-maintenance"
    >
        <b-nav-item-dropdown
            class="d-none d-md-block"
            no-caret
            right
        >

            <template slot="button-content">
                <font-awesome-icon
                    :icon="['fad', 'tools']"
                    fixed-width
                />
                <span :class="`d-${toggleable}-none`">
                    Maintenance
                </span>
            </template>

            <b-dropdown-text text-class="text-center small">

                <font-awesome-icon
                    :icon="['fad', 'tools']"
                    fixed-width
                    class="mb-2 text-warning"
                    size="2x"
                />

                <div>
                    La plateforme sera fermée<br>
                    pour maintenance<br>
                    du <b>{{ dayjsLongDatetime(start) }}</b><br>
                    au <b>{{ dayjsLongDatetime(end) }}</b>.
                </div>

            </b-dropdown-text>

        </b-nav-item-dropdown>
    </b-navbar-nav>

</template>



<script>

    import Dayjs from '@core/mixins/dayjs';

    export default {
        mixins: [Dayjs],
        props: {
            toggleable: {
                type: String,
                required: true,
            },
        },
        computed: {
            scheduled: (vue) => vue.$store.getters['maintenance/scheduled'],
            start: (vue) => vue.$store.getters['maintenance/start'],
            end: (vue) => vue.$store.getters['maintenance/end'],
        },
    };

</script>



<style scoped>

    .navbar-nav :deep(.dropdown-menu) {
        min-width: 15rem;
    }

</style>
