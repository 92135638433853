<template>

    <div>

        <b-alert
            v-if="questionnaireComments.isEmpty"
            class="text-center"
            variant="warning"
            show
        >
            <font-awesome-icon
                :icon="['fad', 'empty-set']"
                fixed-width
            />
            Ce formulaire ne possède pas encore de commentaire.
        </b-alert>

        <b-card
            :header="
                questionnaireEntry.$.questionnaire_comment_count
                    + ` commentaire${questionnaireEntry.$.questionnaire_comment_count > 1 ? 's' : '' }`
            "
            header-bg-variant="primary"
            header-class="mb-0 pb-2"
            header-tag="h2"
            header-text-variant="light"
            class="d-none d-print-block mt-4 mb-3"
            no-body
        />

        <b-card
            v-for="questionnaireComment in questionnaireComments.models"
            :key="questionnaireComment.$.id"
            bg-variant="light"
            class="mb-3 page-break-avoid"
            no-body
        >

            <b-card-header
                header-tag="h3"
                class="d-flex mb-3"
            >

                <b-skeleton-img
                    v-if="questionnaireEntry.isPending"
                    height="50px"
                    width="50px"
                    no-aspect
                />

                <upload-avatar
                    v-else
                    :upload="questionnaireComment.$.user ? questionnaireComment.$.user.$.avatar : null"
                    icon="user"
                    width="50px"
                    rounded
                />

                <div class="ml-3 flex-fill">

                    <b-skeleton
                        v-if="questionnaireEntry.isPending"
                        class="w-75"
                        variant="dark"
                    />

                    <div v-else-if="questionnaireComment.$.user">
                        {{ questionnaireComment.$.user.$.fullname }}
                    </div>

                    <font-awesome-icon
                        v-else
                        :icon="['fad', 'question']"
                        fixed-width
                    />

                    <b-skeleton
                        v-if="questionnaireEntry.isPending"
                        class="w-50"
                    />

                    <small v-else>
                        {{ dayjsLongDate(questionnaireComment.$.updated_at) }}
                    </small>

                </div>

            </b-card-header>

            <b-card-body class="border-top">

                <p v-if="questionnaireEntry.isPending">
                    <b-skeleton class="w-100" />
                    <b-skeleton class="w-75" />
                    <b-skeleton class="w-50" />
                    <b-skeleton class="w-25" />
                </p>

                <p
                    v-else
                    :class="{ 'mb-xl-0': recipientsLabels(questionnaireComment).length === 0 }"
                >
                    <template v-for="(line, index) in questionnaireComment.$.content.split('\n')">
                        {{ line }}<br :key="index">
                    </template>
                </p>

                <b-skeleton
                    v-if="questionnaireEntry.isPending"
                    class="w-50 ml-auto mb-3 mb-xl-0"
                />

                <div
                    v-else-if="recipientsLabels(questionnaireComment).length > 0"
                    class="d-print-none mb-3 mb-xl-0 text-right"
                >
                    <b-badge
                        class="text-wrap"
                        variant="light"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'paper-plane']"
                            fixed-width
                        />
                        Une notification a été envoyée pour
                        {{ recipientsLabels(questionnaireComment).join(' et ') }}.
                    </b-badge>
                </div>

            </b-card-body>

        </b-card>

    </div>

</template>



<script>

    import Dayjs from '@core/mixins/dayjs';
    import Collection from '@core/models/collection';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';

    export default {
        mixins: [Dayjs],
        props: {
            questionnaireEntry: {
                type: QuestionnaireEntry,
                required: true,
            },
            questionnaireComments: {
                type: Collection,
                required: true,
            },
        },
        methods: {
            recipientsLabels(questionnaireComment) {
                return questionnaireComment
                    .$.recipients
                    .map(
                        (recipient) => this.questionnaireEntry.$.questionnaireSurvey.questionnaireCommentRecipients[
                            recipient
                        ],
                    )
                    .filter((recipient) => recipient);
            },
        },
    };

</script>
