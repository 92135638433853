import RestModel from '@core/models/_rest';
import User from '@core/models/user';

export default class QuestionnaireComment extends RestModel {

    get url() {
        return this.$.id ? `questionnaireComments/${this.$.id}` : 'questionnaireComments';
    }

    fill({
        user = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            user: user ? new User(user.$ || user) : undefined,
        });
    }

}
