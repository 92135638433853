<template>

    <button-delete
        v-if="user.$.deletable"
        label="Supprimer le compte"
        :is-pending="user.isPending || parentFormIsPending"
        @submit="submit"
    >

        <slot />

        <div slot="left">

            <b>
                Seront effacés :
            </b>

            <ul class="mb-0">
                <li>l'adresse e-mail et le mot de passe.</li>
                <li>le nom, le prénom et la photo.</li>
                <li>les rôles.</li>
            </ul>

        </div>

        <div slot="right">

            <b>
                Seront conservés :
            </b>

            <ul class="mb-0">
                <li>les profils.</li>
                <li>le contact entreprise.</li>
                <li>l'historique anonymisé des actions.</li>
            </ul>

        </div>

    </button-delete>

    <div
        v-else
        class="d-flex justify-content-end"
    >
        <slot />
    </div>

</template>



<script>

    import User from '@core/models/user';

    export default {
        props: {
            parentFormIsPending: {
                type: Boolean,
                default: false,
            },
            user: {
                type: User,
                required: true,
            },
        },
        methods: {
            submit() {
                this.user.request().delete().then(this.submitSuccessful).catch(() => {});
            },
            submitSuccessful() {
                this.$root.toastSuccess(
                    'Le compte a été supprimé',
                    `${this.user.$.fullname} sera informé par e-mail de la suppression de son compte.`,
                );
                this.$router.push({ name: 'user-index' });
            },
        },
    };

</script>
