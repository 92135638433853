<template>

    <b-card
        :header="header"
        header-tag="h2"
        tag="form"
        @submit.prevent="formSubmit"
    >

        <business-branch-missing-information-alert
            v-if="showMissingInformation"
            :business-branch="formInitialModel"
            :business-branch-edit-route="businessBranchEditRoute"
            :business-branch-office-route="businessBranchOfficeRoute"
            :business-company-edit-route="businessCompanyEditRoute"
            :business-company-office-route="businessCompanyOfficeRoute"
            class="mb-4"
            success-title="Le profil de l'établissement est complet."
            warning-title="Les informations suivantes manquent au profil de l'établissement :"
        />

        <b-alert
            :show="formInitialModel.$.data_source === 'opendatasoft'"
            variant="info"
        >
            <font-awesome-icon
                :icon="['fad', 'info']"
                fixed-width
            />
            Cet établissement est issu du registre officiel de l'INSEE :
            certaines informations ne sont pas modifiables.
        </b-alert>

        <b-alert
            :show="formInitialModel.$.data_source === 'unknown'"
            variant="warning"
        >
            <font-awesome-icon
                :icon="['fad', 'exclamation']"
                fixed-width
            />
            Cet établissement n'est pas référencé dans le registre officiel de l'INSEE.
        </b-alert>

        <form-group
            id="view-businessBranch-edit-business-company-id"
            v-slot="inputProps"
            :left-icon="formInitialModel.$.updatable ? 'industry-alt' : undefined"
            :pending="formInitialModel.isPending"
            label="Employeur"
            business-apprentice-agreement
        >
            <b-form-input
                v-model="formModifiedModel.$.businessCompany.$.name"
                v-bind="inputProps"
                :plaintext="formInitialModel.$.updatable !== true"
                :readonly="formInitialModel.editableAttribues.includes('company_id') === false"
            />
        </form-group>

        <form-group
            id="view-businessBranch-edit-siret"
            v-slot="inputProps"
            :left-icon="formInitialModel.$.updatable ? 'barcode' : undefined"
            :pending="formInitialModel.isPending"
            label="Numéro de SIRET"
            business-apprentice-agreement
        >
            <b-form-input
                v-model="formModifiedModel.$.siret"
                v-bind="inputProps"
                :plaintext="formInitialModel.$.updatable !== true"
                :readonly="formInitialModel.editableAttribues.includes('siret') === false"
            />
        </form-group>

        <model-list
            v-slot="{ collection, model, options, setValue, value: businessNafId }"
            v-model="business_naf_id"
            :option-text="(businessNaf) => `${businessNaf.$.code} - ${businessNaf.$.name}`"
            name="BusinessNaf"
        >
            <form-group
                id="view-businessBranch-edit-business-naf-id"
                v-slot="inputProps"
                :description="
                    formInitialModel.editableAttribues.includes('business_naf_id')
                        ? undefined
                        : 'Cette information est issue du registre officiel de l\'INSEE : elle n\'est pas modifiable.'
                "
                :error="formModifiedModel.errors.business_naf_id"
                :left-icon="formInitialModel.$.updatable ? (collection.isPending ? 'circle-notch' : 'tag') : undefined"
                :left-icon-spin="collection.isPending"
                :pending="formInitialModel.isPending"
                :state="formModifiedModel.states.business_naf_id"
                label="Code NAF"
                business-apprentice-agreement
            >
                <b-form-select
                    v-if="
                        formInitialModel.$.updatable && formInitialModel.editableAttribues.includes('business_naf_id')
                    "
                    v-bind="inputProps"
                    :options="options"
                    :value="businessNafId"
                    @input="setValue"
                />
                <b-form-input
                    v-else
                    v-bind="inputProps"
                    :plaintext="formInitialModel.$.updatable !== true"
                    :readonly="formInitialModel.editableAttribues.includes('siren') === false"
                    :value="model ? `${model.$.code} - ${model.$.name}` : undefined"
                />
            </form-group>
        </model-list>

        <model-list
            v-slot="{ collection, options }"
            :option-text="(businessIdcc) => `${businessIdcc.$.code} - ${businessIdcc.$.name}`"
            :request-sort="{ code: 'asc' }"
            name="BusinessIdcc"
        >
            <div :key="formModifiedModel.$.businessIdccs.length">

                <form-group
                    v-for="(businessIdcc, index) in formModifiedModel.$.businessIdccs"
                    :id="`view-businessBranch-edit-business-idcc-ids-${index}`"
                    :key="index"
                    :left-icon="
                        formInitialModel.$.updatable ? (collection.isPending ? 'circle-notch' : 'tag') : undefined
                    "
                    :left-icon-spin="collection.isPending"
                    :pending="formInitialModel.isPending"
                    :label="index === 0 ? 'Code IDCC' : undefined"
                    business-apprentice-agreement
                >

                    <template #default="inputProps">
                        <b-form-select
                            v-if="
                                formInitialModel.$.updatable
                                    && formInitialModel.editableAttribues.includes('business_idcc_ids')
                            "
                            v-bind="inputProps"
                            :options="options"
                            :value="businessIdcc.$.id"
                            @input="businessIdcc.$.id = $event"
                        />
                        <b-form-input
                            v-else
                            v-bind="inputProps"
                            :value="`${businessIdcc.$.code} - ${businessIdcc.$.name}`"
                            :plaintext="formInitialModel.$.updatable !== true"
                            :readonly="formInitialModel.editableAttribues.includes('business_idcc_ids') === false"
                        />
                    </template>

                    <template
                        v-if="
                            formInitialModel.$.updatable
                                && formInitialModel.editableAttribues.includes('business_idcc_ids')
                        "
                        #right
                    >
                        <b-input-group-append>
                            <b-button
                                variant="outline-warning"
                                @click="formModifiedModel.$.businessIdccs.splice(index, 1)"
                            >
                                <font-awesome-icon
                                    :icon="['fad', 'times']"
                                    fixed-width
                                />
                                Retirer
                            </b-button>
                        </b-input-group-append>
                    </template>

                </form-group>

                <form-group
                    v-if="
                        formInitialModel.$.updatable
                            && formInitialModel.editableAttribues.includes('business_idcc_ids')
                    "
                    id="view-businessBranch-edit-business-idcc-ids-new"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.business_idcc_ids"
                    :label="formModifiedModel.$.businessIdccs.length ? undefined : 'Code IDCC'"
                    :left-icon="collection.isPending ? 'circle-notch' : 'tag'"
                    :left-icon-spin="collection.isPending"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.business_idcc_ids"
                    business-apprentice-agreement
                >
                    <b-form-select
                        v-bind="inputProps"
                        :options="options"
                        :value="null"
                        @input="attachBusinessIdccId($event)"
                    />
                </form-group>

            </div>
        </model-list>

        <model-list
            v-slot="{ collection, model, options, setValue, value: businessBranchTypeId }"
            v-model="business_branch_type_id"
            name="BusinessBranchType"
            :option-text="(model) => `${model.$.code} - ${model.$.name}`"
            :request-sort="({ code: 'asc' })"
        >
            <form-group
                id="view-businessBranch-edit-business-branch-type-id"
                v-slot="inputProps"
                :error="formModifiedModel.errors.business_branch_type_id"
                :left-icon="
                    formInitialModel.$.updatable
                        ? (collection.isPending ? 'circle-notch' : 'tag')
                        : undefined
                "
                :left-icon-spin="collection.isPending"
                :pending="formInitialModel.isPending"
                :state="formModifiedModel.states.business_branch_type_id"
                label="Forme juridique"
                business-apprentice-agreement
            >
                <b-form-select
                    v-if="
                        formInitialModel.$.updatable
                            && formInitialModel.editableAttribues.includes('business_branch_type_id')
                    "
                    v-bind="inputProps"
                    :options="options.sort((a, b) => a.text.startsWith('12 - ') ? -1 : 1)"
                    :value="businessBranchTypeId"
                    @input="setValue"
                />
                <b-form-input
                    v-else
                    v-bind="inputProps"
                    :plaintext="formInitialModel.$.updatable !== true"
                    :readonly="formInitialModel.editableAttribues.includes('business_branch_type_id') === false"
                    :value="model ? model.$.name : undefined"
                />
            </form-group>
        </model-list>

        <form-group
            v-if="$store.getters['auth/canAccessCenterClient']"
            id="view-businessBranch-edit-regional-authority"
            v-slot="inputProps"
            :error="formModifiedModel.errors.regional_authority"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.regional_authority"
            label="Fonction publique territoriale"
        >
            <b-form-checkbox
                v-model="formModifiedModel.$.regional_authority"
                v-bind="inputProps"
                :disabled="
                    formInitialModel.$.updatable !== true
                        || formInitialModel.editableAttribues.includes('regional_authority') !== true
                "
            >
                Éligible pour la convention d'apprentissage CNFPT
            </b-form-checkbox>
        </form-group>

        <form-group
            id="view-businessBranch-edit-importance"
            v-slot="inputProps"
            :error="formModifiedModel.errors.importance"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.importance"
            label="Type d'établissement"
        >
            <b-form-radio-group
                v-if="formInitialModel.$.updatable && formInitialModel.editableAttribues.includes('importance')"
                v-model="formModifiedModel.$.importance"
                v-bind="inputProps"
                :options="Object.entries(importances).map(([value, text]) => ({ text, value }))"
            />
            <b-form-input
                v-else
                v-bind="inputProps"
                :plaintext="formInitialModel.$.updatable !== true"
                :readonly="formInitialModel.editableAttribues.includes('importance') === false"
                :value="importances[formModifiedModel.$.importance]"
            />
        </form-group>

        <form-group
            id="view-businessBranch-edit-employee-headcount"
            :error="formModifiedModel.errors.employee_headcount"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.employee_headcount"
            label="Nombre de salariés"
            :left-icon="formInitialModel.$.updatable ? 'abacus' : undefined"
        >

            <template #default="inputProps">
                <b-form-input
                    v-model="formModifiedModel.$.employee_headcount"
                    v-bind="inputProps"
                    :plaintext="formInitialModel.$.updatable !== true"
                    :readonly="formInitialModel.editableAttribues.includes('employee_headcount') === false"
                    min="0"
                    type="number"
                />
            </template>

            <template
                v-if="formModifiedModel.$.max_employee_headcount"
                #description
            >
                Selon l'INSEE, l'effectif salarial de l'établissement est compris
                entre {{ formModifiedModel.$.min_employee_headcount }}
                et {{ formModifiedModel.$.max_employee_headcount }}.
            </template>

            <template
                v-else
                #description
            >
                Il s'agit de l'effectif salarial de l'établissement (SIRET), pas celui de l'employeur (SIREN).
            </template>

        </form-group>

        <form-group
            id="view-businessBranch-edit-email"
            v-slot="inputProps"
            :error="formModifiedModel.errors.email"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.email"
            label="Adresse e-mail"
            :left-icon="formInitialModel.$.updatable ? 'envelope' : undefined"
            business-apprentice-agreement
        >
            <b-form-input
                v-model="formModifiedModel.$.email"
                v-bind="inputProps"
                :plaintext="formInitialModel.$.updatable !== true"
                :readonly="formInitialModel.editableAttribues.includes('email') === false"
                type="email"
            />
        </form-group>

        <file-form-group
            v-if="$store.getters['auth/canAccessCenterClient']"
            id="view-businessBranch-edit-business-apprentice-commercial-agreement-template"
            v-model="formModifiedModel.$.businessApprenticeCommercialAgreementTemplate"
            :delete-upload.sync="formModifiedModel.$.delete_businessApprenticeCommercialAgreementTemplate"
            :disabled="
                formInitialModel.$.updatable !== true
                    && formInitialModel.editableAttribues.includes('siren') === false
            "
            :error="formModifiedModel.errors.businessApprenticeCommercialAgreementTemplate"
            :initial-upload="formInitialModel.$.businessApprenticeCommercialAgreementTemplate"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.businessApprenticeCommercialAgreementTemplate"
            accept=".doc, .docx"
            label="Modèle de la convention d'apprentissage"
            left-icon="file-word"
            business-apprentice-agreement
            deletable
        >
            <template
                v-if="
                    !formModifiedModel.$.businessApprenticeCommercialAgreementTemplate
                        || formModifiedModel.$.delete_businessApprenticeCommercialAgreementTemplate
                "
                #description
            >
                En l'absence d'un modèle de convention d'apprentissage spécifique pour cet établissement,
                le modèle standard sera utilisé.
            </template>
        </file-form-group>

        <form-group
            v-if="$store.getters['auth/canAccessCenterClient']"
            id="view-businessBranch-edit-center-note"
            v-slot="inputProps"
            label="Notes du CFA"
            :error="formModifiedModel.errors.center_note"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.center_note"
        >
            <b-textarea
                v-model="formModifiedModel.$.center_note"
                v-bind="inputProps"
                :plaintext="
                    formInitialModel.$.updatable !== true
                        || formInitialModel.editableAttribues.includes('center_note') !== true
                "
                max-rows="100"
                rows="5"
            />
        </form-group>

        <div
            v-if="formInitialModel.$.updatable"
            class="text-right"
        >
            <button-submit
                :is-pending="formIsPending"
                icon="edit"
                variant="success"
            >
                Enregistrer l'établissement
            </button-submit>
        </div>

    </b-card>

</template>



<script>

    import BusinessBranch from '@core/models/businessBranch';
    import BusinessBranchType from '@core/models/businessBranchType';
    import BusinessIdcc from '@core/models/businessIdcc';
    import BusinessNaf from '@core/models/businessNaf';
    import Form from '@core/mixins/form';

    export default {
        mixins: [Form],
        props: {
            businessBranchEditRoute: {
                type: Object,
                required: true,
            },
            businessBranchOfficeRoute: {
                type: Object,
                required: true,
            },
            businessCompanyEditRoute: {
                type: Object,
                required: true,
            },
            businessCompanyOfficeRoute: {
                type: Object,
                required: true,
            },
            header: {
                type: String,
                default: undefined,
            },
            showMissingInformation: {
                type: Boolean,
                default: false,
            },
            value: {
                type: BusinessBranch,
                required: true,
            },
        },
        computed: {
            business_branch_type_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.businessBranchType
                        ? vue.formModifiedModel.$.businessBranchType.$.id
                        : undefined
                ),
                set(id) {
                    if (id) {
                        this.formModifiedModel.$.businessBranchType = new BusinessBranchType({ id });
                    } else {
                        this.formModifiedModel.$.businessBranchType = undefined;
                    }
                },
            },
            business_naf_id: {
                get: (vue) => (
                    vue.formModifiedModel.$.businessNaf
                        ? vue.formModifiedModel.$.businessNaf.$.id
                        : undefined
                ),
                set(id) {
                    if (id) {
                        this.formModifiedModel.$.businessNaf = new BusinessNaf({ id });
                    } else {
                        this.formModifiedModel.$.businessNaf = undefined;
                    }
                },
            },
            formInitialModel: {
                get: (vue) => vue.value,
                set(value) {
                    this.$emit('input', value);
                },
            },
            importances: () => BusinessBranch.importances,
        },
        methods: {
            attachBusinessIdccId(id) {
                this.formModifiedModel.$.businessIdccs.push(new BusinessIdcc({ id }));
            },
            formSubmitSuccessful(updatedbusinessBranch) {
                this.$root.toastSuccess(
                    "Les informations de l'établissement ont été modifiées",
                    "L'enregistrement des informations a réussi.",
                );
                this.formInitialModel = new BusinessBranch(updatedbusinessBranch.$);
            },
        },
    };

</script>
