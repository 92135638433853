<template>

    <div class="py-3">

        <div class="d-flex align-items-center mb-2">

            <div class="mr-2">

                <b-skeleton-img
                    v-if="questionnaireEntry.isPending"
                    height="50px"
                    width="50px"
                    no-aspect
                />

                <upload-avatar
                    v-else
                    :upload="questionnaireEntry.learnerPerson.$.avatar"
                    icon="user-graduate"
                    width="50px"
                    rounded
                />

            </div>

            <h3 class="flex-fill mb-0">

                <b-skeleton
                    v-if="questionnaireEntry.isPending"
                    class="w-75"
                    variant="dark"
                />

                <template v-else>
                    {{ questionnaireEntry.learnerPerson.$.fullname }}
                </template>

                <small class="d-block my-0 text-muted">
                    Apprenant
                </small>

            </h3>

            <button-vcard
                v-if="educationStudy"
                :email="learnerPersonEmails[0]"
                :firstname="questionnaireEntry.learnerPerson.$.firstname"
                :is-pending="questionnaireEntry.isPending"
                :lastname="questionnaireEntry.learnerPerson.$.lastname"
                :organization="
                    `${educationStudy.nameAndYear};${educationStudy.$.educationProgramme.$.school.$.name}`
                "
                :phones="{
                    CELL: questionnaireEntry.learnerPerson.$.primaryAddress.$.mobile_phone
                        || questionnaireEntry.learnerPerson.$.secondaryAddress.$.mobile_phone,
                    MAIN: questionnaireEntry.learnerPerson.$.primaryAddress.$.landline_phone
                        || questionnaireEntry.learnerPerson.$.secondaryAddress.$.landline_phone,
                }"
                title="Apprenant"
            />

        </div>

        <div v-if="questionnaireEntry.isPending">
            <b-skeleton class="w-100" />
            <b-skeleton class="w-100" />
            <b-skeleton class="w-25" />
            <b-skeleton class="w-75" />
            <b-skeleton class="w-50" />
            <b-skeleton class="w-50" />
            <b-skeleton class="w-50" />
            <b-skeleton class="w-50" />
        </div>

        <div v-else>

            <div
                v-if="learnerEducationStudies[0]"
                class="mb-2"
            >
                {{ learnerEducationStudies[0].$.educationStudy.$.educationProgramme.$.school.$.name }}

                <p class="my-0 font-weight-bold">
                    {{ learnerEducationStudies[0].$.educationStudy.nameAndYear }}
                </p>
            </div>

            <div
                v-for="learnerPersonEmail in learnerPersonEmails"
                :key="learnerPersonEmail"
            >
                <font-awesome-icon
                    :icon="['fad', 'envelope']"
                    fixed-width
                />

                <a
                    :href="`mailto:${learnerPersonEmail}`"
                    class="font-weight-bold"
                >
                    {{ learnerPersonEmail }}
                </a>
            </div>

            <div v-if="questionnaireEntry.learnerPerson.$.primaryAddress.$.mobile_phone">

                <font-awesome-icon
                    :icon="['fad', 'mobile']"
                    fixed-width
                />

                <a :href="`tel:${questionnaireEntry.learnerPerson.$.primaryAddress.$.mobile_phone}`">
                    {{ questionnaireEntry.learnerPerson.$.primaryAddress.$.mobile_phone }}
                </a>
            </div>

            <div v-if="questionnaireEntry.learnerPerson.$.primaryAddress.$.landline_phone">

                <font-awesome-icon
                    :icon="['fad', 'phone']"
                    fixed-width
                />

                <a :href="`tel:${questionnaireEntry.learnerPerson.$.primaryAddress.$.landline_phone}`">
                    {{ questionnaireEntry.learnerPerson.$.primaryAddress.$.landline_phone }}
                </a>
            </div>

            <div v-if="questionnaireEntry.learnerPerson.$.secondaryAddress.$.mobile_phone">

                <font-awesome-icon
                    :icon="['fad', 'mobile']"
                    fixed-width
                />

                <a :href="`tel:${questionnaireEntry.learnerPerson.$.secondaryAddress.$.mobile_phone}`">
                    {{ questionnaireEntry.learnerPerson.$.secondaryAddress.$.mobile_phone }}
                </a>
            </div>

            <div v-if="questionnaireEntry.learnerPerson.$.secondaryAddress.$.landline_phone">

                <font-awesome-icon
                    :icon="['fad', 'phone']"
                    fixed-width
                />

                <a :href="`tel:${questionnaireEntry.learnerPerson.$.secondaryAddress.$.landline_phone}`">
                    {{ questionnaireEntry.learnerPerson.$.secondaryAddress.$.landline_phone }}
                </a>
            </div>

        </div>

    </div>

</template>



<script>

    import Dayjs from '@core/mixins/dayjs';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';

    export default {
        mixins: [Dayjs],
        props: {
            questionnaireEntry: {
                type: QuestionnaireEntry,
                required: true,
            },
        },
        computed: {
            educationStudy: (vue) => (
                vue.learnerEducationStudies[0]
                    ? vue.learnerEducationStudies[0].$.educationStudy
                    : undefined
            ),
            learnerEducationStudies: (vue) => vue.questionnaireEntry.learnerPerson.$.learnerEducationStudies
                .filter(
                    (learnerEducationStudy) => learnerEducationStudy.$.status !== 'canceled'
                        && learnerEducationStudy.$.status !== 'rejected',
                )
                .filter(
                    (learnerEducationStudy) => vue.dayjsDayBetween(
                        vue.questionnaireEntry.$.end,
                        learnerEducationStudy.$.start || learnerEducationStudy.$.educationStudy.$.start,
                        learnerEducationStudy.$.end || learnerEducationStudy.$.educationStudy.$.end,
                    ),
                ),
            learnerPersonEmails: (vue) => vue.learnerEducationStudies
                .map((learnerEducationStudy) => learnerEducationStudy.$.user.$.email)
                .filter((email, index, emails) => emails.indexOf(email) === index),
        },
    };

</script>
