<template>

    <b-table-simple
        :id="`questionnaireElement-${questionnaireElement.$.id}`"
        caption-top
        responsive
        striped
    >

        <caption class="text-center">

            {{ questionnaireElement.$.attributes.text }}

            <i
                v-if="questionnaireElement.$.attributes.questionnaire_field_attributes.trigger_average"
                class="d-block small"
            >
                Ces notes seront prises en compte dans le calcul automatique de la moyenne du formulaire.
            </i>

            <i
                v-if="questionnaireElement.$.attributes.questionnaire_field_answer_by_role && roles.length <= 1"
                class="d-block small"
            >
                Ce tableau peut être rempli différemment pour chaque participant. <br>
                Une fois le formulaire validé, les réponses de chaque participant seront visibles par tous.
            </i>

        </caption>

        <b-thead>
            <b-tr>

                <b-th />

                <b-th
                    v-for="(columnText, columnValue) in columns"
                    :key="columnValue"
                    class="text-center"
                >

                    <template v-if="questionnaireElement.$.attributes.questionnaire_field_type === 'grade'">
                        {{ columnText }}<br>
                        {{ columnValue }}
                    </template>

                    <template v-else>
                        {{ columnText }}
                    </template>

                </b-th>

            </b-tr>
        </b-thead>

        <b-tbody>
            <questionnaire-element-show-multiple-field
                v-for="questionnaireField in questionnaireElement.$.questionnaireFields"
                :key="questionnaireField.$.id"
                :columns="columns"
                :disabled="disabled"
                :questionnaire-answers="questionnaireAnswers.filter(
                    (questionnaireAnswer) => questionnaireAnswer.$.questionnaire_field_id === questionnaireField.$.id,
                )"
                :questionnaire-entry="questionnaireEntry"
                :questionnaire-field="questionnaireField"
                :questionnaire-survey="questionnaireSurvey"
                :roles="roles"
            />
        </b-tbody>

    </b-table-simple>

</template>



<script>

    import QuestionnaireElement from '@core/models/questionnaireElement';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';
    import QuestionnaireSurvey from '@core/models/questionnaireSurvey';

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            questionnaireElement: {
                type: QuestionnaireElement,
                required: true,
            },
            questionnaireEntry: {
                type: QuestionnaireEntry,
                default: null,
            },
            questionnaireSurvey: {
                type: QuestionnaireSurvey,
                required: true,
            },
        },
        computed: {
            columns: (vue) => vue.questionnaireElement.$.questionnaireFields[0].$.attributes.options,
            questionnaireAnswers: (vue) => (
                vue.questionnaireEntry ? vue.questionnaireEntry.$.questionnaireAnswers : []
            ),
            questionnaireAnswersByQuestionnaireField: (vue) => vue.questionnaireElement
                .$.questionnaireFields
                .reduce((questionnaireAnswersByQuestionnaireField, questionnaireField) => {
                    questionnaireAnswersByQuestionnaireField[questionnaireField.$.id] = vue.questionnaireAnswers.filter(
                        (questionnaireAnswer) => questionnaireAnswer.$.questionnaire_field_id
                            === questionnaireField.$.id,
                    );
                    return questionnaireAnswersByQuestionnaireField;
                }, {}),
            roles: (vue) => {
                if (vue.questionnaireEntry === null) {
                    return ['all'];
                }
                if (vue.questionnaireElement.$.attributes.questionnaire_field_answer_by_role !== true) {
                    return ['all'];
                }
                let roles = [];
                if (['write_answers', 'write_validation'].includes(vue.questionnaireSurvey.$.access_learner_person)) {
                    roles.push('learner_person');
                }
                if (['write_answers', 'write_validation'].includes(vue.questionnaireSurvey.$.access_education_mentor)) {
                    roles.push('education_mentor');
                }
                if (['write_answers', 'write_validation'].includes(vue.questionnaireSurvey.$.access_business_mentor)) {
                    roles.push('business_mentor');
                }
                if (['write_answers', 'write_validation'].includes(vue.questionnaireSurvey.$.access_education_study)) {
                    roles.push('education_study');
                }
                roles = roles.filter(
                    (role) => vue.questionnaireEntry.isLocked || role === vue.questionnaireEntry.$.user_role,
                );
                return roles.length > 0 ? roles : ['all'];
            },
        },
    };

</script>
