<template>

    <form-group
        v-bind="$attrs"
        :id="'pagination-search-' + _uid"
        v-slot="inputProps"
        left-icon="search"
        class="mb-0"
    >

        <b-form-input
            v-model="search"
            v-bind="inputProps"
            :placeholder="placeholder"
            debounce="500"
        />

        <b-input-group-append
            v-if="search"
            slot="right"
        >
            <b-button @click="$emit('input', '')">
                <font-awesome-icon
                    :icon="['fad', 'times']"
                    fixed-width
                />
            </b-button>
        </b-input-group-append>

    </form-group>

</template>



<script>

    export default {
        props: {
            placeholder: {
                type: String,
                default: 'Rechercher',
            },
            value: {
                type: String,
                required: true,
            },
        },
        computed: {
            search: {
                get: (vue) => vue.value,
                set(search) {
                    this.$emit('input', search);
                },
            },
        },
    };

</script>
