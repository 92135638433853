<template>

    <button-delete
        v-if="questionnaireSurvey.$.deletable"
        label="Supprimer le formulaire"
        :is-pending="questionnaireSurvey.isPending || parentFormIsPending"
        @submit="submit"
    >

        <slot />

        <div slot="left">

            <b>
                Seront effacés :
            </b>

            <ul class="mb-0">
                <li>les informations de présentation.</li>
                <li>les informations de publication.</li>
                <li>les informations de participation.</li>
                <li>les affectations.</li>
                <li>les champs.</li>
            </ul>

        </div>

        <div slot="right">

            <b>
                Seront conservés :
            </b>

            <ul class="mb-0">
                <li>la catégorie.</li>
                <li>le périmètre.</li>
            </ul>

        </div>

    </button-delete>

    <div
        v-else
        class="d-flex justify-content-end"
    >
        <slot />
    </div>

</template>



<script>

    import QuestionnaireSurvey from '@core/models/questionnaireSurvey';

    export default {
        props: {
            parentFormIsPending: {
                type: Boolean,
                default: false,
            },
            questionnaireSurvey: {
                type: QuestionnaireSurvey,
                required: true,
            },
        },
        methods: {
            submit() {
                this.questionnaireSurvey.request().delete().then(this.submitSuccessful).catch(() => {});
            },
            submitSuccessful() {
                this.$root.toastSuccess(
                    'Le formulaire a été supprimé',
                    'Ses informations sont effacées.',
                );
                this.$router.push({ name: 'questionnaireSurvey-index', query: this.$route.query });
            },
        },
    };

</script>
