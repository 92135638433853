import LearnerEducationStudy from '@core/models/learnerEducationStudy';
import RestModel from '@core/models/_rest';
import Upload from '@core/models/upload';

export default class LearnerDocument extends RestModel {

    get url() {
        return this.$.id ? `learnerDocuments/${this.$.id}` : 'learnerDocuments';
    }

    fill({
        document = undefined,
        learnerEducationStudy = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            document: document ? new Upload(document.$ || document) : undefined,
            learnerEducationStudy: learnerEducationStudy
                ? new LearnerEducationStudy(learnerEducationStudy.$ || learnerEducationStudy)
                : undefined,
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            document: this.$.document instanceof File ? this.$.document : undefined,
            learner_education_study_id: this.$.learnerEducationStudy ? this.$.learnerEducationStudy.$.id : undefined,
        });
    }

}
