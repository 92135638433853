import RestModel from '@core/models/_rest';

export default class BusinessConvergence extends RestModel {

    static get statusIcons() {
        return {
            created: 'info',
            accepted: 'check',
            rejected: 'exclamation',
            failed: 'times',
        };
    }

    static get statusNames() {
        return {
            created: 'En attente sur ce serveur',
            accepted: "Accepté par la plateforme de l'OPCO",
            rejected: "Rejeté par la plateforme de l'OPCO",
            failed: "Erreur technique lors du depôt la plateforme de l'OPCO",
        };
    }

    static get statusVariants() {
        return {
            created: 'info',
            accepted: 'success',
            rejected: 'warning',
            failed: 'danger',
        };
    }

    get url() {
        return this.$.id ? `businessConvergences/${this.$.id}` : 'businessConvergences';
    }

}
