<template>

    <div v-if="!!centerEmail || !!documentationUrl">

        <b-btn
            variant="outline-info"
            block
            @click="showLinks = !showLinks"
        >
            <font-awesome-icon
                :icon="['fad', 'life-ring']"
                fixed-width
            />
            Besoin d'aide ?
        </b-btn>

        <b-collapse
            v-model="showLinks"
            class="mt-3"
        >
            <b-btn-group
                class="w-100"
                vertical
            >

                <b-btn
                    v-if="documentationUrl"
                    :href="documentationUrl"
                    class="text-left"
                    target="_blank"
                    variant="info"
                >
                    <font-awesome-icon
                        :icon="['fad', 'external-link']"
                        fixed-width
                    />
                    Documentation
                </b-btn>

                <b-btn
                    v-if="centerEmail"
                    :href="`mailto:${centerEmail}?subject=${emailSubject}`"
                    class="text-left"
                    variant="info"
                >
                    <font-awesome-icon
                        :icon="['fad', 'paper-plane']"
                        fixed-width
                    />
                    Nous contacter
                </b-btn>

            </b-btn-group>
        </b-collapse>

    </div>

</template>



<script>

    export default {
        data: () => ({
            showLinks: false,
        }),
        computed: {
            centerEmail: (vue) => vue.$store.getters['center/email'],
            documentationUrl: (vue) => vue.$store.getters['center/documentationUrl'],
            emailSubject: (vue) => `[${vue.platformName}] ${window.location.origin}${vue.$route.fullPath}`,
            platformName: (vue) => vue.$store.getters['center/platformName'],
        },
    };

</script>
