import Errors from '@core/request/errors';
import Model from '@core/models/_model';
import RestModel from '@core/models/_rest';
import StorableCollection from '@core/models/storableCollection';

export default {

    data: () => ({
        formModifiedModel: new RestModel(),
    }),

    watch: {
        formInitialModel: {
            deep: true,
            handler() {
                if (this.formInitialModel === undefined) {
                    return;
                }
                if (this.formInitialModel instanceof StorableCollection) {
                    this.formModifiedModel = new StorableCollection(
                        this.formInitialModel.models.map((model) => new model.constructor(model.$)),
                    );
                } else if (this.formInitialModel instanceof Model) {
                    if (this.formInitialModel.$.id || this.formCreateAllowed()) {
                        this.formModifiedModel = new this.formInitialModel.constructor(this.formInitialModel.$);
                        this.formModifiedModel.errors = this.formInitialModel.errors;
                    }
                }
            },
            immediate: true,
        },
    },

    computed: {
        formIsPending: (vue) => (vue.formInitialModel && vue.formInitialModel.isPending)
            || vue.formModifiedModel.isPending,
    },

    methods: {
        formCreateAllowed() {
            if (this.$store.getters['auth/canCreate'] === undefined) {
                return true;
            }
            if (this.$store.getters['auth/canCreate'](this.formInitialModel.url) !== false) {
                return true;
            }
            Errors[403]();
            return false;
        },
        formSubmit() {
            if (this.formModifiedModel.$ && this.formModifiedModel.$.id) {
                return this.formModifiedModel
                    .request()
                    .update()
                    .then(this.formSubmitSuccessful)
                    .catch(this.formSubmitFailed);
            }
            return this.formModifiedModel
                .request()
                .create()
                .then(this.formSubmitSuccessful)
                .catch(this.formSubmitFailed);
        },
        formSubmitFailed() {
            //
        },
    },

};
