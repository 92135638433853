import EducationStudy from '@core/models/educationStudy';
import LearnerPerson from '@core/models/learnerPerson';
import RestModel from '@core/models/_rest';
import SfpClass from '@core/models/sfpClass';
import SfpDiploma from '@core/models/sfpDiploma';
import Upload from '@core/models/upload';

export default class SfpContract extends RestModel {

    static get jobSeekers() {
        return {
            less_than_six_months: 'Inscrit(e) à Pôle Emploi depuis moins de 6 mois',
            six_to_eleven_months: 'Inscrit(e) à Pôle Emploi depuis 6 à 11 mois',
            twelve_to_twenty_three_months: 'Inscrit(e) à Pôle Emploi depuis 12 à 23 mois',
            more_than_twenty_four_months: 'Inscrit(e) à Pôle Emploi depuis 24 mois ou plus',
            not_enrolled: 'Non inscrit(e) à Pôle Emploi',
        };
    }

    static get previousSituations() {
        return {
            agricultural_operating: "Dans une activité agricole: chef(fe) d'exploitation",
            agricultural_familial: 'Dans une activité agricole: aide familiale',
            agricultural_spouse: 'Dans une activité agricole: conjoint(e)',
            agricultural_employee: 'Dans une activité agricole: employé(e)',
            employee: 'Dans une activité non agricole : salarié(e)',
            unemployee: 'Dans une activité non agricole : non salari(é)',
            job_seeker: "Demandeur(se) d'emploi",
        };
    }

    static get rsas() {
        return {
            not_beneficiary: 'Non bénéficiaire',
            allocatee: "Bénéficiaire à titre d'allocataire",
            rightful_claimant: "Bénéficiaire à titre d'ayant droit",
        };
    }

    static get socialSecurityAffiliations() {
        return {
            general: 'Régime général...',
            operator: "Régime d'assurance maladie des exploitations agricoles en tant qu'exploitant(e)",
            spouse: "Régime d'assurance maladie des exploitations agricoles en tant que conjoint(e)",
            partner: "Régime d'assurance maladie des exploitations agricoles en tant qu'associé(e) d'exploitation",
            familial: "Régime d'assurance maladie des exploitations agricoles en tant qu'aide familiale",
            operating_employee: "Régime des salariés agricoles en tant que salarié(e) d'exploitation",
            agricultural_employee: "Régime des salariés agricoles en tant que salarié(e) d'organisme agricole",
            other: 'Autre régime...',
            any: 'Non affilié(e) à titre personnel',
        };
    }

    static get statusNames() {
        return {
            created: 'Génération du document',
            downloaded: 'Signature des documents',
            uploaded: 'Documents signés',
        };
    }

    static get statusIcons() {
        return {
            created: 'download',
            downloaded: 'upload',
            uploaded: 'check',
        };
    }

    static get types() {
        return {
            start: 'Rentrée',
            terminated: 'Rupture',
        };
    }

    get url() {
        return this.$.id ? `sfpContracts/${this.$.id}` : 'sfpContracts';
    }

    fill({
        educationStudy = {},
        lastSfpClass = undefined,
        lastSfpDiploma = undefined,
        learnerPerson = {},
        signedAgreement = undefined,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            educationStudy: new EducationStudy(educationStudy.$ || educationStudy),
            lastSfpClass: lastSfpClass ? new SfpClass(lastSfpClass.$ || lastSfpClass) : undefined,
            lastSfpDiploma: lastSfpDiploma ? new SfpDiploma(lastSfpDiploma.$ || lastSfpDiploma) : undefined,
            learnerPerson: new LearnerPerson(learnerPerson.$ || learnerPerson),
            signedAgreement: signedAgreement ? new Upload(signedAgreement.$ || signedAgreement) : undefined,
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            education_study_id: this.$.educationStudy.$.id,
            last_sfp_class_id: this.$.lastSfpClass ? this.$.lastSfpClass.$.id : undefined,
            last_sfp_diploma_id: this.$.lastSfpDiploma ? this.$.lastSfpDiploma.$.id : undefined,
            learner_person_id: this.$.learnerPerson.$.id,
            signedAgreement: this.$.signedAgreement instanceof File ? this.$.signedAgreement : undefined,
        });
    }

}
