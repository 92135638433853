<template>

    <b-button
        :disabled="isPending || disabled"
        :size="size"
        :variant="variant"
        type="submit"
        @click="$emit('click')"
    >
        <font-awesome-icon
            :flip="iconFlip"
            :icon="['fad', isPending ? 'circle-notch' : icon]"
            :spin="isPending"
            fixed-width
        />
        <slot />
    </b-button>

</template>



<script>

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            icon: {
                type: String,
                required: true,
            },
            iconFlip: {
                type: String,
                default: null,
            },
            isPending: {
                type: Boolean,
                required: true,
            },
            size: {
                type: String,
                default: null,
            },
            variant: {
                type: String,
                default: 'primary',
            },
        },
    };

</script>
