<template>

    <form-group
        :id="id"
        :error="error"
        :left-icon="disabled ? undefined: 'image'"
        :pending="pending"
        :state="state"
        :description="description"
    >

        <div
            slot="top"
            class="mb-3 text-center"
        >

            <div
                v-if="pending"
                class="w-50 mb-4 mx-auto"
            >
                <b-skeleton-img aspect="1:1" />
            </div>

            <upload-avatar
                v-else
                :id="disabled ? id : undefined"
                :class="{ ...avatarClass, 'mb-4': initialUpload === null }"
                :icon="icon"
                :rounded="rounded"
                :upload="initialUpload"
                :height="avatarHeight"
                :width="initialUpload ? null : '50%'"
            />

            <b-form-checkbox
                v-if="disabled !== true && initialUpload"
                :checked="deleteUpload"
                class="mt-3"
                @input="$emit('update:delete-upload', $event)"
            >
                Supprimer l'image
            </b-form-checkbox>

        </div>

        <template
            v-if="disabled !== true"
            #default="inputProps"
        >
            <b-form-file
                v-model="modifiedUpload"
                v-bind="inputProps"
                accept="image/*"
                browse=""
                placeholder="Aucun fichier sélectionné"
            />
        </template>

    </form-group>

</template>



<script>

    import Upload from '@core/models/upload';

    export default {
        props: {
            avatarClass: {
                type: [Array, Object, String],
                default: undefined,
            },
            avatarHeight: {
                type: String,
                default: undefined,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            deleteUpload: {
                type: Boolean,
                default: false,
            },
            description: {
                type: String,
                default: undefined,
            },
            error: {
                type: String,
                default: undefined,
            },
            icon: {
                type: String,
                required: true,
            },
            id: {
                type: String,
                required: true,
            },
            initialUpload: {
                type: Upload,
                default: null,
            },
            pending: {
                type: Boolean,
                default: false,
            },
            rounded: {
                type: Boolean,
                default: false,
            },
            state: {
                type: Boolean,
                default: undefined,
            },
            value: {
                type: [File, Upload],
                default: null,
            },
        },
        computed: {
            modifiedUpload: {
                get: (vue) => (
                    vue.value instanceof File ? vue.value : null
                ),
                set(modifiedUpload) {
                    if (modifiedUpload || this.value instanceof File) {
                        this.$emit('input', modifiedUpload);
                    }
                },
            },
        },
    };

</script>
