<template>

    <b-skeleton
        v-if="isPending"
        type="btn"
        width="30px"
    />

    <b-button
        v-else
        v-b-tooltip.noninteractive
        :download="`${firstname} ${lastname}`"
        :href="`data:text/vcard;charset=UTF-8,${encodeURIComponent(vcardData)}`"
        size="sm"
        title="Télécharger le contact"
        variant="outline-secondary"
    >
        <font-awesome-icon
            :icon="['fad', 'download']"
            fixed-width
        />
    </b-button>

</template>



<script>

    export default {
        props: {
            email: {
                type: String,
                default: undefined,
            },
            firstname: {
                type: String,
                default: undefined,
            },
            isPending: {
                type: Boolean,
                required: true,
            },
            lastname: {
                type: String,
                default: undefined,
            },
            organization: {
                type: String,
                default: undefined,
            },
            phones: {
                type: Object,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
        },
        computed: {
            vcardData: (vue) => [
                'BEGIN:VCARD',
                'VERSION:3.0',
                `N:${vue.lastname};${vue.firstname};;;`,
                `FN:${vue.firstname} ${vue.lastname}`,
                `EMAIL;type=INTERNET;type=pref:${vue.email}`,
                `TITLE:${vue.title}`,
                vue.organization ? `ORG:${vue.organization};` : undefined,
                Object.entries(vue.phones)
                    .filter(([, phone]) => phone)
                    .map(([type, phone]) => `TEL;type=${type}:${phone}`)
                    .join('\n'),
                'END:VCARD',
            ].filter((line) => line).join('\n'),
        },
    };

</script>
