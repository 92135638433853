<template>

    <div>

        <b-alert
            :show="paginationCollection.isEmpty && withoutValidated"
            class="text-center"
            variant="success"
        >

            <p>
                <font-awesome-icon
                    :icon="['fad', 'check']"
                    fixed-width
                />
                {{ mandatory === undefined ? "Livret de l'apprenant" : mandatoryNames[mandatory ? 1 : 0] }}
                <template v-if="questionnaireCategory">
                    {{ questionnaireCategory.$.name }}
                </template>
                validé.
            </p>

            <b-button
                size="sm"
                variant="light"
                @click="$emit('update:withoutValidated', false)"
            >
                Afficher les formulaires validés
            </b-button>

        </b-alert>

        <b-alert
            :show="paginationCollection.isEmpty && withoutValidated !== true"
            class="text-center"
            variant="warning"
        >
            <font-awesome-icon
                :icon="['fad', 'empty-set']"
                fixed-width
            />
            {{ mandatory === undefined ? "Livret de l'apprenant" : mandatoryNames[mandatory ? 1 : 0] }}
            <template v-if="questionnaireCategory">
                {{ questionnaireCategory.$.name }}
            </template>
            vide.
        </b-alert>

        <component
            :is="(
                mandatory === false || questionnaireEntry.$.questionnaireSurvey.$.mandatory === false
                    ? 'questionnaire-entry-timeline-entry-optional'
                    : 'questionnaire-entry-timeline-entry-mandatory'
            )"
            v-for="(questionnaireEntry, index) in paginationCollection.models"
            :key="questionnaireEntry.$.id"
            :first="index === 0"
            :is-pending="paginationCollection.isPending"
            :last="index === paginationCollection.models.length - 1"
            :questionnaire-entry="questionnaireEntry"
            :route-name-prefix="routeNamePrefix"
        />

    </div>

</template>



<script>

    import Collection from '@core/models/collection';
    import QuestionnaireCategory from '@core/models/questionnaireCategory';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';
    import QuestionnaireSurvey from '@core/models/questionnaireSurvey';
    import Model from '@core/models/_rest';
    import Pagination from '@core/mixins/pagination';

    export default {
        mixins: [Pagination],
        props: {
            learnerPersonId: {
                type: Number,
                required: true,
            },
            mandatory: {
                type: Boolean,
                default: undefined,
            },
            withoutValidated: {
                type: Boolean,
                default: false,
            },
            questionnaireCategory: {
                type: QuestionnaireCategory,
                default: null,
            },
            routeNamePrefix: {
                type: String,
                default: '',
            },
            updatedQuestionnaireEntry: {
                type: QuestionnaireEntry,
                default: undefined,
            },
        },
        data: () => ({
            paginationCollection: new Collection(new Model(), {}, 1000),
        }),
        computed: {
            firstQuestionnaireEntry: (vue) => vue.paginationCollection.models[0],
            mandatoryNames: () => QuestionnaireSurvey.mandatoryNames,
            paginationRequestData: (vue) => ({
                isNotValidated: vue.withoutValidated ? 1 : 0,
                learner_person_id: vue.learnerPersonId,
                mandatory: vue.mandatory === undefined ? null : Number(vue.mandatory),
                questionnaire_category_id: vue.questionnaireCategory
                    ? parseInt(vue.questionnaireCategory.$.id, 10) || undefined
                    : undefined,
            }),
        },
        watch: {
            firstQuestionnaireEntry() {
                if (this.firstQuestionnaireEntry && this.firstQuestionnaireEntry.$.id) {
                    this.$emit('input:firstQuestionnaireEntry', this.firstQuestionnaireEntry);
                }
            },
            learnerPersonId: {
                handler() {
                    if (this.learnerPersonId && this.paginationCollection.model.url === undefined) {
                        this.paginationCollection = new Collection(new QuestionnaireEntry(), { end: 'asc' }, 1000);
                        this.paginationRequest();
                    }
                },
                immediate: true,
            },
            updatedQuestionnaireEntry(updatedQuestionnaireEntry) {
                this.paginationCollection.models = this.paginationCollection.models.map((questionnaireEntry) => (
                    questionnaireEntry.is(updatedQuestionnaireEntry) ? updatedQuestionnaireEntry : questionnaireEntry
                ));
            },
        },
    };

</script>
