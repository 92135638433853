/* eslint-disable-next-line import/no-cycle */
import EducationStudy from '@core/models/educationStudy';
import RestModel from '@core/models/_rest';
/* eslint-disable-next-line import/no-cycle */
import User from '@core/models/user';

export default class LearnerEducationStudy extends RestModel {

    static get schoolings() {
        return {
            apprentice_employee: 'Apprenti',
            apprentice_search: "Admis en recherche d'un contrat d'apprentissage",
            apprentice_terminated: 'Apprenti en rupture',
            professional: 'Contrat de professionnalisation',
            continuing: 'Stage, Formation Continue',
            formal: 'Stage, Formation Initiale',
            other: 'Autre statut',
            withdrawal: 'Abandon',
        };
    }

    static get schoolingsApprentice() {
        return {
            apprentice_employee: 'Apprenti',
            apprentice_search: "Admis en recherche d'un contrat d'apprentissage",
            apprentice_terminated: 'Apprenti en rupture',
        };
    }

    static get schoolingsManual() {
        return {
            apprentice_search: "Admis en recherche d'un contrat d'apprentissage",
            professional: 'Contrat de professionnalisation',
            continuing: 'Stage, Formation Continue',
            formal: 'Stage, Formation Initiale',
            other: 'Autre statut',
            withdrawal: 'Abandon',
        };
    }

    static get statusActions() {
        return {
            created: "Demander la saisie des informations personnelles à l'apprenant",
            completed: "Requérir l'acceptation de l'inscription par l'apprenant",
            accepted: "Accepter l'inscription dans cette formation",
            rejected: "Refuser l'inscription dans cette formation",
            canceled: "Annuler l'inscription de cet apprenant",
        };
    }

    static get statusDescriptions() {
        return {
            created: "En attente des informations par l'apprenant",
            completed: "En attente d'acceptation de l'inscription",
            accepted: 'Acceptée',
            rejected: "Rejetée par l'apprenant",
            canceled: "Annulée par l'antenne",
        };
    }

    static get statusIcons() {
        return {
            created: 'edit',
            completed: 'clock',
            accepted: 'check',
            rejected: 'times',
            canceled: 'trash',
        };
    }

    static get statusVariants() {
        return {
            created: 'warning',
            completed: 'info',
            accepted: 'success',
            rejected: 'danger',
            canceled: 'dark',
        };
    }

    get url() {
        return this.$.id ? `learnerEducationStudies/${this.$.id}` : 'learnerEducationStudies';
    }

    fill({
        educationStudy = {},
        user = {},
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            educationStudy: new EducationStudy(educationStudy.$ || educationStudy),
            user: new User(user.$ || user),
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            education_study_id: this.$.educationStudy.$.id,
            user_id: this.$.user.$.id,
        });
    }

}
