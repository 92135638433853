import BusinessApprenticeContract from '@core/models/businessApprenticeContract';
import BusinessPerson from '@core/models/businessPerson';
import EducationPerson from '@core/models/educationPerson';
import LearnerPerson from '@core/models/learnerPerson';
import QuestionnaireAnswer from '@core/models/questionnaireAnswer';
import QuestionnaireComment from '@core/models/questionnaireComment';
import QuestionnaireElement from '@core/models/questionnaireElement';
import QuestionnaireSurvey from '@core/models/questionnaireSurvey';
import QuestionnaireValidation from '@core/models/questionnaireValidation';
import RestModel from '@core/models/_rest';
import dayjs from '@core/mixins/dayjs';

const newOwner = (attributes) => {
    if (attributes.ine !== undefined) {
        return new LearnerPerson(attributes);
    }
    if (attributes.business_apprentice_agreement_id !== undefined) {
        return new BusinessApprenticeContract(attributes);
    }
    return undefined;
};

export default class QuestionnaireEntry extends RestModel {

    get dateText() {
        const durationToStart = Math.abs(
            dayjs.methods.dayjsDifference(undefined, this.$.questionnaireSurvey.$.start, 'days'),
        );
        const durationToEnd = Math.abs(
            dayjs.methods.dayjsDifference(undefined, this.$.questionnaireSurvey.$.end, 'days'),
        );
        if (this.$.questionnaireSurvey.$.mandatory !== true) {
            return undefined;
        }
        if (this.isValidated) {
            return undefined;
        }
        if (this.isOverdued) {
            return `Retard de ${durationToEnd} jour${durationToEnd > 1 ? 's' : ''}`;
        }
        if (this.isOpen) {
            if (durationToEnd === 0) {
                return "Aujourd'hui";
            }
            return `Reste ${durationToEnd} jour${durationToEnd > 1 ? 's' : ''}`;
        }
        return `Dans ${durationToStart} jour${durationToEnd > 1 ? 's' : ''}`;
    }

    get isCompleted() {
        return this.$.questionnaireValidation.$.answer_rate === 100;
    }

    get isLocked() {
        return this.$.questionnaireValidation.$.validation_rate > 0;
    }

    get isOpen() {
        if (this.$.questionnaireSurvey.$.type === 'assignable') {
            return dayjs.methods.dayjsIsAfter(undefined, this.$.questionnaireSurvey.$.start);
        }
        return true;
    }

    get isOverdued() {
        if (this.$.questionnaireSurvey.$.type === 'assignable') {
            return dayjs.methods.dayjsIsAfter(undefined, this.$.questionnaireSurvey.$.end, 'day');
        }
        return false;
    }

    get isValidated() {
        return this.$.questionnaireValidation.$.validation_rate === 100;
    }

    get learnerPerson() {
        if (this.$.owner === undefined) {
            return new LearnerPerson();
        }
        if (this.$.owner.$.business_apprentice_agreement_id) {
            return this.$.owner.$.learnerPerson;
        }
        return this.$.owner;
    }

    get remainingWriteAnswersRoles() {
        const roles = [];
        if (this.$.questionnaireSurvey.$.access_learner_person === 'write_answers') {
            if (this.$.questionnaireValidation.$.learner_person_validated_at === null) {
                roles.push('learner_person');
            }
        }
        if (this.$.questionnaireSurvey.$.access_education_mentor === 'write_answers') {
            if (this.$.questionnaireValidation.$.education_mentor_validated_at === null) {
                roles.push('education_mentor');
            }
        }
        if (this.$.questionnaireSurvey.$.access_business_mentor === 'write_answers') {
            if (this.$.questionnaireValidation.$.business_mentor_validated_at === null) {
                roles.push('business_mentor');
            }
        }
        if (this.$.questionnaireSurvey.$.access_education_study === 'write_answers') {
            if (this.$.questionnaireValidation.$.education_study_validated_at === null) {
                roles.push('education_study');
            }
        }
        return roles;
    }

    get statusCode() {
        /* eslint-disable-next-line no-bitwise */
        return (((((this.isOpen << 1) | this.isCompleted) << 1) | this.isLocked) << 1) | this.isValidated;
    }

    get url() {
        return this.$.id ? `questionnaireEntries/${this.$.id}` : 'questionnaireEntries';
    }

    fill({
        businessPeople = [],
        educationMentors = [],
        educationStudyPeople = {},
        owner = {},
        questionnaireAnswers = [],
        questionnaireComments = [],
        questionnaireElements = [],
        questionnaireSurvey = {},
        questionnaireValidation = {},
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            businessPeople: businessPeople.map(
                (businessPerson) => new BusinessPerson(businessPerson.$ || businessPerson),
            ),
            educationMentors: educationMentors.map(
                (educationPerson) => new EducationPerson(educationPerson.$ || educationPerson),
            ),
            educationStudyPeople: Object.fromEntries(
                Object.entries(educationStudyPeople).map(([key, educationPerson]) => [
                    key,
                    new EducationPerson(educationPerson.$ || educationPerson),
                ]),
            ),
            owner: newOwner(owner.$ || owner),
            questionnaireAnswers: questionnaireAnswers.map(
                (questionnaireAnswer) => new QuestionnaireAnswer(questionnaireAnswer.$ || questionnaireAnswer),
            ),
            questionnaireComments: questionnaireComments.map(
                (questionnaireComment) => new QuestionnaireComment(questionnaireComment.$ || questionnaireComment),
            ),
            questionnaireElements: questionnaireElements.map(
                (questionnaireElement) => new QuestionnaireElement(questionnaireElement.$ || questionnaireElement),
            ),
            questionnaireSurvey: new QuestionnaireSurvey(questionnaireSurvey.$ || questionnaireSurvey),
            questionnaireValidation: new QuestionnaireValidation(questionnaireValidation.$ || questionnaireValidation),
        });
    }

    action(questionnaireSurveyAccess, validated) {
        if (this.isOverdued && this.isLocked !== true && this.isCompleted) {
            return `En attente de finalisation par ${
                this.$.questionnaireSurvey.rolePhraseNames[this.$.questionnaireSurvey.writeValidationRole]
            }.`;
        }

        if (this.isOverdued && this.isLocked && this.isValidated !== true) {
            return `En attente de validation par ${
                this.remainingWriteAnswersRoles
                    .map((role) => this.$.questionnaireSurvey.rolePhraseNames[role])
                    .join(' et ')
            }.`;
        }

        if (questionnaireSurveyAccess === undefined || questionnaireSurveyAccess === 'none') {
            return '';
        }

        if (questionnaireSurveyAccess === 'read_survey') {
            return 'Vous ne pouvez pas consulter ce formulaire.';
        }

        if (this.isOpen !== true) {
            return "Ce formulaire n'est pas encore ouvert, mais vous pouvez le consulter.";
        }

        if (questionnaireSurveyAccess === 'read_fields') {
            return 'Vous pouvez consulter les questions de ce formulaire.';
        }

        if (questionnaireSurveyAccess === 'read_answers') {
            return 'Vous pouvez consulter les réponses de ce formulaire.';
        }

        if (validated) {
            return 'Vous pouvez consulter et commenter ce formulaire.';
        }

        if (this.isValidated) {
            return 'Vous pouvez valider le formulaire.';
        }

        if (questionnaireSurveyAccess === 'write_answers') {
            return 'Vous pouvez répondre à ce formulaire.';
        }

        return 'Vous pouvez répondre à ce formulaire et le finaliser.';
    }

    icon(questionnaireSurveyAccess) {
        if (questionnaireSurveyAccess === 'write_validation') {
            return this.isCompleted ? 'check' : 'pen';
        }

        if (questionnaireSurveyAccess === 'write_answers') {
            return this.isLocked ? 'check' : 'pen';
        }

        if (questionnaireSurveyAccess !== undefined && questionnaireSurveyAccess !== 'none') {
            return 'eye';
        }

        if (this.isValidated) {
            return 'check';
        }

        if (this.isOverdued) {
            return 'bell-exclamation';
        }

        if (this.isOpen) {
            return 'pen';
        }

        return 'lock';
    }

    variant(questionnaireSurveyAccess, validated) {
        if (this.icon(questionnaireSurveyAccess) === 'eye') {
            return 'secondary';
        }

        if (validated || this.isValidated) {
            return 'success';
        }

        if (this.$.questionnaireSurvey.$.mandatory && this.isOverdued) {
            return 'danger';
        }

        if (this.$.questionnaireSurvey.$.mandatory && this.isOpen) {
            return 'warning';
        }

        return 'secondary';
    }

}
