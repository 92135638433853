export default {

    400: () => {
        window.app.toastDanger(
            'Erreur réseau',
            'Veuillez vérifier votre connexion Internet et recharger la page.',
        );
    },

    401: () => {
        window.app.toastWarning(
            'Connexion requise',
            window.origin === import.meta.env.VITE_APP_URL_CONTRACT
                ? 'Le lien de connexion a expiré.'
                : 'Veuillez vous identifier pour accéder à votre espace.',
        );
        if (window.app.$route.name !== 'login-form') {
            window.app.$router.push({
                name: 'login-form',
                query: { redirect: window.app.$router.currentRoute.fullPath },
            });
        }
    },

    403: () => {
        window.app.toastDanger(
            'Action interdite',
            "Vous n'êtes pas autorisé(e) à effectuer cette action.",
        );
        window.app.$router.push({ name: 'home-summary' });
    },

    404: () => {
        window.app.toastDanger(
            'Information introuvable',
            "L'information recherchée est inexistante ou indisponible.",
        );
    },

    419: () => {
        window.app.toastWarning(
            'Expiration de la connexion',
            'Veuillez recharger la page et réessayer.',
        );
    },

    422: () => {
        window.app.toastWarning(
            'Informations incorrectes',
            'Veuillez vérifier les informations renseignées dans le formulaire.',
        );
    },

    423: () => {
        window.app.toastWarning(
            'Action verrouillée par un autre utilisateur',
            'Elle sera automatiquement disponible une fois déverouillée.',
        );
    },

    429: () => {
        window.app.toastWarning(
            'Trop de requêtes',
            'Veuillez patienter une minute avant de poursuivre votre navigation.',
        );
    },

    500: () => {
        window.app.toastDanger(
            'Erreur serveur',
            `Veuillez réessayer plus tard ou contacter ${window.app.$store.getters['center/companyName']}.`,
            window.app.$store.getters['center/documentationUrl'],
        );
    },

    503: (response) => {
        if (response.data.reason === 'OpenDataSoftUnavailableException') {
            window.app.toastWarning(
                "Le service en ligne de l'INSEE est indisponible",
                `Veuillez réessayer plus tard, puis contacter ${window.app.$store.getters['center/companyName']} `
                    + 'si le problème persiste.',
                window.app.$store.getters['center/documentationUrl'],
            );
        } else if (response.data.reason === 'Docusign') {
            window.app.toastWarning(
                'Le service Docusign est indisponible',
                `Veuillez réessayer plus tard, puis contacter ${window.app.$store.getters['center/companyName']} `
                    + 'si le problème persiste.',
                window.app.$store.getters['center/documentationUrl'],
            );
        } else {
            window.location.reload();
        }
    },

    504: () => {
        window.app.toastInfo(
            'Requête en cours de traitement',
            'Le serveur continue de traiter votre demande, merci de patienter et de NE PAS renouveler la requête.',
        );
    },

};
