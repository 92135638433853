import Model from '@core/models/_model';

export default class Upload extends Model {

    get icon() {
        if (this.$.extension === 'pdf') {
            return 'file-pdf';
        }

        if (['xlsx', 'csv'].includes(this.$.extension)) {
            return 'file-csv';
        }

        if (['png', 'jpg', 'jpeg', 'gif', 'webp'].includes(this.$.extension)) {
            return 'image';
        }

        if (['doc', 'docx', 'dotx', 'odt', 'txt'].includes(this.$.extension)) {
            return 'file-word';
        }

        return 'file';
    }

    formData() {
        return this.$.file || undefined;
    }

}
