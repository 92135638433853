<template>

    <div id="view-questionnaireEntry-show">

        <div
            v-if="noHeader !== true"
            class="d-flex w-100"
        >

            <div class="flex-fill">

                <b-skeleton
                    v-if="questionnaireEntry.isPending"
                    class="w-75 py-3"
                    variant="dark"
                />

                <h1 v-else>
                    {{ pageTitle }}
                </h1>

            </div>

            <div class="d-print-none">

                <b-skeleton
                    v-if="questionnaireEntry.isPending"
                    type="button"
                    variant="primary"
                />

                <b-btn-group v-else>

                    <questionnaire-entry-ics
                        v-if="questionnaireEntry.$.questionnaireSurvey.$.type === 'assignable'"
                        :questionnaire-entry="questionnaireEntry"
                    />

                    <b-button
                        v-if="questionnaireElements.isEmpty !== true"
                        v-b-tooltip.noninteractive
                        :href="pdfUrl"
                        variant="outline-primary"
                        title="Télécharger la version PDF"
                        target="_blank"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'download']"
                            fixed-width
                        />
                    </b-button>

                </b-btn-group>

            </div>

        </div>

        <b-skeleton
            v-if="questionnaireEntry.isPending"
            class="w-50 py-2"
        />

        <p
            v-else-if="questionnaireEntry.$.questionnaireSurvey.$.type === 'assignable'"
            class="mb-0"
        >

            Ce formulaire est ouvert du

            <b :class="{'text-danger': questionnaireEntry.isOverdued, 'text-warning': questionnaireEntry.isOpen }">
                {{ dayjsShortDate(questionnaireEntry.$.questionnaireSurvey.$.start) }}
                au
                {{ dayjsShortDate(questionnaireEntry.$.questionnaireSurvey.$.end) }}
            </b>

            <font-awesome-icon
                v-if="questionnaireEntry.isOverdued"
                :icon="['fad', 'bell-exclamation']"
                class="text-danger"
            />

            .

        </p>

        <hr class="bg-dark">

        <questionnaire-entry-participants
            :questionnaire-entry="questionnaireEntry"
            class="my-4"
        />

        <b-tabs
            v-model="tabIndex"
            active-nav-item-class="bg-primary border border-primary text-white px-4"
            nav-class="d-print-none mb-4 border-primary"
        >

            <b-tab>

                <template #title>
                    <font-awesome-icon
                        :icon="['fad', 'folder-open']"
                        class="mr-1"
                        fixed-width
                    />
                    Formulaire
                </template>

                <questionnaire-element-index
                    :allow-answer="questionnaireEntry.$.user_role !== null"
                    :questionnaire-elements="questionnaireElements"
                    :questionnaire-entry="questionnaireEntry"
                    :questionnaire-survey="questionnaireEntry.$.questionnaireSurvey"
                />

                <questionnaire-validation-delete
                    v-if="questionnaireValidation.$.deletable"
                    v-model="questionnaireValidation"
                    class="d-print-none mb-4"
                />

                <questionnaire-validation-update
                    v-if="questionnaireElements.isEmpty !== true && questionnaireEntry.isPending !== true"
                    :questionnaire-entry="questionnaireEntry"
                    :questionnaire-fields="questionnaireFields"
                    :value="questionnaireValidation"
                    class="d-print-none mb-4"
                />

                <div
                    v-if="indexableQuestionnaireComments"
                    class="d-print-none mb-4 text-center"
                >
                    <b-button
                        size="lg"
                        variant="outline-primary"
                        @click="tabIndex = 1"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'comments']"
                            fixed-width
                        />
                        Consulter les commentaires
                    </b-button>
                </div>

                <questionnaire-entry-statuses
                    v-if="
                        questionnaireElements.isEmpty !== true && questionnaireEntry.$.questionnaireSurvey.$.mandatory
                    "
                    :questionnaire-entry="questionnaireEntry"
                    class="d-print-none mb-4"
                />

            </b-tab>

            <b-tab
                :class="{ 'd-print-block': indexableQuestionnaireComments }"
                :disabled="indexableQuestionnaireComments !== true"
            >

                <template #title>

                    <font-awesome-icon
                        :icon="['fad', 'comments']"
                        fixed-width
                        class="mr-1"
                    />

                    <template v-if="indexableQuestionnaireComments">
                        {{ questionnaireEntry.$.questionnaire_comment_count }}
                        commentaire{{ questionnaireEntry.$.questionnaire_comment_count > 1 ? 's' : '' }}
                    </template>

                    <template v-else>
                        Commentaires
                    </template>

                    <font-awesome-icon
                        v-if="indexableQuestionnaireComments !== true"
                        :icon="['fad', 'lock']"
                        class="ml-2"
                        fixed-width
                    />

                </template>

                <questionnaire-comment-index
                    :questionnaire-comments="questionnaireComments"
                    :questionnaire-entry="questionnaireEntry"
                />

                <questionnaire-comment-create
                    v-if="questionnaireEntry.$.creatableQuestionnaireComments"
                    :questionnaire-entry="questionnaireEntry"
                    class="d-print-none"
                />

            </b-tab>

        </b-tabs>

    </div>

</template>



<script>

    import Collection from '@core/models/collection';
    import Dayjs from '@core/mixins/dayjs';
    import Echo from 'laravel-echo';
    import QuestionnaireAnswer from '@core/models/questionnaireAnswer';
    import QuestionnaireComment from '@core/models/questionnaireComment';
    import QuestionnaireElement from '@core/models/questionnaireElement';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';
    import QuestionnaireLock from '@core/models/questionnaireLock';
    import QuestionnaireValidation from '@core/models/questionnaireValidation';
    import Request from '@core/request/index';

    export default {
        mixins: [Dayjs],
        props: {
            noHeader: {
                type: Boolean,
                default: false,
            },
            questionnaireEntryId: {
                type: [Number, String],
                required: true,
            },
        },
        data: () => ({
            echo: undefined,
            questionnaireEntry: new QuestionnaireEntry(),
            tabIndex: 0,
        }),
        computed: {
            indexableQuestionnaireComments: (vue) => vue.questionnaireEntry.isPending !== true
                && vue.questionnaireEntry.$.questionnaireValidation.$.validation_rate === 100,
            pageTitle: (vue) => vue.questionnaireEntry.$.questionnaireSurvey.$.name,
            pdfUrl: (vue) => `${new Request().axiosConfig.baseURL}/${vue.questionnaireEntry.url}/pdf`,
            questionnaireComments: (vue) => {
                const questionnaireComments = new Collection(new QuestionnaireComment(), { id: 'asc' }, 1000);
                if (vue.questionnaireEntry.isPending) {
                    questionnaireComments.prefill(5);
                } else {
                    questionnaireComments.models = vue.questionnaireEntry.$.questionnaireComments;
                }
                return questionnaireComments;
            },
            questionnaireElements: (vue) => {
                const questionnaireElements = new Collection(new QuestionnaireElement(), { position: 'asc' }, 1000);
                if (vue.questionnaireEntry.isPending) {
                    questionnaireElements.prefill(10);
                } else {
                    questionnaireElements.models = vue.questionnaireEntry.$.questionnaireElements;
                }
                return questionnaireElements;
            },
            questionnaireFields: (vue) => vue.questionnaireEntry
                .$.questionnaireElements
                .reduce(
                    (questionnaireFields, questionnaireElement) => [
                        ...questionnaireFields,
                        ...questionnaireElement.$.questionnaireFields,
                    ],
                    [],
                ),
            questionnaireValidation: {
                get: (vue) => vue.questionnaireEntry.$.questionnaireValidation,
                set(questionnaireValidation) {
                    const oldValidationRate = this.questionnaireEntry.$.questionnaireValidation.$.validation_rate;
                    this.questionnaireEntry.$.questionnaireValidation = questionnaireValidation;
                    if (oldValidationRate !== questionnaireValidation.$.validation_rate) {
                        this.refresh();
                    }
                },
            },
        },
        watch: {
            questionnaireEntryId: {
                handler(newId, oldId) {
                    this.leaveChannel(oldId);
                    this.refresh();
                },
                immediate: true,
            },
            'questionnaireEntry.$.user_role': {
                handler(userRole) {
                    if (userRole && this.printMode !== true) {
                        this.joinChannel(this.questionnaireEntryId);
                    }
                },
                immediate: true,
            },
        },
        destroyed() {
            this.disconnect();
        },
        methods: {
            channelName(questionnaireEntryId) {
                return `questionnaireEntries.${questionnaireEntryId}.${this.$store.getters['auth/id']}`;
            },
            connect() {
                this.echo = new Echo({
                    broadcaster: 'pusher',
                    key: import.meta.env.VITE_ABLY_PUBLIC_KEY,
                    wsHost: 'realtime-pusher.ably.io',
                    wsPort: 443,
                    disableStats: true,
                    encrypted: true,
                    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
                });
            },
            disconnect() {
                if (this.echo) {
                    this.echo.disconnect();
                    this.echo = undefined;
                }
            },
            joinChannel(questionnaireEntryId) {
                if (this.echo) {
                    this.disconnect();
                }
                this.connect();
                this.echo.private(this.channelName(questionnaireEntryId))
                    .listen('.QuestionnaireAnswerEvent', ({ questionnaireAnswer }) => {
                        const questionnaireAnswerIndex = this.questionnaireEntry.$.questionnaireAnswers.findIndex(
                            (qA) => qA.$.questionnaire_field_id === questionnaireAnswer.questionnaire_field_id
                                && qA.$.role === questionnaireAnswer.role,
                        );

                        if (questionnaireAnswer.content === null) {
                            if (questionnaireAnswerIndex !== -1) {
                                this.questionnaireEntry.$.questionnaireAnswers.splice(questionnaireAnswerIndex, 1);
                            }
                            return;
                        }

                        if (questionnaireAnswerIndex === -1) {
                            this.questionnaireEntry.$.questionnaireAnswers.splice(
                                this.questionnaireEntry.$.questionnaireAnswers.length,
                                0,
                                new QuestionnaireAnswer(questionnaireAnswer),
                            );
                            return;
                        }

                        this.questionnaireEntry.$.questionnaireAnswers.splice(
                            questionnaireAnswerIndex,
                            1,
                            new QuestionnaireAnswer(questionnaireAnswer),
                        );
                    })
                    .listen('.QuestionnaireCommentEvent', ({ questionnaireComment }) => {
                        this.questionnaireEntry.$.questionnaireComments.push(
                            new QuestionnaireComment(questionnaireComment),
                        );
                        this.questionnaireEntry.$.questionnaire_comment_count += 1;
                        this.$emit(
                            'input:questionnaireEntry',
                            new QuestionnaireEntry({ ...this.questionnaireEntry.$ }),
                        );
                    })
                    .listen('.QuestionnaireLockEvent', ({ questionnaireLock }) => {
                        const questionnaireField = this.questionnaireFields
                            .find((qF) => qF.$.id === questionnaireLock.questionnaire_field_id);
                        questionnaireField.$.questionnaireLock = new QuestionnaireLock(questionnaireLock);
                    })
                    .listen('.QuestionnaireValidationEvent', ({ questionnaireValidation }) => {
                        this.questionnaireValidation = new QuestionnaireValidation(questionnaireValidation);
                    });
            },
            leaveChannel(questionnaireEntryId) {
                if (this.echo) {
                    this.echo.leave(this.channelName(questionnaireEntryId));
                }
            },
            refresh() {
                this.questionnaireEntry.fill({ id: this.questionnaireEntryId })
                    .request()
                    .show()
                    .then((questionnaireEntry) => {
                        this.$emit(
                            'input:questionnaireEntry',
                            new QuestionnaireEntry({ ...questionnaireEntry.$ }),
                        );
                    })
                    .catch(() => this.$router.push({
                        name: 'questionnaireEntry-index',
                        params: { mandatory: 1, questionnaireCategoryId: 0 },
                    }));
            },
        },
    };

</script>



<style scoped>

    hr {
        height: 3px;
    }

    .tabs :deep(.nav-link:hover) {
        border-bottom-color: inherit;
    }


</style>
