import BusinessApprenticeContract from '@core/models/businessApprenticeContract';
import BusinessPerson from '@core/models/businessPerson';
import RestModel from '@core/models/_rest';

export default class BusinessApprenticeMentorship extends RestModel {

    get url() {
        return this.$.id ? `businessApprenticeMentorships/${this.$.id}` : 'businessApprenticeMentorships';
    }

    fill({
        businessApprenticeContract = {},
        businessPerson = {},
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            businessApprenticeContract: new BusinessApprenticeContract(
                businessApprenticeContract.$
                || businessApprenticeContract,
            ),
            businessPerson: new BusinessPerson(businessPerson.$ || businessPerson),
        });
    }

    formData(attributes = {}) {
        return super.formData({
            ...attributes,
            business_apprentice_contract_id: this.$.businessApprenticeContract.$.id,
            business_person_id: this.$.businessPerson.$.id,
        });
    }

}
