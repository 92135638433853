export default {

    computed: {
        paginationPage: {
            get: (vue) => parseInt(vue.$route.query.page, 10) || undefined,
            set(page) {
                this.$router.replace({ ...this.$route, query: { ...this.$route.query, page } });
            },
        },
        paginationPrefill: () => 10,
        paginationRequestData: () => undefined,
        paginationSearch: {
            get: (vue) => vue.$route.query.search || '',
            set(search) {
                this.$router.replace({ ...this.$route, query: { ...this.$route.query, search } });
            },
        },
        paginationSort: {
            get: (vue) => vue.$route.query.sort || '',
            set(sort) {
                this.$router.replace({ ...this.$route, query: { ...this.$route.query, sort } });
            },
        },
        paginationSortColumn: (vue) => (vue.paginationSort ? vue.paginationSort.split('-')[0] : undefined),
        paginationSortDirection: (vue) => (vue.paginationSort ? vue.paginationSort.split('-')[1] : undefined),
    },

    watch: {
        paginationPage(oldValue, newValue) {
            if (oldValue !== newValue) {
                this.paginationRequest();
            }
        },
        paginationRequestData(oldValue, newValue) {
            if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
                this.paginationRequest();
            }
        },
        paginationSearch(oldValue, newValue) {
            if (oldValue !== newValue) {
                this.paginationRequest();
            }
        },
        paginationSort(oldValue, newValue) {
            if (oldValue !== newValue) {
                this.paginationRequest();
            }
        },
    },

    created() {
        this.paginationRequest();
    },

    methods: {
        paginationRequest() {
            this.paginationCollection.prefill(this.paginationPrefill);
            if (this.paginationCollection.url === undefined) {
                return;
            }
            this.paginationCollection
                .request(
                    this.paginationPage || (this.paginationCollection.perPage ? 1 : undefined),
                    this.paginationSearch || undefined,
                    this.paginationSort
                        ? { [this.paginationSortColumn]: this.paginationSortDirection }
                        : undefined,
                )
                .index(this.paginationRequestData)
                .catch((error) => {
                    if (error instanceof RangeError) {
                        this.paginationPage = error.message;
                        return true;
                    }
                    return error;
                });
        },
        paginationSortBy(column) {
            const activeColumn = this.paginationSortColumn || Object.keys(this.paginationCollection.sort)[0];
            const direction = this.paginationSortDirection || Object.values(this.paginationCollection.sort)[0];
            this.paginationSort = `${column}-${activeColumn !== column || direction === 'desc' ? 'asc' : 'desc'}`;
        },
        paginationSortIcon(column) {
            const activeColumn = this.paginationSortColumn || Object.keys(this.paginationCollection.sort)[0];
            const direction = this.paginationSortDirection || Object.values(this.paginationCollection.sort)[0];
            if (activeColumn !== column) {
                return 'sort';
            }
            if (direction === 'asc') {
                return 'sort-up';
            }
            return 'sort-down';
        },
    },

};
