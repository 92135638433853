import AcademyModel from '@core/models/academy';
import CenterModel from '@core/models/center';
/* eslint-disable-next-line import/no-cycle */
import EducationStudyModel from '@core/models/educationStudy';
import RestModel from '@core/models/_rest';
import SchoolModel from '@core/models/school';

const newJurisdiction = (type, attributes) => {
    if (type === AcademyModel.className) {
        return new AcademyModel(attributes);
    }
    if (type === CenterModel.className) {
        return new CenterModel(attributes);
    }
    if (type === EducationStudyModel.className) {
        return new EducationStudyModel(attributes);
    }
    if (type === SchoolModel.className) {
        return new SchoolModel(attributes);
    }
    return undefined;
};

export default class Capability extends RestModel {

    static get areas() {
        return {
            pedagogy: 'Pédagogie',
            business: 'Entreprise',
            accounting: 'Finance',
            management: 'Administration',
        };
    }

    static get modes() {
        return {
            [null]: 'Aucun accès',
            read: 'Consultation',
            write: 'Édition',
        };
    }

    static get modeIcons() {
        return {
            [null]: 'times',
            read: 'eye',
            write: 'edit',
        };
    }

    static get modeVariants() {
        return {
            [null]: 'danger',
            read: 'info',
            write: 'success',
        };
    }

    get url() {
        return this.$.id ? `capabilities/${this.$.id}` : 'capabilities';
    }

    fill({ jurisdiction = {}, ...attributes }) {
        return super.fill({
            ...attributes,
            jurisdiction: newJurisdiction(attributes.jurisdiction_type, jurisdiction.$ || jurisdiction),
        });
    }

}
