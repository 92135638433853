import RestModel from '@core/models/_rest';
import Upload from '@core/models/upload';

export default class QuestionnaireAnswer extends RestModel {

    get url() {
        return this.$.id ? `questionnaireAnswers/${this.$.id}` : 'questionnaireAnswers';
    }

    fill({
        content = undefined,
        id,
        ...attributes
    }) {
        return super.fill({
            ...attributes,
            content: content && content.url ? new Upload(content.$ || content) : content,
        });
    }

}
