<template>

    <span
        v-b-tooltip.noninteractive
        :title="title"
        class="text-info"
    >
        <font-awesome-icon
            :icon="['fad', 'info-circle']"
            fixed-width
        />
    </span>

</template>



<script>

    export default {
        props: {
            title: {
                type: String,
                default: 'Cette information est utilisée pour le conventionnement',
            },
        },
    };

</script>
