<template>

    <b-card
        bg-variant="light"
        border-variant="0"
        body-class="p-0"
        no-body
    >

        <b-card-header
            v-b-toggle="`questionnaire-entry-participants-${questionnaireEntry.$.id}`"
            header-bg-variant="primary"
            header-class="mb-0 pb-2"
            header-tag="h2"
            header-text-variant="white"
        >

            <b-button
                class="d-print-none float-right"
                size="sm"
                variant="primary"
            >
                <font-awesome-icon
                    :icon="['fad', 'caret-down']"
                    fixed-width
                />
                <font-awesome-icon
                    :icon="['fad', 'caret-up']"
                    fixed-width
                />
                Coordonnées
            </b-button>

            Participants

        </b-card-header>

        <b-card-body class="p-0">

            <div class="d-print-none d-lg-flex flex-wrap">
                <questionnaire-entry-participants-title
                    v-if="questionnaireEntry.$.questionnaireSurvey.$.access_learner_person !== 'none'"
                    :questionnaire-entry="questionnaireEntry"
                    :questionnaire-survey-access="questionnaireEntry.$.questionnaireSurvey.$.access_learner_person"
                    :title="questionnaireEntry.$.questionnaireSurvey.roleTitleNames.learner_person"
                    :validated-at="questionnaireEntry.$.questionnaireValidation.$.learner_person_validated_at"
                    class="m-3 media-body"
                />
                <questionnaire-entry-participants-title
                    v-if="questionnaireEntry.$.questionnaireSurvey.$.access_education_mentor !== 'none'"
                    :questionnaire-entry="questionnaireEntry"
                    :questionnaire-survey-access="questionnaireEntry.$.questionnaireSurvey.$.access_education_mentor"
                    :title="questionnaireEntry.$.questionnaireSurvey.roleTitleNames.education_mentor"
                    :validated-at="questionnaireEntry.$.questionnaireValidation.$.education_mentor_validated_at"
                    class="m-3 media-body"
                />
                <questionnaire-entry-participants-title
                    v-if="questionnaireEntry.$.questionnaireSurvey.$.access_business_mentor !== 'none'"
                    :questionnaire-entry="questionnaireEntry"
                    :questionnaire-survey-access="questionnaireEntry.$.questionnaireSurvey.$.access_business_mentor"
                    :title="questionnaireEntry.$.questionnaireSurvey.roleTitleNames.business_mentor"
                    :validated-at="questionnaireEntry.$.questionnaireValidation.$.business_mentor_validated_at"
                    class="m-3 media-body"
                />
                <questionnaire-entry-participants-title
                    v-if="questionnaireEntry.$.questionnaireSurvey.$.access_education_study !== 'none'"
                    :questionnaire-entry="questionnaireEntry"
                    :questionnaire-survey-access="questionnaireEntry.$.questionnaireSurvey.$.access_education_study"
                    :title="questionnaireEntry.$.questionnaireSurvey.roleTitleNames.education_study"
                    :validated-at="questionnaireEntry.$.questionnaireValidation.$.education_study_validated_at"
                    class="m-3 media-body"
                />
            </div>

            <b-collapse
                :id="`questionnaire-entry-participants-${questionnaireEntry.$.id}`"
                visible
            >
                <div class="d-lg-flex flex-wrap border-top border-dark">
                    <questionnaire-entry-participants-learner
                        v-if="questionnaireEntry.$.questionnaireSurvey.$.access_learner_person !== 'none'"
                        :questionnaire-entry="questionnaireEntry"
                        :class="{
                            'border-right border-bottom':
                                questionnaireEntry.$.questionnaireSurvey.$.access_education_mentor !== 'none'
                                || questionnaireEntry.$.questionnaireSurvey.$.access_business_mentor !== 'none'
                                || questionnaireEntry.$.questionnaireSurvey.$.access_education_study !== 'none',
                        }"
                        class="px-3 media-body border-dark"
                    />
                    <questionnaire-entry-participants-education
                        v-if="questionnaireEntry.$.questionnaireSurvey.$.access_education_mentor !== 'none'"
                        :questionnaire-entry="questionnaireEntry"
                        :class="{
                            'border-right border-bottom':
                                questionnaireEntry.$.questionnaireSurvey.$.access_business_mentor !== 'none'
                                || questionnaireEntry.$.questionnaireSurvey.$.access_education_study !== 'none',
                        }"
                        class="px-3 media-body border-dark"
                    />
                    <questionnaire-entry-participants-business
                        v-if="questionnaireEntry.$.questionnaireSurvey.$.access_business_mentor !== 'none'"
                        :questionnaire-entry="questionnaireEntry"
                        :class="{
                            'border-right border-bottom':
                                questionnaireEntry.$.questionnaireSurvey.$.access_education_study !== 'none',
                        }"
                        class="px-3 media-body border-dark"
                    />
                    <questionnaire-entry-participants-education-study
                        v-if="questionnaireEntry.$.questionnaireSurvey.$.access_education_study !== 'none'"
                        :questionnaire-entry="questionnaireEntry"
                        class="px-3 media-body"
                    />
                </div>
            </b-collapse>

        </b-card-body>

    </b-card>

</template>



<script>

    import QuestionnaireEntry from '@core/models/questionnaireEntry';

    export default {
        props: {
            questionnaireEntry: {
                type: QuestionnaireEntry,
                required: true,
            },
        },
    };

</script>



<style scoped>

    .not-collapsed .fa-caret-down,
    .collapsed .fa-caret-up {
        display: none;
    }

    @media (min-width: 992px) {
        .border-bottom {
            border-bottom: none !important;
        }
    }

    @media (max-width: 991px) {
        .border-right {
            border-right: none !important;
        }
    }

</style>
