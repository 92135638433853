<template>

    <b-row class="m-0 flex-fill">

        <b-col
            class="py-4 d-print-none bg-grey-darker"
            cols="12"
            lg="4"
        >

            <div class="d-lg-none text-center">
                <b-button
                    :pressed.sync="showQuestionnaireEntryTimeline"
                    variant="outline-primary"
                >
                    <font-awesome-icon
                        :icon="['fad', 'folder-open']"
                        fixed-width
                    />
                    Parcourir le livret
                </b-button>
            </div>

            <b-collapse
                id="questionnaireEntry-timeline"
                v-model="showQuestionnaireEntryTimeline"
                class="position-sticky d-lg-block"
            >

                <b-skeleton
                    v-if="questionnaireCategory && questionnaireCategory.isPending"
                    class="w-75 py-3"
                    variant="dark"
                />

                <h1 v-else>

                    {{ pageTitle }}

                    <div
                        v-if="questionnaireCategory"
                        class="small text-muted"
                    >
                        {{ questionnaireCategory.$.name }}
                    </div>

                </h1>

                <label class="d-flex align-items-top">

                    Afficher uniquement<br>
                    les formulaires non validés.

                    <b-form-checkbox
                        v-model="withoutValidated"
                        class="ml-2 d-inline align-self-center"
                        switch
                    />

                </label>

                <hr class="bg-dark mb-2">

                <questionnaire-entry-timeline
                    :learner-person-id="learnerPersonId"
                    :mandatory="mandatory"
                    :questionnaire-category="questionnaireCategory"
                    :route-name-prefix="routeNamePrefix"
                    :updated-questionnaire-entry="updatedQuestionnaireEntry"
                    :without-validated.sync="withoutValidated"
                    @input:firstQuestionnaireEntry="
                        $route.params.questionnaireEntryId === undefined
                            ? $router.push({
                                name: `${routeNamePrefix}questionnaireEntry-show`,
                                params: { questionnaireEntryId: $event.$.id },
                            })
                            : undefined
                    "
                />

            </b-collapse>

        </b-col>

        <b-col
            class="py-4"
            cols="12"
            lg="8"
        >

            <b-alert
                :show="!$route.params.questionnaireEntryId"
                class="d-none d-lg-block"
                variant="info"
            >
                <font-awesome-icon
                    :icon="['fad', 'info']"
                    fixed-width
                />
                Sélectionnez un formulaire dans la liste ci-contre pour le consulter.
            </b-alert>

            <router-view @input:questionnaireEntry="updatedQuestionnaireEntry = $event" />

        </b-col>

    </b-row>

</template>



<script>

    import QuestionnaireCategory from '@core/models/questionnaireCategory';
    import QuestionnaireSurvey from '@core/models/questionnaireSurvey';

    export default {
        props: {
            learnerPersonId: {
                type: Number,
                required: true,
            },
            mandatory: {
                type: Boolean,
                default: undefined,
            },
            questionnaireCategoryId: {
                type: Number,
                default: 0,
            },
            routeNamePrefix: {
                type: String,
                default: '',
            },
        },
        data: () => ({
            questionnaireCategory: null,
            showQuestionnaireEntryTimeline: false,
            updatedQuestionnaireEntry: undefined,
            withoutValidated: false,
        }),
        computed: {
            mandatoryNames: () => QuestionnaireSurvey.mandatoryNames,
            pageTitle: (vue) => (
                vue.mandatory === undefined ? "Livret de l'apprenant" : vue.mandatoryNames[vue.mandatory ? 1 : 0]
            ),
        },
        watch: {
            '$route.params.questionnaireEntryId': {
                handler() {
                    this.showQuestionnaireEntryTimeline = !this.$route.params.questionnaireEntryId
                        && document.documentElement.clientWidth < 768;
                },
                immediate: true,
            },
            questionnaireCategoryId: {
                handler(id) {
                    if (id === 0) {
                        this.questionnaireCategory = null;
                    } else {
                        this.questionnaireCategory = new QuestionnaireCategory({ id });
                        this.questionnaireCategory.request()
                            .show()
                            .catch(() => this.$router.push({
                                name: 'questionnaireEntry-index',
                                params: { mandatory: this.mandatory, questionnaireCategoryId: 0 },
                            }));
                    }
                },
                immediate: true,
            },
        },
    };

</script>



<style scoped>

    @media (min-width: 768px) {
        .position-sticky {
            top: 1rem;
            max-height: 100vh;
            overflow-y: auto;
        }
    }

    @media print {
        .row > * {
            flex: none;
            max-width: 100%;
        }
    }

    .row {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .bg-grey-darker {
        background: #e2e4e5;
    }

    hr {
        height: 3px;
    }

</style>
