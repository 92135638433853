<template>

    <layout-plain-x-template v-if="guest && xTemplate">
        <transition
            :enter-active-class="enterTransition"
            :leave-active-class="leaveTransition"
            mode="out-in"
            appear
        >
            <router-view />
        </transition>
    </layout-plain-x-template>

    <b-container
        v-else-if="guest"
        class="d-flex min-vh-100 py-4 flex-column align-items-center justify-content-center"
    >

        <h1 class="mb-5 text-center">

            <b-img
                v-if="upload"
                :alt="title"
                :src="upload.$.url"
                fluid
            />

            <template v-else>
                {{ title }}
            </template>

        </h1>

        <transition
            :enter-active-class="enterTransition"
            :leave-active-class="leaveTransition"
            mode="out-in"
            appear
        >
            <router-view />
        </transition>

    </b-container>

    <div
        v-else
        class="page-loader"
    >
        <div />
    </div>

</template>



<script>

    import User from '@core/models/auth/user';

    export default {
        data: () => ({
            enterTransition: 'fadeIn',
            guest: false,
            leaveTransition: 'fadeOut',
        }),
        computed: {
            title: (vue) => vue.$store.getters['center/platformName'],
            upload: (vue) => vue.$store.getters['center/platformLogoMd'],
            xTemplate: () => document.querySelector('#layout-plain[type="text/x-template"]'),
        },
        watch: {
            $route(to) {
                this.enterTransition = to.name === 'login-form' ? 'fadeInLeft' : 'fadeInRight';
                this.leaveTransition = to.name === 'login-form' ? 'fadeOutRight' : 'fadeOutLeft';
            },
        },
        created() {
            new User()
                .request()
                .silent()
                .show()
                .then(() => this.$router.push(this.$route.query.redirect || '/'))
                .catch(() => { this.guest = true; });
        },
    };

</script>
