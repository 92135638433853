<template>

    <p
        v-if="variant.variant === 'none'"
        :id="`questionnaireElement-${questionnaireElement.$.id}`"
        style="white-space: pre-line"
    >
        {{ questionnaireElement.$.attributes.text }}
    </p>

    <b-alert
        v-else
        :id="`questionnaireElement-${questionnaireElement.$.id}`"
        :variant="variant.variant"
        style="white-space: pre-line"
        show
    >
        <font-awesome-icon
            :icon="['fad', variant.icon]"
            class="pr-2"
            fixed-width
        />
        {{ questionnaireElement.$.attributes.text }}
    </b-alert>

</template>



<script>

    import QuestionnaireElement from '@core/models/questionnaireElement';

    export default {
        props: {
            questionnaireElement: {
                type: QuestionnaireElement,
                required: true,
            },
        },
        computed: {
            variant: (vue) => QuestionnaireElement.variants.find(
                (variant) => variant.variant === vue.questionnaireElement.$.attributes.variant,
            ) || {},
        },
    };

</script>
