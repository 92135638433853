<script>

    import SfpContract from '@core/models/sfpContract';

    export default {
        props: {
            sfpContract: {
                type: SfpContract,
                required: true,
            },
        },
        computed: {
            legends: () => ({
                dark: 'Non disponible',
                info: 'En cours',
                success: 'Terminé',
            }),
            sfpContractStatusIndex: (vue) => Object.keys(SfpContract.statusNames).indexOf(vue.sfpContract.$.status),
        },
        render() {
            return this.$scopedSlots.default({
                statuses: Object.keys(SfpContract.statusNames).map((status) => {
                    const statusIndex = Object.keys(SfpContract.statusNames).indexOf(status);
                    let variant = 'info';

                    if (this.sfpContractStatusIndex > statusIndex) {
                        variant = 'success';
                    }

                    if (this.sfpContractStatusIndex < statusIndex) {
                        variant = 'dark';
                    }

                    return {
                        name: SfpContract.statusNames[status],
                        icon: SfpContract.statusIcons[status],
                        legend: this.legends[variant],
                        status,
                        variant,
                    };
                }),
            });
        },
    };

</script>
