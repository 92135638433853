<template>

    <p v-if="pending">
        <b-skeleton class="w-75" />
        <b-skeleton class="w-50" />
    </p>

    <p
        v-else-if="address.isEmpty"
        class="text-muted"
    >
        Adresse non renseignée
    </p>

    <p
        v-else
        class="m-0"
    >

        <template v-if="address.$.street_name">

            <template v-if="address.$.street_number">
                {{ address.$.street_number }}
            </template>

            {{ address.$.street_name }}<br>

        </template>

        <template v-if="address.$.building">
            {{ address.$.building }}<br>
        </template>

        <template v-if="address.$.appartment">
            {{ address.$.appartment }}<br>
        </template>

        <template v-if="address.$.locality">
            {{ address.$.locality }}<br>
        </template>

        <template v-if="address.$.city">

            <template v-if="address.$.post_code">
                {{ address.$.post_code }}
            </template>

            {{ address.$.city }}<br>

        </template>

        <template v-if="address.$.country !== 'France'">
            {{ address.$.country }}<br>
        </template>

        <template v-if="address.$.mobile_phone">

            <font-awesome-icon
                :icon="['fad', 'mobile']"
                fixed-width
            />

            <a :href="`tel:${address.$.mobile_phone}`">
                {{ address.$.mobile_phone }}
            </a>

        </template>

        <template v-if="address.$.landline_phone">

            <font-awesome-icon
                :icon="['fad', 'phone-office']"
                fixed-width
            />

            <a :href="`tel:${address.$.landline_phone}`">
                {{ address.$.landline_phone }}
            </a>

        </template>

    </p>

</template>



<script>

    import Address from '@core/models/address';

    export default {
        props: {
            address: {
                type: Address,
                required: true,
            },
            pending: {
                type: Boolean,
                default: false,
            },
        },
    };

</script>
