<template>

    <form-group
        :id="`questionnaireField-${questionnaireField.$.id}`"
        :error="value.errors.content"
        :label="questionnaireField.$.name"
        :required="questionnaireField.$.mandatory"
        :state="value.states.content"
        label-class="pb-0"
    >

        <template #top>
            <div class="small text-muted pb-2">
                <div v-if="questionnaireField.$.description">
                    {{ questionnaireField.$.description }}.
                </div>
            </div>
        </template>

        <template #default="inputProps">
            <b-form-radio-group
                v-if="questionnaireField.$.type === 'unique_choice'"
                v-bind="inputProps"
                v-model="content"
                :disabled="disabled || value.isPending"
                :options="optionOptions"
                stacked
            />
            <b-form-checkbox-group
                v-else-if="questionnaireField.$.type === 'multiple_choices'"
                v-bind="inputProps"
                v-model="content"
                :disabled="disabled || value.isPending"
                :options="optionOptions"
                stacked
            />
        </template>

        <template #description>
            <slot name="description" />
        </template>

    </form-group>

</template>



<script>

    import QuestionnaireAnswer from '@core/models/questionnaireAnswer';
    import QuestionnaireField from '@core/models/questionnaireField';

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            questionnaireField: {
                type: QuestionnaireField,
                required: true,
            },
            value: {
                type: QuestionnaireAnswer,
                required: true,
            },
        },
        computed: {
            content: {
                get: (vue) => {
                    if (vue.value.$.content !== undefined && vue.value.$.content !== null) {
                        return vue.value.$.content;
                    }
                    if (vue.questionnaireField.$.type === 'multiple_choices') {
                        return [];
                    }
                    return '';
                },
                set(content) {
                    if (content !== this.content) {
                        this.$emit('input', new QuestionnaireAnswer({ ...this.value.$, content }));
                    }
                },
            },
            optionOptions: (vue) => Object.entries(vue.questionnaireField.$.attributes.options).map(
                ([value, text]) => ({ text, value }),
            ),
        },
    };

</script>
