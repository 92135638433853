<template>

    <div>

        <b-alert
            :show="educationStudyPeople.length === 0"
            class="my-3"
            variant="warning"
        >
            <font-awesome-icon
                :icon="['fad', 'exclamation']"
                fixed-width
            />
            Aucun responsable de formation n'a été défini.
        </b-alert>

        <div
            v-for="(educationPerson, key) in educationStudyPeople"
            :key="key"
            class="py-3"
        >

            <div class="d-flex align-items-center mb-2">

                <b-skeleton-img
                    v-if="questionnaireEntry.isPending"
                    height="50px"
                    width="50px"
                    no-aspect
                />

                <upload-avatar
                    v-else
                    :upload="educationPerson.$.user ? educationPerson.$.user.$.avatar : null"
                    icon="chalkboard-teacher"
                    width="50px"
                />

                <h3 class="flex-fill mb-0 ml-2">

                    <b-skeleton
                        v-if="questionnaireEntry.isPending"
                        class="w-75"
                        variant="dark"
                    />

                    <div v-else>
                        {{ educationPerson.$.fullname }}
                    </div>

                    <small class="d-block text-muted">
                        {{ titles[key] }}
                    </small>

                </h3>

                <button-vcard
                    :email="educationPerson.$.email"
                    :firstname="educationPerson.$.firstname"
                    :is-pending="questionnaireEntry.isPending"
                    :lastname="educationPerson.$.lastname"
                    :organization="educationPerson.$.school.$.name"
                    :phones="{
                        CELL: educationPerson.$.mobile_phone,
                        MAIN: educationPerson.$.direct_phone,
                        WORK: educationPerson.$.reception_phone,
                    }"
                    :title="titles[key]"
                />

            </div>

            <div v-if="questionnaireEntry.isPending">
                <b-skeleton class="w-75" />
                <b-skeleton class="w-50" />
                <b-skeleton class="w-50" />
                <b-skeleton class="w-50" />
            </div>

            <div v-else>

                <div class="mb-2">
                    <p class="my-0 font-weight-bold">
                        {{ educationPerson.$.school.$.name }}
                    </p>
                </div>

                <div v-if="educationPerson.$.email">

                    <font-awesome-icon
                        :icon="['fad', 'envelope']"
                        fixed-width
                    />

                    <a
                        :href="`mailto:${educationPerson.$.email}`"
                        class="font-weight-bold"
                    >
                        {{ educationPerson.$.email }}
                    </a>
                </div>


                <div v-if="educationPerson.$.direct_phone">

                    <font-awesome-icon
                        :icon="['fad', 'phone']"
                        fixed-width
                    />

                    <a :href="`tel:${educationPerson.$.direct_phone}`">
                        {{ educationPerson.$.direct_phone }}
                    </a>

                </div>

                <div v-if="educationPerson.$.mobile_phone">

                    <font-awesome-icon
                        :icon="['fad', 'mobile']"
                        fixed-width
                    />

                    <a :href="`tel:${educationPerson.$.mobile_phone}`">
                        {{ educationPerson.$.mobile_phone }}
                    </a>
                </div>

                <div v-if="educationPerson.$.reception_phone">
                    <font-awesome-icon
                        :icon="['fad', 'phone-office']"
                        fixed-width
                    />

                    <a :href="`tel:${educationPerson.$.reception_phone}`">
                        {{ educationPerson.$.reception_phone }}
                    </a>

                </div>
            </div>

        </div>

    </div>

</template>



<script>

    import EducationPerson from '@core/models/educationPerson';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';

    export default {
        props: {
            questionnaireEntry: {
                type: QuestionnaireEntry,
                required: true,
            },
        },
        computed: {
            educationStudyPeople: (vue) => (
                vue.questionnaireEntry.isPending
                    ? {
                        educationPersonAdministrative1: new EducationPerson(),
                        educationPersonPedagogy1: new EducationPerson(),
                    }
                    : vue.questionnaireEntry.$.educationStudyPeople
            ),
            titles: () => ({
                educationPersonAdministrative1: 'Contact administratif',
                educationPersonAdministrative2: 'Contact administratif',
                educationPersonAdministrative3: 'Contact administratif',
                educationPersonPedagogy1: 'Contact pédagogique',
                educationPersonPedagogy2: 'Contact pédagogique',
                educationPersonPedagogy3: 'Contact pédagogique',
            }),
        },
    };

</script>
