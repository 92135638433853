<template>

    <div class="d-flex">

        <div class="d-flex flex-column justify-content-center align-items-center mr-2">

            <div
                :class="[`border-${isPending ? 'secondary' : variant}`, { invisible: first }]"
                class="h-100 border"
            />

            <div v-if="isPending">
                <b-skeleton
                    size="62px"
                    type="avatar"
                    variant="secondary"
                />
            </div>

            <div
                v-else
                :class="`bg-${variant}`"
                class="p-3 rounded-circle text-white"
            >
                <font-awesome-icon
                    :icon="['fad', questionnaireEntry.icon()]"
                    size="lg"
                    fixed-width
                />
            </div>

            <div
                :class="[`border-${isPending ? 'secondary' : variant}`, { invisible: last }]"
                class="h-100 border"
            />

        </div>

        <div class="flex-fill my-2">

            <b-skeleton
                v-if="isPending"
                class="w-100 py-4"
                type="button"
                variant="secondary"
            />

            <div v-else>
                <b-card
                    body-class="p-2 border-top"
                    class="text-left"
                    header-class="m-0 p-0 rounded-top"
                >

                    <template #header>
                        <b-button
                            :to="{
                                name: `${routeNamePrefix}questionnaireEntry-show`,
                                params: { questionnaireEntryId: questionnaireEntry.$.id },
                            }"
                            :variant="`outline-${variant}`"
                            class="text-left border-0"
                            block
                        >

                            <div
                                :class="{ 'text-body': active !== true && questionnaireEntry.isOpen }"
                                class="font-weight-bold"
                            >
                                {{ questionnaireEntry.$.questionnaireSurvey.$.name }}
                            </div>

                            <div>
                                {{ action }}
                            </div>

                            <div class="font-weight-bold">
                                Échéance le {{ dayjsShortDate(questionnaireEntry.$.questionnaireSurvey.$.end) }}
                            </div>

                        </b-button>
                    </template>

                    <div
                        v-b-toggle="`questionnaire-entry-timeline-entry-${questionnaireEntry.$.id}`"
                        class="d-flex align-items-center justify-content-between"
                    >

                        <div>

                            <b>
                                Statuts:
                            </b>

                            <span
                                v-if="questionnaireEntry.$.questionnaireSurvey.$.access_learner_person !== 'none'"
                                v-b-tooltip.noninteractive
                                :class="
                                    `text-${
                                        questionnaireEntry.variant(
                                            questionnaireEntry.$.questionnaireSurvey.$.access_learner_person,
                                            questionnaireEntry.$.questionnaireValidation.$.learner_person_validated_at,
                                        )
                                    }`
                                "
                                :title="questionnaireEntry.$.questionnaireSurvey.roleTitleNames.learner_person"
                                class="mx-1"
                            >
                                <font-awesome-icon
                                    :icon="['fad', roleIcons.learner_person]"
                                    fixed-width
                                />
                            </span>

                            <span
                                v-if="questionnaireEntry.$.questionnaireSurvey.$.access_education_mentor !== 'none'"
                                v-b-tooltip.noninteractive
                                :class="
                                    `text-${
                                        questionnaireEntry.variant(
                                            questionnaireEntry.$.questionnaireSurvey.$.access_education_mentor,
                                            questionnaireEntry
                                                .$.questionnaireValidation
                                                .$.education_mentor_validated_at,
                                        )
                                    }`
                                "
                                :title="questionnaireEntry.$.questionnaireSurvey.roleTitleNames.education_mentor"
                                class="mx-1"
                            >
                                <font-awesome-icon
                                    :icon="['fad', roleIcons.education_mentor]"
                                    fixed-width
                                />
                            </span>

                            <span
                                v-if="questionnaireEntry.$.questionnaireSurvey.$.access_business_mentor !== 'none'"
                                v-b-tooltip.noninteractive
                                :class="
                                    `text-${
                                        questionnaireEntry.variant(
                                            questionnaireEntry.$.questionnaireSurvey.$.access_business_mentor,
                                            questionnaireEntry.$.questionnaireValidation.$.business_mentor_validated_at,
                                        )
                                    }`
                                "
                                :title="questionnaireEntry.$.questionnaireSurvey.roleTitleNames.business_mentor"
                                class="mx-1"
                            >
                                <font-awesome-icon
                                    :icon="['fad', roleIcons.business_mentor]"
                                    fixed-width
                                />
                            </span>

                            <span
                                v-if="questionnaireEntry.$.questionnaireSurvey.$.access_education_study !== 'none'"
                                v-b-tooltip.noninteractive
                                :class="
                                    `text-${
                                        questionnaireEntry.variant(
                                            questionnaireEntry.$.questionnaireSurvey.$.access_education_study,
                                            questionnaireEntry.$.questionnaireValidation.$.education_study_validated_at,
                                        )
                                    }`
                                "
                                :title="questionnaireEntry.$.questionnaireSurvey.roleTitleNames.education_study"
                                class="mx-1"
                            >
                                <font-awesome-icon
                                    :icon="['fad', roleIcons.education_study]"
                                    fixed-width
                                />
                            </span>

                            <span
                                v-if="indexableQuestionnaireComments"
                                v-b-tooltip.noninteractive
                                :class="
                                    questionnaireEntry.$.questionnaire_comment_count ? 'text-success' : 'text-muted'
                                "
                                class="mx-1"
                                title="Commentaires"
                            >
                                <font-awesome-icon
                                    :icon="['fad', 'comments']"
                                    fixed-width
                                />
                            </span>

                        </div>

                        <font-awesome-icon
                            class="float-right"
                            :icon="['fad', 'caret-down']"
                            fixed-width
                        />

                        <font-awesome-icon
                            class="float-right"
                            :icon="['fad', 'caret-up']"
                            fixed-width
                        />

                    </div>

                    <b-collapse :id="`questionnaire-entry-timeline-entry-${questionnaireEntry.$.id}`">
                        <div class="d-flex flex-column">

                            <div
                                v-if="questionnaireEntry.$.questionnaireSurvey.$.access_learner_person !== 'none'"
                                :class="
                                    `text-${
                                        questionnaireEntry.variant(
                                            questionnaireEntry.$.questionnaireSurvey.$.access_learner_person,
                                            questionnaireEntry.$.questionnaireValidation.$.learner_person_validated_at,
                                        )
                                    }`
                                "
                                class="my-1"
                            >

                                <font-awesome-icon
                                    :icon="['fad', roleIcons.learner_person]"
                                    fixed-width
                                />

                                {{ questionnaireEntry.$.questionnaireSurvey.roleTitleNames.learner_person }} :<br>

                                <b>
                                    {{ accessNames[questionnaireEntry.$.questionnaireSurvey.$.access_learner_person] }}
                                </b>

                            </div>

                            <div
                                v-if="questionnaireEntry.$.questionnaireSurvey.$.access_education_mentor !== 'none'"
                                :class="
                                    `text-${
                                        questionnaireEntry.variant(
                                            questionnaireEntry.$.questionnaireSurvey.$.access_education_mentor,
                                            questionnaireEntry
                                                .$.questionnaireValidation
                                                .$.education_mentor_validated_at,
                                        )
                                    }`
                                "
                                class="my-1"
                            >

                                <font-awesome-icon
                                    class="ml-1"
                                    :icon="['fad', roleIcons.education_mentor]"
                                />

                                {{ questionnaireEntry.$.questionnaireSurvey.roleTitleNames.education_mentor }} :<br>

                                <b>
                                    {{
                                        accessNames[questionnaireEntry.$.questionnaireSurvey.$.access_education_mentor]
                                    }}
                                </b>

                            </div>

                            <div
                                v-if="questionnaireEntry.$.questionnaireSurvey.$.access_business_mentor !== 'none'"
                                :class="
                                    `text-${
                                        questionnaireEntry.variant(
                                            questionnaireEntry.$.questionnaireSurvey.$.access_business_mentor,
                                            questionnaireEntry.$.questionnaireValidation.$.business_mentor_validated_at,
                                        )
                                    }`
                                "
                                class="my-1"
                            >

                                <font-awesome-icon
                                    class="ml-1"
                                    :icon="['fad', roleIcons.business_mentor]"
                                />

                                {{ questionnaireEntry.$.questionnaireSurvey.roleTitleNames.business_mentor }} :<br>

                                <b>
                                    {{ accessNames[questionnaireEntry.$.questionnaireSurvey.$.access_business_mentor] }}
                                </b>

                            </div>

                            <div
                                v-if="questionnaireEntry.$.questionnaireSurvey.$.access_education_study !== 'none'"
                                :class="
                                    `text-${
                                        questionnaireEntry.variant(
                                            questionnaireEntry.$.questionnaireSurvey.$.access_education_study,
                                            questionnaireEntry.$.questionnaireValidation.$.education_study_validated_at,
                                        )
                                    }`
                                "
                                class="my-1"
                            >

                                <font-awesome-icon
                                    class="ml-1"
                                    :icon="['fad', roleIcons.education_study]"
                                />

                                {{ questionnaireEntry.$.questionnaireSurvey.roleTitleNames.education_study }} :<br>

                                <b>
                                    {{ accessNames[questionnaireEntry.$.questionnaireSurvey.$.access_education_study] }}
                                </b>

                            </div>

                            <div
                                v-if="indexableQuestionnaireComments"
                                :class="{ 'text-muted': !indexableQuestionnaireComments }"
                                class="my-1"
                            >

                                <font-awesome-icon
                                    class="ml-1"
                                    :icon="['fad', 'comments']"
                                />

                                Commentaires :<br>

                                <b v-if="questionnaireEntry.$.questionnaire_comment_count > 1">
                                    {{ questionnaireEntry.$.questionnaire_comment_count }} commentaires
                                </b>

                                <b v-else-if="questionnaireEntry.$.questionnaire_comment_count > 0">
                                    1 commentaire
                                </b>

                                <b v-else>
                                    Aucun commentaire
                                </b>

                            </div>

                        </div>
                    </b-collapse>

                </b-card>
            </div>

        </div>

    </div>

</template>



<script>

    import Dayjs from '@core/mixins/dayjs';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';
    import QuestionnaireSurvey from '@core/models/questionnaireSurvey';

    export default {
        mixins: [Dayjs],
        props: {
            first: {
                type: Boolean,
                default: false,
            },
            isPending: {
                type: Boolean,
                required: true,
            },
            last: {
                type: Boolean,
                default: false,
            },
            questionnaireEntry: {
                type: QuestionnaireEntry,
                required: true,
            },
            routeNamePrefix: {
                type: String,
                default: '',
            },
        },
        computed: {
            accessNames: () => QuestionnaireSurvey.accessNames,
            active: (vue) => vue.$route.params.questionnaireEntryId === vue.questionnaireEntry.$.id,
            action: (vue) => vue.questionnaireEntry.action(
                vue.questionnaireEntry.$.questionnaireSurvey.$[`access_${vue.questionnaireEntry.$.user_role}`],
                vue.questionnaireEntry.$.questionnaireValidation.$[
                    `${vue.questionnaireEntry.$.user_role}_validated_at`
                ],
            ),
            indexableQuestionnaireComments: (vue) => vue.questionnaireEntry.$.creatableQuestionnaireComments
                || vue.questionnaireEntry.$.questionnaire_comment_count > 0,
            roleIcons: () => QuestionnaireSurvey.roleIcons,
            variant: (vue) => vue.questionnaireEntry.variant(
                vue.questionnaireEntry.$.questionnaireSurvey.$[`access_${vue.questionnaireEntry.$.user_role}`],
                vue.questionnaireEntry.$.questionnaireValidation.$[
                    `${vue.questionnaireEntry.$.user_role}_validated_at`
                ],
            ),
        },
    };

</script>



<style scoped>

    .not-collapsed > .fa-caret-down,
    .collapsed > .fa-caret-up {
        display: none;
    }

    .btn {
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }

</style>
